import {axiosInstance} from '@/api';
import {
  Brick,
  BrickCreatedVmUpdate,
  BrickType,
  BrickUpdatedVmUpdate,
  CreateBrickEvent,
  CreateTermDefinitionEvent,
  TermDefinition,
  TermDefinitionCreatedVmUpdate,
  TermDefinitionUpdatedVmUpdate,
  UpdateBrickEvent,
  UpdateTermDefinitionEvent
} from '@/api/models/library.model';

const LIBRARY_PATH = '/library';
const LIBRARY_BRICK_PATH = LIBRARY_PATH + '/brick';
const LIBRARY_TERM_DEFINITION_PATH = LIBRARY_PATH + '/term';
const LIBRARY_BRICK_SEARCH_PATH = LIBRARY_BRICK_PATH + '/search';
const LIBRARY_TERM_DEFINITION_SEARCH_PATH = LIBRARY_TERM_DEFINITION_PATH + '/search';

export const CreateBrick = async (event: CreateBrickEvent): Promise<Brick> => {
    const res = await axiosInstance.post(LIBRARY_BRICK_PATH, event);
    return (res?.data as BrickCreatedVmUpdate).brick;
};

export const CreateTermDefinition = async (event: CreateTermDefinitionEvent): Promise<TermDefinition> => {
    const res = await axiosInstance.post(LIBRARY_TERM_DEFINITION_PATH, event);
    return (res?.data as TermDefinitionCreatedVmUpdate).termDefinition;
};

export const UpdateBrick = async (event: UpdateBrickEvent): Promise<Brick> => {
    const res = await axiosInstance.put(LIBRARY_BRICK_PATH, event);
    return (res?.data as BrickUpdatedVmUpdate).brick;
};

export const UpdateTermDefinition = async (event: UpdateTermDefinitionEvent): Promise<TermDefinition> => {
    const res = await axiosInstance.put(LIBRARY_TERM_DEFINITION_PATH, event);
    return (res?.data as TermDefinitionUpdatedVmUpdate).termDefinition;
};

export const GetBrick = async (guid: string): Promise<Brick> => {
    const res = await axiosInstance.get(LIBRARY_BRICK_PATH + '/' + guid);
    return res?.data as Brick;
};
export const SearchBricks = async (brickType: BrickType, searchTerms?: string[]): Promise<Brick[]> => {
    const res = await axiosInstance.post(LIBRARY_BRICK_SEARCH_PATH + '/'  + brickType, searchTerms);
    return res?.data as Brick[];
};

export const GetTermDefinition = async (guid: string): Promise<TermDefinition> => {
    const res = await axiosInstance.get(LIBRARY_TERM_DEFINITION_PATH + '/' + guid);
    return res?.data as TermDefinition;
};
export const SearchTermDefinitions = async (searchTerms?: string[]): Promise<TermDefinition[]> => {
    const res = await axiosInstance.post(LIBRARY_TERM_DEFINITION_SEARCH_PATH, searchTerms);
    return res?.data as TermDefinition[];
};

export const DeleteBrick = async (guid: string): Promise<void> => {
    await axiosInstance.delete(`${LIBRARY_BRICK_PATH}/${guid}`);
};

export const DeleteTermDefinition = async (guid: string): Promise<void> => {
    await axiosInstance.delete(`${LIBRARY_TERM_DEFINITION_PATH}/${guid}`);
};
