import PermissionModule from '@/store/modules/PermissionModule';
import router from '@/router';
import {SplashScreenDialog as SplashScreenDialogClass} from '@/components/SplashScreenDialog.vue';
import {NavigationFailure} from 'vue-router';

function timeout(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Opens the splash screen and routes to the given path.
 *
 * @param path
 * @return Promise with the Route object
 */
export async function routeTo(path: string): Promise<NavigationFailure | void | undefined> {
  const splashScreen: SplashScreenDialogClass | null = PermissionModule.splashScreen;
  if (splashScreen) {
    splashScreen.open();
    // Delay the routing, so we won't see the target page flickering before the splash screen is visible
    await timeout(100);
  }
  return router.push({path});
}
