import {axiosBlobInstance, axiosInstance} from '@/api';
import {ApplicationTemplate} from '@/api/models/applicationTemplate.model';

const APPLICATION_TEMPLATE_PATH = '/template';
const LOGO_PATH = '/template/logo';

export const FetchAllApplicationTemplate = async (): Promise<ApplicationTemplate[]> => {
  const res = await axiosInstance.get(`${APPLICATION_TEMPLATE_PATH}`);
  return res?.data as ApplicationTemplate[];
};

export const FetchLogoImage = async (guid: string): Promise<BinaryType> => {
  const path = `${LOGO_PATH}/${guid}`;
  const res = await axiosBlobInstance.get(path);
  return res?.data as BinaryType;
};