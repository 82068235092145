import {UpdateTwoDocumentEditorSplitModeRequest} from '@/api/models/userprofile.model';
import {
  DocumentEditorSplitMode,
  DocumentEditorSplitState,
  SingleDocumentEditorSplitState,
  TwoDocumentEditorSplitState,
  TwoDocumentEditorsSplitMode
} from '@/api/models/editor.model';
import {axiosInstance, cancelToken, queuedAxiosInstance} from '@/api';
import {CancelTokenSource} from 'axios';

export const USER_PROFILE_PATH = '/userprofile';


export const GetActiveDocumentEditorSplitState = async (userId: string): Promise<DocumentEditorSplitState> => {
  const res = await queuedAxiosInstance.get(`${USER_PROFILE_PATH}/${userId}/documentEditorSplitState`);
  return res?.data;
}

export const GetDocumentEditorSplitStateForOneEditor = async (userId: string): Promise<SingleDocumentEditorSplitState> => {
  const res = await queuedAxiosInstance.get(`${USER_PROFILE_PATH}/${userId}/documentEditorSplitState/ONE`);
  return res?.data;
}

export const GetDocumentEditorSplitStateForTwoEditors = async (userId: string, split: TwoDocumentEditorsSplitMode): Promise<TwoDocumentEditorSplitState> => {
  const res = await queuedAxiosInstance.get(`${USER_PROFILE_PATH}/${userId}/documentEditorSplitState/TWO/${split}`);
  return res?.data;
}

export interface ToggleDocumentEditorSplitStateParam {
  userId: string;
  mode: DocumentEditorSplitMode;
  split: TwoDocumentEditorsSplitMode | undefined;
}

let toggleDocumentEditorSplitStateStateCancelToken: CancelTokenSource | null = null;

export const ToggleDocumentEditorSplitState = async (param: ToggleDocumentEditorSplitStateParam):  Promise<DocumentEditorSplitState> => {
  const url = () => {
    switch (param.mode){
      case DocumentEditorSplitMode.ONE:
        return `${USER_PROFILE_PATH}/${param.userId}/documentEditorSplitState/toggle?mode=${param.mode}`;
      case DocumentEditorSplitMode.TWO:
        return `${USER_PROFILE_PATH}/${param.userId}/documentEditorSplitState/toggle?mode=${param.mode}&split=${param.split}`;
    }
  }

  toggleDocumentEditorSplitStateStateCancelToken?.cancel("Cancel old request before starting new toggleDocumentEditorSplitStateStateCancelToken.")
  toggleDocumentEditorSplitStateStateCancelToken = cancelToken.source();
  const res = await axiosInstance.put(url(),
                                                   undefined,
                                                  {  cancelToken: toggleDocumentEditorSplitStateStateCancelToken.token});
  return res?.data;
}

export interface UpdateDocumentEditorSplitStateForTwoEditorsParam {
  userId: string;
  splitState: UpdateTwoDocumentEditorSplitModeRequest;
}

let updateDocumentEditorSplitStateStateCancelToken: CancelTokenSource | null = null;

export const UpdateDocumentEditorSplitStateForTwoEditors = async (userId: string, request: UpdateTwoDocumentEditorSplitModeRequest): Promise<TwoDocumentEditorSplitState> => {
  updateDocumentEditorSplitStateStateCancelToken?.cancel("Cancel old request before starting new PutUpdateDocumentEditorSplitStateStateCancelToken.")
  updateDocumentEditorSplitStateStateCancelToken = cancelToken.source();
  const res = await axiosInstance.put(`${USER_PROFILE_PATH}/${userId}/documentEditorSplitState/TWO`,
                                      request,
                                      {  cancelToken: updateDocumentEditorSplitStateStateCancelToken.token});
  return res?.data;
}
