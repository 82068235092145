import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-aee41630"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  contenteditable: "false"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_view_content = _resolveComponent("node-view-content")!
  const _component_node_view_wrapper = _resolveComponent("node-view-wrapper")!

  return (_openBlock(), _createBlock(_component_node_view_wrapper, {
    as: "p",
    class: _normalizeClass([_ctx.getSemanticType(), 'block', {'no-logical-block': _ctx.isNoLogicalBlock()}]),
    title: _ctx.getTitle(),
    id: _ctx.getGuid(),
    style: _normalizeStyle(_ctx.indent)
  }, {
    default: _withCtx(() => [
      (_ctx.hasEntryNo())
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.getEntryNo()) + " ", 1))
        : _createCommentVNode("", true),
      _createVNode(_component_node_view_content, {
        as: "span",
        class: "description"
      })
    ]),
    _: 1
  }, 8, ["class", "title", "id", "style"]))
}