<template>
  <span class="application-figure">

    <!-- Use the modal component, pass in the prop -->
    <modal ref="dialog" :showHeader="true" :showFooter="false" :width="contextWidth" :margin="contextMargin">
      <template v-slot:body>
          <label class="label">{{ $t('searchStemForm') }}
            <!-- b-tooltip position="is-bottom" type="is-dark" square -->
              <i class="exi exi-info" :title="$t('searchStemFormTitle')"/>
            <!-- /b-tooltip -->
          </label>
          <b-field class="stem-forms">
            <b-taginput size="is-small" :modelValue="stemForms" :closable="stemForms.length > 1" icon="label"
                        @@update:modelValue="stemFormsChanged" :placeholder="$t('stemForm')"></b-taginput>
          </b-field>
          <label class="label">{{ $t('excludeStemFormMatches') }}
            <!-- b-tooltip position="is-right" type="is-dark" square -->
              <i class="exi exi-info" :title="$t('excludeStemFormMatchesTitle')"/>
            <!-- /b-tooltip -->
          </label>
          <b-field class="exclude-stem-form-matches">
            <b-taginput size="is-small" readonly :modelValue="excludeStemFormMatches" @update:modelValue="onExclusionRemoved"></b-taginput>
          </b-field>
          <label class="label">{{ $t('stemFormMatches') }}
            <!-- b-tooltip :label="$t('stemFormMatchesTitle')" position="is-right" type="is-dark" square -->
              <i class="exi exi-info" :title="$t('stemFormMatchesTitle')"/>
            <!-- /b-tooltip -->
          </label>
          <b-field class="stem-form-matches">
            <b-taginput size="is-small" readonly :modelValue="stemFormMatches" @update:modelValue="onMatchRemoved"></b-taginput>
          </b-field>
      </template>
    </modal>

  </span>
</template>

<script lang="ts">
import {Component, toNative, Vue} from 'vue-facing-decorator';

@Component
class ApplicationFigureDialog extends Vue {
}

export default toNative(ApplicationFigureDialog);
</script>