
export interface CreateBrickEvent {
    type: BrickType;
    name: string;
    keywords: Array<string>;
    text: string;
    locale: string;
}
export interface CreateTermDefinitionEvent {
    name: string;
    keywords: Array<string>;
    text: string;
    locale: string;
}

export interface UpdateBrickEvent extends CreateBrickEvent {
    guid: string;
}

export interface UpdateTermDefinitionEvent extends CreateTermDefinitionEvent {
    guid: string;
}

export interface LibraryEntity {
    guid: string;
    name: string;
    keywords: Array<string>;
    text: string;
    locale: string;
}
export interface Brick extends LibraryEntity {
    type: BrickType;
}
export type TermDefinition = LibraryEntity;

export enum BrickType {
    A = 'A',
    B = 'B',
    OTHER = 'OTHER'
}

export interface BrickCreatedVmUpdate {
    brick: Brick;
}

export type BrickUpdatedVmUpdate = BrickCreatedVmUpdate;

export interface TermDefinitionCreatedVmUpdate {
    termDefinition: TermDefinition;
}

export type TermDefinitionUpdatedVmUpdate = TermDefinitionCreatedVmUpdate;
