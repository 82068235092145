import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {EditorState} from '@/store/models/block.model';
import {
  AbstractBlockViewModel,
  BlockCreatedVmUpdate,
  BlockDeletedVmUpdate,
  BlockInlineVmUpdate,
  BlockReviewedVmUpdate,
  BlocksGeneratedVmUpdate,
  BlocksUpdatedVmUpdate,
  CommandStackItemViewModel,
  CommandStackViewModel,
  CommandStackVmUpdate,
  CreateBlockEvent,
  DocumentEditorSplitMode,
  DocumentUpdate,
  GenerateBlockEvent,
  LocalVmUpdateName,
  ModeAndSplit,
  NodeAttributeUpdate,
  NodeDeleteUpdate,
  NodeInsertUpdate,
  NodeReplaceUpdate,
  SemanticType,
  StructuralBlockViewModel,
  TextBlockViewModel,
  TwoDocumentEditorsSplitMode,
  UpdateBlockInlineEvent,
  UpdateBlocksEvent,
  UpdateBlockViewModel,
  VmUpdateName
} from '@/api/models/editor.model';
import {
  blockPreprocessor,
  CreateBlock,
  DeleteBlock,
  GenerateBlock,
  GetApplicationDocumentRootBlock,
  ReviewBlock,
  UpdateBlockInline,
  UpdateBlocks
} from '@/api/services/editor.api';
import {latestUpdate, lineageForBlock, pushNew, searchBlockByGuid, updateBlock, updateBlockOnAutoFill} from '@/store/util/editor.util';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {GetCommandStackForApplicationDocument, RedoCommandByGuid, UndoCommandByGuid} from '@/api/services/command.api';
import {GenerateAllBlocks} from '@/api/services/application.api';
import {v4 as uuidv4} from 'uuid';
import {ReferenceSignsAppliedVmUpdate} from '@/api/models/applyReferenceSigns.model';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import UserProfileModule from '@/store/modules/UserProfileModule';
import {ToggleDocumentEditorSplitStateParam} from '@/api/services/userprofile.api';
import AuthModule from '@/store/modules/AuthModule';
import {useDefaultErrorHandling} from '@/errorHandling';
import RequestQueueModule from '@/store/modules/RequestQueueModule';
import AiAssistantModule from '@/store/modules/AiAssistantModule';
import AiTemplateModule from '@/store/modules/AiTemplateModule';

/**
 * The store module to handle actions and mutations concerning the editor state.
 */
@Module({dynamic: true, namespaced: true, store, name: 'editor'})
class EditorModule extends VuexModule implements EditorState {

  private _isLoading = false;
  private _isCurrentApplicationDocumentLoading = false; // used to display or hide the loader in the application editorw while loading
  // the application

  // Header & Search and Replace bar buttons
  private _isSearchBarVisible = false;
  private _searchTermOnKeyDown = '';
  private _isReplacing = false;
  private _triggerSaveCounter = 0;
  private _triggerClearSearchCounter = 0;
  private _openSpellcheckDictionaryManagement = false;

  private _isGenerateBlockLoading = false;
  private _isGenerateAllLoading = false;
  private _isUndoLoading = false;
  private _isRedoLoading = false;
  private _isApplyReferenceSignForApplicationDocumentLoading = false;
  private _blockEditor = false;

  private _reloadCount = 0;
  private _updateCount = 0;
  private _reviewCount = 0;
  private _keepLocalHistory = false;
  private _documentUpdate: DocumentUpdate[] = [];       // Contains all nodes that must be inserted/replaced/deleted
  private _currentRootBlock: AbstractBlockViewModel | null = null;
  private _guidsOfChangedNodes: string[] = [];

  private _guidsOfUnsavedNodes: string[] = [];

  private _createdGuidForEditor: string | null = null;
  private _deletedGuidForEditor: string | null = null;
  private _inlinedGuidForEditor: string | null = null;
  private _selectionGuidForEditor: string | null = null;
  private _scrollOnSelectionGuidForEditorChanged = true;
  private _selectionGuidForDocumentStructureTree: string | null = null;
  private _selectionInEditorLineage: AbstractBlockViewModel[] = []; // contains all semantic types of the selected block in the editor and its ancestors

  private _undoStack: Array<CommandStackItemViewModel> = [];
  private _redoStack: Array<CommandStackItemViewModel> = [];
  private _localUndoCount = 0;
  private _localRedoCount = 0;

  private pendingBlockChanges: { [key: string]: string[] } = {};
  private _pendingBlockUpdates = false;

  private _blockMaxDepth = 0;

  private _showReferenceSignsInText = false;

  private _semanticType: string | null = null;

  get semanticType(): string | null {
    return this._semanticType;
  }

  private _zoomLevel = 1.0;

  private _refocusCount = 0;

  get isSearchBarVisible(): boolean {
    return this._isSearchBarVisible;
  }

  get searchTermOnKeyDown(): string {
    return this._searchTermOnKeyDown;
  }

  get triggerSaveCounter(): number {
    return this._triggerSaveCounter;
  }

  get triggerClearSearchCounter(): number {
    return this._triggerClearSearchCounter;
  }

  get openSpellcheckDictionaryManagement(): boolean {
    return this._openSpellcheckDictionaryManagement;
  }

  get isReplacing(): boolean {
    return this._isReplacing;
  }

  get undoStack(): Array<CommandStackItemViewModel> {
    return this._undoStack;
  }

  get redoStack(): Array<CommandStackItemViewModel> {
    return this._redoStack;
  }

  get localUndoCount(): number {
    return this._localUndoCount;
  }

  get localRedoCount(): number {
    return this._localRedoCount;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isCurrentApplicationDocumentLoading(): boolean {
    return this._isCurrentApplicationDocumentLoading;
  }

  get isGenerateUnRedoLoading(): boolean {
    return this._isGenerateBlockLoading
      || this._isGenerateAllLoading
      || this._isUndoLoading
      || this._isRedoLoading
      || this._isApplyReferenceSignForApplicationDocumentLoading
      || this._blockEditor
      || AiTemplateModule.isAutoFillLoading
      || RequestQueueModule.isWorking;
  }

  get isGenerateBlockLoading(): boolean {
    return this._isGenerateBlockLoading;
  }

  get isGenerateAllLoading(): boolean {
    return this._isGenerateAllLoading;
  }

  get isUndoLoading(): boolean {
    return this._isUndoLoading;
  }

  get isRedoLoading(): boolean {
    return this._isRedoLoading;
  }

  get isApplyReferenceSignForApplicationDocumentLoading(): boolean {
    return this._isApplyReferenceSignForApplicationDocumentLoading;
  }

  get reloadCount(): number {
    return this._reloadCount;
  }

  get currentRootBlock(): AbstractBlockViewModel | null {
    return this._currentRootBlock;
  }

  get updateCount(): number {
    return this._updateCount;
  }

  get reviewCount(): number {
    return this._reviewCount;
  }

  get keepLocalHistory(): boolean {
    return this._keepLocalHistory;
  }

  get documentUpdate(): DocumentUpdate[] {
    return this._documentUpdate;
  }

  get pendingBlockUpdates(): boolean {
    return this._pendingBlockUpdates;
  }

  /**
   * The lineage of the currently slected block inside the editor.
   * (This is "in" editor, not "for" editor)
   *
   * The first entry is the currently selected block in the editor
   * The next entry is the parent of the selected block
   * The next entry the parent of the parent and so on
   * The latestUpdate entry is the the rootBlock
   */
  get selectionInEditorLineage(): AbstractBlockViewModel[] {
    return this._selectionInEditorLineage;
  }

  get createdGuidForEditor(): string | null {
    return this._createdGuidForEditor;
  }

  get deletedGuidForEditor(): string | null {
    return this._deletedGuidForEditor;
  }

  get inlinedGuidForEditor(): string | null {
    return this._inlinedGuidForEditor;
  }

  get selectionGuidForEditor(): string | null {
    return this._selectionGuidForEditor;
  }

  get scrollOnSelectionGuidForEditorChanged(): boolean {
    return this._scrollOnSelectionGuidForEditorChanged;
  }

  get selectionGuidForDocumentStructureTree(): string | null {
    return this._selectionGuidForDocumentStructureTree;
  }

  get blockMaxDepth(): number {
    return this._blockMaxDepth;
  }

  get showReferenceSignsInText(): boolean {
    return this._showReferenceSignsInText
  }

  get zoomLevel(): number {
    return this._zoomLevel;
  }

  @Mutation
  public setZoomLevel(value: number) {
    this._zoomLevel = value;
  }

  @Action
  public resetKeepLocalHistory(): void {
    this.setKeepLocalHistory(false);
  }

  @Mutation
  public toggleSearchBar(): void {
    this._isSearchBarVisible = !this._isSearchBarVisible;
  }

  @Mutation
  public setSearchBarVisible(isToggle: boolean): void {
    this._isSearchBarVisible = isToggle;
  }

  @Mutation
  public setSearchTermOnKeyDown(searchTerm: string): void {
    this._searchTermOnKeyDown = searchTerm;
  }


  @Mutation
  public triggerSave(): void {
    this._triggerSaveCounter++;
  }

  @Mutation
  public triggerClearSearch(): void {
    this._triggerClearSearchCounter++;
  }

  @Mutation
  public setOpenSpellcheckDictionaryManagement(isOpen: boolean): void {
    this._openSpellcheckDictionaryManagement = isOpen;
  }

  @Mutation
  public setIsReplacing(isReplacing: boolean): void {
    this._isReplacing = isReplacing;
  }

  @Mutation
  private setKeepLocalHistory(keepLocalHistory: boolean): void {
    this._keepLocalHistory = keepLocalHistory;
  }

  @Mutation
  private setCreatedGuidForEditor(guid: string | null): void {
    this._createdGuidForEditor = guid;
  }

  @Mutation
  private setDeletedGuidForEditor(guid: string | null): void {
    this._deletedGuidForEditor = guid;
  }

  @Mutation
  private setInlinedGuidForEditor(guid: string | null): void {
    this._inlinedGuidForEditor = guid;
  }

  @Mutation
  private setSelectionGuidForEditor(payload: { guid: string | null; shouldScroll: boolean }): void {
    this._scrollOnSelectionGuidForEditorChanged = payload.shouldScroll; // Reset to default
    this._selectionGuidForEditor = payload.guid;

    // Fix: Track last selected block in ai-module.
    if (payload.guid) {
      AiAssistantModule.setLastSelectionGuid(payload.guid);
    }
  }

  @Mutation
  private setScrollOnSelectionGuidForEditorChanged(scroll: boolean): void {
    this._scrollOnSelectionGuidForEditorChanged = scroll;
  }

  @Mutation
  private setSelectionGuidForDocumentStructureTree(guid: string | null): void {
    this._selectionGuidForDocumentStructureTree = guid;

    if (this._currentRootBlock != null && this._selectionGuidForDocumentStructureTree != null && 'children' in this._currentRootBlock) {
      const lineage = lineageForBlock(this._currentRootBlock as StructuralBlockViewModel, this._selectionGuidForDocumentStructureTree);
      this._selectionInEditorLineage = lineage;
    }
  }

  @Mutation
  private setCurrentApplicationDocumentLoading(doumentLoading: boolean): void {
    this._isCurrentApplicationDocumentLoading = doumentLoading;
  }

  @Mutation
  private setEditorLoading(changes?: { changeUuid: string; changedBlocks: string[] }): void {
    this._isLoading = true;

    if (changes) {
      this.pendingBlockChanges[changes.changeUuid] = changes.changedBlocks;
    }
  }

  @Mutation
  private setGenerateBlockLoading(): void {
    this._isLoading = true;
    this._isGenerateBlockLoading = true;
  }

  @Mutation
  private setGenerateAllLoading(): void {
    this._isLoading = true;
    this._isGenerateAllLoading = true;
  }

  @Mutation
  private setUndoLoading(): void {
    this._isLoading = true;
    this._isUndoLoading = true;
  }

  @Mutation
  private setRedoLoading(): void {
    this._isLoading = true;
    this._isRedoLoading = true;
  }

  @Mutation
  public setLocalUndoCount(count: number): void {
    this._localUndoCount = count;
  }

  @Mutation
  public setLocalRedoCount(count: number): void {
    this._localRedoCount = count;
  }

  // Will be set from ReferenceSignModule
  @Mutation
  public setApplyReferenceSignForApplicationDocumentLoading(isLoading: boolean): void {
    this._isApplyReferenceSignForApplicationDocumentLoading = isLoading;
  }

  @Mutation
  public setBlockEditor(isBlocked: boolean): void {
    this._blockEditor = isBlocked;
  }

  @Mutation
  public setShowReferenceSignInText(showReferenceSignInText: boolean) {
    this._showReferenceSignsInText = showReferenceSignInText;
  }

  @Action
  public toogleShowReferenceSignsInText(): Promise<boolean> {
    this.setShowReferenceSignInText(!this.showReferenceSignsInText);
    return Promise.resolve(this.showReferenceSignsInText);
  }

  @Mutation
  private fetchRootBlockEnd(rootBlock: AbstractBlockViewModel | null): void {
    if (rootBlock) {
      this._currentRootBlock = rootBlock;
      latestUpdate(this._documentUpdate).addReplace(EditorModule.createNodeReplaceUpdates([rootBlock], this._currentRootBlock));
      this._reloadCount++;
    }
    this._isLoading = false;
    this._isCurrentApplicationDocumentLoading = false;
  }

  @Mutation
  private createBlockEnd(payload: { vmUpdate: BlockCreatedVmUpdate | null; scrollToCreatedBlock: boolean }): void {
    const {vmUpdate, scrollToCreatedBlock} = payload;
    if (vmUpdate && vmUpdate.block && vmUpdate.parentGuid && vmUpdate.positionAsChild !== null) {

      // Add block at specified position
      const parentBlock: AbstractBlockViewModel | null = searchBlockByGuid(this._currentRootBlock, vmUpdate.parentGuid);
      if (parentBlock && Object.getOwnPropertyNames(parentBlock).includes('children')) {

        const children = (parentBlock as StructuralBlockViewModel).children;

        // If specified position is not valid, add block at the end of the array
        const position = Math.min(children.length, vmUpdate.positionAsChild);

        vmUpdate.block.parent = parentBlock;
        children.splice(position, 0, vmUpdate.block);

        if (scrollToCreatedBlock) {
          // FIX: (PENGINESUP-614) focus the empty text block of characterizing parts instead of its preface.
          // Currently, the backend only provides the guid of the created block instead of the guid of the new empty block.
          if (vmUpdate.block.semanticType === SemanticType.CHARACTERIZING_PART) {
            const partTextBlock = (vmUpdate.block as StructuralBlockViewModel).children
              .find(it => it.semanticType === SemanticType.CHARACTERIZING_PART_TEXT);
            this._createdGuidForEditor = partTextBlock ? partTextBlock.guid : vmUpdate.block.guid;
          } else {
            this._createdGuidForEditor = vmUpdate.block.guid;
          }
        }

        latestUpdate(this._documentUpdate).addInsert([new NodeInsertUpdate(vmUpdate.parentGuid, position, vmUpdate.block)]);

        vmUpdate.updatedBlocks.forEach((block) => updateBlock(block, this._currentRootBlock));
        latestUpdate(this._documentUpdate).addReplace(
          EditorModule.createNodeReplaceUpdates(vmUpdate.updatedBlocks, this._currentRootBlock));
        this._updateCount++;
      }
    }

    this._isLoading = false;
  }

  @Mutation
  private updateBlockEnd(payload: { changeUuid: string | null; vmUpdate: BlocksUpdatedVmUpdate | null }): void {
    if (payload.changeUuid) {
      delete this.pendingBlockChanges[payload.changeUuid];
    }
    if (payload.vmUpdate) {
      // Update block at specified position
      if (payload.vmUpdate.blocks) {
        const filteredBlocks = EditorModule.filterPendingBlocks(payload.vmUpdate.blocks,
                                                                this._guidsOfChangedNodes,
                                                                this.pendingBlockChanges);
        filteredBlocks.forEach((block: AbstractBlockViewModel) => updateBlock(block, this._currentRootBlock));
        latestUpdate(this._documentUpdate).addReplace(filteredBlocks.map(block => new NodeReplaceUpdate(block)));
      }
      // Update affected blocks
      if (payload.vmUpdate.affectedBlocks) {
        const filteredAffectedBlocks = EditorModule.filterPendingBlocks(payload.vmUpdate.affectedBlocks,
                                                                        this._guidsOfChangedNodes,
                                                                        this.pendingBlockChanges);
        filteredAffectedBlocks.forEach((block: AbstractBlockViewModel) => updateBlock(block, this._currentRootBlock));
        latestUpdate(this._documentUpdate).addReplace(
          EditorModule.createNodeReplaceUpdates(filteredAffectedBlocks, this._currentRootBlock));
      }
      this._updateCount++;
    }
    this._isLoading = false;
  }

  @Mutation
  updateBlockForAutoFill(payload: { guid: string; newBlockText: string }) {
    const blockToUpdate: AbstractBlockViewModel | null = searchBlockByGuid(this._currentRootBlock, payload.guid);
    updateBlockOnAutoFill(payload.newBlockText, blockToUpdate);
    if (blockToUpdate != null) {
      latestUpdate(this._documentUpdate).addReplace([new NodeReplaceUpdate(blockToUpdate)]);
      this._updateCount++;
    }
  }

  @Mutation
  private updateBlockAttributes(blocks: AbstractBlockViewModel[]): void {
    if (blocks) {
      blocks.forEach((block: AbstractBlockViewModel) => updateBlock(block, this._currentRootBlock));
      latestUpdate(this._documentUpdate).addAttribute(blocks.map(block => new NodeAttributeUpdate(block)));
      this._updateCount++;
    }
    this._isLoading = false;
  }

  @Action
  private handleBlocksGenerated(vmUpdate: BlocksGeneratedVmUpdate | null): void {
    if (vmUpdate) {
      vmUpdate.deletedBlocks.forEach((block) => {
        this.deleteBlockEnd({changeUuid: null, vmUpdate: block, scrollToDeletedBlock: false});
      });
      vmUpdate.addedBlocks.forEach((block) => {
        // Fix: process blocks and add parent reference.
        block.block = blockPreprocessor(block.block, null);
        this.createBlockEnd({vmUpdate: block, scrollToCreatedBlock: false});
      });
      this.updateBlockEnd({changeUuid: null, vmUpdate: vmUpdate.updatedBlocks});
      this.updateUndoRedoStack(vmUpdate.commandStack);
    }
  }

  @Mutation
  private generateBlockEnd(): void {
    this._isLoading = false;
    this._isGenerateBlockLoading = false;
  }

  @Mutation
  private generateAllBlocksEnd(): void {
    this._isLoading = false;
    this._isGenerateAllLoading = false;
  }

  @Mutation
  private undoRedoEnd(): void {
    this._isLoading = false;
    this._isUndoLoading = false;
    this._isRedoLoading = false;
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  @Mutation
  private deleteBlockEnd(payload: {
    changeUuid: string | null;
    vmUpdate: BlockDeletedVmUpdate | null;
    scrollToDeletedBlock: boolean
  }): void {
    const {changeUuid, vmUpdate, scrollToDeletedBlock} = payload;
    if (changeUuid) {
      delete this.pendingBlockChanges[changeUuid];
    }
    if (vmUpdate && vmUpdate.guid && vmUpdate.parentGuid) {
      const parentBlock: AbstractBlockViewModel | null = searchBlockByGuid(this._currentRootBlock, vmUpdate.parentGuid);
      if (parentBlock && Object.getOwnPropertyNames(parentBlock).includes('children')) {
        const structuralParentBlock = (parentBlock as StructuralBlockViewModel);
        const deletedBlockIdx = structuralParentBlock.children.findIndex((child: AbstractBlockViewModel) => child.guid === vmUpdate?.guid);
        structuralParentBlock.children = structuralParentBlock.children
          .filter((child: AbstractBlockViewModel) => (child.guid !== vmUpdate?.guid));

        if (scrollToDeletedBlock && deletedBlockIdx >= 0) {
          this._deletedGuidForEditor = structuralParentBlock.children[Math.max(deletedBlockIdx - 1, 0)].guid;
        }
        latestUpdate(this._documentUpdate).addDelete([new NodeDeleteUpdate(vmUpdate.parentGuid, vmUpdate.guid)]);
        const filteredUpdatedBlocks = EditorModule.filterPendingBlocks(vmUpdate.updatedBlocks,
                                                                       this._guidsOfChangedNodes,
                                                                       this.pendingBlockChanges);
        filteredUpdatedBlocks.forEach((block) => updateBlock(block, this._currentRootBlock));
        latestUpdate(this._documentUpdate).addReplace(EditorModule.createNodeReplaceUpdates(filteredUpdatedBlocks, this._currentRootBlock));
        this._updateCount++;
      }
    }
    this._isLoading = false;
  }

  @Mutation
  private reviewBlockEnd(vmUpdate: BlockReviewedVmUpdate | null): void {
    if (vmUpdate && vmUpdate.guid) {
      const originalBlock: AbstractBlockViewModel | null = searchBlockByGuid(this._currentRootBlock, vmUpdate.guid);
      if (originalBlock) {
        originalBlock.reviewNeeded = false;
        this._reviewCount++;
      }
    }
    this._isLoading = false;
  }

  get guidsOfChangedNodes(): string[] {
    return this._guidsOfChangedNodes;
  }

  @Mutation
  private addGuidOfChangedNode(guid: string): void {
    if (!this._guidsOfChangedNodes.includes(guid)) {
      this._guidsOfChangedNodes.push(guid);
    }
  }

  @Action
  addChange(guid: string): void {
    this.addGuidOfChangedNode(guid);
  }

  @Mutation
  private clearGuidOfChangedNode(): void {
    this._guidsOfChangedNodes.length = 0;
  }


  get guidsOfUnsavedNodes(): string[] {
    return this._guidsOfUnsavedNodes;
  }

  @Mutation
  private addGuidOfUnsavedNode(guid: string): void {
    if (!this._guidsOfUnsavedNodes.includes(guid)) {
      this._guidsOfUnsavedNodes.push(guid);
    }
  }

  @Action
  addUnsavedChange(guid: string): void {
    this.addGuidOfUnsavedNode(guid);
  }

  @Mutation
  private removeSavedNodeFromUnsavedNodeList(guid: string): void {
    this._guidsOfUnsavedNodes.forEach((unsavedNode, index) => {
      if (unsavedNode === guid) {
        this._guidsOfUnsavedNodes.splice(index, 1);
      }
    });
  }


  @Mutation
  public updateBlocks(blocks: Array<AbstractBlockViewModel>): void {
    const filteredBlocks = EditorModule.filterPendingBlocks(blocks, this._guidsOfChangedNodes, this.pendingBlockChanges);
    filteredBlocks.forEach((block) => updateBlock(block, this._currentRootBlock));
    latestUpdate(this._documentUpdate).addReplace(EditorModule.createNodeReplaceUpdates(filteredBlocks, this._currentRootBlock));
    this._updateCount++;
  }

  @Mutation
  public updateUndoRedoStack(commandStack: CommandStackViewModel): void {
    let undoStack: Array<CommandStackItemViewModel> = [];
    let redoStack: Array<CommandStackItemViewModel> = [];
    if (commandStack && commandStack.stackItems) {
      const currentUndoItem = commandStack.stackItems.find(stackItem => (stackItem.guid === commandStack.stackPointer));
      if (currentUndoItem) {
        const indexOfCurrentUndoItem = commandStack.stackItems.indexOf(currentUndoItem);
        undoStack = commandStack.stackItems.slice(0, indexOfCurrentUndoItem + 1);
        redoStack = commandStack.stackItems.slice(indexOfCurrentUndoItem + 1, commandStack.stackItems.length);
      } else {
        redoStack = commandStack.stackItems;
      }
    }

    this._undoStack = undoStack;
    this._redoStack = redoStack;
  }

  @Action
  setMaxDepth(maxDepth: number): void {
    this.updateMaxDepth(maxDepth);
  }

  @Mutation
  private updateMaxDepth(maxDepth: number): void {
    this._blockMaxDepth = maxDepth;
  }

  @Action
  clearChanges(): void {
    this.clearGuidOfChangedNode();
  }

  @Action
  updateUnsavedNodeList(vmUpdate: BlocksUpdatedVmUpdate): void {
    vmUpdate.blocks.forEach(block => {
      this.removeSavedNodeFromUnsavedNodeList(block.guid);
    });
  }

  @Mutation
  private clearUpdatedNodes(): void {
    this._documentUpdate = [];
  }

  @Mutation
  public pushDocumentUpdate(kind: VmUpdateName | LocalVmUpdateName): void {
    this._documentUpdate = pushNew(this._documentUpdate, kind);
  }

  @Mutation
  private setPendingBlockUpdates(pendingBlockUpdates: boolean): void {
    this._pendingBlockUpdates = pendingBlockUpdates;
  }

  @Action
  clearPendingBlockUpdates(): void {
    this.setPendingBlockUpdates(false);
  }

  @Action
  resetUpdatedNodes(): void {
    if (!this._pendingBlockUpdates) {
      this.clearUpdatedNodes();
    }
  }

  @Action
  selectCreatedGuidForEditor(guid: string | null): void {
    this.setCreatedGuidForEditor(guid);
  }

  @Action
  selectDeletedGuidForEditor(guid: string | null): void {
    this.setDeletedGuidForEditor(guid);
  }

  @Action
  selectInlinedGuidForEditor(guid: string | null): void {
    this.setInlinedGuidForEditor(guid);
  }

  @Action
  selectGuidForEditor(guid: string | null): void {
    this.setSelectionGuidForEditor({guid: guid, shouldScroll: true});
  }

  @Action
  selectGuidForEditorNoScroll(guid: string | null): void {
    this.setSelectionGuidForEditor({guid: guid, shouldScroll: false});
  }

  @Action
  selectSemanticType(semanticType: string | null): void {
    this.setSemanticType(semanticType);
  }

  @Mutation
  private setSemanticType(semanticType: string | null): void {
    this._semanticType = semanticType

  }


  @Action
  selectGuidForDocumentStructureTree(guid: string | null): void {
    this.setSelectionGuidForDocumentStructureTree(guid);
  }

  @Action
  async loadDocument(applicationDocumentGuid: string): Promise<void> {
    if (applicationDocumentGuid) {
      this.setCurrentApplicationDocumentLoading(true);
      this.setEditorLoading();
    }

    GetApplicationDocumentRootBlock(applicationDocumentGuid)
      .then((block) => {
        this.pushDocumentUpdate("loadDocument");
        GetCommandStackForApplicationDocument(applicationDocumentGuid)
          .then((stack) => {
            this.updateUndoRedoStack(stack);
            this.fetchRootBlockEnd(block);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        this.fetchRootBlockEnd(null);
        throw error;
      });
  }

  @Action
  async fetchUndoRedoStack(applicationDocumentGuid: string): Promise<void> {
    if (applicationDocumentGuid) {
      this.setEditorLoading();
    }

    GetCommandStackForApplicationDocument(applicationDocumentGuid)
      .then((stack) => this.updateUndoRedoStack(stack))
      .catch((error) => {
        throw error;
      });
  }

  @Action
  async fetchRootBlock(applicationDocumentGuid: string): Promise<void> {
    if (applicationDocumentGuid) {
      this.setEditorLoading();

      GetApplicationDocumentRootBlock(applicationDocumentGuid)
        .then((block) => {
          this.pushDocumentUpdate("fetchRootBlock");
          this.fetchRootBlockEnd(block);
        })
        .catch((error) => {
          this.fetchRootBlockEnd(null);
          throw error;
        });
    }
  }

  @Action
  async createBlock(payload: { semanticType: SemanticType; parentGuid: string; childGuid: string }): Promise<void> {
    if (payload.parentGuid) {
      this.setEditorLoading();

      const event: CreateBlockEvent = {
        parentGuid: payload.parentGuid,
        childGuid: payload.childGuid,
        semanticType: payload.semanticType
      };

      CreateBlock(event)
        .then((vmUpdate: BlockCreatedVmUpdate) => {
          this.pushDocumentUpdate(vmUpdate.name);
          this.createBlockEnd({vmUpdate: vmUpdate, scrollToCreatedBlock: true});
          this.updateUndoRedoStack(vmUpdate.commandStack);
        })
        .catch((error) => {
          this.createBlockEnd({vmUpdate: null, scrollToCreatedBlock: false});
          throw error;
        });
    } else {
      console.error("Claim not added because of missing parentGuid or claimNo");
    }
  }

  @Action
  async setBlockInline(payload: { guid: string; isInline: boolean }): Promise<void> {
    const changeUuid = uuidv4();
    this.setEditorLoading({changeUuid: changeUuid, changedBlocks: [payload.guid]});
    const event: UpdateBlockInlineEvent = {guid: payload.guid, isInline: payload.isInline};

    return UpdateBlockInline(event)
      .then((vmUpdate: BlockInlineVmUpdate) => {
        this.pushDocumentUpdate(vmUpdate.name);
        this.updateBlockAttributes(vmUpdate.blocks);
        this.updateUndoRedoStack(vmUpdate.commandStack);
        this.setInlinedGuidForEditor(payload.guid);
      })
      .catch((error) => {
        this.updateBlockEnd({changeUuid, vmUpdate: null});
        throw error;
      });
  }

  @Action
  async saveBlocks(payload: {
    changes: Array<{ guid: string; content: string; semanticType: string; libraryReferences: Array<string> }>;
    keepLocalHistory: boolean;
  }): Promise<void> {
    if (payload.changes.some(change => change.semanticType === "GENERIC_TERM" || change.semanticType === "GENERIC_TERM_COMPLEMENT_TEXT")) {
      this.setBlockEditor(true);
    }
    const changes = payload.changes;

    // Check if the change is still valid (may have been reverted in client e.g. via Undo)
    const changedBlocks = changes.filter(change => change.content != (searchBlockByGuid(this._currentRootBlock, change.guid) as
      TextBlockViewModel).richText);

    // We have to check any unsaved blocks if they were reverted within client in the meantime.
    // If so, we do not save them (not included in 'changedBlocks'), but we have to reset their unsaved state.
    changes.filter(change => change.content == (searchBlockByGuid(this._currentRootBlock, change.guid) as TextBlockViewModel).richText)
      .forEach((block) => {
        if (this._guidsOfUnsavedNodes.includes(block.guid)) {
          this.removeSavedNodeFromUnsavedNodeList(block.guid);
        }
      });

    const event: UpdateBlocksEvent = {
      blocks: changedBlocks.map((change): UpdateBlockViewModel => (
        {guid: change.guid, richText: change.content, libraryReferences: change.libraryReferences}
      ))
    };
    return this.processBlockUpdate(
      {
        changedBlocks: changedBlocks.map(it => it.guid),
        updateFunction: () => UpdateBlocks(event),
        keepLocalHistory: payload.keepLocalHistory
      });
  }

  @Action
  async processBlockUpdate(payload: {
    changedBlocks: Array<string>;
    updateFunction: () => Promise<BlocksUpdatedVmUpdate>,
    keepLocalHistory: boolean;
  }): Promise<void> {
    const changeUuid = uuidv4();
    this.setEditorLoading({changeUuid: changeUuid, changedBlocks: payload.changedBlocks});
    this.setKeepLocalHistory(payload.keepLocalHistory);

    if (payload.changedBlocks.length > 0) {
      return payload.updateFunction()
        .then((vmUpdate: BlocksUpdatedVmUpdate) => {
          this.pushDocumentUpdate(vmUpdate.name);
          this.updateBlockEnd({changeUuid, vmUpdate});
          this.updateUndoRedoStack(vmUpdate.commandStack);

          //remove the successfully saved block(s) from the list of unsaved blocks
          this.updateUnsavedNodeList(vmUpdate);

          if (vmUpdate.applicationDocumentAffected) {
            ApplicationModule.reloadApplicationDocument()
              .then(() => this.setBlockEditor(false))
              .catch(useDefaultErrorHandling);
          } else {
            this.setBlockEditor(false);
          }
        })
        .catch((error) => {
          this.updateBlockEnd({changeUuid, vmUpdate: null});
          payload.changedBlocks.forEach(it => {
            this.addChange(it);
            this.addUnsavedChange(it);
          });
          this.setBlockEditor(false);
          throw error;
        });
    } else {
      this.setIsLoading(false);
    }
    this.setBlockEditor(false);
  }

  @Action
  async generateBlock(guid: string): Promise<void> {
    this.setGenerateBlockLoading();

    const event: GenerateBlockEvent = {
      guid: guid
    };
    return GenerateBlock(guid, event)
      .then((vmUpdate: BlocksGeneratedVmUpdate) => {
        this.pushDocumentUpdate(vmUpdate.name);
        this.handleBlocksGenerated(vmUpdate);
        this.setSelectionGuidForEditor({guid: (vmUpdate.guid) ? vmUpdate.guid : null, shouldScroll: true});
        this.generateBlockEnd();
      })
      .catch((error) => {
        this.handleBlocksGenerated(null);
        this.generateBlockEnd();
        throw error;
      });
  }

  @Action
  async generateAllBlocks(applicationDocumentGuid: string): Promise<void> {
    this.setGenerateAllLoading();
    ReferenceSignModule.setReferenceSignLoading();

    return GenerateAllBlocks(applicationDocumentGuid)
      .then((vmUpdate: BlocksGeneratedVmUpdate) => {
        // Generates all the blocks avoiding to remove the pending block updates for applying the reference signs
        this.setPendingBlockUpdates(true);
        this.pushDocumentUpdate(vmUpdate.name);
        this.handleBlocksGenerated(vmUpdate);
        this.generateAllBlocksEnd();

        // Apply the reference signs
        ReferenceSignModule.fetchReferenceSignsForApplicationDocumentEnd(vmUpdate.referenceSigns);
      })
      .catch((error) => {
        ReferenceSignModule.fetchReferenceSignsForApplicationDocumentEnd(null);
        this.handleBlocksGenerated(null);
        this.generateAllBlocksEnd();
        throw error;
      });
  }

  @Action
  async deleteBlock(guid: string): Promise<void> {
    const changeUuid = uuidv4();

    this.setEditorLoading({changeUuid, changedBlocks: [guid]});

    return DeleteBlock(guid)
      .then((vmUpdate: BlockDeletedVmUpdate) => {
        this.pushDocumentUpdate(vmUpdate.name);
        this.deleteBlockEnd({changeUuid, vmUpdate, scrollToDeletedBlock: true});
        this.updateUndoRedoStack(vmUpdate.commandStack);
      })
      .catch((error) => {
        this.deleteBlockEnd({changeUuid, vmUpdate: null, scrollToDeletedBlock: true});
        throw error;
      });
  }

  @Action
  async undo(payload: { applicationDocumentGuid: string; undoStepGuid: string }): Promise<void> {
    this.setUndoLoading();
    this.clearGuidOfChangedNode();

    const {applicationDocumentGuid, undoStepGuid} = payload;

    const stepIndex = this._undoStack.findIndex((stackItem) => stackItem.guid === undoStepGuid);
    const stepCount = this._undoStack.length - stepIndex;

    this.setKeepLocalHistory(true);

    return UndoCommandByGuid(applicationDocumentGuid, undoStepGuid, stepCount)
      .then((vmUpdate) => {
        this.handleUndoRedo(vmUpdate);
      })
      .catch((error) => {
        this.handleUndoRedo(null);
        throw error;
      });
  }

  @Action
  async redo(payload: { applicationDocumentGuid: string; redoStepGuid: string }): Promise<void> {
    this.setRedoLoading();
    this.clearGuidOfChangedNode();

    const {applicationDocumentGuid, redoStepGuid} = payload;

    const stepIndex = this._redoStack.findIndex((stackItem) => stackItem.guid === redoStepGuid);
    const stepCount = stepIndex + 1;

    this.setKeepLocalHistory(true);

    return RedoCommandByGuid(applicationDocumentGuid, redoStepGuid, stepCount)
      .then((vmUpdate) => {
        this.handleUndoRedo(vmUpdate);
      })
      .catch((error) => {
        this.handleUndoRedo(null);
        throw error;
      });
  }

  @Action
  private handleUndoRedo(vmUpdate: CommandStackVmUpdate | null): void {
    if (!vmUpdate) {
      this.undoRedoEnd();
      this.resetKeepLocalHistory();
      return;
    }

    let guidOfBlockToSelect: string | null = null;

    const vmUpdates = vmUpdate.vmUpdates;
    for (let i = 0; i < vmUpdates.length; i++) {
      const vmUpdate = vmUpdates[i];
      this.pushDocumentUpdate(vmUpdate.name);

      switch (vmUpdate.name) {
        case 'BlockCreatedVmUpdate': {
          const blockCreatedVmUpdate = vmUpdate as BlockCreatedVmUpdate;
          this.createBlockEnd({vmUpdate: blockCreatedVmUpdate, scrollToCreatedBlock: false});
          guidOfBlockToSelect = blockCreatedVmUpdate.block.guid;
          break;
        }
        case 'BlocksUpdatedVmUpdate': {
          const blocksUpdatedVmUpdate = vmUpdate as BlocksUpdatedVmUpdate;
          this.updateBlockEnd({changeUuid: null, vmUpdate: blocksUpdatedVmUpdate});
          if (blocksUpdatedVmUpdate.blocks.length > 0) {
            guidOfBlockToSelect = blocksUpdatedVmUpdate.blocks[blocksUpdatedVmUpdate.blocks.length - 1].guid;
          }
          break;
        }
        case 'BlocksGeneratedVmUpdate': {
          const blocksGeneratedVmUpdate = vmUpdate as BlocksGeneratedVmUpdate;
          this.handleBlocksGenerated(blocksGeneratedVmUpdate);
          if (blocksGeneratedVmUpdate.guid) {
            guidOfBlockToSelect = blocksGeneratedVmUpdate.guid;
          }
          break;
        }
        case 'BlockDeletedVmUpdate': {
          this.deleteBlockEnd({changeUuid: null, vmUpdate: vmUpdate as BlockDeletedVmUpdate, scrollToDeletedBlock: false});
          break;
        }
        case 'ReferenceSignsAppliedVmUpdate': {
          ReferenceSignModule.fetchReferenceSignsForApplicationDocumentEnd((vmUpdate as ReferenceSignsAppliedVmUpdate).referenceSigns);
          this.updateBlocks((vmUpdate as ReferenceSignsAppliedVmUpdate).changedBlocks);
          break;
        }
        case 'BlockInlineVmUpdate': {
          const blocksInlineVmUpdate = vmUpdate as BlockInlineVmUpdate;
          this.updateBlockAttributes(blocksInlineVmUpdate.blocks);
          if (blocksInlineVmUpdate.guid) {
            guidOfBlockToSelect = blocksInlineVmUpdate.guid;
          }
          break;
        }
        case 'LlmAutoFilledVmUpdate': {
          const blocksUpdatedVmUpdate = vmUpdate as BlocksUpdatedVmUpdate;
          this.updateBlockEnd({changeUuid: null, vmUpdate: blocksUpdatedVmUpdate});
          if (blocksUpdatedVmUpdate.blocks.length > 0) {
            guidOfBlockToSelect = blocksUpdatedVmUpdate.blocks[blocksUpdatedVmUpdate.blocks.length - 1].guid;
          }
          break;
        }
        default: {
          const errorCase: never = vmUpdate.name
          throw new Error(`Unhandled VmUpdate: ${errorCase}`)
        }
      }
    }

    this.updateUndoRedoStack(vmUpdate.commandStack);
    this.setSelectionGuidForEditor({guid: guidOfBlockToSelect, shouldScroll: true});
    this.undoRedoEnd();
  }

  @Action
  async reviewBlock(guid: string): Promise<void> {
    this.setEditorLoading();

    return ReviewBlock(guid)
      .then((vmUpdate: BlockReviewedVmUpdate) => {
        this.pushDocumentUpdate("reviewBlock");
        this.reviewBlockEnd(vmUpdate);
      })
      .catch((error) => {
        this.reviewBlockEnd(null);
        throw error;
      });
  }

  @Action
  async toggleToSingleSplitMode(): Promise<void> {
    const modeAndSplit: ModeAndSplit = {
      mode: DocumentEditorSplitMode.ONE,
    }
    UserProfileModule.setActiveModeAndSplit(modeAndSplit);

    const requestData: ToggleDocumentEditorSplitStateParam = {
      userId: AuthModule.user!.id,
      mode: DocumentEditorSplitMode.ONE,
      split: undefined
    }
    return UserProfileModule.toggleActiveDocumentEditorSplitState(requestData);
  }

  @Action
  async toggleToVerticalSplitMode(): Promise<void> {
    const modeAndSplit: ModeAndSplit = {
      mode: DocumentEditorSplitMode.TWO,
      split: TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY,
    }
    UserProfileModule.setActiveModeAndSplit(modeAndSplit);

    const requestData: ToggleDocumentEditorSplitStateParam = {
      userId: AuthModule.user!.id,
      mode: DocumentEditorSplitMode.TWO,
      split: TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY,
    }
    return UserProfileModule.toggleActiveDocumentEditorSplitState(requestData);
  }

  @Action
  async toggleToHorizontalSplitMode(): Promise<void> {
    const modeAndSplit: ModeAndSplit = {
      mode: DocumentEditorSplitMode.TWO,
      split: TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY,
    }
    UserProfileModule.setActiveModeAndSplit(modeAndSplit);

    const requestData: ToggleDocumentEditorSplitStateParam = {
      userId: AuthModule.user!.id,
      mode: DocumentEditorSplitMode.TWO,
      split: TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY,
    }
    return UserProfileModule.toggleActiveDocumentEditorSplitState(requestData);
  }

  /**
   * Since we can't (or actually don't) update a single inner node (without changing its children), we have to replace the inner node.
   * Therefore, we must ensure, that the inner node also has all children. Since the backend doesn't transfer the (unchanged) children,
   * we have use the node of the store.
   *
   * As soon as the editor can update a inner node (without changing its children) (e.g. like described here:
   * https://discuss.prosemirror.net/t/changing-doc-attrs/784), this method can be simplified to
   * "return updatedBlocks.map(block => new NodeReplaceUpdate(block))"
   *
   * @param updatedBlocks the blocks that should be replaced
   * @param _currentRootBlock the root block of the document
   */
  private static createNodeReplaceUpdates(updatedBlocks: Array<AbstractBlockViewModel>, _currentRootBlock: AbstractBlockViewModel | null): NodeReplaceUpdate[] {
    return updatedBlocks
      .map(block => searchBlockByGuid(_currentRootBlock, block.guid))
      .filter((block): block is AbstractBlockViewModel => block !== null)
      .map(block => new NodeReplaceUpdate(block));
  }

  /**
   * Filters the given array of blocks, removing blocks with unsaved changes.
   *
   * @param blocks the blocks that should be filtered
   * @param guidsOfChangedBlocks the GUIDs of all blocks with changes that are neither saved nor pending
   * @param pendingBlockChanges the blocks known to be updated by pending requests
   */
  private static filterPendingBlocks(blocks: AbstractBlockViewModel[],
                                     guidsOfChangedBlocks: string[],
                                     pendingBlockChanges: { [key: string]: string[] }): AbstractBlockViewModel[] {
    const flatPendingBlockChanges = Array.from(
      Object.keys(pendingBlockChanges).map((key) => {
        return pendingBlockChanges[key]
      })
    ).flat(1);
    return blocks.filter(block => !guidsOfChangedBlocks.includes(block.guid))
      .filter(block => flatPendingBlockChanges.indexOf(block.guid) < 0);
  }
}

export default getModule(EditorModule);
