import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {readBlobAsJson} from '@/util/blob.util';
import {WorklistState} from '@/store/models/worklist.model';
import {WorklistApplicationDocument} from '@/api/models/worklist.model';
import {CreateDocxDocument, DeleteApplicationDocument, GetApplicationDocuments} from '@/api/services/worklist.api';

@Module({dynamic: true, namespaced: true, store, name: 'worklist'})
class WorklistModule extends VuexModule implements WorklistState {

  // initial state
  private _isLoading = false;
  private _deletingApplicationDocuments: string[] = [];
  private _applicationDocuments: WorklistApplicationDocument[] = [];
  private _applicationDocumentsSorting = "";
  private _applicationDocumentsSortingDirection = "";

  get isLoading(): boolean {
    return this._isLoading;
  }

  get deletingApplicationDocuments(): string[] {
    return this._deletingApplicationDocuments;
  }

  get applicationDocuments(): WorklistApplicationDocument[] {
    return this._applicationDocuments;
  }

  get applicationDocumentsSorting(): string {
    return this._applicationDocumentsSorting;
  }

  get applicationDocumentsSortingDirection(): string {
    return this._applicationDocumentsSortingDirection;
  }

  @Mutation
  setApplicationDocuments(applicationDocuments: WorklistApplicationDocument[]): void {
    this._applicationDocuments = applicationDocuments;
  }

  @Mutation
  setApplicationDocumentLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  setApplicationDocumentReady(): void {
    this._isLoading = true;
  }

  @Mutation
  private setApplicationDocumentDeleting(applicationDocument: WorklistApplicationDocument): void {
    this._deletingApplicationDocuments.push(applicationDocument.guid as string);
  }

  @Mutation
  private fetchApplicationDocumentListEnd(applicationDocuments: WorklistApplicationDocument[] | null) {
    if (applicationDocuments) {
      this._applicationDocuments = applicationDocuments;
    }
    this._isLoading = false;
  }

  @Mutation
  setApplicationDocumentsSorting(sorting: string) {
    this._applicationDocumentsSorting = sorting;
  }

  @Mutation
  setApplicationDocumentsSortingDirection(direction: string) {
    this._applicationDocumentsSortingDirection = direction;
  }

  @Mutation
  private deleteApplicationDocumentEnd(appDocAndGuid: { applicationDocument: WorklistApplicationDocument | null; guid: string }) {
    if (appDocAndGuid.applicationDocument) {
      const appDocOriginal = this._applicationDocuments.find((adToTest) => adToTest.guid === appDocAndGuid.guid);
      if (appDocOriginal) {
        this._applicationDocuments.splice(this._applicationDocuments.indexOf(appDocOriginal), 1);
      }
    }
    const deletionIndex = this._deletingApplicationDocuments.indexOf(appDocAndGuid.guid);
    if (deletionIndex > -1) {
      this._deletingApplicationDocuments.splice(deletionIndex, 1);
    }
    this._isLoading = false;
  }

  @Action
  async fetchApplicationDocuments(): Promise<void> {
    this.setApplicationDocumentLoading();
    return GetApplicationDocuments().then((applicationDocuments) => {
      this.fetchApplicationDocumentListEnd(applicationDocuments);
    }).catch((error: any) => {
      this.fetchApplicationDocumentListEnd(null);
      throw error;
    });
  }

  // The caller needs to handle the real error, so we need rawError=true to not get a wrapped error.
  // Actions only allow one parameter, so we pass an object.
  @Action({rawError: true})
  async downloadApplicationDocument(param: { applicationDocument: WorklistApplicationDocument; force: boolean; singleFile: boolean }): Promise<void> {
    return CreateDocxDocument(param.applicationDocument, param.force, param.singleFile)
      .catch(async (error: any) => {
        // Because we use axiosBlobInstance we get a blob even for an exception.
        // We need to unwrap and parse the blob to get the exception entity from the backend.
        const data = error?.response?.data;
        if (data instanceof Blob) {
          return Promise.reject(await readBlobAsJson(data as Blob));
        }
        return Promise.reject(error);
      });
  }

  @Action
  async deleteApplicationDocument(applicationDocument: WorklistApplicationDocument): Promise<void> {
    this.setApplicationDocumentLoading();
    this.setApplicationDocumentDeleting(applicationDocument);
    const appDocAndGuid = {
      applicationDocument: applicationDocument,
      guid: applicationDocument.guid as string
    };
    return DeleteApplicationDocument(appDocAndGuid.guid).then(() => {
      this.deleteApplicationDocumentEnd(appDocAndGuid);
    }).catch((error: any) => {
      this.deleteApplicationDocumentEnd({applicationDocument: null, guid: appDocAndGuid.guid});
      throw error;
    });
  }
}

export default getModule(WorklistModule);
