import {ComponentPublicInstance} from 'vue';
import {LocalizedMessageKey} from '@/api/models/exception.model';
import {ThrowError} from '@/api';

/**
 * Use this to wrap an error SOURCES. It makes sure that an error isn't pushed into the toaster queue multiple times,
 * while one instance of the same error is still displayed or in the toaster queue.
 */
export class ErrorWrapper {

  private readonly key: LocalizedMessageKey;
  // eslint-disable-next-line
  private readonly values: any[] | undefined = undefined;
  private notice: ComponentPublicInstance | undefined = undefined;

  // eslint-disable-next-line
  constructor(key: LocalizedMessageKey, values?: any[]) {
    this.key = key;
    if (values) {
      this.values = values;
    }
  }

  // eslint-disable-next-line
  throwError(values?: any[]): void {
    // Optional overwrite the values for only this toast
    const valuesToUse = values ? values : this.values;
    this.notice = ThrowError(this.key, valuesToUse);
  }
}
