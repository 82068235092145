<template>
  <span class="splash-screen">

    <modal-dialog ref="modalDialog" :showHeader="false" :showFooter="false" :displayOnInit="true">
      <template v-slot:body>

        <img class="patent-engine-logo animate-flicker" src="../assets/logo/PE_mit_Logo.svg" alt="Patent-Engine Logo"/>

      </template>
    </modal-dialog>
  </span>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';

@Component(
  {
    components: {
      ModalDialog
    }
  })
class SplashScreenDialog extends Vue {
  @Ref('modalDialog') private modalDialog!: ModalDialogClass;

  private minDuration = 1000;        // Minimal duration the splash screen shout be displayed before hiding again
  private blocked = true;            // Can not be closed while blocked is true
  private closeAfterBlocked = false; // Will be closed automatically after the block got lifted
  private blockingCoroutine: number | null = null; // Reference to the timeout to be able to cancel it

  open(): void {
    // If there's already a coroutine running, stop it
    if (this.blockingCoroutine) {
      clearTimeout(this.blockingCoroutine);
      this.blockingCoroutine = null;
    }

    this.modalDialog.openModal();

    // Start a new coroutine that blocks the possibility to close the splash screen early
    this.startBlockCounter();
  }

  close(): void {
    // If the splash screen wasn't visible for long enough yet, just prevent closing
    if (this.blocked) {
      // But also remember to close it directly after the coroutine ran out
      this.closeAfterBlocked = true;
      return;
    }

    this.modalDialog.closeModal();
  }

  mounted(): void {
    this.startBlockCounter();
  }

  private startBlockCounter(): void {
    this.blocked = true;
    this.closeAfterBlocked = false;

    // Wait a while before allowing to close the splash screen
    this.blockingCoroutine = setTimeout(() => {
      this.blocked = false;

      // If it was already tried to close the splash screen we can now do it directly
      if (this.closeAfterBlocked) {
        this.closeAfterBlocked = false;
        this.close();
      }
      this.blockingCoroutine = null;
    }, this.minDuration);
  }
}

export default toNative(SplashScreenDialog);
export {SplashScreenDialog};
</script>


<style lang="scss">
@import 'src/assets/styles/colors';

.splash-screen {
  .modal-dialog {
    top: 0px;
    z-index: 1001; // Above header = 35; Above Error Toasts = 1001
    overflow-y: hidden;

    &-dialog {
      width: 100%;
      height: 100%;

      .modal-dialog-body {
        align-items: center;
        height: 100%;

        .patent-engine-logo {
          width: 300px;
          position: absolute;
          top: 50%;
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }

        $flicker-speed: 2s;

        @keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-o-keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-moz-keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-webkit-keyframes flickerAnimation {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .animate-flicker {
          -webkit-animation: flickerAnimation $flicker-speed infinite;
          -moz-animation: flickerAnimation $flicker-speed infinite;
          -o-animation: flickerAnimation $flicker-speed infinite;
          animation: flickerAnimation $flicker-speed infinite;
        }
      }
    }
  }
}

</style>
