<template>
  <node-view-wrapper as="span"
                     :class="[this.node.attrs.semanticType, 'block', 'no-logical-block', {'inline-non-logical': isInline()}]"
                     :id="getGuid()">
    <node-view-content as="span"></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import {hasLogicalBlockAsDescendant} from '@/components/applicationEditor/utils/prosemirror.util';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a structural, non logical inline node.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class NonLogicalStructuralBlockNodeView extends Vue {

  @Prop() node!: PmNode;

  private getGuid(): string {
    return this.node.attrs.guid;
  }

  private isInline() {
    return !hasLogicalBlockAsDescendant(this.node);
  }
}

export default toNative(NonLogicalStructuralBlockNodeView);
</script>
<style lang="scss" scoped>

span:not(.inline-non-logical) {
  display: inherit;
  margin-bottom: 17px !important;
}

</style>
