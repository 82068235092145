import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "splitpanes__pane",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPaneClick($event, _ctx._.uid))),
    style: _normalizeStyle(_ctx.style)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 4))
}