import {Node as PmNode, ResolvedPos as PmResolvedPos} from '@tiptap/pm/model';
import {EditorState} from '@tiptap/pm/state';
import {InlineMode} from '@/api/models/editor.model';

/**
 * Ascends in the node tree upwards from the given resolve, searching for a node that conforms with the provided predicate and return
 * its depth.
 *
 * @param resolve the resolve position in the node tree to start the search from
 * @param predicate the predicate to identify the node to find
 * @return depth the lowest depth (heighest value) at which a node confroms the provided predicate, -1 if no such position exists.
 */
export function findLowestDepth(resolve: PmResolvedPos, predicate: (node: PmNode) => boolean): number {
  for (let d = resolve.depth; d >= 0; d--) {
    const currentNode = resolve.node(d);
    if (predicate(currentNode)) {
      return d;
    }
  }
  return -1;
}

/**
 * Ascends in the node tree upwards from the given resolve, searching for a parent node that conforms with the provided predicate.
 *
 * @param resolve the resolve position in the node tree to start the search from
 * @param predicate the predicate to identify the node to find
 */
export function findParentNode(resolve: PmResolvedPos, predicate: (node: PmNode) => boolean): PmNode | null {
  const depth = findLowestDepth(resolve, predicate);
  if (depth === -1) {
    return null;
  }
  return resolve.node(depth);
}

/**
 * Determines the lowest node at a given position in a document (representen by a given root node).
 *
 * @param root the root node of the document.
 * @param pos the position that the returned node should contain.
 */
export function findNodeAtPosition(root: PmNode, pos: number) {
  const resolvedPos = root.resolve(pos);
  return resolvedPos.node(resolvedPos.depth);
}

/**
 * Determines the lowest node at a given position in a document (representen by the given state).
 *
 * @param state the state that contains the document.
 * @param pos the position that the returned node should contain.
 */
export function findNodeAtPositionInState(state: EditorState, pos: number) {
  return findNodeAtPosition(state.doc, pos);
}

/**
 * Traves the tree, searching for an arbitrary node that conforms with the provided predicate.
 *
 * @param root the root of the tree that should be searched
 * @param predicate the predicate to identify the node to find
 */
export function findChildNode(root: PmNode, predicate: (node: PmNode) => boolean): PmNode | null {
  let found: PmNode | null = null;
  root.descendants((node: PmNode) => {
    // I wasn't successfull to stop the descendants-function,
    // but at least we can skip the predicate evaluation and return the first node found.
    if (found) {
      return false;
    }
    if (predicate(node)) {
      found = node;
    }
    return true;
  });
  return found;
}

/**
 * Traves the tree, searching for the node that has the given GUID
 *
 * @param root the root of the tree that should be searched
 * @param guid the GUID of the target node
 */
export function findNodeByGuid(root: PmNode, guid: string | undefined): PmNode | null {
  if (!guid) {
    return null;
  }

  if (root.attrs.guid === guid) {
    return root;
  }
  return findChildNode(root, (innerNode: PmNode) => {
    return innerNode && innerNode.attrs.guid === guid;
  });
}

/**
 * Checks if the given node is a logical block.
 *
 * @param node that should be checked
 * @return true if the given node is a logical block, false otherwise.
 */
export function isLogicalBlock(node: PmNode): boolean {
  return node.attrs.logicalBlock;
}

/**
 * Checks if the given node is an inline block.
 *
 * @param node that should be checked
 * @return true if the given node is an inline block, false otherwise.
 */
export function isInlineBlock(node: PmNode): boolean {
  return (node.attrs.inlineMode == InlineMode.EXPLICIT) || (node.attrs.inlineMode == InlineMode.IMPLICIT);
}

/**
 * Checks if the given node is an explicit inline block.
 *
 * @param node that should be checked
 * @return true if the given node is an explicit inline block, false otherwise.
 */
export function isExplicitInlineBlock(node: PmNode): boolean {
  return node.attrs.inlineMode == InlineMode.EXPLICIT;
}

/**
 * Finds the depth of the lowest logical block (heigher or equal to the given startDepth) of the given resolved position.
 *
 * @param resolvedPos the resolvedPos that should be searched
 * @param startDepth an optional startDepth
 * @return the depth of the logical block node or -1 if there is no such logical block.
 */
export function findDepthOfLogicalBlock(resolvedPos: PmResolvedPos, startDepth = resolvedPos.depth): number {
  let depth = startDepth;
  while (depth >= 0) {
    const node = resolvedPos.node(depth);
    if (isLogicalBlock(node)) {
      return depth;
    }
    depth--;
  }
  return depth;
}

export class NodeRange {
  node: PmNode;
  start: number;
  end: number;

  constructor(node: PmNode, start: number, end: number) {
    this.node = node;
    this.start = start;
    this.end = end;
  }
}

/**
 * Finds the node-range (start,end) of those visited descendants (of a given node) that conforms with the provided (find)predicate. The
 * range is
 * shifted by the given offset.
 *
 * @param parent node for which its descendants should be found
 * @param recursionPredicate returning false stops the recursion, otherwise the descendants of the node are also visited/considered.
 * @param findPredicate the predicate to identify the nodes to find
 * @param offset that the range should be shifted
 * @return an array containg the range of all nodes that fullfills criteria described above.
 */
export function findDescendants(parent: PmNode,
                                recursionPredicate: (node: PmNode) => boolean,
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                findPredicate = ((_: PmNode) => true),
                                offset = 0): NodeRange[] {
  const children: NodeRange[] = [];
  parent.descendants((node: PmNode, pos: number) => {
    if (findPredicate(node)) {
      const nodeStart = offset + pos;
      const nodeEnd = nodeStart + node.nodeSize;
      children.push(new NodeRange(node, nodeStart, nodeEnd));
    }
    return recursionPredicate(node);
  });
  return children;
}

/**
 * Finds the node-range (start,end) of all descendants of a node that conforms with the provided predicate. The range is shifted by the
 * given offset.
 *
 * @param parent node for which its descendants should be found
 * @param predicate the predicate to identify the nodes to find
 * @param offset that the range should be shifted
 * @return an array containg the range of all nodes that fullfills criteria described above.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function findAllDescendants(parent: PmNode, predicate = ((_: PmNode) => true), offset = 0): NodeRange[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return findDescendants(parent, (_: PmNode) => true, predicate, offset);
}

/**
 * Finds the node-range (start,end) of all direct descendants of a node that conforms with the provided predicate. The range is shifted
 * by the given offset.
 *
 * @param parent node for which its descendants should be found
 * @param predicate the predicate to identify the nodes to find
 * @param offset that the range should be shifted
 * @return an array containg the range of all nodes that fullfills criteria described above.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function findAllDirectChildren(parent: PmNode, predicate = ((_: PmNode) => true), offset = 0): NodeRange[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return findDescendants(parent, (_: PmNode) => false, predicate, offset);
}

/**
 * Finds the node-range (start,end) of all ancestors that conforms with the provided predicate and lie in a given depth-range.
 *
 * @param resolvedPos the resolvedPos that should be searched
 * @param predicate the predicate to identify the nodes to find
 * @param startDepth the deepest depth (inclusive)
 * @param endDepth the lowest depth (inclusive)
 * @return an array containg the range of all nodes that fullfills criteria described above.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function findAllAncestorRanges(resolvedPos: PmResolvedPos, predicate = ((_: PmNode) => true), startDepth = resolvedPos.depth, endDepth = 0): NodeRange[] {
  const ancestors: NodeRange[] = [];

  for (let depth = startDepth; depth >= endDepth; depth--) {
    const node = resolvedPos.node(depth);
    if (predicate(node)) {
      const nodeStart = resolvedPos.start(depth) - 1;
      const nodeEnd = nodeStart + node.nodeSize;
      ancestors.push(new NodeRange(node, nodeStart, nodeEnd));
    }
  }
  return ancestors;
}

/**
 * Finds all ancestor nodes of the given position that conform with the provided predicate.
 * @param resolvedPos the position to get all ancestors nodes for
 * @param predicate   the predicate the ancestors must conform to be returned
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function findAllAncestorNodes(resolvedPos: PmResolvedPos, predicate = ((_: PmNode) => true)) {
  const ancestors: PmNode[] = [];

  for (let depth = resolvedPos.depth; depth >= 0; depth--) {
    const node = resolvedPos.node(depth);
    if (predicate(node)) {
      ancestors.push(node);
    }
  }
  return ancestors;
}

/**
 * Checks if the given node is the leftmost textblock of a given logical block.
 *
 * @param resolvedPos the resolved text position to be checked
 * @param candidate the node at the resolved text position to be checked
 * @return true if the given node is a logical block, false otherwise.
 */
export function isLeftmostTextblockOfLogicalBlock(resolvedPos: PmResolvedPos, candidate: PmNode): boolean {
  let guidOfLeftmostTextblock = "";

  const depth = findDepthOfLogicalBlock(resolvedPos);
  if (depth >= 0) {
    const logicalBlock = resolvedPos.node(depth);

    let minimum: number = Number.MAX_VALUE;

    logicalBlock.descendants((node: PmNode, pos: number) => {
      if (node.isTextblock && pos < minimum) {
        minimum = pos;
        guidOfLeftmostTextblock = node.attrs.guid;
      }
      return true;
    })
  }

  return guidOfLeftmostTextblock === candidate.attrs.guid;
}

/**
 * Get the the node-range (start,end) of a PmMode.
 *
 * @param resolvedPos the resolvedPos that should be searched
 * @param depth the level of the node
 * @param node the node for which the range to be found
 */
export function getNodeRange(resolvedPos: PmResolvedPos, depth: number, node: PmNode | null): NodeRange | null {
  const nodeStart = resolvedPos.start(depth) - 1;
  if (node) {
    const nodeEnd = nodeStart + node.nodeSize;
    return new NodeRange(node, nodeStart, nodeEnd);
  }
  return null;
}

/**
 * Finds the NodeRange of the lowest logical block within the provided resolved position.
 *
 * @param resolvedPos the resolved position to search within
 * @return the found NodeRange (node, start, end) of the lowest logical block
 */
export function findLogicalBlockNodeRange(resolvedPos: PmResolvedPos): NodeRange | null {
  const depth = findDepthOfLogicalBlock(resolvedPos);
  if (depth < 0) {
    return null;
  }
  return {
    node: resolvedPos.node(depth),
    start: resolvedPos.start(depth),
    end: resolvedPos.end(depth)
  };
}

/**
 * Finds the lowest logical block within the provided root at the specified position.
 *
 * @param root the root to search within
 * @param pos the position to resolve
 * @return the found lowest logical block
 */
export function findLogicalBlock(root: PmNode, pos: number): PmNode | null {
  const resolvedPos = root.resolve(pos);
  const depth = findDepthOfLogicalBlock(resolvedPos);
  if (depth < 0) {
    return null;
  }
  return resolvedPos.node(depth);
}

/**
 * Returns the GUID of the logical block specified by the provided position.
 *
 * @param root the root block to resolve the position
 * @param pos the position within the given root to resolve the logical block
 * @return the guid of the found logical block
 */
export function calcGuidOfLogicalBlock(root: PmNode, pos: number): string {
  const logicalBlock = findLogicalBlock(root, pos);
  if (logicalBlock === null) {
    return '';
  }
  return logicalBlock.attrs.guid;
}

export function calcSemanticTypeOfLogicalBlock(root: PmNode, pos: number): string {
  const logicalBlock = findLogicalBlock(root, pos);
  if (logicalBlock === null) {
    return '';
  }
  return logicalBlock?.attrs.semanticType;
}

/**
 * Determines the range of the block with the given GUID within the provided root block.
 *
 * @param root the root block to search the block within
 * @param guid the guid of the block to search for
 * @return the NodeRange of the found block
 */
export function findNodeRangeOfGuid(root: PmNode, guid: string): NodeRange | null {
  const nodeRanges = findAllDescendants(root, (node: PmNode) => {
    return node && node.attrs.guid === guid;
  });
  /*
  TODO rhe - use when client desync is fixed
  if(nodeRanges.length === 0){
    return null;
  } else if(nodeRanges.length === 1){
    return nodeRanges[0];
  } else {
    const guids = `[${nodeRanges.map(range => range.node.attrs.guid).reduce((prev, cur) => prev + ',' + cur, '')}]`;
    const docString = nodeToString(root);
    throw new Error('Duplicate GUID in document:' + '\n' + guids + '\n' + docString);
  }
   */
  return nodeRanges.length === 1 ? nodeRanges[0] : null;
}

/**
 * Returns the position of first (= leftmost) textblock in the given range within the provided root block.
 *
 * @param root the block to search the textblock within
 * @param nodeRange the range to search the textblock in
 * @return the position of the first textblock within range
 */
export function findFirstTextBlockInRange(root: PmNode, nodeRange: NodeRange): number | null {
  for (let i = nodeRange.start + 1; i < nodeRange.end; i++) {
    const resolvedPos = root.resolve(i);
    const node = resolvedPos.node();

    if (node.isTextblock) {
      return i;
    }
  }
  return null;
}

/**
 * Returns the position of last (= rightmost) textblock in the given range within the provided root block.
 *
 * @param root the block to search the textblock within
 * @param nodeRange the range to search the textblock in
 * @return the position of the last textblock within range
 */
export function findLastTextBlockInRange(root: PmNode, nodeRange: NodeRange): number | null {
  for (let i = nodeRange.end - 1; i > nodeRange.start; i--) {
    const resolvedPos = root.resolve(i);
    const node = resolvedPos.node();

    if (node.isTextblock) {
      return i;
    }
  }
  return null;
}

/**
 * Check if the content of a node with a specific guid is identical in the old and new state.
 * This compares start/end position and text content of both nodes.
 * The old implementation with findDiffStart() didn't work as expected when sub-nodes where replaced with equivalent ones (after saving).
 *
 * @param oldState old state
 * @param newState new state
 * @param guid GUID of the node to look for in both states
 * @return true, if identical
 */
export function isNodeContentIdentical(oldState: EditorState, newState: EditorState, guid: string): boolean {
  const oldNodeRange = findNodeRangeOfGuid(oldState.doc, guid);
  const newNodeRange = findNodeRangeOfGuid(newState.doc, guid);
  if (oldNodeRange && newNodeRange) {
    return oldNodeRange.start === newNodeRange.start
      && oldNodeRange.end === newNodeRange.end
      && oldNodeRange.node.textContent === newNodeRange.node.textContent;
  }
  return false;
}
