<template>
  <span class="application-figure">

    <!-- Use the modal component, pass in the prop -->
    <modal-dialog ref="application-figure-dialog" :showHeader="!isPreviewLoading" :dataLoaded="!isPreviewLoading" :showFooter="false">
      <template v-slot:header>
        <div class="header-message" v-show="!!headerText">
          <span class="icon warning-sign-wrapper">
            <i class="exi exi-warning-sign"></i>
          </span>
          {{ headerText }}
        </div>
      </template>
      <template v-slot:body>
        <img v-if="!isPreviewLoading && !!applicationFigurePreview"
             :src="applicationFigurePreview.fullResolution" :alt="$t('figure')"/>
        <i v-else class="exi exi-small-spinner-unmasked rotating"/>
      </template>
    </modal-dialog>

  </span>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import {FigureOrientation} from '../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import ApplicationFigureModule from '@/store/modules/ApplicationFigureModule';
import {ApplicationFigurePreview} from '@/api/models/applicationFigure.model';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    components: {
      ModalDialog
    }
  })
class ApplicationFigurePreviewDialog extends Vue {
  @Ref('application-figure-dialog') private dialog!: ModalDialogClass;

  contextWidth?: number;
  contextHeight: number | undefined = window.innerHeight;
  contextMargin: string | undefined = 'auto auto auto auto';

  get isPreviewLoading(): boolean {
    return ApplicationFigureModule.isPreviewLoading;
  }

  get applicationFigurePreview(): ApplicationFigurePreview | null {
    return ApplicationFigureModule.applicationFigurePreview;
  }

  get headerText(): string {
    return !this.applicationFigurePreview?.upToDate ? this.$t('figureRendering.notUpToDate') as string :
      this.applicationFigurePreview.error ? this.$t('figureRendering.error') as string :
        '';
  }

  async open(applicationFigureGuid: string): Promise<void> {
    this.dialog.openModal();

    await ApplicationFigureModule.getApplicationFigurePreview(applicationFigureGuid)
      .catch(useDefaultErrorHandling);
  }

  setWidth(width: number | undefined): void {
    this.contextWidth = width;
  }

  setMargin(margin: string | undefined): void {
    this.contextMargin = margin;
  }
}

export default toNative(ApplicationFigurePreviewDialog);
export {ApplicationFigurePreviewDialog};
</script>

<style lang="scss">
@import "src/assets/styles/colors";

.application-figure {
  .modal-dialog {

    &-dialog {
      min-width: 400px;
      min-height: 220px;
      width: auto;
      height: auto;

      .modal-dialog-header {
        flex-direction: row;
        justify-content: flex-end;

        .icon-button {
          margin-top: 0;
          margin-right: 0;
          padding: 0;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .header-message {
          width: 100%;
          font-weight: bold;
          font-size: small;
          margin: 2rem 0 0 1rem;
          display: flex;

          .warning-sign-wrapper {
            width: 18px;
            height: 18px;
            margin: 0 5px;

            i {
              background-color: $pengine-warn-yellow;
            }
          }
        }
      }
    }

    &-body {
      display: flex;
      flex: 1;

      img {
        max-width: 80vw;
        max-height: 80vh;
        object-fit: contain;
      }

      i {
        display: block;
        margin: auto auto;
      }
    }
  }
}
</style>
