import {Extension} from '@tiptap/vue-3';
import {
  changeNodeAttribute,
  debugDumpAllNodesOfDocument,
  debugDumpElementsAtAllDocumentPositions,
  debugDumpRootNodeOfDocument,
  debugPrintAnchorPosition,
  debugPrintStructureAtAnchorLevel,
  printLogicalBlockNodeAttributes
} from '@/components/applicationEditor/utils/debug.util';
import {EditorState} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    printAnchor: {
      printAnchor: () => ReturnType,
    }
  }
}
/**
 * Extension so that developer can print the prose mirror structure to the console
 */
export const DeveloperDebugExtension = Extension.create(
  {
    name: 'developerCheatcodes',

    addCommands() {
      return {
        printAnchor: () => (editorProps: { state: EditorState, view: EditorView }): boolean => {
          return debugPrintAnchorPosition(editorProps);
        }
      }
    },

    addKeyboardShortcuts() {
      return {
        'Ctrl-Alt-D': () => debugDumpElementsAtAllDocumentPositions(this.editor),
        'Ctrl-Alt-6': () => debugPrintStructureAtAnchorLevel(6)(this.editor),
        'Ctrl-Alt-5': () => debugPrintStructureAtAnchorLevel(5)(this.editor),
        'Ctrl-Alt-4': () => debugPrintStructureAtAnchorLevel(4)(this.editor),
        'Ctrl-Alt-3': () => debugPrintStructureAtAnchorLevel(3)(this.editor),
        'Ctrl-Alt-2': () => debugPrintStructureAtAnchorLevel(2)(this.editor),
        'Ctrl-Alt-1': () => debugPrintStructureAtAnchorLevel(1)(this.editor),
        'Ctrl-Alt-0': () => debugPrintStructureAtAnchorLevel(0)(this.editor),
        'Ctrl-Alt-P': () => debugPrintAnchorPosition(this.editor),
        'Ctrl-Alt-T': () => debugDumpAllNodesOfDocument(this.editor),
        'Ctrl-Alt-N': () => changeNodeAttribute(this.editor),
        'Ctrl-Alt-M': () => printLogicalBlockNodeAttributes(this.editor),
        'Ctrl-Alt-B': () => debugDumpRootNodeOfDocument(this.editor)
      }
    }
  });
