import {ApplicationFigurePreview, ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';

export enum ImageUploadType {NEW_FIGURE, CHANGE_BASE_IMAGE}

export interface ApplicationFigureState {
  isApplicationFiguresLoading: boolean;
  applicationFigures: Array<ApplicationFigureThumbnail>;
  isPreviewLoading: boolean;
  applicationFigurePreview: ApplicationFigurePreview | null;
}

export interface ApplicationFigureListEntry extends ApplicationFigureThumbnail {
  edit: boolean;
}