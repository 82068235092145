import {calculateDateIn90DaysFromNow} from '@/util/time.util';

export const cookies = Object.freeze({
                                       cookieConsent: 'COOKIE_CONSENT',
                                       functionals: {
                                         keycloak: {
                                           functionalIdentifier: 'FUNCTIONAL_KEYCLOAK_LOCALE',
                                           technicalCookie: 'KEYCLOAK_LOCALE',
                                         },
                                       },
                                     });

export const isAcceptedCookieValue = 'isAccepted';

export const checkIfCookieExists = (cookieName: string): boolean => {
  const name = `${cookieName}`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return true;
    }
  }
  return false;
}

export const createCookie = (cookieName: string, cookieValue: string, expires?: string): void => {
  document.cookie = `${cookieName}=${cookieValue};expires=${expires}`;
}

export const deleteCookie = (cookieName: string): void => {
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
}

export const createEssentialCookie = (): void => {
  createCookie(cookies.cookieConsent,
               isAcceptedCookieValue,
               calculateDateIn90DaysFromNow().toUTCString());
}

export const createFunctionalCookie = (): void => {
  createCookie(cookies.functionals.keycloak.functionalIdentifier,
               isAcceptedCookieValue,
               calculateDateIn90DaysFromNow().toUTCString());
}