import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {SearchState} from '@/store/models/search.model';
import {SearchQuery, SearchResult, SearchSpace} from '@/api/models/search.model';
import {SendSearchRequest} from '@/api/services/search.api';
import {Brick, BrickType, TermDefinition} from '@/api/models/library.model';
import {GetBrick, GetTermDefinition} from '@/api/services/library.api';

@Module({dynamic: true, namespaced: true, store, name: 'search'})
class SearchModule extends VuexModule implements SearchState {

  // Initial state
  private _isLoading = false;
  private _searchSpace = SearchSpace.TERM;
  private _results: Array<SearchResult> = [];
  private _resultDetails: Brick | TermDefinition = {
    guid: '',
    type: BrickType.A,
    name: '',
    text: '',
    keywords: [],
    locale: 'de'
  };

  get isLoading(): boolean {
    return this._isLoading;
  }

  get searchSpace(): SearchSpace {
    return this._searchSpace;
  }

  get results(): Array<SearchResult> {
    return this._results;
  }

  get resultDetails(): Brick | TermDefinition {
    return this._resultDetails;
  }

  @Mutation
  private setSearchLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  private setSearchSpace(searchSpace: SearchSpace): void {
    this._searchSpace = searchSpace;
  }

  @Mutation
  private sendSearchRequestEnd(results: Array<SearchResult> | null) {
    if (results != null) {
      this._results = results;
    }
    this._isLoading = false;
  }

  @Mutation
  private getSearchResultDetailsEnd(resultDetails: Brick | TermDefinition | null) {
    if (resultDetails != null) {
      this._resultDetails = resultDetails;
    }
    this._isLoading = false;
  }

  @Action
  storeSearchSpace(searchSpace: SearchSpace): void {
    this.setSearchSpace(searchSpace);
  }

  @Action
  async sendSearchRequest(query: SearchQuery): Promise<Array<SearchResult>> {
    this.setSearchLoading();
    return SendSearchRequest(query).then((results: Array<SearchResult>) => {
      this.sendSearchRequestEnd(results);
      return results;
    }).catch((error) => {
      this.sendSearchRequestEnd(null);
      throw error;
    });
  }

  @Action
  async getSearchResultDetails(searchResult: SearchResult): Promise<Brick | TermDefinition> {
    this.setSearchLoading();
    let getBrickOrTermPromise: Promise<Brick | TermDefinition>;
    if (searchResult.searchSpace === SearchSpace.TERM) {
      getBrickOrTermPromise = GetTermDefinition(searchResult.guid);
    } else {
      getBrickOrTermPromise = GetBrick(searchResult.guid);
    }
    return getBrickOrTermPromise.then((resultDetails: Brick | TermDefinition) => {
      this.getSearchResultDetailsEnd(resultDetails);
      return resultDetails;
    }).catch((error) => {
      this.getSearchResultDetailsEnd(null);
      throw error;
    });
  }
}

export default getModule(SearchModule);
