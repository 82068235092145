<template>
  <div class="user-icon-container">
    <b-dropdown
      v-if="!isLoading"
      :mobile-modal="false"
      position="is-bottom-left"
      append-to-body
      aria-role="menu">
      <template #trigger>
        <button class="icon-button user-icon"
                :title="$t('header.userInfo')">
          <i class="exi exi-face"/>
        </button>
      </template>

      <!-- user info -->
      <b-dropdown-item aria-role="listitem" :title="$t('header.userInfo')" disabled>
        <div class="media">
          <i class="exi exi-user-info media-left"/>
          <div class="media-content" v-if="user">
            {{ user.firstName + ' ' + user.lastName }}
          </div>
        </div>
      </b-dropdown-item>

      <!-- logout -->
      <b-dropdown-item aria-role="listitem" :title="$t('header.logout')" @click="() => this.logout()">
        <!-- Redirects the user to the home page -->
        <div class="media">
          <i class="exi exi-logout media-left"/>
          <div class="media-content">
            {{ $t('header.logout') }}
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>

    <!-- loading icon -->
    <button v-if="isLoading"
            :title="$t('general.loading')"
            class="icon-button loading-button">
      <i class="exi exi-small-spinner-unmasked rotating"/>
    </button>
  </div>
</template>

<script lang="ts">
import {Component, toNative, Vue} from 'vue-facing-decorator';
import {UserData} from '@/store/models/auth.model';
import AuthModule from '@/store/modules/AuthModule';

// Build the logout URI. It consists of a call of an endpoint of the oauth2proxy that has a query parameter "rd" to
// redirect to the keycloak logout. The keycloak logout endpoint has a "redirect_uri" query parameter that will
// contain the URL of the front page.
// With these two endpoint calls we will have cleared the oauth2proxy cookie and have been logged out of keycloak.
const encodedFrontpageLocation = encodeURI(`${window.location.protocol}//${window.location.host}`);
const proxyRedirectUri = `${window.location.protocol}//${window.location.host}`
  + `/realms/patent-engine/protocol/openid-connect/logout?redirect_uri=${encodedFrontpageLocation}`;
const encodedProxyRedirectUri = encodeURI(proxyRedirectUri);
const logOutHref = `/oauth2/sign_out?rd=${encodedProxyRedirectUri}`;
@Component({})
class UserMenu extends Vue {
  get user(): UserData | null {
    return AuthModule.user;
  }

  get isLoading(): boolean {
    return AuthModule.isLoading;
  }

  private logout(): void {
    console.log('logging out')
    window.location.href = logOutHref;
  }
}

export default toNative(UserMenu);
</script>

<style scoped lang="scss">
.user-icon-container {
  padding-right: 16px;
  align-self: center;

  .exi-face { // eXXcellent Icons
    height: 48px;
    width: 48px;
  }
}
</style>