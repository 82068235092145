<template>
  <div>
    <!-- Current Node -->
    <div v-if="node.visible"
         :id="`document-structure-tree-${node.guid}`"
         class="document-structure-node"
         :style="indent"
         @click="toggleChildren"
         :class="node.active ? 'document-structure-node-selected' : ''">

      <!-- Show expand icon if node has children -->
      <i v-if="node.hasChildren()" :class="'exi exi-tree-node-' + (node.expanded ? 'expanded' : 'collapsed')"></i>
      <div class="inner-node-container" :class="node.isEmpty ? 'emptyNode' : ''">
        {{ getName() }}
      </div>

      <!-- Change for PENGINE-701. Warnings will be activated again so leave this here-->
      <!--div v-if="node.reviewNeeded" class="warning-wrapper" @click="reviewNode">
        <i :class="'exi exi-report-problem'"></i>
        <i :class="'exi exi-report-problem-empty'" :title="$t('nodes.reviewNeeded')"></i>
      </div-->

    </div>

    <!-- Recursive Tree -->
    <div v-if="node.expanded">
      <Tree
        v-for="child in this.node.children"
        :key="child.guid"
        :node="child"
        :depth="depth + 1"
        @onClick="(node) => emitOnClick(node)"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, toNative, Vue} from 'vue-facing-decorator';
import {DocumentStructureNode} from '@/components/documentStructureTree/DocumentStructureNode';
import CoverSheetDialog from '@/views/CoverSheetDialog.vue';
import EditorModule from '@/store/modules/EditorModule';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    name: 'Tree',
    components: {
      CoverSheetDialog,
    }
  })
class Tree extends Vue {

  @Prop() node!: DocumentStructureNode;
  @Prop({default: 0}) readonly depth!: number;

  @Emit('onClick')
  emitOnClick(node: DocumentStructureNode) {
    return node;
  }

  private toggleChildren(): void {
    this.node.hasChildren() ? this.node.toggleExpanded() : this.emitOnClick(this.node);
  }

  private reviewNode(): void {
    EditorModule.reviewBlock(this.node.guid).catch(useDefaultErrorHandling);
  }

  get indent() {
    const offset = this.node.hasChildren() ? 0 : 17;
    return {marginLeft: `${(this.depth - 1) * 20 + offset}px`}
  }

  getName(): string {
    // get the template text
    return ApplicationModule.templateText(`${this.node.name}.name`, this.node.properties);
  }
}

export default toNative(Tree);
</script>


<style lang="scss">

.edit-coversheet-icon {
  > .icon-button {
    margin-bottom: 0px !important;
    padding-right: 0px !important;
  }
}

</style>


<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.tree {
  > div {
    min-width: 100%;
    width: fit-content;
  }
}

.document-structure-node {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 24px;

  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;

  div {
    text-align: left;
    font-size: $font-size-normal;
    font-family: $text-font;
  }
}

.document-structure-node-selected {
  color: black;
  background-color: $pengine-orange-light;
  width: inherit;
}

.inner-node-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
}

.emptyNode {
  color: $pengine-document-structure-tree-empty-block;
}

.exi {
  align-self: center;
  height: 12px !important;
  width: 12px !important;
  margin-left: 5px;
  flex-shrink: 0;
}

.warning-wrapper {
  margin-left: -6px;

  .exi {
    width: 16px !important;
    height: 16px !important;;
  }

  .exi-report-problem-empty {
    display: none;
  }
}

.warning-wrapper:hover {
  .exi-report-problem-empty {
    display: inherit;
  }

  .exi-report-problem {
    display: none;
  }
}
</style>
