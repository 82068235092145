export enum ProsemirrorTransactionMeta {
  ADD_TO_HISTORY = 'addToHistory',
  APPENDED_TRANSACTION = 'appendedTransaction',
  CLEAR_SEARCH = 'clearSearch',
  DISPATCH_SOURCE = 'dispatchSource',
  INITIAL_STATE = 'initialState',
  IS_SEARCH_REPLACEMENT = 'isSearchReplacement',
  KEEP_LOCAL_HISTORY = 'keepLocalHistory',
  PASTE = 'paste',
  PATENTENGINE_HISTORY_PLUGIN = 'patentengineHistoryPlugin',
  PATENTENGINE_ALLOWED_TRANSFORMATION = 'patentengineAllowedTransformation',
  PROCESSED_IN_BLOCK_NODE_DELETION_PLUGIN = 'processedInBlockNodeDeletionPlugin',
  REBASED = 'rebased',
  SPELLCHECK_RESULT = 'spellcheckResult',
  UPDATE_ACTIVE_EDITOR = 'updateActiveEditor',
  UPDATE_BLOCK_FAILED = 'updateBlockFailed',
  UPDATE_FROM_BACKEND = 'updateFromBackend',
  UPDATE_LOGICAL_DEPTH_ATTRIBUTE = 'updateLogicalDepthAttr',
  REPLICATED_TRANSACTION = 'replicatedTransaction'
}
