import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {PermissionState} from '@/store/models/permission.model';
import {Permission} from '@/api/models/permission.model';
import {GetPermissions} from '@/api/services/permission.api';
import {SplashScreenDialog as SplashScreenDialogClass} from '@/components/SplashScreenDialog.vue';

@Module({dynamic: true, namespaced: true, store, name: 'permission'})
class PermissionModule extends VuexModule implements PermissionState {

  // initial state
  private _isLoading = false;
  private _permissions: Array<Permission> = [];
  private _splashScreen: SplashScreenDialogClass | null = null; // Reference to the splash screen
  private _successfulResponses = 0; // Count how often OnResponseSuccess was called

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get permissions(): Array<Permission> {
    return this._permissions;
  }

  public get successfulResponses(): number {
    return this._successfulResponses;
  }

  public get splashScreen(): SplashScreenDialogClass | null {
    return this._splashScreen;
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  @Mutation
  public setPermissions(permissions: Array<Permission>): void {
    this._permissions = permissions;
  }

  @Mutation
  public increaseSuccessfulResponses(): void {
    this._successfulResponses++;
  }

  @Mutation
  public setSplashScreen(splashScreen: null | SplashScreenDialogClass): void {
    this._splashScreen = splashScreen;
  }

  @Action
  public getPermissions(): Promise<void> {
    this.setIsLoading(true);
    return GetPermissions().then((permissions: Array<Permission>) => {
      this.setIsLoading(false);
      this.setPermissions(permissions);
    }).catch((error) => {
      console.error(error);
      this.setIsLoading(false);
      this.setPermissions([]);
    });
  }
}

export default getModule(PermissionModule);
