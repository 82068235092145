// Classes for the spellcheck responses

export class SpellcheckContext {
    constructor(context: SpellcheckContext) {
        this.text = context.text;
        this.offset = context.offset;
        this.length = context.length;
    }

    text: string;
    offset: number;
    length: number;
}

export enum SpellcheckCategoryID {
    CONFUSED_WORDS = 'confused-words',
    PUNCTUATION = 'punctuation',
    HILFESTELLUNG_KOMMASETZUNG = 'hilfestellung-kommasetzung',
    GRAMMAR = 'grammar', // grammar
    MISC = 'misc', // misspelling
    SEMANTICS = 'semantics',
    CASING = 'casing',

    TYPOS = 'typos', // misspelling

    REDUNDANCY = 'redundancy'
}

export class SpellcheckRuleURL {
    constructor() {
        this.value = '';
    }

    value: string;
}

export class SpellcheckRule {
    constructor(rule: SpellcheckRule) {
        this.id = rule.id;
        this.description = rule.description;
        this.issueType = rule.issueType;
        this.category = {
            id: rule.category.id,
            name: rule.category.name
        }
    }

    id: string;
    description: string;
    issueType: string;
    category: {
        id: SpellcheckCategoryID;
        name: string;
    };
    urls?: SpellcheckRuleURL[];
}

export class SpellcheckReplacement {
    constructor() {
        this.value = '';
    }

    value: string;
    shortDescription?: string;
}

// Only sent by the backend, so an interface is enough
export interface SpellcheckMatch {
    message: string;
    shortMessage: string;
    replacements: SpellcheckReplacement[];
    offset: number;
    length: number;
    context: SpellcheckContext;
    rule: SpellcheckRule;
}

// Only sent by the backend, so an interface is enough
export interface SpellcheckApiResult {
    software?: any;
    warnings?: any;
    language?: any;
    matches: SpellcheckMatch[];
}

// Classes for the client side spellcheck handling

// Other

export class SpellcheckData {
    constructor() {
        this.annotation = [];
    }

    annotation: AnnotationItem[];

    public isEmpty(): boolean {
        return this.annotation.length === 0;
    }

    public add(...items: AnnotationItem[]): void {
        this.annotation.push(...items);
    }
}

export abstract class AnnotationItem {
}

export class TextAnnotationItem extends AnnotationItem {
    constructor(text: string) {
        super();
        this.text = text;
    }

    text: string;
}

export class MarkupAnnotationItem extends AnnotationItem {
    constructor(text: string, interpretAs?: string) {
        super();
        this.markup = text;
        this.interpretAs = interpretAs;
    }

    markup: string;
    interpretAs?: string;
}

export enum AnnotationType {
    Text,
    Markup
}

// Dictionary Interfaces

export interface SpellcheckWord {
    word: string;
    guid: string;
}

export interface SpellcheckDictionaryWord extends SpellcheckWord {
    language: string;
}

export interface SpellcheckIgnoreWord extends SpellcheckWord {
    applicationDocumentGuid: string;
    errorType: string;
}

// Other

export interface SpellcheckParams {
    applicationDocumentGuid: string;
    locale: string;
    spellcheckData: string | null;
    logicalBlockGuid: string;
    nodePosOffset: number; // This offset must be added to the node start pos to get the original range start pos
    skip?: boolean; // Marker that this request can be skipped, because another one for the same block is scheduled
}
