import {Extension} from '@tiptap/vue-3';
import {EditorState as PmEditorState, Plugin, Transaction as PmTransaction} from '@tiptap/pm/state';
import {Step as PmStep} from '@tiptap/pm/transform';
import {Node as PmNode} from '@tiptap/pm/model';

/**
 * Extension to check exceeded lengths of textblocks.
 * If a node has the "maxLength" attribute and its content text is longer than that number,
 * all transaction steps that lead to this new state are inverted.
 */
export const MaxLengthExtension = Extension.create(
  {
    name: 'maxLengthExtension',

    addProseMirrorPlugins() {
      return [
        new Plugin(
          {
            appendTransaction(transactions: readonly PmTransaction[], oldState: PmEditorState, newState: PmEditorState) {
              const selNode = newState.selection.$anchor.node();
              // Check if content of the current node is not too long
              if (!selNode.attrs.maxLength || selNode.textContent.length <= selNode.attrs.maxLength) {
                return;
              }

              // Helper function to find nodes by guid
              const findNode = (state: PmEditorState, guid: string): PmNode | null => {
                let foundNode = null;
                state.doc.descendants((node: PmNode) => {
                  if (node.attrs.guid === guid) {
                    foundNode = node;
                    return false;
                  }
                  return true;
                });
                return foundNode;
              };

              const additionalTransaction: PmTransaction = newState.tr;

              const oldNode = findNode(oldState, selNode.attrs.guid);
              const newNode = findNode(newState, selNode.attrs.guid);

              // If the nodes could not be found or the content of these nodes (same node before & after) are the same
              if (!oldNode || !newNode || (oldNode.textContent === newNode.textContent)) {
                return;
              }

              // If both states of the node were found and the text changed we must revert
              if (oldNode && newNode && oldNode.textContent != newNode.textContent) {
                let inverted = false;
                // Revert changes
                transactions.forEach((transaction: PmTransaction) => {
                  transaction.steps.forEach((step: PmStep) => {
                    additionalTransaction.step(step.invert(transaction.before));
                    inverted = true;
                  });
                });

                if (inverted) {
                  return additionalTransaction;
                }
              }
            }
          })
      ];
    }
  });
