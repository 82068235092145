<template>
  <node-view-wrapper class="rootNode" spellcheck="false">
    <node-view-content></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, toNative, Vue} from 'vue-facing-decorator';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a {@link RootNode}.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class RootNodeView extends Vue {

}

export default toNative(RootNodeView);
</script>

<style lang="scss">
</style>
