import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import RequestQueueModule from '@/store/modules/RequestQueueModule';

/**
 * Interface for an item in the request queue,
 * wrapping the request itself and it's resolving function.
 */
interface RequestQueueItem {
  request: InternalAxiosRequestConfig;
  resolver: (request: InternalAxiosRequestConfig) => void;
}

/**
 * A simple queue for requests to resolve them one after another.
 */
class RequestQueue {

  private readonly queue: RequestQueueItem[];
  private running: RequestQueueItem | null;
  private readonly resolveNextFunction: (queue: RequestQueueItem[]) => RequestQueueItem | undefined;

  constructor(resolveNext: (queue: RequestQueueItem[]) => (RequestQueueItem | undefined)) {
    this.queue = [];
    this.running = null;
    this.resolveNextFunction = resolveNext;
  }

  /**
   * Queues up the given request. If the queue is empty right now, this also triggers the resolving it.
   * @param requestWithResolver the request to queue and it's resolving function
   */
  public push(requestWithResolver: RequestQueueItem) {
    this.queue.push(requestWithResolver);

    // if no request is running right now (because the queue was empty), we can resolve the pushed request right away
    setTimeout(() => {
      if (!this.running) {
        this.resolveNextInternal();
      }
    }, 0);
  }

  /**
   * Triggers the next request in queue to be resolved.
   */
  public resolveNext() {
    this.running = null;
    this.resolveNextInternal();
  }

  private resolveNextInternal() {
    if (this.queue.length > 0) {
      const queued: RequestQueueItem | undefined = this.resolveNextFunction(this.queue);
      if (queued) {
        queued.resolver(queued.request);
        this.running = queued;
      }
    }
  }
}

/**
 * Creates and sets up an axios instance which queues it's requests and executes them according to the provided strategy.
 *
 * @param config              the axios configuration to use for the created axios instance
 * @param onSuccess           the response handler for handling successful requests
 * @param onFailure           the response handler for handling failed requests
 * @param queueInstance       the queue instance with the strategy to get the next queue item for resolving
 *                            (contains the resolveNext function)
 * @param _requestInterceptor a request interceptor (triggert before queueing up requests)
 */
const createQueuedAxiosInstance = (config: InternalAxiosRequestConfig,
                                   onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
                                   // eslint-disable-next-line
                                   onFailure: (error: any) => any,
                                   queueInstance: RequestQueue,
                                   _requestInterceptor?: (value: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>) => {

  const axiosInstance = axios.create(config);

  // A request interceptor to queue up requests
  const requestInterceptor = _requestInterceptor ? _requestInterceptor
    : (value: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
      RequestQueueModule.startingRequest();
      return new Promise(resolve => {
        queueInstance.push({request: value, resolver: resolve});
      });
    };

  // A response interceptor to shift to next queued request and handle success
  const onResponseFulfilled = (value: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
    queueInstance.resolveNext();
    RequestQueueModule.requestDone();
    return onSuccess(value);
  }

  // A response interceptor to shift to next queued request and handle failure
  // eslint-disable-next-line
  const onResponseRejected = (error: any): any | undefined => {
    queueInstance.resolveNext();
    RequestQueueModule.requestDone();
    return onFailure(error);
  }

  // setup interceptors
  axiosInstance.interceptors.request.use(requestInterceptor);
  axiosInstance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

  return axiosInstance;
}

/**
 * Creates and sets up an axios instance which queues it's requests and executes them one after another (FIFO).
 *
 * @param config      the axios configuration to use for the created axios instance
 * @param onSuccess   the response handler for handling successful requests
 * @param onFailure   the response handler for handling failed requests
 */
export const createFirstInFirstOutAxiosInstance = (config: InternalAxiosRequestConfig,
                                                   onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
                                                   // eslint-disable-next-line
                                                   onFailure: (error: any) => any) => {

  // Standard FIFO behaviour: shift to the next item in queue
  const resolveNext: (queue: RequestQueueItem[]) => RequestQueueItem | undefined = (queue) => queue.shift();
  return createQueuedAxiosInstance(config, onSuccess, onFailure, new RequestQueue(resolveNext));
}

/**
 * Creates and sets up an axios instance which queues all requests but only executes the latest while discarding all other requests.
 *
 * @param config      the axios configuration to use for the created axios instance
 * @param onSuccess   the response handler for handling successful requests
 * @param onFailure   the response handler for handling failed requests
 */
export const createLatestOnlyAxiosInstance = (config: InternalAxiosRequestConfig,
                                              onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
                                              // eslint-disable-next-line
                                              onFailure: (error: any) => any) => {

  // Get the latest item in queue, then clear the queue
  const resolveNext: (queue: RequestQueueItem[]) => RequestQueueItem | undefined = (queue) => {
    const next = queue.pop();
    while (queue.length) {
      queue.pop();
    }
    return next;
  }

  return createQueuedAxiosInstance(config, onSuccess, onFailure, new RequestQueue(resolveNext));
}

const createRepetitionError = (request: InternalAxiosRequestConfig): any => {
  return {
    config: request,
    response: {
      status: -42, // OnResponseFailure will ignore this one
    },
    message: 'Rejected because of repetition'
  };
}

/**
 * Creates and sets up an axios instance which queues all requests but only executes that ones, that didn't already occure in the last
 * 5 seconds.
 *
 * @param config           the axios configuration to use for the created axios instance
 * @param onSuccess        the response handler for handling successful requests
 * @param onFailure        the response handler for handling failed requests
 * @param noRepeatTime     time in ms that a request should not be repeated
 */
export const createNoRepeatAxiosInstance = (config: InternalAxiosRequestConfig,
                                            onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
                                            // eslint-disable-next-line
                                            onFailure: (error: any) => any,
                                            noRepeatTime: number) => {

  // List of the previous requests that should not be repeated
  const previousRequests: InternalAxiosRequestConfig[] = [];

  // Standard FIFO behaviour: shift to the next item in queue
  const resolveNext: (queue: RequestQueueItem[]) => RequestQueueItem | undefined = (queue) => queue.shift();

  const queueInstance = new RequestQueue(resolveNext);

  // Check each new request and only allow requests that were not already sent in the last noRepeatTime ms.
  const requestInterceptor = (value: InternalAxiosRequestConfig): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {

    // Compare with previous requests and prevent sending the same repetitive in a short time
    for (const oldRequest of previousRequests) {
      // We found a similar one - skip this
      if (oldRequest.url === value.url && oldRequest.data === value.data) {
        return Promise.reject(createRepetitionError(value));
      }
    }

    // Remember
    previousRequests.push(value);
    setTimeout(() => {
      // Remove it after a while
      if (value) {
        const index = previousRequests.indexOf(value);
        if (index > -1) {
          previousRequests.splice(index, 1);
        }
      }
    }, noRepeatTime);

    return new Promise(resolve => {
      queueInstance.push({request: value, resolver: resolve});
    });
  };

  return createQueuedAxiosInstance(config, onSuccess, onFailure, queueInstance, requestInterceptor);
}