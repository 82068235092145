export default class Menu {
  private options: any;
  private preventHide: boolean;
  private mousedownHandler: any;
  private blurHandler: any

  constructor(props: {options: any}) {
    this.options = props.options;
    this.preventHide = false; // the mousedown event is fired before blur so we can prevent it

    this.mousedownHandler = this.handleClick.bind(this);
    this.options.element.addEventListener('mousedown', this.mousedownHandler, {
      capture: true
    });

    this.blurHandler = () => {
      if (this.preventHide) {
        this.preventHide = false;
        return;
      }

      this.options.editor.emit('menubar:focusUpdate', false);
    };

    this.options.editor.on('blur', this.blurHandler);
  }

  handleClick() {
    this.preventHide = true;
  }

  destroy() {
    this.options.element.removeEventListener('mousedown', this.mousedownHandler);
    this.options.editor.off('blur', this.blurHandler);
  }

}