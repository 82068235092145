import {createApp} from 'vue';
import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router';
import store from './store';
import i18n from './i18n';
import Buefy from '@ntohq/buefy-next';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import detectBrowser from "vue-detect-browser";
import '@/assets/styles/main.scss';
import '@ntohq/buefy-next/dist/buefy.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'vue-easy-dnd/dist/dnd.css'
import activeElementDirective from '@/directives/ActiveElementDirective';
import mouseMarginDirective from '@/directives/MouseMarginDirective';
import clickOutsideDirective from '@/directives/ClickOutsideDirective';

const app = createApp(App); // Create a Vue 3 app instance

app.use(VueAxios, axios)
app.use(i18n); // Use i18n
app.use(Buefy);
app.use(PerfectScrollbar); // Use PerfectScrollbar
app.use(detectBrowser); // Use Browser Detect

app.use(router); // Use Vue Router
app.use(store); // Use Vuex

// use if you want to know about the currently active element having the focus
app.directive('active-element',activeElementDirective)

// use if you want to know when a mouse button has moved a certain threshold away from an element
app.directive('mouse-margin-leave',mouseMarginDirective)

// use if you want to be informed that the user has clicked somewhere outside an element - children of an element count as inside
app.directive('click-outside', clickOutsideDirective)

app.mount('#app'); // Mount your app to the DOM
