/**
 * Number of characters of a label that is shown in the UI. Labels that are longer are truncated
 */
export const MAX_SHOWN_LABEL_LENGTH = 10;

export function shorten(word: string, maxLength: number): string {
  if (word.length > maxLength) {
    return word.substr(0, maxLength) + '...';
  }
  return word;
}
