import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-85ebb498"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  class: "content",
  ref: "canvas-content-div"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_Canvas = _resolveComponent("Canvas")!
  const _directive_active_element = _resolveDirective("active-element")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "figureEditor",
    ref: "figureEditor",
    tabindex: "-1",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (this.figureEditor.focus())),
    onMouseenter: _cache[1] || (_cache[1] = () => this.hasMouseInWidget = true),
    onMouseleave: _cache[2] || (_cache[2] = () => this.hasMouseInWidget = false),
    onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
  }, [
    _createVNode(_component_ConfirmationDialog, { ref: "confirmationDialog" }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Toolbar, {
        disabled: _ctx.toolbarDisabled,
        "figure-number-model": _ctx.figureNumberComputed,
        "figure-list": this.figures,
        "figure-number-showing": this.figureNumberShowing,
        "zoom-in-clicked": () => this.canvas.zoomIn(),
        "zoom-out-clicked": () => this.canvas.zoomOut(),
        "full-zoom-out-clicked": () => this.canvas.onFullZoomOut(),
        "scaling-changed": (newScaling, withContentArea) => this.canvas.setScaling(newScaling, withContentArea),
        "figure-number-clicked": () => this.setShowFigureNumber(!this.figureNumberShowing),
        "toggle-help-lines": () => this.onToggleHelpLines(),
        "underline-button-clicked": () => this.canvas.changeUnderliningSelected(),
        "delete-button-clicked": () => this.canvas.removeSelected(),
        "figure-number-changed": (newFigureNumber) => this.figureNumberChanged(newFigureNumber)
      }, null, 8, ["disabled", "figure-number-model", "figure-list", "figure-number-showing", "zoom-in-clicked", "zoom-out-clicked", "full-zoom-out-clicked", "scaling-changed", "figure-number-clicked", "toggle-help-lines", "underline-button-clicked", "delete-button-clicked", "figure-number-changed"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Canvas, {
        ref: "canvas",
        "focusable-parent": _ctx.figureEditor,
        "client-width": _ctx.widthComputed,
        "client-height": _ctx.clientHeightComputed,
        "_application-figure": _ctx.applicationFigureEdit
      }, null, 8, ["focusable-parent", "client-width", "client-height", "_application-figure"])
    ], 512)
  ], 32)), [
    [_directive_active_element, _ctx.onActiveElementChange],
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}