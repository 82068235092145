import {EditorView} from '@tiptap/pm/view';
import {coordsAtPos} from '@/components/applicationEditor/plugins/MenuBubble/PengineMenuBubble';

interface MenuDisplayData {
  /** Left position of the cursor. */
  left: number;
  /** Bottom position of the cursor. */
  bottom: number;
  /** Top position of the cursor. */
  top: number;
  /** Whether or not there is an active selection. */
  isActive: boolean;
}

/**
 * Calculate position for the content of the menu bubble (left, top, bottom).
 * This can be used in a CSS style but only *one* of top and bottom is needed (for aligning below or above).
 * The bubble content is positioned according to the 'from' and 'to' editor position (maybe the selection).
 * Horizontally it's centered between both, vertically it's aligned just below or above them.
 *
 * @param view EditorView
 * @param element DIV content of the menu bubble
 * @param from start position for the bubble
 * @param to end position for the bubble (exclusive)
 */
function calcMenuDisplayData(view: EditorView, element: HTMLElement, from: number, to: number): MenuDisplayData {
  const result = {
    isActive: false,
    left: 0,
    bottom: 0,
    top: 0
  }
  const width = element.clientWidth;
  const parentElement = element.offsetParent;
  if (!parentElement) {
    return result;
  }

  // These are in screen coordinates
  // We can't use EditorView.cordsAtPos here because it can't handle linebreaks correctly
  // See: https://github.com/ProseMirror/prosemirror-view/pull/47
  const start = coordsAtPos(view, from);
  // As 'to' is exclusive, we take the right edge of to-1 (if to > from).
  const end = coordsAtPos(view, (to > from) ? to - 1 : from, true);

  const box = parentElement.getBoundingClientRect();
  const scrollbarHeight = box.height - parentElement.clientHeight;

  // Find a center-ish x position from the selection endpoints (when
  // crossing lines, end may be more to the left)
  let left = Math.round((start.left + end.right) / 2 - box.left);
  // For aliging the bubble just below the text using style="top: .."
  let top = Math.round(end.bottom - box.top);
  // For aligning the bubble just above the text using style="bottom: .."
  let bottom = Math.round(box.bottom - start.top - scrollbarHeight);

  const clientWidth = parentElement.clientWidth;
  const halfWidth = width / 2;
  const padding = 5;

  // Apply limits if near left or right border
  left = Math.max(left, halfWidth + padding);
  left = Math.min(left, clientWidth - halfWidth - padding);

  // Add scrolling offset
  left += parentElement.scrollLeft;
  bottom -= parentElement.scrollTop;
  top += parentElement.scrollTop;

  result.isActive = true;
  result.left = left;
  result.top = top;
  result.bottom = bottom;
  return result;
}

/**
 * Calculate CSS style for positioning the content of the menu bubble *above* the text ("left: ..; bottom: ..;").
 * The bubble content is positioned according to the 'from' and 'to' editor position (could be the selection).
 * Horizontally it's centered between both, vertically it's aligned just *above* them (using style="bottom: ..").
 *
 * @param view EditorView
 * @param element DIV content of the menu bubble
 * @param from start position for the bubble
 * @param to end position for the bubble
 */
export function calcBubblePositioningStyleAbove(view: EditorView, element: HTMLElement, from: number, to: number): string {
  const data = calcMenuDisplayData(view, element, from, to);
  return data.isActive ? `left: ${data.left}px; bottom: ${data.bottom}px;` : '';
}

/**
 * Calculate CSS style for positioning the content of the menu bubble *below* the text ("left: ..; top: ..;").
 * The bubble content is positioned according to the 'from' and 'to' editor position (could be the selection).
 * Horizontally it's centered between both, vertically it's aligned just *below* them (using style="top: ..").
 *
 * @param view EditorView
 * @param element DIV content of the menu bubble
 * @param from start position for the bubble
 * @param to end position for the bubble
 */
export function calcBubblePositioningStyleBelow(view: EditorView, element: HTMLElement, from: number, to: number): string {
  const data = calcMenuDisplayData(view, element, from, to);
  return data.isActive ? `left: ${data.left}px; top: ${data.top}px;` : '';
}
