import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {ApplicationTemplate} from '@/api/models/applicationTemplate.model';
import {FetchAllApplicationTemplate, FetchLogoImage} from '@/api/services/applicationTemplate.api';

@Module({dynamic: true, namespaced: true, store, name: 'applicationTemplate'})
class ApplicationTemplateModule extends VuexModule {

  private _applicationTemplates: ApplicationTemplate[] = [];
  private _logoImage: BinaryType | null = null;
  // TODO: Should be changed to type string and resolve the errors. 
  private _logoGuid: any = '';

  get applicationTemplates(): ApplicationTemplate[] {
    return this._applicationTemplates;
  }

  get logoImage(): BinaryType | null {
    return this._logoImage;
  }

  @Mutation
  private setAllApplicationTemplate(applicationTemplates: ApplicationTemplate[] | null) {
    if (applicationTemplates) {
      this._applicationTemplates = applicationTemplates;
    }
  }

  @Action
  async fetchAllAplicationTemplate(): Promise<void> {
    return FetchAllApplicationTemplate().then((applicationTemplates) => {
      this.setAllApplicationTemplate(applicationTemplates);
    }).catch((error) => {
      this.setAllApplicationTemplate(null);
      throw error;
    });
  }

  @Mutation
  private setLogoImage(imageData: BinaryType | null) {
    this._logoImage = imageData;
  }

  @Mutation
  private setLogoGuid(guid: string) {
    if(guid != undefined) {
      this._logoGuid = guid;
    }
  }

  public get logoGuid(): string {
    return this._logoGuid.toString();
  }

  @Action
  async fetchLogoImage(guid: string): Promise<void> {
    this.setLogoImage(null);

    if(guid == '') {
      this.setLogoGuid(guid);
      this.setLogoImage(null);
      return;
    }

    return FetchLogoImage(guid).then((imageData: BinaryType) => {
      this.setLogoGuid(guid);
      this.setLogoImage(imageData);
    }).catch((error) => {
      this.setLogoGuid(guid);
      this.setLogoImage(null);
      throw error;
    })
  }
}

export default getModule(ApplicationTemplateModule);
