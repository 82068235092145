<template>
  <div class="worklist">

    <ConfirmationDialog ref="deleteApplicationDialogDialog"/>

    <header>
      <div class="vertical-separator-input"></div>
      <h1>{{ $t('worklist') }}</h1>
    </header>

    <div class="table-container">

      <CoverSheetDialog :edit-mode="Mode.CREATE"/>

      <div class="table-wrapper">
        <b-table :key="tableDataKey"
                 :data="applicationDocumentList"
                 :default-sort="defaultSort"
                 :default-sort-direction="defaultSortDirection"
                 :mobile-cards="false"
                 :sticky-header="true"
                 @sort="onSort"
                 @click="clickOnApplication($event)">

          <b-table-column id="creationDate" field="creationDate" :label="$t('creationDate')" header-class="table-header"
                          cell-class="table-cell"
                          sortable v-slot="props">
            <div class="table-cell-link" :title="getCreationDateTooltip(props.row.creationDate)">
              <span>{{ getPassedTimeString(props.row.creationDate) }}</span>
            </div>
          </b-table-column>
          <b-table-column field="application.referenceNo" :label="$t('referenceNo')" header-class="table-header"
                          cell-class="table-cell" sortable v-slot="props">

            <div class="table-cell-link">
              {{ props.row.application.referenceNo }}
            </div>

          </b-table-column>

          <b-table-column field="genericTerm.term"
                          :label="$t('title')"
                          header-class="table-header"
                          cell-class="table-cell generic-term-cell"
                          v-slot="props"
                          sortable>
            <div class="table-cell-link">{{ getGenericTermText(props.row.genericTerm) }}</div>
          </b-table-column>

          <b-table-column field="type" :label="$t('type')" header-class="table-header" cell-class="table-cell" sortable v-slot="props">
            <div class="table-cell-link">
              <span>{{ $t(`applicationTypes.${props.row.template.applicationType}`) }} ({{ props.row.template.name }})</span>
            </div>
          </b-table-column>

          <b-table-column field="patentHolder.name" :label="$t('client')" header-class="table-header"
                          cell-class="table-cell"
                          sortable v-slot="props">
            <div class="table-cell-link">
              <span>{{ props.row.patentHolder.name }}</span>
            </div>
          </b-table-column>

          <b-table-column header-class="table-header" cell-class="table-cell" v-slot="props">
            <div class="table-cell-padding">
              <CoverSheetDialog :edit-mode="Mode.CLONE" @click="blockClickOnApplication"
                                :application-document-copy="props.row"/>

              <WorklistDownloadButton :application-document="props.row" @click="blockClickOnApplication"/>

              <button class="icon-button"
                      :title="props.row.isDeletable
                      ? $t('deleteApplicationDocument.title')
                      : $t('deleteApplicationDocument.inactive.title')"
                      @click="clickDelete(props.row)"
                      :disabled="!props.row.isDeletable">
                <i class="exi"
                   :class="(isDeleting(props.row.guid) ? 'exi-small-spinner-unmasked rotating' : 'exi-delete')"/>
              </button>
            </div>
          </b-table-column>

          <template v-slot:empty>
            <div class="table-empty-text">
              <div v-if="isLoading" class="is-loading">
                <i class="exi exi-small-spinner-unmasked rotating"/> {{ $t('loadWorklist') }}
              </div>
              <div v-if="!isLoading">{{ $t('emptyWorklist') }}</div>
            </div>
          </template>

        </b-table>
        <!-- Leave this here. For demonstrating the buefy native loading blocker -->
        <!-- b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading -->
      </div>
    </div>

    <!-- relevant links -->
    <footer>
      <!-- privacy policy -->
      <!--
            <a target="_blank"
                rel="noopener noreferrer"
                v-bind:href="`${$t('regulatoryInformation.languagePath')}/data-privacy-policy.html`"
                :title="$t('regulatoryInformation.privacyPolicy')">

              {{ $t('regulatoryInformation.privacyPolicy') }}
            </a>
      -->

      <!-- terms of use -->
      <!--      <a target="_blank"
               rel="noopener noreferrer"
               v-bind:href="`${$t('regulatoryInformation.languagePath')}/terms-of-use.html`"
               :title="$t('regulatoryInformation.termsOfUse')">

              {{ $t('regulatoryInformation.termsOfUse') }}
            </a>-->

      <!-- service level agreement -->
      <!--<a target="_blank" rel="noopener noreferrer" v-bind:href="`${$t('regulatoryInformation.languagePath')}/service-level-agreement.html`">
        {{ $t('regulatoryInformation.sla') }}
      </a>-->

      <!-- cookies settings -->
      <!--      <button v-on:click="showCookiesBanner"
                    class="icon-button"
                    :title="$t('cookies.cookieSettings')">
              {{ $t('cookies.cookieSettings') }}
            </button>-->

      <!-- imprint -->
      <!--      <a target="_blank"
                rel="noopener noreferrer"
                v-bind:href="`${$t('regulatoryInformation.languagePath')}/imprint.html`"
                :title="$t('regulatoryInformation.imprint')">

              {{ $t('regulatoryInformation.imprint') }}
            </a>-->
    </footer>
  </div>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import CoverSheetDialog from '@/views/CoverSheetDialog.vue';
import {ApplicationDocument, GenericTerm} from '@/api/models/application.model';
import WorklistDownloadButton from '@/components/WorklistDownloadButton.vue';
import {PatentEngineConstraints} from '@/constraints';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import CookiesBanner, {CookiesBanner as CookiesBannerClass} from '@/components/CookiesBanner.vue';
import {cookies, deleteCookie} from '@/util/cookies.util';
import {findRefByName} from '@/util/dom.util';
import {routeTo} from '@/util/router.util';
import {Mode} from '@/util/enums';
import {useDefaultErrorHandling} from '@/errorHandling';
import WorklistModule from '@/store/modules/WorklistModule';
import {WorklistApplicationDocument} from '@/api/models/worklist.model';

@Component(
  {
    components: {
      WorklistDownloadButton,
      CoverSheetDialog,
      ConfirmationDialog,
      CookiesBanner,
    }
  })
class Worklist extends Vue {

  @Ref('deleteApplicationDialogDialog') private deleteApplicationDialogDialog!: ConfirmationDialogClass;

  /**
   * Set to true if the delete or download icon is clicked to prevent routing to the application editor.
   */
  private blockClickOnApplicationFlag = false;
  private tableDataKey = 0;

  get Mode() {
    return Mode;
  }

  /**
   * Gets the CookiesBanner declared on an upper level within App component.
   */
  get cookiesBanner(): CookiesBannerClass | undefined {
    return findRefByName(this, 'cookiesBanner') as CookiesBannerClass;
  }

  get applicationDocumentList(): WorklistApplicationDocument[] {
    return WorklistModule.applicationDocuments.sort((a: WorklistApplicationDocument, b: WorklistApplicationDocument) => {
      return b.creationDate.getTime() - a.creationDate.getTime()
    });
  }

  get defaultSort() {
    return WorklistModule.applicationDocumentsSorting;
  }

  get defaultSortDirection() {
    return WorklistModule.applicationDocumentsSortingDirection;
  }

  get isLoading(): boolean {
    return WorklistModule.isLoading;
  }

  // Fix: Trigger rerendering table using key
  @Watch("applicationDocumentList", {deep: true})
  applicationDocumentListChanged() {
    this.tableDataKey++;
  }

  onSort(field: string, direction: string) {
    WorklistModule.setApplicationDocumentsSorting(field);
    WorklistModule.setApplicationDocumentsSortingDirection(direction);
  }

  private isDeleting(guid: string): boolean {
    return WorklistModule.deletingApplicationDocuments.indexOf(guid) > -1;
  }

  private getGenericTermText(genericTerm: GenericTerm): string {
    const termString = (genericTerm.term?.length > 0) ? genericTerm.term : '';
    const genericTermText = (genericTerm.complementText) ? termString + ' ' + genericTerm.complementText : termString;
    return (genericTermText.length > PatentEngineConstraints.MAX_GENERIC_TERM_TEXT_LENGTH) ?
      genericTermText.slice(0, PatentEngineConstraints.MAX_GENERIC_TERM_TEXT_LENGTH - 3) + "..." : genericTermText;
  }

  private clickDelete(applicationDocument: WorklistApplicationDocument): void {
    this.blockClickOnApplication();
    this.deleteApplicationDialogDialog.open(
      {
        titleKey: 'deleteApplicationDocument.title',
        questionKey: 'deleteApplicationDocument.question',
        questionValues: [applicationDocument.application.referenceNo],
        options: [
          {
            labelKey: 'general.delete',
            class: 'button-delete',
            callback: () => WorklistModule.deleteApplicationDocument(applicationDocument)
              .catch(useDefaultErrorHandling),
            autofocus: true
          }, {
            labelKey: 'general.cancel',
            class: 'button-cancel'
          }]
      });
  }

  private blockClickOnApplication(): void {
    this.blockClickOnApplicationFlag = true;
    setTimeout(() => this.blockClickOnApplicationFlag = false, 100);
  }

  private clickOnApplication(applicationDocument: ApplicationDocument): void {
    if (!this.blockClickOnApplicationFlag) {
      // Display the splash screen and open the application editor
      routeTo('application/' + applicationDocument.guid);
    }
  }

  /**
   * Called after the instance has been mounted.
   * @public
   */
  mounted(): void {
    WorklistModule.fetchApplicationDocuments()
      .catch(useDefaultErrorHandling); // TODO: Toast error
  }

  /**
   * Get the string related to seconds if the application was created lass than a minute ago.
   * @param seconds The number of seconds to be printed.
   * @return The string related to seconds if the application was created lass than a minute ago.
   */
  private getSecondString(seconds: number): string {
    const secondsToShow = (seconds < 1) ? 1 : seconds;
    const secondKey = secondsToShow == 1 ? "second" : "seconds";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${secondsToShow} ` + this.$t(secondKey);
    }
    // default english
    return `${secondsToShow} ` + this.$t(secondKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string related to minutes if the application was created lass than an hour ago.
   * @param minutes The number of minutes to be printed.
   * @return The string related to minutes if the application was created lass than an hour ago.
   */
  private getMinuteString(minutes: number): string {
    const minuteKey = minutes == 1 ? "minute" : "minutes";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${minutes} ` + this.$t(minuteKey);
    }
    // default english
    return `${minutes} ` + this.$t(minuteKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string related to hous if the application was created lass than a day ago.
   * @param hours The number of hours to be printed.
   * @return The string related to hous if the application was created lass than a day ago.
   */
  private getHourString(hours: number): string {
    const hourKey = hours == 1 ? "hour" : "hours";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${hours} ` + this.$t(hourKey);
    }
    // default english
    return `${hours} ` + this.$t(hourKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string related to days if the application was created lass than a week ago.
   * @param days: number of days.
   */
  private getDayString(days: number): string {
    const dayKey = days == 1 ? "day" : "days";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${days} ` + this.$t(dayKey);
    }
    // default english
    return `${days} ` + this.$t(dayKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string related to weeks if the application was created lass than a month ago.
   * @param weeks The number of weeks to be printed.
   * @return The string related to weeks if the application was created lass than a month ago.
   */
  private getWeekString(weeks: number): string {
    const weekKey = weeks == 1 ? "week" : "weeks";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${weeks} ` + this.$t(weekKey);
    }
    // default english
    return `${weeks} ` + this.$t(weekKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string related to months if the application was created lass than a year ago.
   * @param months The number of months to be printed.
   * @return The string related to months if the application was created lass than a year ago.
   */
  private getMonthString(months: number): string {
    const monthKey = months == 1 ? "month" : "months";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${months} ` + this.$t(monthKey);
    }
    // default english
    return ` ${months} ` + this.$t(monthKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string related to years if the application was created more than a year ago.
   * @param years The number of ago years to be printed.
   * @return The string related to years if the application was created more than a year ago.
   */
  private getYearString(years: number): string {
    const yearKey = years == 1 ? "year" : "years";
    if (this.$i18n.locale == "de") {
      return this.$t("ago") + ` ${years} ` + this.$t(yearKey);
    }
    // default english
    return `${years} ` + this.$t(yearKey) + ' ' + this.$t("ago");
  }

  /**
   * Get the string to display in the creation date column (Exapmle: 2 days ago) based on the selected language.
   * @param creationDate The creation date of the application.
   * @return The string to display in the creation date column (Exapmle: 2 days ago) based on the selected language.
   */
  private getPassedTimeString(creationDate: Date): string | undefined {
    const passedTime = (new Date().getTime() - new Date(creationDate.getTime()).getTime()) / 1000;
    // divide the date trough 1000 to get the seconds
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    const year = day * 365;

    if (passedTime < minute) {
      return this.getSecondString(Math.floor(passedTime));
    }
    if (passedTime < hour) {
      return this.getMinuteString(Math.floor(passedTime / minute));
    }
    if (passedTime < day) {
      return this.getHourString(Math.floor(passedTime / hour));
    }
    if (passedTime < week) {
      return this.getDayString(Math.floor(passedTime / day));
    }
    if (passedTime < month) {
      return this.getWeekString(Math.floor(passedTime / week));
    }
    if (passedTime < year) {
      return this.getMonthString(Math.floor(passedTime / month));
    }

    return this.getYearString(Math.floor(passedTime / year));
  }

  private getCreationDateTooltip(date: Date): string {
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    //  date.getMonth() + 1: because zero indicates the first month of the year
    const month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    const year = date.getFullYear() < 10 ? "0" + date.getFullYear() : date.getFullYear();
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    return `${this.$t("created")} ${this.$t("datePreposition")} ${day}.${month}.${year} ${this.$t("timePreposition")} ${hours}:${minutes}`;
  }

  /**
   * Shows the cookies banner and deletes the preexisting cookies in beforehand.
   */
  private showCookiesBanner(): void {
    if (!this.cookiesBanner) {
      return;
    }

    deleteCookie(cookies.cookieConsent);
    this.cookiesBanner.open();
  }
}

export default toNative(Worklist);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/constants';

.worklist {
  width: 100%;

  .cover-sheet {
    overflow: hidden;
  }

  .table-container {
    width: 1280px;
    min-width: 1280px;
    text-align: left;
    margin: 0 auto;
    padding-bottom: $footer-hight;
    height: calc(100% - #{$vertical-separator-input-padding-top} - 61px /* h1*/
    );

    .table-wrapper {
      position: relative; // Needed for buefy native loading blocker
      padding-left: 24px;
      padding-right: 24px;
      height: calc(100% - 28px /* height cover-sheet*/
      );

      .table {
        .table-cell {
          vertical-align: middle;

          .table-cell-link {
            width: 100%;
            height: 100%;
            padding: 6px 8px;
          }

          .table-cell-padding {
            display: flex;
            padding: 8px 12px;

            .icon-button {
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.worklist {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .b-table {
    height: 100%;

    .table-wrapper {
      height: 100% !important;
    }
  }

  .table {
    tr:hover {
      cursor: pointer;
    }

    tr:nth-child(even):hover {
      background-color: $pengine-grey-light-hover;
    }

    tr:nth-child(odd):hover {
      background-color: $pengine-grey-light-light-hover;
    }

    .table-header {
      padding-right: 25px;
      :nth-child(1) {
        :nth-child(1) {
          right: auto;
          left: 100%;
        }
      }
    }

    .table-cell {
      padding: 0;
    }

    .generic-term-cell {
      max-width: 25rem;
      word-wrap: break-word;
    }

    .th-wrap {
      width: max-content;
    }
  }

  footer {
    margin-top: auto;
    margin-bottom: 2em;

    button.icon-button {
      color: $pengine-grey-dark-dark;
      margin-right: 2em;
      font-size: $font-size-modal;
      font-family: Avenir, Helvetica, Arial, sans-serif;
    }

    button.icon-button:hover {
      color: $pengine-blue-dark;
    }

    a {
      color: $pengine-grey-dark-dark;
      margin-right: 2em;
    }

    a:hover {
      color: $pengine-blue-dark;
    }
  }
}
</style>
