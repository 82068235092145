import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_b_dropdown, {
      position: "is-bottom-left",
      "mobile-modal": false,
      "append-to-body": "",
      "aria-role": "menu",
      "trap-focus": "",
      disabled: _ctx.isLoading
    }, {
      trigger: _withCtx(({}) => [
        _createVNode(_component_b_button, {
          class: _normalizeClass(["download-docx-button icon-button", (_ctx.isLoading ? 'loading-cursor' : 'download-cursor')]),
          title: _ctx.$t('download.docx.tooltip'),
          disabled: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass(["exi", (_ctx.isLoading ? 'exi-small-spinner-unmasked rotating' : 'exi-download')])
            }, null, 2)
          ]),
          _: 1
        }, 8, ["class", "title", "disabled"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_dropdown_item, {
          key: "withFigures",
          "aria-role": "listitem",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadDocx(true)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`download.docx.withFigures`)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_dropdown_item, {
          key: "withoutFigures",
          "aria-role": "listitem",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.downloadDocx(false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(`download.docx.withoutFigures`)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_ConfirmationDialog, { ref: "defectExportImagesDialog" }, null, 512)
  ]))
}