<template>
  <span>
    <span v-if="createableTypes.length > 0 && !isLoading">
      <b-button type="is-info" v-for="type in createableTypes"
                @mousedown.left="createBlock(type)"
                :key="type"
                :title="getTooltip(type)">
        <i class="exi exi-plus"/>
        {{ getType(type) }}
      </b-button>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Inject, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import {findAllAncestorNodes, findLowestDepth} from '@/components/applicationEditor/utils/node.util';
import EditorModule from '@/store/modules/EditorModule';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {EditorState} from '@tiptap/pm/state';
import {SemanticType} from '@/api/models/editor.model';
import {useDefaultErrorHandling} from '@/errorHandling';

/**
 * Component to add buttons for all semantic types that may be added to the current active node or it's parents
 */
@Component
class CreateBlock extends Vue {
  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Inject({from: 'applicationEditor.activeEditorState'}) state!: EditorState;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  mounted() {
    return;
  }

  get createableTypes(): string[] {
    const types: string[] = [];

    // Find all semantic types that may be added to parents
    findAllAncestorNodes(this.state.selection.$anchor,
                         (ancestor) => ancestor.attrs.creatableBlocks && (ancestor.attrs.creatableBlocks.length > 0))
      .map((ancestor: PmNode) => ancestor.attrs.creatableBlocks)
      .reverse()
      .flat()
      .forEach((optionalType: string) => {
        if (!types.includes(optionalType)) {
          types.push(optionalType);
        }
      });
    return types;
  }

  getType(type: string): string {
    return ApplicationModule.templateText(`${type}.type`);
  }

  getTooltip(type: string): string {
    return this.$t(`menubar.create`, [ApplicationModule.templateText(`${type}.type`)]).toString();
  }

  createBlock(type: string) : boolean  {
    const resolve = this.state.selection.$from;

    // Find the closest parent the provided semantic type can be added to
    const depth = findLowestDepth(resolve, (node) => {
      if (node.attrs.creatableBlocks) {
        return !!node.attrs.creatableBlocks.find((t: string) => (t === type));
      }
      return false;
    });

    // Create the block within the found parent
    if (depth !== -1) {
      const parentNode = resolve.node(depth);
      const childNode = resolve.node(depth + 1);

      this.saveEditorChangesFn(false)
        .catch(useDefaultErrorHandling)
        .then(() =>
                EditorModule.createBlock(
                  {
                    semanticType: type as SemanticType,
                    parentGuid: parentNode.attrs.guid,
                    childGuid: childNode.attrs.guid
                  }));
    }
    return true;
  }
}

export default toNative(CreateBlock);
</script>
