import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {AiTemplateState} from '@/store/models/aiTemplate.model';
import {AiTemplate} from '@/api/models/aiTemplate.model';
import {GetAiTemplates} from '@/api/services/aiAssisstant.api';
import EditorModule from '@/store/modules/EditorModule';
import LlmModule from '@/store/modules/LlmModule';
import {UseCase} from '@/api/models/aiTemplateUseCase.model';
import {SemanticType, SingleBlockDescriptor, WithinBlockDescriptor} from '@/api/models/editor.model';
import {translateBlockDescriptorForAutofillButtonAiTemplates} from '@/components/applicationEditor/menubar/UndoRedoAction';

@Module({dynamic: true, namespaced: true, store, name: 'aiTemplate'})
class AiTemplateModule extends VuexModule implements AiTemplateState {

  // initial state
  private _isLoading = false;
  private _isAutoFillLoading = false;
  private _aiTemplates: AiTemplate[] = [];
  private _selectedAiTemplate: AiTemplate | null = null;

  get selectedSemanticType(): string | null {
    return EditorModule.semanticType;
  }

  get selectedLlm(): string | undefined {
    return LlmModule.selectedLlm?.llmName;
  }

  get aiAssistantAiTemplates(): AiTemplate[] {
    if (this.selectedSemanticType === null || this.selectedLlm === undefined) {
      return [];
    }
    const aiTemplatesWithUseCasesAiAssistant: AiTemplate[] = [];
    let i = 0;
    for (const aiTemplate of this.aiTemplates) {
      if (aiTemplate.semanticType === this.selectedSemanticType) {
        for (const useCase of aiTemplate.useCases) {
          if (useCase === UseCase.AI_ASSISTANT) {
            aiTemplatesWithUseCasesAiAssistant[i++] = aiTemplate;
          }
        }
      }
    }
    return aiTemplatesWithUseCasesAiAssistant;
  }

  get autofillButtonAiTemplates(): [{ key: { semanticType: string, claimType: string, displayedText: string }, children: AiTemplate[] }] {
    const aiTemplatesForAutofillButton: [{ key: { semanticType: string, claimType: string, displayedText: string }, children: AiTemplate[] }]
      = [{key: {semanticType: '', claimType: '', displayedText: ''}, children: []}];

    const aiTemplatesWithUseCasesAutoFill: AiTemplate[] = [];
    let i = 0;
    for (const aiTemplate of this.aiTemplates) {
      for (const useCase of aiTemplate.useCases) {
        if (useCase === UseCase.AUTO_FILL) {
          aiTemplatesWithUseCasesAutoFill[i++] = aiTemplate;
        }
      }
    }
    if (aiTemplatesWithUseCasesAutoFill.length != 0) {

      // Create set of the combinations of semantic type and claim type in aiTemplates with use case autoFill
      const semanticTypeAndClaimTypes = new Set<string>(
        aiTemplatesWithUseCasesAutoFill.map(it => JSON.stringify([it.semanticType, it.claimType])));

      // Fill in the first dropdown level
      let i = 0;
      for (const semanticTypeAndClaimType of semanticTypeAndClaimTypes) {
        const [semanticType, claimType] = JSON.parse(semanticTypeAndClaimType);
        aiTemplatesForAutofillButton[i++] = {
          children: [], key: {semanticType: semanticType, claimType: claimType, displayedText: ""}
        }
      }

      // fill in the second dropdown level
      for (let j = 0; j < aiTemplatesWithUseCasesAutoFill.length; j++) {
        for (let k = 0; k < aiTemplatesForAutofillButton.length; k++) {
          if (aiTemplatesWithUseCasesAutoFill[j].semanticType === aiTemplatesForAutofillButton[k].key.semanticType
            && aiTemplatesWithUseCasesAutoFill[j].claimType === aiTemplatesForAutofillButton[k].key.claimType) {
            aiTemplatesForAutofillButton[k].children.push(aiTemplatesWithUseCasesAutoFill[j]);
            break;
          }
        }
      }

      // Translate semantic Type and claim type to continuous text to display it in dropdown
      aiTemplatesForAutofillButton.forEach(entry => {
        if (entry.children[0].claimType as string === "") {
          const singleBlockDescriptor: SingleBlockDescriptor = {
            additionalValue: "",
            semanticType: entry.key.semanticType as SemanticType,
            type: "Single"
          }
          entry.key.displayedText = translateBlockDescriptorForAutofillButtonAiTemplates(singleBlockDescriptor);
        } else {
          const withinBlockDescriptor: WithinBlockDescriptor =
            {
              type: "Within",
              child: {additionalValue: "", semanticType: entry.key.semanticType as SemanticType, type: "Single"},
              parent: {additionalValue: "", semanticType: entry.key.claimType as SemanticType, type: "Single"}
            }
          entry.key.displayedText = translateBlockDescriptorForAutofillButtonAiTemplates(withinBlockDescriptor);
        }
      })
    } else {
      // If there are no aiTemplates with use case auto fill, remove the first and empty entry of aiTemplatesForAutofillButton,
      // which was necessary to add to aiTemplatesForAutofillButton for its creation in the first place
      aiTemplatesForAutofillButton.shift();
    }
    return aiTemplatesForAutofillButton!;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get isAutoFillLoading(): boolean {
    return this._isAutoFillLoading;
  }

  get aiTemplates(): AiTemplate[] {
    return this._aiTemplates;
  }

  get selectedAiTemplate(): AiTemplate | null {
    return this._selectedAiTemplate;
  }

  @Mutation
  private setLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  @Mutation
  public setAutoFillLoading(isLoading: boolean): void {
    EditorModule.setIsLoading(isLoading);
    this._isAutoFillLoading = isLoading;
  }

  @Mutation
  public setSelectedAiTemplate(value: AiTemplate | null) {
    this._selectedAiTemplate = value;
  }

  @Mutation
  public setAiTemplates(value: AiTemplate[]) {
    this._aiTemplates = value;
  }

  @Mutation
  private getAiTemplatesEnd(results: Array<AiTemplate> | null) {
    if (results != null) {
      this._aiTemplates = results;
    }
    this._isLoading = false;
  }

  @Action
  async getAiTemplates(applicationDocumentGuid: string): Promise<Array<AiTemplate>> {
    this.setLoading(true);
    return GetAiTemplates(applicationDocumentGuid).then((results: Array<AiTemplate>) => {
      this.getAiTemplatesEnd(results);
      return results;
    }).catch((error) => {
      this.getAiTemplatesEnd(null);
      throw error;
    });
  }
}

export default getModule(AiTemplateModule);