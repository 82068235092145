import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import PlaceholderView from '@/components/applicationEditor/nodes/baseElements/views/PlaceholderView.vue'

/**
 * The node for a placeholder. Those are shown in the editor for elements that are generateable, but not generated yet, as a substitute
 * so that the user can click on the element to generate it new.
 */
export const PlaceholderNode = TiptapNode.create(
  {
    name: 'placeholderNode',
    content: 'inline*',
    group: 'block',
    defining: true,

    addAttributes() {
      return {
        isReadOnly: {default: true}
      };
    },

    parseHTML() {
      return [
        {
          tag: 'placeholderNode',
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['placeholderNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(PlaceholderView);
    }

    /*stopEvent(event: Event): boolean {
      return false;
    }*/
  });
