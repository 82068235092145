import {getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';

@Module({dynamic: true, namespaced: true, store, name: 'communications'})
class RequestQueueModule extends VuexModule {

  private _numQueuedRequests = 0;

  get isWorking(): boolean {
    return this._numQueuedRequests > 0;
  }

  @Mutation
  public startingRequest(): void {
    this._numQueuedRequests = this._numQueuedRequests + 1;
  }

  @Mutation
  public requestDone(): void {
    this._numQueuedRequests = this._numQueuedRequests - 1;
  }
}

export default getModule(RequestQueueModule);