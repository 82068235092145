<template>
  <section>
    <AboutDialog ref="aboutDialog"/>
    <LicensesDialog ref="licensesDialog"/>
    <b-sidebar ref="sidebar" type="is-light" :fullheight="fullheight" :overlay="overlay" v-model="open">
      <b-menu>

        <!-- Library -->
        <ul class="menu-list"> <!-- TODO: replace with buey component with next version of buefy-next -->
          <b-menu-item :active="isLibraryItemActive" @click="clickLibrarySetup()">
            <template #label>
              <i class="exi exi-add-library"/>
              <div class="menu-item-label">
                {{ $t('sidebar.library.label') }}
              </div>
            </template>
            <!-- b-menu-item> // This will be added later
              <template #label>
                <i class="exi exi-plus"/>
                <div class="menu-item-label">{{$t('sidebar.library.createEntry')}}</div>
              </template>
            </b-menu-item -->

          </b-menu-item>

          <b-menu-item>
            <template #label="props">
              <i class="exi exi-patent-engine-logo"/>
              <div class="menu-item-label">{{ $t('sidebar.about') }}</div>
              <i :class="'expand-icon exi ' + (props.expanded ? 'exi-tree-node-expanded' : 'exi-tree-node-collapsed')"/>
            </template>

            <b-menu-item @click="clickAboutDialog()" class="inner-menu-item">
              <template #label>
                <i class="exi exi-info"/>
                <div class="menu-item-label">{{ $t('sidebar.information') }}</div>
              </template>
            </b-menu-item>
            <b-menu-item @click="clickLicensesDialog()" class="inner-menu-item">
              <template #label>
                <i class="exi exi-license"/>
                <div class="menu-item-label">{{ $t('sidebar.licenses') }}</div>
              </template>
            </b-menu-item>
          </b-menu-item>

        </ul>

        <!-- Administration -->
        <!-- b-menu-list> // This will be added later
          <b-menu-item disabled>
            <template #label="props">
              <i class="exi exi-calendar-check"/>
              <div class="menu-item-label">{{$t('sidebar.administration.label')}}</div>
              <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-down' : 'menu-up'"></b-icon>
            </template>
          </b-menu-item>
        </b-menu-list -->
      </b-menu>
    </b-sidebar>
  </section>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import AboutDialog, {AboutDialog as AboutDialogClass} from "@/components/AboutDialog.vue";
import LicensesDialog, {LicensesDialog as LicensesDialogClass} from "@/components/LicensesDialog.vue";
import {routeTo} from '@/util/router.util';

@Component(
  {
    components: {
      LicensesDialog,
      AboutDialog,
    }
  })
class Sidebar extends Vue {
  @Ref('sidebar') private sidebar!: Sidebar;
  @Ref('aboutDialog') private aboutDialog!: AboutDialogClass;
  @Ref('licensesDialog') private licensesDialog!: LicensesDialogClass;

  open = false;
  isLibraryItemActive = true; // Initially expanded
  overlay = false;
  fullheight = true;

  private clickLibrarySetup(): void {
    this.open = false;
    // Display the splash screen and open the library management
    routeTo('library-management');
  }

  private clickAboutDialog(): void {
    this.open = false;
    this.licensesDialog.close();
    this.aboutDialog.open();
  }

  private clickLicensesDialog(): void {
    this.open = false;
    this.aboutDialog.close();
    this.licensesDialog.open();
  }
}

export default toNative(Sidebar);
export {Sidebar};
</script>

<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.b-sidebar {
  .sidebar-background {
    // If we activate the overlay we still want it to be transparent
    background: none !important;
  }

  .sidebar-content {
    margin-top: $header-height;
    background-color: white !important;

    .menu-list {
      li {
        ul {
          margin: 0;
          border-left: none;
        }
      }

      a {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .menu-item-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 16px;
          flex: 1;

          .expand-icon {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    :not(.inner-menu-item) a.is-active {
      background-color: $pengine-grey-light;
      color: $text-color;

      > .exi {
        background-color: $text-color;
      }
    }

    .inner-menu-item {
      a:hover.is-active {
        background-color: $pengine-grey-light-light;
      }

      a:not(:hover).is-active {
        background-color: white;
      }
    }
  }
}
</style>
