import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8e17e114"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_view_content = _resolveComponent("node-view-content")!
  const _component_node_view_wrapper = _resolveComponent("node-view-wrapper")!

  return (_openBlock(), _createBlock(_component_node_view_wrapper, {
    as: "span",
    class: _normalizeClass(['block-mode'])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.getSemanticType(), 'block', 'text-container']),
        id: _ctx.getGuid(),
        style: _normalizeStyle(_ctx.indent)
      }, [
        _withDirectives(_createElementVNode("p", {
          contenteditable: "false",
          class: "header"
        }, _toDisplayString(_ctx.getHeaderText()), 513), [
          [_vShow, _ctx.hasHeaderText()]
        ]),
        _createVNode(_component_node_view_content)
      ], 14, _hoisted_1)
    ]),
    _: 1
  }))
}