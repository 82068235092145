<template>
  <node-view-wrapper class="coversheet block" :id="getGuid()" contenteditable="false">
    <table v-if="applicationDocument">
      <tr>
        <td class="logo" colspan="2">
          <!-- in html for document export: remove "../../assets/logo/" -->

          <img v-if="this.logo !== ''" class="logo-details" :src="this.logo" alt="Template logo"/>
          <img v-else class="logo-details" src="../../../../../assets/logo/design-toip-logo.png" alt="Template logo"/>

        </td>
      </tr>
      <tr>
        <td class="date">
          <span>{{ placeAndDateText }}</span>
        </td>
      </tr>
      <tr>
        <td class="client-reference-number">{{ applicationDocument.application.referenceNo }}</td>

      </tr>
      <tr>
        <td colspan="2" class="client-sign">{{ applicationDocument.client.sign }}</td>
      </tr>

      <!-- Application Type -->
      <tr>
        <td colspan="2" class="patent-application">
          {{ getFixText("COVERSHEET_PATENT_APPLICATION_TYPE." + applicationDocument.template.applicationType) }}
        </td>
      </tr>

      <!-- Draft -->
      <tr>
        <td colspan="2" class="draft">{{ getFixText("COVERSHEET_DRAFT") }}</td>
      </tr>

      <!-- Patentholder -->
      <tr>
        <td colspan="2" class="patent-proprietor-type" v-if="isPatentholderNameOrAddressGiven()">
          {{ getFixText("COVERSHEET_PATENT_PROPRIETOR_TYPE") }}
        </td>
        <td colspan="2" class="patent-proprietor-type" v-else>
          {{ getFixText("COVERSHEET_PATENT_PROPRIETOR_TYPE_NOT_GIVEN") }}
        </td>
      </tr>
      <tr v-if="isPatentholderNameOrAddressGiven()">
        <td colspan="2" class="patent-proprietor">
          <span>{{ applicationDocument.patentHolder.name }}</span>
          <span v-for="addressLine in addressLineList" :key="addressLine"><br/>{{ addressLine }}</span>
        </td>
      </tr>

      <!-- With the designation -->
      <tr>
        <td colspan="2" class="with-the-designation" v-if="genericTermWithComplementText">
          {{ getFixText("COVERSHEET_WITH_DESIGNATION") }}
        </td>
        <td colspan="2" class="with-the-designation" v-else>
          {{ getFixText("COVERSHEET_WITH_DESIGNATION_NOT_GIVEN") }}
        </td>
      </tr>

      <tr v-if="genericTermWithComplementText">
        <td colspan="2" class="generic-term">{{ genericTermWithComplementText }}</td>
      </tr>
    </table>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue, Watch} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import {ApplicationDocument} from '@/api/models/application.model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import ApplicationTemplateModule from '@/store/modules/ApplicationTemplateModule';
import {NodeViewWrapper} from '@tiptap/vue-3';
import {useDefaultErrorHandling} from '@/errorHandling';

/**
 * The visualization of a {@link CoverSheetNode} as an HTML table.
 */
@Component({components: {NodeViewWrapper}})
class CoverSheetView extends Vue {

  @Prop() node!: PmNode;
  private applicationDocument: ApplicationDocument | null = ApplicationModule.currentApplicationDocument;

  get addressLineList(): string[] {
    return this.applicationDocument ? this.applicationDocument.patentHolder.address.name.split('\n') : [];
  }

  get reload(): ApplicationDocument | null {
    return ApplicationModule.currentApplicationDocument;
  }

  get genericTermWithComplementText(): string {
    if (this.applicationDocument?.genericTerm) {

      const genericTerm = (this.applicationDocument.genericTerm.term?.length > 0) ? this.applicationDocument.genericTerm.term : '';

      if (this.applicationDocument.genericTerm.complementText
        && this.applicationDocument.genericTerm.complementText.trim().length > 0) {
        return genericTerm + ' ' + this.applicationDocument.genericTerm.complementText;
      } else {
        return genericTerm;
      }
    }

    return '';
  }

  getGuid(): string {
    return this.node.attrs.guid;
  }

  private getFixText(fixTextKey: string) {
    return ApplicationModule.templateText("fixtext." + fixTextKey);
  }

  private get dateText(): string {
    if (this.applicationDocument && this.applicationDocument.date) {
      const locale = this.applicationDocument.locale;
      return new Intl.DateTimeFormat(locale, {day: '2-digit', month: 'long', year: 'numeric'}).format(this.applicationDocument.date);
    }
    return '';
  }

  private get placeAndDateText(): string {
    const date = this.dateText;
    if (this.applicationDocument && this.applicationDocument.place && date) {
      return this.applicationDocument.place + ', ' + date;
    }
    if (this.applicationDocument && this.applicationDocument.place) {
      return this.applicationDocument.place;
    }
    return date;
  }

  private isPatentholderNameOrAddressGiven(): boolean {
    if (this.applicationDocument && this.applicationDocument.patentHolder && this.applicationDocument.patentHolder.name) {
      return true;
    }
    this.addressLineList.forEach((addressLine) => {
      if (addressLine) {
        return true;
      }
    })
    return false;
  }

  @Watch("reload", {immediate: true})
  private onCurrentApplicationDocumentChange(): void {
    this.applicationDocument = ApplicationModule.currentApplicationDocument;

    const logoGuid = ApplicationTemplateModule.logoGuid;
    const guid = this.applicationDocument?.template?.logo?.guid || '';

    if (logoGuid != guid) {
      ApplicationTemplateModule.fetchLogoImage(guid).catch(useDefaultErrorHandling);
    }

  }

  private get logo(): string {
    const dataImg = ApplicationTemplateModule.logoImage;
    if (dataImg == null) {
      return '';
    }
    const blob = new Blob([dataImg], {type: 'image/png'});
    return URL.createObjectURL(blob);
  }
}

export default toNative(CoverSheetView);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/document.scss';

table {
  table-layout: fixed;
}

.logo {
  text-align: right;
}

.logo .logo-details {
  max-width: 265px;
  max-height: 115px;
  text-decoration: none;
}

.client-reference-number {
  text-align: left;
}

.date {
  text-align: left;
}

.client-sign {
  height: 3cm;
  text-align: left;
}

.patent-application {
  letter-spacing: 3pt;
  text-align: center;
  font-weight: bold;
}

.draft {
  height: 3.5cm;
  letter-spacing: 3pt;
  text-align: center;
  font-weight: bold;
}

.patent-proprietor-type {
  height: 2cm;
  text-align: left;
}

.patent-proprietor {
  height: 4.5cm;
  text-align: left;
}

.with-the-designation {
  height: 2.5cm;
  text-align: left;
}

.generic-term {
  text-align: center;
  height: 6cm;
  font-weight: bold;
}

.coversheet {
  font-size: 12px;
  font-family: Arial, sans-serif;
  position: relative;
  padding: 15px;
}

.coversheet table {
  width: 100%;
  empty-cells: show;
  border-collapse: collapse;
  border-spacing: 0;
}

.coversheet tr {
  page-break-inside: avoid;
}

.coversheet td {
  padding: 0px;
  border: none;
  font-size: 1.25em;
  vertical-align: top;
}
</style>
