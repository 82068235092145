<template>

  <div ref="searchRef" class="search">

    <div class="search-header">
      <h2 class="search-in">{{ $t('searchIn') }}</h2>
    </div>
    <div class="search-body">
      <div class="search-body-content">

        <b-tabs v-model="activeSearchSpaceTab" class="search-space-tabs" :multiline="multiline"
                @update:modelValue="searchSpaceTabChanged($event)">
          <template v-for="tab in searchSpaceTabs[activeMainTab]" :key="tab.id">
            <b-tab-item :value="String(tab.id)" :label="tab.label"/>
          </template>
        </b-tabs>

        <b-field class="search-terms">
          <button class="search-button icon-button" :title="$t('toSearch')" @click="clickSearch()">
            <i class="search-icon exi exi-search"></i>
          </button>

          <b-taginput ref="taginput" size="is-small" :modelValue="searchTerms" v-bind:class="{ 'is-empty': searchTerms.length === 0 }"
                      @update:modelValue="searchTermsChanged" :placeholder="$t('toSearch')"
                      @keyup.enter="onSearchEnter($event)" class="search-bar-inner"/>

          <button v-if="searchTerms.length > 0" class="delete-all-button icon-button" :title="$t('deleteAllSearchTerms')"
                  @click="clickDeleteAll()">
            <i class="delete-all-icon exi exi-close"></i>
          </button>
          <div v-else class="delete-all-button">
            <div class="delete-all-button-dummy"></div>
          </div>
        </b-field>

        <div class="search-results" v-if="isSearchTriggered">
          <div v-for="searchResult in searchResults" :key="searchResult.id" class="search-result-box"
               tabindex="0"
               @keydown.enter="clickSearchResult(searchResult)"
               @click="clickSearchResult(searchResult)">
            <div class="search-result-content">
              <h3>{{ searchResult.name }}</h3>
              <span class="search-result-text">{{ searchResult.text }}</span>
            </div>
          </div>
          <div v-if="searchResults.length === 0" class="table-empty-text">
            <div v-if="isLoading" class="is-loading">
              <i class="exi exi-small-spinner-unmasked rotating"/> {{ $t('toSearch') }}
            </div>
            <div v-if="!isLoading">{{ $t('emptyLibraryManagement') }}</div>
          </div>
        </div>

        <SearchResultDialog ref="searchResultDialog" :showButton="false" :readOnly="true"/>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import SearchModule from '@/store/modules/SearchModule';
import {SearchApplicationDocuments, SearchMainTabs, SearchQuery, SearchResult, SearchSpace} from '@/api/models/search.model';
import SearchResultDialog, {SearchResultDialog as SearchResultDialogClass} from '@/components/SearchResultDialog.vue';
import {ApplicationDocument} from '@/api/models/application.model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import EditorModule from '@/store/modules/EditorModule';
import {AbstractBlockViewModel, SemanticType} from '@/api/models/editor.model';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    components: {
      SearchResultDialog
    }
  })
class Search extends Vue {
  @Ref('searchRef') private searchRef!: HTMLElement;
  @Ref('taginput') private taginput!: HTMLElement;
  @Ref('searchResultDialog') private searchResultDialog!: SearchResultDialogClass;
  @Prop({default: undefined}) private mainTab!: SearchMainTabs | undefined;
  @Prop({default: SearchSpace.TERM}) private searchSpaceTab!: SearchSpace | undefined;

  private applicationDocumentGuid = '';
  private activeMainTab = 1;              // 0: Application Documents, 1: library
  private activeSearchSpaceTab = 0;       // Sub tabs of current main tab
  private activeSearchSpaceTabs = [0, 0]; // Sub tabs as a list to remember

  private mainTabKeys = Object.values(SearchMainTabs); // ['APPLICATION_DOCUMENTS', 'LIBRARY'];
  private applicationDocumentsTabKeys = Object.values(SearchApplicationDocuments);
  private libraryTabKeys = Object.values(SearchSpace);
  private searchSpaceKeys = [this.applicationDocumentsTabKeys, this.libraryTabKeys];

  // Use this if main tabs are wanted again: private mainTabs = this.createTabs('searchMainTabs', this.mainTabKeys);
  private searchSpaceTabs = [
    this.createTabs('searchApplicationDocuments', this.applicationDocumentsTabKeys),
    this.createTabs('searchSpace', this.libraryTabKeys)];

  private searchTerms: Array<string> = [];
  private searchTermsChangedBlocker = false;
  private multiline = true; // Allow line break for tabs

  private isSearchTriggered = false; // Becomes and stays true after a first search

  get currentApplicationDocument(): ApplicationDocument | null {
    return ApplicationModule.currentApplicationDocument;
  }

  @Watch('currentApplicationDocument')
  private currentApplicationDocumentChanged(applicationDocument: ApplicationDocument | null): void {
    this.applicationDocumentGuid = (applicationDocument === null || applicationDocument.guid === undefined) ? '' : applicationDocument.guid;
    if (this.applicationDocumentGuid) {
      // Initial search
      this.clickSearch();
    }
  }

  @Watch('$i18n.locale')
  private onLocaleChange(): void {
    this.searchSpaceTabs = [
      this.createTabs('searchApplicationDocuments', this.applicationDocumentsTabKeys),
      this.createTabs('searchSpace', this.libraryTabKeys)];
  }

  get searchResults(): Array<SearchResult> {
    // Reduce temporarly to only 20 hits (PENGINE-714)
    return SearchModule.results.map((searchResult: SearchResult, id: number) => ({...searchResult, id})).slice(0, 20);
  }

  get isLoading(): boolean {
    return SearchModule.isLoading;
  }

  private createTabs(prefix: string, keys: Array<string>): Array<{ id: number; label: string }> {
    return keys.map((key: string, id: number) => ({id, label: this.$t(prefix + '.' + key) as string}));
  }

  private searchSpaceTabChanged(tabValue: number): void {
    // Will automatically set this.activeSearchSpaceTab = tabValue;
    // Store sub tab for later
    this.activeSearchSpaceTabs[this.activeMainTab] = tabValue;

    this.storeSearchSpace();

    // Always trigger the search on tab change, even if it wasn't triggered before.
    this.clickSearch();
  }

  private searchTermsChanged(values: Array<string>): void {
    this.searchTerms = values;
    this.searchTermsChangedBlocker = true;
    // Block triggering the search on 'enter' for a short time
    setTimeout(() => this.searchTermsChangedBlocker = false, 100);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onSearchEnter(event: Event): void {
    if (!this.searchTermsChangedBlocker) {
      this.clickSearch();
    }
  }

  private clickSearch(): void {
    if (this.applicationDocumentGuid === '') {
      console.error('Search: applicationDocumentGuid is missing.');
      return;
    }
    const event: SearchQuery = {
      applicationDocumentGuid: this.applicationDocumentGuid,
      searchSpaces: [this.libraryTabKeys[this.activeSearchSpaceTab]],
      searchTerms: this.searchTerms
    }
    SearchModule.sendSearchRequest(event)
      .catch(useDefaultErrorHandling);
    this.isSearchTriggered = true;
  }

  private clickDeleteAll(): void {
    this.searchTerms = [];
    this.taginput.focus();
  }

  private clickSearchResult(searchResult: SearchResult): void {
    this.searchResultDialog.openForSearchResult(searchResult);
  }

  // Store the current active tab in the store so that it can be accessed from other components
  // (eg. dialog for creating library entries: SearchResultDialog)
  private storeSearchSpace(): void {
    SearchModule.storeSearchSpace(this.libraryTabKeys[this.activeSearchSpaceTab]);
  }

  mounted(): void {
    // Check if tabs were set from outside
    if (this.mainTab) {
      const nextActiveMainTab = this.mainTabKeys.indexOf(this.mainTab);
      if (nextActiveMainTab >= 0) {
        this.activeMainTab = nextActiveMainTab;
      }
    }
    if (this.searchSpaceTab) {
      const nextSearchSpaceTab = (this.searchSpaceKeys[this.activeMainTab] as SearchSpace[]).indexOf(this.searchSpaceTab);
      if (nextSearchSpaceTab >= 0) {
        this.activeSearchSpaceTabs[this.activeMainTab] = nextSearchSpaceTab;
        this.activeSearchSpaceTab = nextSearchSpaceTab;
        this.storeSearchSpace();
      }
    }
  }

  get selectionInEditorLineage(): AbstractBlockViewModel[] {
    return EditorModule.selectionInEditorLineage;
  }

  @Watch("selectionInEditorLineage", {immediate: true})
  private onSelectionInEditorLineageChange(): void {
    const lastActiveSearchSpaceTab = this.activeSearchSpaceTab;

    const lineage = EditorModule.selectionInEditorLineage.map(block => block.semanticType);

    if (lineage?.find(st => st === SemanticType.TERM_DEFINITION)) { // "Begriffsdefinition"
      this.activeSearchSpaceTab = this.libraryTabKeys.indexOf(SearchSpace.TERM); // Tab "Begriffe"
    } else if (lineage?.find(st => st === SemanticType.GENERAL_DESCRIPTION)) { // "Allgemeiner Teil"
      this.activeSearchSpaceTab = this.libraryTabKeys.indexOf(SearchSpace.TEXT_BLOCK_A); // Tab "Gattungen allgemein"
    } else if (lineage?.find(st => st === SemanticType.EXPLANATION_PREAMBLE)) { // "Erläuterung des Oberbegriffs (Gattung Figuren)"
      this.activeSearchSpaceTab = this.libraryTabKeys.indexOf(SearchSpace.TEXT_BLOCK_B); // Tab "Gattungen Figuren"
    }

    if (lastActiveSearchSpaceTab !== this.activeSearchSpaceTab) {
      this.storeSearchSpace();
      this.clickSearch();
    }
  }
}

export default toNative(Search);
</script>

<style lang="scss">
@import 'src/assets/styles/constants';

.search {
  .b-tabs {
    width: 100%;
    margin-bottom: 0px !important;

    .tab-content {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    > nav ul {
      // width: fit-content;
    }
  }

  .field {
    // min-width: 430px;
    margin-top: 8px;
    $searchButtonSize: 27px;

    .taginput {
      margin-left: 6px;
      width: 105%;

      .taginput-container {
        min-height: 35px;
        padding-right: 14px !important;

        // The first tag should save some space for the search button on the left
        .tag:first-of-type {
          margin-left: $searchButtonSize;
        }

        .tag:not(body) {
          background-color: $pengine-blue-dark !important;
          color: white;
          padding-top: 1px;

          a {
            &:before, &:after {
              background-color: white;
            }
          }
        }

        .autocomplete {

          .control {
            input {
              padding-top: 1px;
              padding-left: 4px !important;
              font-size: $font-size-input;
            }

            .icon {
              width: 24px;
              height: 24px;
              font-size: 22px;
            }
          }
        }
      }

      .taginput-container {
        border: 1px solid #FFFFFF !important;

        &:hover:enabled, &:focus:enabled {
          border: 1px solid #FFFFFF !important;
        }
        .autocomplete {
          .control {
            input.is-empty {
              padding-left: $searchButtonSize !important;
            }
          }
        }
      }
    }

    .search-button {
      height: 35px;
      padding-top: 1px;
      margin-right: -8px - $searchButtonSize;
      z-index: 5; // Stay in front of the input field (even on hover)
    }

    .delete-all-button {
      height: 35px;
      margin-right: -9px;
      margin-top: 0px;
      margin-left: -26px;
      padding-right: 11px;
      z-index: 5; // Stay in front of the input field (even on hover)
      .exi {
        height: 13px;
        width: 13px;
        // padding-right: 4px;
      }

      &-dummy {
        width: 19px;
      }
    }
  }

  .search-space-tabs {
    margin: 5px auto 0px auto;
    width: fit-content;

    .tabs ul {
      border: none;
    }

    .tabs li {
      a {
        $tab-a-padding-horizontal: 7px;
        padding-left: $tab-a-padding-horizontal;
        padding-right: $tab-a-padding-horizontal;
        margin-bottom: 5px;
      }
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import 'src/assets/styles/constants';

.search {
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  height: 100%;

  .search-header {
    width: 100%;
    min-height: $subheader-height;
    height: $subheader-height;
    padding-left: 5px;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    -webkit-user-select: none;
    user-select: none;
    border-bottom: 1px solid $pengine-grey;

    .search-in {
      margin-left: 9px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .search-body {
    //full height - search-header-height
    height: calc(100% - #{$subheader-height});
    width: 100%;
    display: flex;
  }

  .search-body-content {
    padding-left: 9px;
    padding-right: 9px;
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    width: inherit;
  }

  .search-terms {
    margin-top: 3px;

    .search-bar-inner {
      padding-left: 30px !important;
      border: 1px solid #878787 !important;
    }
  }

  .search-result-box {
    padding: 5px;
    margin: 0 5px;

    &:hover {
      background-color: $pengine-grey-light-light-hover;
      cursor: pointer;
    }

    .search-result-content {
      h3 {
        font-size: $font-size-subheader;
        word-wrap: break-word;
      }

      .search-result-text {
        margin-top: 3px;
        line-height: 1.3em;
        font-size: $font-size-small;

        overflow: hidden;
        word-wrap: break-word;
        text-align: start;

        //noinspection CssUnknownProperty
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;

      }
    }
  }

  .search-results {
    overflow-y: auto;
    height: inherit;
    flex-grow: 1;
    font-family: $editor-font;
  }

}
</style>
