import {FigureOrientation, FigureSymbolType, OFFSET, RESOLUTION, SIZES} from '../api/models/drawingbase.model';
import * as paper from 'paper';

/**
 * Converts the given number centimiters to pixels.
 *
 * @example
 * dpi = 300 px / in
 * 1 inch = 2.54 cm
 * 300 dpi = 300 px / 2.54 cm
 *
 * @see https://github.com/ryanve/res
 * @param cm The number of centimiters to be converted.
 * @returns {number}
 */
const cmToPixels = (cm: number): number => {
  const dpi = RESOLUTION;
  return dpi * cm / 2.54;
}

/**
 * Gets the offset of the canvas.
 * @returns {number}
 */
export const getOffset = (): number => {
  return cmToPixels(OFFSET);
}

/**
 * Gets the corresponding canvas size based on the given orientation.
 * @param orientation The orientation of the figure to be used to determine the canvas size.
 */
export const getCanvasSizeFromOrientation = (orientation: FigureOrientation): { width: number; height: number } => {
  return SIZES.A4[orientation]
}

/**
 * Throws an error, if obj is undefined or null.
 * @param obj
 */
export function required<T>(obj: T | undefined | null): T {
  if (obj === undefined || obj === null) {
    throw new Error("Object is required");
  }
  return obj;
}

export function asGroup(item: paper.Item | undefined): paper.Group | undefined {
  return item && item.className === 'Group' ? item as paper.Group : undefined;
}

export function asGroupRequired(item: paper.Item): paper.Group {
  return required(asGroup(item));
}

export function filterOnlyGroups(items: paper.Item[]): paper.Group[] {
  return items
    .map(it => asGroup(it))
    .filter(it => !!it)
    .map(it => required(it));
}

export function getSymbolType(item: paper.Item | undefined): FigureSymbolType | undefined {
  return asGroup(item)?.data.type;
}

function isType(item: paper.Item, type: FigureSymbolType): boolean {
  return getSymbolType(item) === type;
}

export type ItemPredicate = (item: paper.Item) => boolean;

export function isTypeLine(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.LINE);
}

export function isTypeArrow(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.ARROW);
}

export function isTypeBrace(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.BRACE);
}

export function isTypeCurve(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.CURVE);
}

export function isTypePaletteSymbol(item: paper.Item): boolean {
  return isTypeLine(item) || isTypeArrow(item) || isTypeBrace(item) || isTypeCurve(item);
}

export function isTypeReferenceSignMarker(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.REFERENCE_SIGN_MARKER);
}

export function isTypeHelpLine(item: paper.Item): boolean {
  return isType(item, FigureSymbolType.HELP_LINE);
}

export function isTypeWithUnderlineSupport(item: paper.Item): boolean {
  return isTypeReferenceSignMarker(item);
}

export function getSymbolGuid(item: paper.Item | undefined): string | undefined {
  return asGroup(item)?.name;
}

export function getSymbolGuidRequired(item: paper.Item | undefined): string {
  return required(getSymbolGuid(item));
}

/**
 * Compare two strings using localeCompare() with numeric sorting and base sensitivity.
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 */
export function compareStrings(a: string, b: string): number {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
}
