import {Extension} from '@tiptap/vue-3';
import {CreateReferenceSignEvent} from '@/api/models/referenceSign.model';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {EditorState} from '@tiptap/pm/state';
import {useDefaultErrorHandling} from '@/errorHandling';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    addReferenceSignExtension: {
      addReferenceSignExtension: () => ReturnType,
    }
  }
}

export const AddReferenceSignExtension = Extension.create(
  {
    name: 'addReferenceSignExtension',

    addCommands() {
      return {
        addReferenceSignExtension: () => ({state}: { state: EditorState }): boolean => {
          const {selection, doc} = state;
          const selectedText = doc.textBetween(selection.from, selection.to).trim();
          const applicationGuid = ApplicationModule.currentApplicationDocument?.guid;

          if (selectedText && applicationGuid) {
            const createReferenceSignEvent: CreateReferenceSignEvent = {
              applicationDocument: applicationGuid,
              name: selectedText,
            }
            ReferenceSignModule.createReferenceSign(createReferenceSignEvent)
              .catch(useDefaultErrorHandling);
          }
          return true;
        }
      };
    }
  });
