<template>
  <canvas :id="idFromNamedEditor()" style="position: absolute;"></canvas>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import ResizeObserver from 'resize-observer-polyfill';
import {hashCode} from '@/util/hash.util';
import {nextTick} from 'vue';
import {debounce} from 'lodash';
import {NamedEditor} from '@/components/ApplicationEditor.vue';

class Padding {
  private _left = 0;
  private _right = 0;
  private _top = 0;
  private _bottom = 0;

  constructor(left: number, right: number, top: number, bottom: number) {
    this._left = left;
    this._right = right;
    this._top = top;
    this._bottom = bottom;
  }

  get left(): number {
    return this._left;
  }

  set left(value: number) {
    this._left = value;
  }

  get right(): number {
    return this._right;
  }

  set right(value: number) {
    this._right = value;
  }

  get top(): number {
    return this._top;
  }

  set top(value: number) {
    this._top = value;
  }

  get bottom(): number {
    return this._bottom;
  }

  set bottom(value: number) {
    this._bottom = value;
  }
}

/**
 * Placed on top of the application editor view, this component uses an HTML Canvas to render the borders/outlines of inline blocks.
 *
 * For not-inline blocks, we use HTML/CSS borders, which is a faster approach. Unfortunately, the start and end of an inline block is
 * "inline" and HTML/CSS is not able to render this properly. Therefore, we have to render this explicitly using a canvas.
 */
@Component
class BorderVisualizationCanvas extends Vue {

  @Prop({required: true}) editor!: NamedEditor | null;
  @Prop({required: true}) editorId!: string;
  @Prop({required: true}) editorContentId!: string;
  @Prop zoomLevel: number = 1.0;

  private canvasElement!: HTMLCanvasElement;
  private context!: CanvasRenderingContext2D;
  private editorElement!: HTMLElement;
  private editorContentElement!: HTMLElement;
  private zoomWrapperElement!: HTMLElement;
  private editorContentResizeObserver!: ResizeObserver;
  private zoomWrapperResizeObserver!: ResizeObserver;

  private static DOCUMENT_VERTICAL_OFFSET = 30;
  private greyColor = '#c9c9c9'; // rgb(201, 201, 201)
  private orangeColor = '#eb641e'; // rgb(235, 100, 30)
  private redColor = '#ff0000'; // rgb(255, 0, 0)

  // Triggering redraw on zoom events
  private currentRatio = window.devicePixelRatio;

  // Hash calculated based on the border positions. A redraw is only necessary if hash has changed.
  private lastHash = -1;

  private inlineBlockStartXCoordinate = 0;
  private readonly padding = 3;

  private debounceUpdateCanvas = debounce(this.updateCanvas, 150, {leading: true, maxWait: 100});

  mounted() {
    this.initFields();
    this.addEventListeners();
    this.resizeCanvas();
  }

  beforeUnmount() {
    this.zoomWrapperResizeObserver.disconnect();
    this.editorContentResizeObserver.disconnect();
    this.editorContentElement.removeEventListener('scroll', this.updateCanvas);
  }

  private idFromNamedEditor() {
    return "borderVisualizationCanvas-" + this.editor?.editorName;
  }

  private initFields(): void {
    const canvasElement = document.getElementById(this.idFromNamedEditor());
    if (!canvasElement) {
      throw new Error("Element 'borderVisualizationCanvas' not found!");
    }
    this.canvasElement = canvasElement as HTMLCanvasElement;

    const context = this.canvasElement.getContext('2d');
    if (!context) {
      throw new Error("Drawing context for 'borderVisualizationCanvas' could not be acquired!");
    }
    this.context = context;

    const editorElement = document.getElementById(this.editorId);
    if (!editorElement) {
      throw new Error("Editor element not found!");
    }
    this.editorElement = editorElement as HTMLElement;

    const editorContentElement = document.getElementById(this.editorContentId);
    if (!editorContentElement) {
      throw new Error("Editor content element not found!");
    }
    this.editorContentElement = editorContentElement as HTMLElement;

    const zoomWrapperElement = this.editorContentElement.querySelector("#zoomWrapper");
    if (!zoomWrapperElement) {
      throw new Error("Wrapper element not found!");
    }
    this.zoomWrapperElement = zoomWrapperElement as HTMLElement;
  }

  private addEventListeners() {
    this.zoomWrapperResizeObserver = new ResizeObserver(this.debounceUpdateCanvas);
    this.zoomWrapperResizeObserver.observe(this.zoomWrapperElement);

    this.editorContentResizeObserver = new ResizeObserver(this.debounceUpdateCanvas);
    this.editorContentResizeObserver.observe(this.editorContentElement);

    this.editorContentElement.addEventListener('scroll', this.updateCanvas);

    this.editor?.on('transaction', props => {
      this.debounceUpdateCanvas();
    });
  }

  updateCanvas() {
    this.resizeCanvas();
    this.draw();
  }

  public resizeCanvas() {
    // Force a redraw
    this.lastHash = -1;

    const editorContentRect = this.editorContentElement.getBoundingClientRect();
    const zoomWrapperRect = this.zoomWrapperElement.getBoundingClientRect();

    const visibleTop = Math.max(zoomWrapperRect.top, editorContentRect.top);
    const visibleBottom = Math.min(zoomWrapperRect.bottom, editorContentRect.bottom);
    const width = Math.round((zoomWrapperRect.width) / this.zoomLevel);
    const height = Math.round(Math.max(visibleBottom - visibleTop, 0) / this.zoomLevel);
    const offsetTop = Math.round(Math.max(this.editorContentElement.scrollTop - BorderVisualizationCanvas.DOCUMENT_VERTICAL_OFFSET, 0)
      / this.zoomLevel);

    this.canvasElement.style.width = `${width}px`;
    this.canvasElement.style.height = `${height}px`;

    this.currentRatio = window.devicePixelRatio;
    this.canvasElement.width = width * this.currentRatio;
    this.canvasElement.height = height * this.currentRatio;

    this.canvasElement.style.top = `${offsetTop}px`;

    const spanStart = this.editorElement.getElementsByClassName("span-start");
    if (spanStart.length > 0) {
      const p1 = this.normalize(spanStart[0].getBoundingClientRect());
      this.inlineBlockStartXCoordinate = p1.x;
    }
  }

  public draw() {
    nextTick(() => {
      const current = this.editorElement.querySelectorAll('.inline-mode.current-logical-block');
      const siblings = this.editorElement.querySelectorAll('.inline-mode.sibling-of-logical-block, .inline-mode.parent-of-logical-block');
      const unsaved = this.editorElement.querySelectorAll('.block-failed-to-update');

      const hash = this.calcHash(current, siblings, unsaved);
      if (this.lastHash === hash) {
        return;
      }

      this.lastHash = hash;
      this.clearCanvas();

      this.context.save();
      this.context.resetTransform();
      this.context.scale(this.currentRatio / this.zoomLevel, this.currentRatio / this.zoomLevel);

      // Draw grey before orange, so that orange is on top
      siblings.forEach(element => this.drawBlockBorder(element as HTMLElement, this.greyColor));
      current.forEach(element => this.drawBlockBorder(element as HTMLElement, this.orangeColor));
      unsaved.forEach(element => this.drawBlockBorder(element as HTMLElement, this.redColor, true /*bold border*/));

      this.context.restore();
    });
  }

  private calcHash(current: NodeListOf<Element>, siblings: NodeListOf<Element>, unsaved: NodeListOf<Element>): number {
    const hashes: number[] = [];
    current.forEach(element => hashes.push(this.calcHashForElement(element as HTMLElement)));
    hashes.push(-1);    // separator between node-lists
    siblings.forEach(element => hashes.push(this.calcHashForElement(element as HTMLElement)));
    hashes.push(-1);    // separator between node-lists
    unsaved.forEach(element => hashes.push(this.calcHashForElement(element as HTMLElement)));
    return hashCode(hashes);
  }

  private calcHashForElement(element: HTMLElement): number {
    const vals: number[] = [];
    const spanStart = element.querySelector('.span-start')?.getBoundingClientRect();
    const spanEnd = element.querySelector('.span-end')?.getBoundingClientRect();
    const parentDiv = this.upTo(element, 'div')?.getBoundingClientRect();
    if (spanStart && spanEnd && parentDiv) {
      vals.push(spanStart.x, spanStart.y, spanStart.width, spanStart.height);
      vals.push(spanEnd.x, spanEnd.y, spanEnd.width, spanEnd.height);
      vals.push(parentDiv.x, parentDiv.y, parentDiv.width, parentDiv.height);
    }
    return hashCode(vals);
  }

  private drawBlockBorder(element: HTMLElement, borderColor: string, bold?: boolean) {
    this.context.strokeStyle = borderColor;

    let spanStart = element.querySelector('.span-start')?.getBoundingClientRect();
    const spanEnd = element.querySelector('.span-end')?.getBoundingClientRect();
    const parentDiv = this.upTo(element, 'div');
    const lastBlock = element.className.includes("last-inline-block");

    // the getBoundingClientRect-Method has a Bug in Safari therefore needs extra handling to create a correct respresentation of the block border
    if (this.detectBrowser.isSafari) {
      // in case it takes the wrong coordinates for spanStart
      if (spanStart?.height === 0) {
        spanStart = element.querySelector('.span-start')?.nextElementSibling?.getBoundingClientRect();
      }
      // if it doesn't read spanEnd correct in case of a line-break followed by no characters
      if (spanEnd?.height === 0 && spanStart) {
        // calculate missing line breaks
        // 17 = own line height (works disregaring zoom level or font size); spanStart.height = full height of the inline block
        spanEnd.y += (spanStart.height - 17) + this.padding;
        spanEnd.height = spanStart.height;
        // set full width with fallback on 2 to make the caret fit inside the border
        const parentRect = parentDiv?.getBoundingClientRect();
        spanEnd.x = (parentRect) ? (parentRect?.x + parentRect?.width) : 2;
        // get the y-Coordinate of the next Inline-Block to check if there is a line break with no text behind it
        const nextStartSpan = element.nextElementSibling?.getBoundingClientRect();
        if (nextStartSpan && (nextStartSpan.y > spanEnd.y) && !lastBlock) {
          spanEnd.y = nextStartSpan.y - spanStart.height;
        }
        if (lastBlock) {
          // hard coded coordinates for exactly one line break. As it is the last block, there is no next block for spacial reference
          // Set height twice the line-height to accomodate new line. Substract twice the padding and another 2 (don't know why) for correction
          const heightWithoutPadding = spanStart.height - this.padding;
          spanEnd.y = spanStart.y + (heightWithoutPadding * 2) - 2;
        }
      }
    }

    if (!this.context || !parentDiv || !spanStart || !spanEnd) {
      return;
    }

    const p1 = this.normalize(spanStart);
    const p2 = this.normalize(spanEnd);

    this.context.lineWidth = (bold ? 2 : 1) * this.zoomLevel;
    const padding = new Padding(this.padding, this.padding, this.padding, this.padding);

    // Check for single or multi line border drawing
    // Condition 1: The height of spanStart and the text element is the same
    // Condition 2: The y-value of spanStart and spanEnd is the same
    const singleLineSpan = (p1.y === p2.y);
    if (singleLineSpan) {
      this.drawSingleLineSpan(p1, p2, padding);
    } else {
      this.drawMultiLineSpan(p1, p2, parentDiv, padding);
    }
  }

  private normalize(domRect: DOMRect): DOMRect {
    const zoomWrapperRect = this.zoomWrapperElement.getBoundingClientRect();

    const scrollTop = this.editorContentElement.scrollTop;
    const visibleMargin = Math.max(0, BorderVisualizationCanvas.DOCUMENT_VERTICAL_OFFSET - scrollTop);
    const offsetTop = scrollTop - (BorderVisualizationCanvas.DOCUMENT_VERTICAL_OFFSET - visibleMargin);

    // we have to draw our 1px lines at exactly .5-coordinates
    domRect.x = Math.round(domRect.x) - zoomWrapperRect.x + 0.5;
    domRect.y = Math.round(domRect.y) - zoomWrapperRect.y - offsetTop + 0.5;

    return domRect;
  }

  /*      p1-----------------p2
          |                   | <- p2.height
          ---------------------

          0 = start point
          4 = end point
          0/4-----------------1
          |                   |
          3-------------------2       */
  private drawSingleLineSpan(p1: DOMRect, p2: DOMRect, padding: Padding): void {
    this.context.beginPath();
    // Condition stating that the start of the current block is after a "span-end" placed at the begininng of the line.
    const paddingLeft = (p1.x == this.inlineBlockStartXCoordinate + 6) ? padding.left + 6 : padding.left;

    this.context.moveTo(p1.x - paddingLeft, p1.y - padding.top); // 0
    this.context.lineTo(p2.x + padding.right, p2.y - padding.top); // 1
    this.context.lineTo(p2.x + padding.right, p2.y + p2.height + padding.bottom); // 2
    this.context.lineTo(p1.x - paddingLeft, p1.y + p1.height + padding.bottom); // 3
    this.context.lineTo(p1.x - paddingLeft, p1.y - padding.top); // 4
    this.context.lineTo(p1.x - padding.left + 1, p1.y - padding.top); // fill corner
    this.context.stroke();
  }


  /*          p1-----------------p3
              |                   |
      ---------           p2-------
      |                   |
      p4-------------------

              0 = start point
              8 = end point
              0/8-----------------1
              |                   |
      6-------7           3-------2
      |                   |
      5-------------------4

       // If the previous inline node ends with line-break, we need to draw the border from the start of the line.
      6'-------0/8-----------------1
      |                            |
      |                    3-------2
      |                   |
      5-------------------4
            */

  private drawMultiLineSpan(p1: DOMRect, p2: DOMRect, parentDiv: HTMLElement, padding: Padding) {
    const outerRect = parentDiv.getBoundingClientRect();
    const p3 = this.normalize(new DOMRect(outerRect.x + outerRect.width, outerRect.y));
    const p4 = this.normalize(new DOMRect(outerRect.x, outerRect.y + outerRect.height));

    this.context.beginPath();
    this.context.moveTo(p1.x - padding.left, p1.y - padding.top); // 0
    this.context.lineTo(p3.x + padding.right, p1.y - padding.top); // 1
    this.context.lineTo(p3.x + padding.right, p2.y - this.padding); // 2
    this.context.lineTo(p2.x + padding.right, p2.y - this.padding); // 3
    // If the difference between the coordinate x of the points 4 and 5 is around 0, that means there is no content between the 2 points
    // and we do not need to draw the border between them.
    // Instead we need to draw the border to new point having the same height as the point 3.
    if (p2.x + padding.right - p4.x - padding.left == 0) {
      this.context.lineTo(p4.x - padding.left, p2.y - this.padding); // 4

    } else {
      this.context.lineTo(p2.x + padding.right, p2.y + p2.height + padding.bottom); // 4
      this.context.lineTo(p4.x - padding.left, p2.y + p2.height + padding.bottom); // 5
    }

    if (p1.x == this.inlineBlockStartXCoordinate + 6) { // This condition states that the start of the current inline block is right after
      // a span-end (of the a previous inline block) situated at the beginning of the line,
      // so we need to visualize the border from the start of this line.
      this.context.lineTo(p4.x - padding.left - 1, p1.y - padding.top);//6'
    } else {
      this.context.lineTo(p4.x - padding.left, p1.y + p1.height + padding.top); // 6
      this.context.lineTo(p1.x - padding.left, p1.y + p1.height + padding.top); // 7
    }

    this.context.lineTo(p1.x - padding.left, p1.y - padding.top); // 8
    this.context.lineTo(p1.x - padding.left + 1, p1.y - padding.top); // fill corner
    this.context.stroke();
  }

  clearCanvas() {
    this.context.save();
    this.context.resetTransform();
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.context.restore();
  }

  // Find first ancestor of htmlElement with tagName or null if not found
  private upTo(htmlElement: HTMLElement, tagName: string): HTMLElement | null {
    let element = htmlElement;
    while (element && element.parentNode) {
      element = element.parentNode as HTMLElement;
      if (element.tagName && element.tagName.toLowerCase() == tagName.toLowerCase()) {
        return element;
      }
    }
    // Return null if the element is not found
    return null;
  }
}

export default toNative(BorderVisualizationCanvas);
export {BorderVisualizationCanvas};
</script>

<style lang="scss" scoped>

canvas {
  left: 0px;
  position: absolute;
}

</style>
