import {checkIfCookieExists, cookies, createCookie} from '@/util/cookies.util';
import i18n from '@/i18n';
import axios, {AxiosRequestConfig} from 'axios';
import {axiosInstance} from '@/api';
import {UserData} from '@/store/models/auth.model';
import {USER_PROFILE_PATH} from '@/api/services/userprofile.api';

const API_CHECK = '/auth/check';
const API_ENTRYPOINT = '/api/auth/entrypoint';
export const RecoverStateAfterRedirect = 'redirectStateHash';
export const LanguageParamAfterRedirect = 'lng';

/**
 * Check if authorized.
 * Remark: Other than Gatekeeper endpoint /oauth/expired this might trigger a token refresh!
 *
 * @returns Promise - resolved, if authorized; rejected otherwise
 */
export const checkAuthorized = (): Promise<any> => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: process.env.VUE_APP_API_URL,
    url: API_CHECK,
    method: 'get',
  };
  return axios.request(axiosConfig);
}

/**
 * Trigger login inside this window using the API_ENTRYPOINT.
 * The API_ENTRYPOINT should be routed via the "login" Gatekeeper to trigger OAuth authorize.
 * After successful login the browser is redirected back to the current path. In order to restore the previous
 * redux state a random hash is used for identifying the redirect. Using this hash the current state will be stored
 * and can later be retrieved by giving this hash to the redirect url as query param.
 */
// PENGINE-525: We dont't store or restore the Vuex state for now!
export const triggerLogin = (currentState: any): void => {

  if (checkIfCookieExists(cookies.functionals.keycloak.functionalIdentifier)) {
    createCookie(`${cookies.functionals.keycloak.technicalCookie}`,
                 `${i18n.global.t('regulatoryInformation.language_code')};path=/realms/patent-engine/`);
  }

  const target = `${window.location.pathname}`;
  const url = `${API_ENTRYPOINT}?target=${target}&${LanguageParamAfterRedirect}=${i18n.global.t('general.language_code')}`;

  // eslint-disable-next-line no-console
  console.log(`Triggering login (${url})..`);
  window.location.replace(url);
}

export const GetUserProfile = async (): Promise<UserData> => {
  const res = await axiosInstance.get(`${USER_PROFILE_PATH}`);
  return res?.data as UserData;
};