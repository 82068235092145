/**
 * This util class tracks changes and backend requests for objects inside a Vuex module.
 * The objects are identified by string GUID.
 * Together with ViewModelChangeTracker a diff can be calculated between store and view versions of objects.
 * The methods #wrap*Request() provide a convenient mechanism for handling backend calls.
 * Views can register change listeners that get notified whenever a changed-date of a tracked object is modified.
 */
import {removeElement} from '@/util/array.util';

class StoreModelChangeTracker {

  private _changedDateMap: Map<string, Date> = new Map();
  private _pendingRequestMap: Map<string, Promise<unknown>> = new Map();
  private _changeListener: (() => void)[] = [];

  /**
   * Wait until all pending requests are completed, then reset the state.
   * This should be called before changing the underlining model.
   */
  async waitForPendingRequestsAndResetState(): Promise<void> {
    await Promise.all(this._pendingRequestMap.values())
      .catch(error => this.handleError(error))
      .finally(() => {
        this._changedDateMap = new Map();
        this._pendingRequestMap = new Map();
      });
  }

  resetState() {
    this._changedDateMap = new Map();
    this._pendingRequestMap = new Map();
  }

  /**
   * Register a change listener that gets called whenever a changed-date of a tracked object is modified
   * (by #addEntryChangedDate(), #updateEntryChangedDate(), #removeEntry()).
   * This happens whenever a backend request completes successfully or fails.
   * This can be used to trigger a sync to view.
   */
  addChangeListener(listener: () => void) {
    this._changeListener.push(listener);
  }

  /**
   * Unregister a change listener (see #addChangeListener()).
   */
  removeChangeListener(listener: () => void) {
    removeElement(this._changeListener, (it) => it === listener);
  }

  private notifyChangeListeners(): void {
    this._changeListener
      .forEach(listener => setTimeout(() => listener()));
  }

  /**
   * Special case of #wrapGenericRequest() for create-requests.
   * Additionally it updates the change date for guid (calling #addEntryChangedDate() normally and #removeEntry() on error).
   * This should ensure the entries in _changedDateMap are consistent with the store objects and that a diff works correctly.
   *
   * @param guids for tracking the objects
   * @param backendCall function that makes the backend call
   * @param storeUpdater consumer that should handle the backend response, if call was successful
   */
  async wrapCreateRequest<T>(guids: string[],
                             backendCall: (() => Promise<T>),
                             storeUpdater?: ((result: T) => void)): Promise<T | undefined> {
    return this.wrapGenericRequest(guids[0], backendCall, storeUpdater)
      .then((result) => {
        guids.forEach((guid) => this.addEntryChangedDate(guid));
        return result;
      }, (error) => {
        guids.forEach((guid) => this.removeEntry(guid));
        return this.handleError(error);
      });
  }

  /**
   * Special case of #wrapGenericRequest() for update-requests.
   * Additionally it updates the change date for guid (calling #updateEntryChangedDate() normally AND on error).
   * This should ensure the entries in _changedDateMap are consistent with the store objects and that a diff works correctly.
   *
   * @param guid for tracking the object
   * @param backendCall function that makes the backend call
   * @param storeUpdater consumer that should handle the backend response, if call was successful
   */
  async wrapUpdateRequest<T>(guid: string,
                             backendCall: (() => Promise<T>),
                             storeUpdater?: ((result: T) => void)): Promise<T | undefined> {
    return this.wrapGenericRequest(guid, backendCall, storeUpdater)
      .then((result) => {
        this.updateEntryChangedDate(guid);
        return result;
      }, (error) => {
        this.updateEntryChangedDate(guid);
        return this.handleError(error);
      });
  }

  /**
   * Special case of #wrapGenericRequest() for delete-requests.
   * Additionally it updates the change date for guid (calling #removeEntry() normally and #updateEntryChangedDate() on error).
   * This should ensure the entries in _changedDateMap are consistent with the store objects and that a diff works correctly.
   *
   * @param guid for tracking the object
   * @param backendCall function that makes the backend call
   * @param storeUpdater consumer that should handle the backend response, if call was successful
   */
  async wrapDeleteRequest<T>(guid: string,
                             backendCall: (() => Promise<T>),
                             storeUpdater?: ((result: T) => void)): Promise<T | undefined> {
    return this.wrapGenericRequest(guid, backendCall, storeUpdater)
      .then((result) => {
        this.removeEntry(guid);
        return result;
      }, (error) => {
        this.updateEntryChangedDate(guid);
        return this.handleError(error);
      });
  }

  /**
   * Handles a backend call for a specific guid.
   *
   * This ensures that only one backend request per guid is executed at a time.
   * If another request is added for the same guid, it waits for the previous request to succeed.
   * If the previous request fails the new requests is not even started and fails as well.
   *
   * If the given request succeeds it's response is passed to the storeUpdater.
   *
   * @param guid for tracking the object
   * @param backendCall function that makes the backend call
   * @param storeUpdater consumer that should handle the backend response, if call was successful
   */
  async wrapGenericRequest<T>(guid: string,
                              backendCall: (() => Promise<T>),
                              storeUpdater?: ((result: T) => void)): Promise<T> {
    // Check for last pending request for same guid.
    const pendingRequestPromise = this.getPendingRequestPromise(guid);
    // If a pending request exists they are chained.
    const promise = !pendingRequestPromise
      ? backendCall()
      : pendingRequestPromise
        .catch(() => Promise.reject(new Error("Previous request for same item has failed.")))
        .then(() => backendCall());
    // Make this request the last request for this guid.
    this.setPendingRequestPromise(guid, promise);

    try {
      // Wait for request result (after waiting for all previous requests).
      const result = await promise;
      if (storeUpdater) {
        // Success: Pass response to storeUpdater.
        storeUpdater(result);
      }
      return result;

    } finally {
      // Remove it if this is still the last request for this guid.
      this.removePendingRequestForPromise(guid, promise);
    }
  }

  private handleError(error: unknown): undefined {
    console.error(error);
    return undefined;
  }

  /**
   * Set changed-date for guid to new timestamp (default: now).
   * Updates overall last change timestamp to now.
   * Note: This should be called manually for initial store.
   *
   * @param guid
   * @param ts
   */
  addEntryChangedDate(guid: string, ts?: Date) {
    this._changedDateMap.set(guid, ts || new Date());
    this.notifyChangeListeners();
  }

  /**
   * Set changed-date for guid to new timestamp (default: now), but only if an entry already exists.
   * Updates overall last change timestamp to now.
   *
   * @param guid
   * @param ts
   */
  updateEntryChangedDate(guid: string, ts?: Date) {
    if (this._changedDateMap.get(guid)) {
      this._changedDateMap.set(guid, ts || new Date());
      this.notifyChangeListeners();
    }
  }

  /**
   * Remove changed-date for guid.
   * Updates overall last change timestamp to now.
   *
   * @param guid
   */
  removeEntry(guid: string) {
    this._changedDateMap.delete(guid);
    this._pendingRequestMap.delete(guid);
    this.notifyChangeListeners();
  }

  /**
   * Get all guids that exist as keys in changed-dates map.
   */
  getAllGuids(): string[] {
    return Array.from(this._changedDateMap.keys());
  }

  /**
   * Get changed-date for guid.
   *
   * @param guid
   */
  getChangedDate(guid: string): Date | undefined {
    return this._changedDateMap.get(guid);
  }

  /**
   * Check if guid is locked, i.e. there is a pending request.
   *
   * @param guid
   */
  isLocked(guid: string): boolean {
    return !!this._pendingRequestMap.get(guid);
  }

  private getPendingRequestPromise(guid: string): Promise<unknown> | undefined {
    return this._pendingRequestMap.get(guid);
  }

  private setPendingRequestPromise(guid: string, promise: Promise<unknown>) {
    this._pendingRequestMap.set(guid, promise);
  }

  private removePendingRequestForPromise(guid: string, promise: Promise<unknown>) {
    if (this._pendingRequestMap.get(guid) === promise) {
      this._pendingRequestMap.delete(guid);
    }
  }
}

const trackerMap: Map<string, StoreModelChangeTracker> = new Map();

/**
 * Get StoreModelChangeTracker by key.
 * Retrieve tracker from static map. If it doesn't exist yet create new instance.
 * This map is needed because non-primitive fields don't work in Vuex modules!
 *
 * @param key identier for the tracker instance
 */
export function getStoreModelChangeTracker(key: string): StoreModelChangeTracker {
  let tracker = trackerMap.get(key);
  if (!tracker) {
    tracker = new StoreModelChangeTracker();
    trackerMap.set(key, tracker);
  }
  return tracker;
}

export default StoreModelChangeTracker;
