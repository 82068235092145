<template>
  <div class="library-management" id="library-management">

    <div class="vertical-separator-input"></div>
    <h1>{{ $t('library-management') }}</h1>

    <ConfirmationDialog ref="deleteLibEntryDialog" titleKey="deleteLibraryEntry.title"
                        questionKey="deleteReferenceSign.question"/>

    <div class="search-header">
      <b-tabs :modelValue="activeTab" position="is-centered" :multiline="false" @update:modelValue="tabChanged($event)">
        <template v-for="tab in tabs" :key="tab.id">
          <b-tab-item :value="String(tab.id)" :label="tab.label"/>
        </template>
      </b-tabs>
    </div>

    <b-table ref="table"
             :data="libraryList"
             :default-sort="defaultSort"
             :default-sort-direction="defaultSortDirection"
             :mobile-cards="false"
             :debounce-search="500"
             :sticky-header="true"
             @sort="onSort">

      <div class="filter-row">
        <b-field class="search-terms">
          <button v-if="searchTerms.length > 0" class="delete-all-button icon-button"
                  :title="$t('deleteAllSearchTerms')" @click="clickDeleteAllSearchTerms()">
            <i class="delete-all-icon exi exi-close"></i>
          </button>
          <b-taginput ref="taginput" size="is-small" :modelValue="searchTerms" v-bind:class="{ 'is-empty': searchTerms.length === 0 }"
                      @update:modelValue="searchTermsChanged" :placeholder="$t('toSearch')"
                      @keyup.enter="onSearchEnter($event)"/>
          <button class="search-button icon-button" :title="$t('toSearch')">
            <i class="search-icon exi exi-search" @click="clickSearch()"></i>
          </button>
        </b-field>

        <div class="filter">
          <b-input ref="filterInput" id="filter" class="filter-input" v-model="filter" :maxlength="filterMaxLength"
                   :placeholder="$t('filterPlaceholder')" :has-counter="false">
          </b-input>
        </div>
      </div>


      <div class="add-button-row">
        <b-dropdown position="is-bottom-left" :mobile-modal="false" append-to-body aria-role="menu" trap-focus>
          <template #trigger="{}">
            <button class="icon-button" :title="$t('sidebar.library.createEntry')">
              <i :class="'exi exi-plus'"/>
            </button>
          </template>
          <b-dropdown-item v-for="key in tabKeys" :key="key" aria-role="listitem" @click="clickCreateEntry(key)">
            {{ $t(`searchSpaceSingular.${key}.create`) }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <b-table-column field="name" :label="$t('general.name')" header-class="table-header" cell-class="name-cell"
                      sortable v-slot="props" width="30%">
        <div class="table-cell-link" @click="openDetails(props.row)">
          {{ props.row.name }}
        </div>
      </b-table-column>

      <!-- Will be used later -->
      <!-- >b-table-column field="type" :label="$t('type2')" header-class="table-header" sortable>
        <div class="table-cell-link" @click="openDetails(props.row)">
          {{ $t('searchSpaceSingular.' + tabKeys[activeTab]) }}
        </div>
      </b-table-column -->

      <b-table-column field="textShort" :label="$t('description')" header-class="table-header"
                      cell-class="description-cell" sortable v-slot="props">
        <div class="table-cell-link" @click="openDetails(props.row)">
          {{ props.row.textShort }}
        </div>
      </b-table-column>

      <b-table-column field="keywords" :label="$t('keywords')" header-class="table-header"
                      cell-class="keyword-cell" sortable v-slot="props" width="30%">
        <div class="table-cell-link" @click="openDetails(props.row)">
          <b-taglist id="keywords">
            <b-tag v-for="keyword in props.row.keywords" :key="keyword">{{ keyword }}</b-tag>
          </b-taglist>
        </div>
      </b-table-column>

      <b-table-column header-class="table-header" cell-class="button-cell" width="85" v-slot="props">
        <div class="table-cell-padding">
          <button class="icon-button" :title="$t('general.edit')" @click="clickEdit(props.row)">
            <i class="exi exi-pen"/>
          </button>
          <button class="icon-button" :title="$t('general.delete')" @click="clickDelete(props.row)">
            <i class="exi" :class="(isDeleting(props.row.guid) ? 'exi-small-spinner-unmasked rotating' : 'exi-delete')"/>
          </button>
        </div>
      </b-table-column>

      <template v-slot:empty>
        <div class="table-empty-text">
          <div v-if="isLoading" class="is-loading">
            <i class="exi exi-small-spinner-unmasked rotating"/> {{ $t('loadLibraryManagement') }}
          </div>
          <div v-if="!isLoading">{{ $t('emptyLibraryManagement') }}</div>
        </div>
      </template>

    </b-table>

    <SearchResultDialog ref="createEditEntityDialog" :showButton="false" :readOnly="readOnlyDialog"/>

  </div>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import {SearchSpace} from '@/api/models/search.model';
import LibraryModule from '@/store/modules/LibraryModule';
import {Brick, BrickType, LibraryEntity, TermDefinition} from '@/api/models/library.model';
import {PatentEngineConstraints} from '@/constraints';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import SearchResultDialog, {SearchResultDialog as SearchResultDialogClass} from '@/components/SearchResultDialog.vue';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component(
  {
    name: 'library-management',
    components: {
      SearchResultDialog,
      ConfirmationDialog
    }
  })
class LibraryManagement extends Vue {
  @Ref('taginput') private taginput!: HTMLElement;
  // eslint-disable-next-line
  @Ref('table') private table!: any; // Buefy Table
  @Ref('createEditEntityDialog') private createEditEntityDialog!: SearchResultDialogClass;
  @Ref('deleteLibEntryDialog') private deleteLibEntryDialog!: ConfirmationDialogClass;

  private activeTab = 0;
  private readOnlyDialog = false;
  private descriptionMaxLength = 200;
  private tabKeys: SearchSpace[] = Object.values(SearchSpace);
  private tabs = this.createTabs('searchSpace', this.tabKeys);
  private searchTerms: Array<string> = [];
  private searchTermsChangedBlocker = false;
  private filter = '';
  private filterMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME;

  @Watch('$i18n.locale')
  private onLocaleChange(): void {
    this.tabs = this.createTabs('searchSpace', this.tabKeys);
  }

  get libraryList(): LibraryEntity[] {
    return (this.activeTab === 0 ? LibraryModule.termDefinitions : LibraryModule.bricks
      .filter(brick => this.getActiveBrickType() === brick.type))
      .map((entity: LibraryEntity) => ({
        ...entity, textShort:
          entity.text.length > this.descriptionMaxLength ? (entity.text.substring(0, this.descriptionMaxLength) + ' ...') : entity.text
      }))
      // Add local filtering
      .filter((entity) => {
        if (this.filter === '') {
          return true;
        }
        const filter = this.filter.toLowerCase();
        return entity.name.toLowerCase().includes(filter) || entity.keywords.some((keyword) => keyword.toLowerCase().includes(filter));
      });
  }

  private getActiveBrickType(): BrickType | undefined {
    switch (this.activeTab) {
      case 1:
        return BrickType.A;
      case 2:
        return BrickType.B;
      case 3:
        return BrickType.OTHER;
      default:
        return undefined;
    }
  }

  get defaultSort() {
    return LibraryModule.libraryListSorting;
  }

  get defaultSortDirection() {
    return LibraryModule.libraryListSortingDirection;
  }

  get isLoading(): boolean {
    return LibraryModule.isLoading;
  }

  onSort(field: string, direction: string) {
    LibraryModule.setLibraryListSorting(field);
    LibraryModule.setLibraryListSortingDirection(direction);
  }

  private isDeleting(guid: string): boolean {
    return LibraryModule.deletingLibraryEntities.indexOf(guid) > -1;
  }

  private createTabs(prefix: string, keys: Array<string>): Array<{ id: number; label: string }> {
    return keys.map((key: string, id: number) => ({id, label: this.$t(prefix + '.' + key) as string}));
  }

  private tabChanged(tabValue: number): void {
    this.activeTab = tabValue;
    this.clickSearch();
  }

  private searchTermsChanged(values: Array<string>): void {
    this.searchTerms = values;
    this.searchTermsChangedBlocker = true;
    // Block triggering the search on 'enter' for a short time
    setTimeout(() => this.searchTermsChangedBlocker = false, 100);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onSearchEnter(event: Event): void {
    if (!this.searchTermsChangedBlocker) {
      this.clickSearch();
    }
  }

  private clickDeleteAllSearchTerms(): void {
    this.searchTerms = [];
    this.taginput.focus();
  }

  private clickSearch(): void {
    if (this.activeTab === 0) {
      LibraryModule.searchTermDefinitions(this.searchTerms)
        .catch(useDefaultErrorHandling);
    } else {
      const activeBrickType = this.getActiveBrickType();
      if (activeBrickType !== undefined) {
        LibraryModule.searchBricks({brickType: activeBrickType, searchTerms: this.searchTerms})
          .catch(useDefaultErrorHandling);
      }
    }
  }

  private openDetails(libraryEntity: LibraryEntity): void {
    this.readOnlyDialog = true;
    this.createEditEntityDialog.openForLibEntryReading(libraryEntity);
  }

  private clickCreateEntry(searchSpace: SearchSpace): void {
    this.readOnlyDialog = false;
    this.createEditEntityDialog.openForLibEntryCreation([], '', searchSpace);
  }

  private clickEdit(libraryEntity: LibraryEntity): void {
    this.readOnlyDialog = false;
    this.createEditEntityDialog.openForLibEntryEditing(libraryEntity);
  }

  private clickDelete(libraryEntity: LibraryEntity): void {
    const searchSpaceTranslation = this.$t(`searchSpaceSingular.${this.tabKeys[this.activeTab]}.name`) as string;
    this.deleteLibEntryDialog.open({
                                     titleKey: 'deleteLibraryEntry.title',
                                     titleValues: [searchSpaceTranslation],
                                     questionKey: 'deleteLibraryEntry.question',
                                     questionValues: [searchSpaceTranslation, libraryEntity.name],
                                     options: [
                                       {
                                         labelKey: 'general.delete',
                                         class: 'button-delete',
                                         callback: () => {
                                           (this.activeTab === 0) ? LibraryModule.deleteTermDefinition(libraryEntity as TermDefinition)
                                             : LibraryModule.deleteBrick(libraryEntity as Brick).catch(useDefaultErrorHandling);
                                         },
                                         autofocus: true
                                       }, {
                                         labelKey: 'general.cancel',
                                         class: 'button-cancel',
                                       }]
                                   });
  }

  mounted(): void {
    this.tabChanged(this.activeTab);
  }
}

export default toNative(LibraryManagement);
</script>

<style lang="scss">
@import 'src/assets/styles/constants';

.library-management {
  margin: 0px 20px $library-margin-bottom 20px;
  $header-height: 32px;
  $filter-row-height: 45px;
  $width: 1280px;
  min-width: $width;

  .search-header {
    height: $header-height;
    width: $width;
    min-width: $width;
    margin: 0 auto;

    .b-tabs {
      margin-bottom: 0px;
      float: left;

      .tab-content {
        padding: 6px;
      }
    }

  }

  .b-table {
    width: $width;
    min-width: $width;
    margin: 4px auto 0px;
    padding-bottom: $footer-hight;
    height: calc(100% - #{$vertical-separator-input-padding-top} - 61px/* h1*/ - #{$header-height}/*search-header*/ - 10px /* unknown */
    );

    .filter-row {
      height: $filter-row-height;
      padding-top: 10px;
      position: relative;

      .field {
        min-width: 470px;
        max-width: 800px;
        float: left;
        margin-top: 0px;
        margin-bottom: 4px;
        $searchButtonSize: 27px;
        flex-direction: row-reverse;

        .taginput {
          margin-left: 6px;
          width: 100%;

          .taginput-container {
            min-height: 35px;
            padding-right: 14px !important;

            // The first tag should save some space for the search button on the left
            .tag:first-of-type {
              margin-left: $searchButtonSize;
            }

            .tag:not(body) {
              background-color: $pengine-blue-dark !important;
              color: white;

              a {
                &:before, &:after {
                  background-color: white;
                }
              }
            }

            .autocomplete {

              .control {
                input {
                  padding-left: 4px !important;
                  font-size: $font-size-input;
                }

                .icon {
                  width: 24px;
                  height: 24px;
                  font-size: 22px;
                }
              }
            }
          }

          // If there are no tags: let some space for the search button left to the input
          .taginput-container {
            .autocomplete {
              .control {
                input.is-empty {
                  padding-left: $searchButtonSize !important;
                }
              }
            }
          }
        }

        .search-button {
          height: 35px;
          margin-top: 0px;
          margin-right: -8px - $searchButtonSize;
          z-index: 5; // Stay in front of the input field (even on hover)
        }

        .delete-all-button {
          height: 35px;
          margin-top: 0px;
          margin-left: -26px;
          z-index: 5; // Stay in front of the input field (even on hover)
          .exi {
            height: 13px;
            width: 13px;
          }
        }
      }

      .filter {
        position: absolute;
        right: 0px;
        bottom: 0px;

        .control {
          float: right;
          margin-top: -28px;

          input.filter-input {
            width: inherit;
            min-width: 400px;
            max-width: 800px;
            height: 35px;
            font-size: $font-size-input;

            &:hover, &:focus {
              padding-left: 8px !important;
            }
          }
        }
      }
    }

    .add-button-row {
      .dropdown {
        float: right;
        margin-bottom: -3px;
        margin-right: 14px;
        padding-top: 12px;
        padding-bottom: 4px;
      }
    }

    .table-wrapper {
      position: inherit;
      min-width: $width;
      height: calc(100% - #{$filter-row-height} - 8px /* gap to filter-row*/
      ) !important;

      table {
        // Hack for Chrome and IE11 to make the clickable area 100% height.
        height: 1px;

        td {
          padding: 0px;
          text-align: left;

          .table-cell-link {
            width: 100%;
            height: 100%;
            padding: 6px 8px;
            cursor: pointer;
          }

          &.name-cell {
            height: 100%;
            max-width: 250px;
            overflow-wrap: break-word;
          }

          &.description-cell {
            max-width: 350px;
            overflow-wrap: break-word;
          }

          &.keyword-cell {

            .tags {
              margin-top: -1px;
              margin-bottom: -11px !important;

              .tag {
                background-color: $pengine-grey-dark !important;
                color: white;
              }
            }
          }

          &.button-cell {
            text-align: right;

            .table-cell-padding {
              padding-top: 8px;
              padding-right: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
