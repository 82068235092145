import {EditorView as PmEditorView} from '@tiptap/pm/view';
import {findAllTextNodes} from '@/components/applicationEditor/utils/prosemirror.util';

export const handleCopy = (view: PmEditorView, event: ClipboardEvent): boolean => {

  if (!(document.activeElement === view.dom)){
    return true;
  }

  /*
   * When coping text to clipboard prosemirror <br> tags are not handled correctly.
   * Therefore, the selected text is set manually to clipboard.
   * When coping html to clipboard there are no breaks between prosemirror nodes.
   * Therefore, the selected html is set manually to clipboard.
   */
  if (event.clipboardData !== null) {
    const docSelection = document.getSelection();
    if (docSelection) {

      // Check for all text nodes that are selected,
      // because if it's exactly one the formatting will get lost.
      const viewSelection = view.state.selection;
      const selectedNode = view.state.doc.cut(viewSelection.from, viewSelection.to);
      const textNodes = findAllTextNodes(selectedNode);

      event.clipboardData.setData('text/plain', docSelection.toString());

      let html = '';
      if (docSelection.rangeCount) {
        const container = document.createElement('div');
        container.setAttribute('style', 'font-family:Arial, Avenir, Helvetica, sans-serif;');

        for (let rangeIndex = 0, len = docSelection.rangeCount; rangeIndex < len; ++rangeIndex) {
          container.appendChild(docSelection.getRangeAt(rangeIndex).cloneContents());
        }

        // If we have exactly one text node the formatting will get lost, so we need to restore it.
        if (textNodes.length === 1) {

          // Tags for surrounding the original text
          let before = '';
          let after = '';

          // For each formatting mark we need to add one tag
          textNodes[0].marks.forEach(mark => {
            if (mark.type.name === 'bold') {
              before += '<strong>';
              after = '</strong>' + after;
            } else if (mark.type.name === 'italic') {
              before += '<em>';
              after = '</em>' + after;
            } else if (mark.type.name === 'underline') {
              before += '<u>';
              after = '</u>' + after;
            }
          });

          // If we at least added one tag
          if (before !== '') {
            // we surround the original text with it/them
            container.innerHTML = before + container.innerHTML + after;
          }
        }

        html = container.outerHTML;
      }
      event.clipboardData.setData('text/html', html);
      event.preventDefault();
    }
  }
  return false;
}