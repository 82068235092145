// Source: https://github.com/ueberdosis/tiptap/blob/tiptap-utils@1.13.1/packages/tiptap/src/Components/EditorMenuBubble.js
/* eslint-disable */

// jshint ignore: start
import PengineMenuBubble from './PengineMenuBubble';
import {nextTick} from 'vue';

export default {
    emits: ['show', 'hide'],

    props: {
        editor: {
            required: true,
            type: Object,
        },
        isSelectionNeeded: {
            default: true,
            type: Boolean,
        },
        keepInBounds: {
            default: true,
            type: Boolean,
        }
    },

    data() {
        return {
            menu: {
                isActive: false,
                left: 0,
                bottom: 0,
            }
        };
    },

    watch: {
        editor: {
            immediate: true,
            handler(editor) {
                if (editor) {
                    nextTick(() => {
                        editor.registerPlugin(PengineMenuBubble(
                                {
                                    editor,
                                    element: this.$el,
                                    keepInBounds: this.keepInBounds,
                                    isSelectionNeeded: this.isSelectionNeeded,
                                    onUpdate: menu => {
                                        // the second check ensures event is fired only once
                                        if (menu.isActive && this.menu.isActive === false) {
                                            this.$emit('show', menu)
                                        } else if (!menu.isActive && this.menu.isActive === true) {
                                            this.$emit('hide', menu)
                                        }
                                        this.menu = menu
                                    }
                                }));
                    });
                }
            },
        },
    },

    render() {
        if (!this.editor) {
            return null;
        }

        return this.$slots.default(
                {
                    focused: this.editor.view.focused,
                    focus: this.editor.focus,
                    commands: this.editor.commands,
                    setBold: () => this.editor.chain().focus().toggleBold().run(),
                    setItalic: () => this.editor.chain().focus().toggleItalic().run(),
                    setUnderline: () => this.editor.chain().focus().toggleUnderline().run(),
                    setAiGenerated: () => this.editor.chain().focus().unsetAiGenerated().run(),
                    isActive: this.editor.isActive.bind(this.editor),
                    menu: this.menu,
                });
    },

    beforeUnmount() {
        this.editor.unregisterPlugin('pengine_menu_bubble');
    }
}
