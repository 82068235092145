import {createStore} from 'vuex';
import FieldLogger from '@/store/logging/FieldLogger';
import ActionLogger from '@/store/logging/ActionLogger';
import log from 'loglevel';

// Create a new store instance.
const store = createStore({

  plugins: [
    FieldLogger<string[]>('editor._guidsOfChangedNodes', (field, oldValue, newValue) => {
      if(log.getLevel() <= log.levels.INFO) {
        log.info(`Store Change: ${field}`, oldValue, newValue);
      }
    }),
    FieldLogger<string[]>('editor._guidsOfUnsavedNodes', (field, oldValue, newValue) => {
      if(log.getLevel() <= log.levels.INFO) {
        log.info(`Store Change: ${field}`, oldValue, newValue);
      }
    }),
    FieldLogger<string[]>('editor.pendingBlockChanges', (field, oldValue, newValue) => {
      if(log.getLevel() <= log.levels.INFO) {
        log.info(`Store Change: ${field}`, oldValue, newValue);
      }
    }),
    ActionLogger('editor', [
      // 'addChange', // produces too much traffic
      'clearPendingBlockUpdates',
      'updateUnsavedNodeList',
      'resetUpdatedNodes',
      'saveBlocks'
    ], (action, state) => {
      if(log.getLevel() <= log.levels.INFO) {
        log.info('Action - Before', action.type);
      }
    }, (action, state) => {
      if(log.getLevel() <= log.levels.INFO) {
        log.info('Action - After', action.type);
      }
    })
  ]
});

export default store;
