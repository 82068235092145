import {DnDEvent} from 'vue-easy-dnd';
import {ReferenceSignListEntry} from '@/store/models/referenceSign.model';
import paper from 'paper';
import {ApplicationFigureEdit} from '@/api/models/applicationFigure.model';
import {BraceType, FigureSymbolType, Orientation} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {ReferenceSignMarkerPrototype} from '@/api/models/referenceSign.model';

export type CanvasSymbol = paper.Item;

export interface FigureState {
  isLoading: boolean;
  applicationFigure: ApplicationFigureEdit | null;
  selectedSymbols: CanvasSymbol[];
}

export interface FigureEditorDndEvent extends DnDEvent {
  data: ReferenceSignListEntry | SymbolsListEntry | HelpLineListEntry;
}

export interface SymbolsListEntry {
  name: string;
  type: FigureSymbolType;
  dashed: boolean;
  braceType: BraceType;
}

export interface HelpLineListEntry {
  name: string;
  type: FigureSymbolType;
  orientation: Orientation;
}

export const CANVAS_HIT_OPTIONS = {
  segments: true,
  stroke: true,
  fill: true,
  bounds: true,
  handles: true,
  tolerance: 10
};

export const SEGMENT_HIT_OPTIONS = {
  segments: true,
  stroke: true,
  fill: true,
  tolerance: 25
};

export const CURVE_HANDLE_HIT_OPTIONS = {
  segments: false,
  handles: true,
  stroke: false,
  fill: false,
  tolerance: 50
};

export const SELECTION_RECTANGLE_STYLE = {
  strokeColor: "black",
  dashArray: [4, 6]
}

export interface DraggableSymbolEntry {
  name: string;
  label: string;
  edit: boolean;
  type: FigureSymbolType;
  braceType?: BraceType;
  dashed?: boolean;
}

export interface SymbolData {
  applicationGuid: string;
  figureGuid: string;
  copiedData: string[];
  referenceSigns: ReferenceSignMarkerPrototype[];
}

export interface ReferenceSignUpdate {
  newLabel: string;
  newName: string;
  refSignGuid: string;
}

export interface UpdateReferenceSignLabels {
  changeRefSigns: ReferenceSignUpdate[];
  deletedReferenceSignGuids: string[];
}

export const DRAGGABLE_SYMBOL_LIST: DraggableSymbolEntry[] = [
  {name: 'curve', label: 'curve', edit: false, type: FigureSymbolType.CURVE, dashed: false},
  {name: 'dashed-curve', label: 'dashedCurve', edit: false, type: FigureSymbolType.CURVE, dashed: true},
  {name: 'line', label: 'line', edit: false, type: FigureSymbolType.LINE, dashed: false},
  {name: 'dashed-line', label: 'dashedLine', edit: false, type: FigureSymbolType.LINE, dashed: true},
  {name: 'arrow', label: 'arrow', edit: false, type: FigureSymbolType.ARROW, dashed: false},
  {name: 'dashed-arrow', label: 'dashedArrow', edit: false, type: FigureSymbolType.ARROW, dashed: true},
  {name: 'brace', label: 'brace', edit: false, type: FigureSymbolType.BRACE, braceType: BraceType.FULL},
  {name: 'bottom-open-brace', label: 'bottomOpenBrace', edit: false, type: FigureSymbolType.BRACE, braceType: BraceType.OPEN_BOTTOM},
  {name: 'top-open-brace', label: 'topOpenBrace', edit: false, type: FigureSymbolType.BRACE, braceType: BraceType.OPEN_TOP},
];

export const DRAGGABLE_HELP_LINE_LIST: any[] = [
  {
    name: 'horizontal-help-line',
    label: 'horizontalHelpLine',
    edit: false,
    type: FigureSymbolType.HELP_LINE,
    orientation: Orientation.HORIZONTAL
  },
  {
    name: 'vertical-help-line',
    label: 'verticalHelpLine',
    edit: false,
    type: FigureSymbolType.HELP_LINE,
    orientation: Orientation.VERTICAL
  },
];