import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-068cca86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirmation-dialog" }
const _hoisted_2 = { class: "button-row" }
const _hoisted_3 = ["onClickOnce"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_modal, { ref: "dialog" }, {
      header: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t(_ctx.titleKey, _ctx.titleValues)), 1)
      ]),
      body: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.questionKey, _ctx.questionValues)), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "button-container",
              key: option.labelKey
            }, [
              _createElementVNode("button", {
                class: _normalizeClass('column ' + option.class ? option.class : ''),
                onClickOnce: ($event: any) => {option.callback(); _ctx.dialog.closeModal();},
                ref_for: true,
                ref: 'option' + option.id
              }, _toDisplayString(_ctx.$t(option.labelKey)), 43, _hoisted_3)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 512)
  ]))
}