import {ComponentPublicInstance} from "vue";

/**
 * This will add class names of the detected browser(s) to a given HTML element.
 * (is-chrome, is-firefox, is-safari, ...)
 * Attention, a browser can be multiple at one: eg. Chrome on iOS is also Safari at the same time.
 *
 * @param context Current Vue component as context (used to access browser detection)
 * @param element HTMLElement (DOM-Node) to add the classes to
 */
export const addBrowserClasses = <T = any>(context: ComponentPublicInstance<T>, element: HTMLElement): void => {
  if (context.detectBrowser.isChrome) {
    element.className += ' is-chrome';
  }
  if (context.detectBrowser.isFirefox) {
    element.className += ' is-firefox';
  }
  if (context.detectBrowser.isSafari) {
    element.className += ' is-safari';
  }
  if (context.detectBrowser.isIE) {
    element.className += ' is-ie';
  }
  if (context.detectBrowser.isEdge) {
    element.className += ' is-edge';
  }
  if (context.detectBrowser.isOpera) {
    element.className += ' is-opera';
  }
  if (context.detectBrowser.isIOS) {
    element.className += ' is-ios';
  }
};
