<template>
  <div class="splitpanes__pane" @click="onPaneClick($event, _.uid)" :style="style">
    <slot/>
  </div>
</template>

<script lang="ts">
import {Component, Inject, Prop, toNative, Vue, Watch} from 'vue-facing-decorator';

@Component
class Pane extends Vue {
  // Injected methods from parent component
  @Inject({from: 'requestUpdate'}) requestUpdate!: Function;
  @Inject({from: 'onPaneAdd'}) onPaneAdd!: Function;
  @Inject({from: 'onPaneRemove'}) onPaneRemove!: Function;
  @Inject({from: 'onPaneClick'}) onPaneClick!: Function;

  @Prop({ default: null, type: [Number, String] }) readonly size!: number | string;
  @Prop({ default: 0, type: [Number, String] }) readonly minSize!: number | string;
  @Prop({ default: 100, type: [Number, String] }) readonly maxSize!: number | string;

  style: Record<string, any> = {};

  mounted() {
    this.onPaneAdd(this);
  }

  beforeUnmount() {
    this.onPaneRemove(this);
  }

  update(style: Record<string, any>) {
    this.style = style;
  }

  @Watch('sizeNumber')
  onSizeNumberChanged(size: number) {
    this.requestUpdate({ target: this, size });
  }

  @Watch('minSizeNumber')
  onMinSizeNumberChanged(min: number) {
    this.requestUpdate({ target: this, min });
  }

  @Watch('maxSizeNumber')
  onMaxSizeNumberChanged(max: number) {
    this.requestUpdate({ target: this, max });
  }

  get sizeNumber() {
    return (this.size || this.size === 0) ? parseFloat(this.size as string) : null;
  }

  get minSizeNumber() {
    return parseFloat(this.minSize as string);
  }

  get maxSizeNumber() {
    return parseFloat(this.maxSize as string);
  }
}

export default toNative(Pane);
export {Pane};
</script>
