<template>
  <div class="figureEditor"
       ref="figureEditor"
       tabindex="-1"
       @click="this.figureEditor.focus()"
       @mouseenter="() => this.hasMouseInWidget = true"
       @mouseleave="() => this.hasMouseInWidget = false"
       @keydown="onKeyDown"
       v-active-element="onActiveElementChange"
       v-click-outside="onClickOutside">
    <ConfirmationDialog ref="confirmationDialog"/>
    <div class="header">
      <Toolbar
        :disabled="toolbarDisabled"
        :figure-number-model="figureNumberComputed"
        :figure-list="this.figures"
        :figure-number-showing="this.figureNumberShowing"
        :zoom-in-clicked="() => this.canvas.zoomIn()"
        :zoom-out-clicked="() => this.canvas.zoomOut()"
        :full-zoom-out-clicked="() => this.canvas.onFullZoomOut()"
        :scaling-changed="(newScaling, withContentArea) => this.canvas.setScaling(newScaling, withContentArea)"
        :figure-number-clicked="() => this.setShowFigureNumber(!this.figureNumberShowing)"
        :toggle-help-lines="() => this.onToggleHelpLines()"
        :underline-button-clicked="() => this.canvas.changeUnderliningSelected()"
        :delete-button-clicked="() => this.canvas.removeSelected()"
        :figure-number-changed="(newFigureNumber) => this.figureNumberChanged(newFigureNumber)"
      />
    </div>
    <div class="content" ref="canvas-content-div">
      <Canvas
        ref="canvas"
        :focusable-parent="figureEditor"
        :client-width="widthComputed"
        :client-height="clientHeightComputed"
        :_application-figure="applicationFigureEdit"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import Toolbar from '@/components/figureEditor/Toolbar.vue';
import Canvas, {Canvas as CanvasClass} from '@/components/figureEditor/Canvas.vue';
import FigureModule from '@/store/modules/FigureModule';
import * as paper from 'paper';
import {ApplicationFigureEdit, ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';
import ResizeObserver from 'resize-observer-polyfill';
import {CanvasSymbol, SymbolData} from '@/store/models/figure.model';
import {FigureSymbolType, PASTE_OFFSET, ReferenceSignMarker} from '../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import ReferenceSignModule from '@/store/modules/ReferenceSignModule';
import ConfirmationDialog, {ConfirmationDialog as ConfirmationDialogClass} from '@/components/common/ConfirmationDialog.vue';
import {InsertElementsEvent, UserConsent} from '@/api/models/figureSymbol.model';
import {BasicReferenceSign, ReferenceSignMarkerPrototype} from '@/api/models/referenceSign.model';
import {debounce} from 'lodash';
import {useDefaultErrorHandling} from '@/errorHandling';
import ActiveElementDirective from '@/directives/ActiveElementDirective';
import {hasFigureSameThumbnail, selectNewFigure} from '@/store/modules/ApplicationFigureModule';
import {areArraysIdenticalByKey} from '@/util/array.util';

export type FigureGuidChangedHandler = (newFigureGuid: string | null) => void;

@Component({
             emits: ['figureChanged'],
             name: 'figureEditor',
             components: {
               ConfirmationDialog,
               Toolbar,
               Canvas
             },
             directives: {
               'active-element': ActiveElementDirective
             }
           })
class FigureEditor extends Vue {

  @Ref('canvas-content-div') canvasContentDiv!: HTMLDivElement;

  @Ref('canvas') readonly canvas!: CanvasClass;

  @Ref('confirmationDialog') dialog!: ConfirmationDialogClass;

  @Ref('figureEditor') figureEditor!: HTMLDivElement;

  private clientWidth = 300;

  private clientHeight = 300;

  @Prop({default: 300})
  private minWidth!: number;

  @Prop({default: null, required: true})
  private figureGuid!: string | null;

  @Prop({required: true})
  private applicationGuid!: string;

  @Prop({required: true})
  private figures!: ApplicationFigureThumbnail[];

  private figureNumber: number | null = null;

  private resizeObserver!: ResizeObserver | null;

  private currentFigureGUID: string | null = this.figureGuid;

  private activeElement: Element | null = null;

  private hasMouseInWidget = false;

  private get widthComputed(): number {
    const width = this.clientWidth < this.minWidth ? this.minWidth : this.clientWidth;
    return width;
  }

  private get clientHeightComputed(): number {
    return this.clientHeight;
  }

  private onCanvasContentResize(): void {
    if (this.canvasContentDiv) {
      this.clientWidth = this.canvasContentDiv.clientWidth;
      this.clientHeight = this.canvasContentDiv.clientHeight;
    }
  }

  mounted(): void {

    document.addEventListener('copy', this.onCopy);
    document.addEventListener('paste', this.onPaste);
    this.hasMouseInWidget = false;

    // debounce is needed, as Splitpanes can cause too many resize actions to handle
    this.resizeObserver = new ResizeObserver(debounce(this.onCanvasContentResize));
    this.resizeObserver.observe(this.canvasContentDiv);

    if(this.figureGuid !== null) {
      this.debouncedLoadFigure(this.figureGuid);
    }
  }

  beforeUnmount(): void {
    this.resizeObserver?.disconnect();
    this.resizeObserver = null;

    document.removeEventListener('copy', this.onCopy);
    document.removeEventListener('paste', this.onPaste);
  }

  get figureNumberComputed(): number | null {
    return this.figures.length === 0 ? null : this.figureNumber;
  }

  get toolbarDisabled(): boolean {
    return this.figureGuid === null;
  }

  @Watch('figureGuid', {immediate: true})
  loadNewFigure(newValue: string | null, oldValue: string | null): void {
    this.currentFigureGUID = newValue;
    this.debouncedLoadFigure(newValue);
  }

  @Emit('figureChanged')
  emitFigureChanged(figureGuid: string | null){
    return figureGuid;
  }

  @Watch('currentFigureGUID', {immediate: true})
  private onCurrentFigureGuidChanged(newValue: string, oldValue: string | undefined){
    this.resetFigureNumber();
    this.emitFigureChanged(newValue);
  }

  @Watch('figures', {deep: true})
  private onFiguresChanged(newValues: ApplicationFigureThumbnail[], oldValues: ApplicationFigureThumbnail[]) {

    const noFiguresRemovedOrAdded = areArraysIdenticalByKey(newValues, oldValues, item => item.guid);

    if(noFiguresRemovedOrAdded){
      // is there was no change the number of figures then just check the thumbnail of the current figure
      if (this.currentFigureGUID !== null && !hasFigureSameThumbnail(this.currentFigureGUID, newValues, oldValues)) {
        this.debouncedLoadFigure(this.currentFigureGUID);
        return;
      }
    }

    // else check if the current figure was removed and trigger reload
    const oldCurrentFigure = this.currentFigureGUID;
    this.currentFigureGUID = selectNewFigure(this.currentFigureGUID, newValues, oldValues);

    if (oldCurrentFigure !== this.currentFigureGUID) {
      this.debouncedLoadFigure(this.currentFigureGUID);
      this.emitFigureChanged(this.currentFigureGUID);
    }
    this.resetFigureNumber();
  }

  resetFigureNumber() {
    if (this.figures.length === 0) {
      this.figureNumber = null;
      return;
    }
    for (let i = 0; i < this.figures.length; i++) {
      if (this.currentFigureGUID === this.figures[i].guid) {
        this.figureNumber = i + 1;
      }
    }
  }

  get applicationFigureEdit(): ApplicationFigureEdit | null {
    return FigureModule.applicationFigure
  }

  public onCopy(event: ClipboardEvent): void {
    if (!this.hasFocus) {
      return;
    }
    const applicationGuid = this.applicationGuid;
    if (this.currentFigureGUID && applicationGuid && this.hasMouseInWidget) {
      const currentSelection = FigureModule.selectedSymbols;
      const exportdata = currentSelection
        .filter((symbol) => symbol.data.type !== FigureSymbolType.REFERENCE_SIGN_MARKER)
        .map((symbol) => symbol.exportJSON());

      const referenceSignMarkerPrototypes = this.getReferenceSignMarkerPrototypes(currentSelection)
      const symbolData: SymbolData = {
        copiedData: exportdata,
        figureGuid: this.currentFigureGUID,
        applicationGuid: applicationGuid,
        referenceSigns: referenceSignMarkerPrototypes
      };
      // Save to clipboard
      const symbolDataAsJson = JSON.stringify(symbolData)
      event.clipboardData?.setData('application/json', symbolDataAsJson);
      event.preventDefault();
    }
  }

  private getReferenceSignMarkerPrototypes(currentSelection: CanvasSymbol[]) {
    return currentSelection
      .filter((symbol) => symbol.data.type === FigureSymbolType.REFERENCE_SIGN_MARKER)
      .map((referenceSignMarker) => {
        const figureSymbol = FigureModule.symbol(referenceSignMarker.name) as ReferenceSignMarker;
        const basicReferenceSigns = figureSymbol.referenceSigns.map((refSign) => {
          const referenceSign = ReferenceSignModule.referenceSigns.find(sign => sign.guid === refSign.guid);
          const basicRefSign: BasicReferenceSign = {
            name: refSign.name,
            stemForms: referenceSign?.stemForms ? referenceSign.stemForms : [],
            excludedStems: referenceSign?.excludeStemFormMatches ? referenceSign.excludeStemFormMatches : []
          };
          return basicRefSign;
        })

        const referenceSignMarkerPrototype: ReferenceSignMarkerPrototype = {
          topLeftX: referenceSignMarker.position.x - (referenceSignMarker.data.boundingBox.getWidth() / 2),
          topLeftY: referenceSignMarker.position.y - (referenceSignMarker.data.boundingBox.getHeight() / 2),
          bottomRightX: referenceSignMarker.position.x + (referenceSignMarker.data.boundingBox.getWidth() / 2),
          bottomRightY: referenceSignMarker.position.y + (referenceSignMarker.data.boundingBox.getHeight() / 2),
          referenceSigns: basicReferenceSigns,
          underlined: figureSymbol.underlined
        }
        return referenceSignMarkerPrototype;
      });
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (!this.hasFocus) {
      return;
    }
    const keyLowerCase = event.key.toLowerCase();
    if (event.ctrlKey && keyLowerCase === 'a') {
      this.canvas.selectAllItems();
      event.preventDefault();
    }
  }

  private get figureNumberShowing(): boolean {
    if (FigureModule.applicationFigure === null) {
      return false;
    }
    return FigureModule.applicationFigure.showFigureNumber;
  }

  private setShowFigureNumber(showFigureNumber: boolean): void {
    if (FigureModule.applicationFigure === null) {
      return;
    }

    this.canvas.setFigureNumberVisible(showFigureNumber);
  }

  private onToggleHelpLines(): void {
    FigureModule.toggleShowHelpLines();
  }

  private figureNumberChanged(newFigureNumber: number): void {
    if (this.figures.length === 0) {
      return;
    }
    const figNum = newFigureNumber - 1;
    if (figNum >= 0 && figNum < this.figures.length) {
      this.figureNumber = figNum + 1;
      const guid = this.figures[this.figureNumber - 1].guid;
      this.currentFigureGUID = guid;
      FigureModule.setIsLoading(true);
      this.debouncedLoadFigure(guid);
    }
  }

  private debouncedLoadFigure = debounce(this.loadFigure, 200);

  public onPaste(event: ClipboardEvent): void {
    if (!this.hasFocus) {
      return;
    }
    // Get Data
    const data = event.clipboardData?.getData('application/json');
    if (data) {
      const parsedData: SymbolData = JSON.parse(data);
      // Check if symbols are from the same figure
      const figureGuidOfCopy = parsedData.figureGuid
      const isFromSameFigure = (figureGuidOfCopy === this.currentFigureGUID);

      // Check whether it is necessary to create new ReferenceSigns
      const refSignsFromCopy = parsedData.referenceSigns;
      if (refSignsFromCopy?.length > 0 && this.currentFigureGUID) {
        this.insertReferenceSigns(refSignsFromCopy, isFromSameFigure);
      }

      // deserialize data
      const importData = parsedData.copiedData.map((symbol) => {
        // Use import to create a paperjs group - this also places the group onto the primary (active) layer.
        // We don't want that and are only interested in the resulting object, so we have to remove the group
        // from the primary layer right away.
        const group = new paper.Group().importJSON(symbol);
        // remove group (created by importJSON, see above!)
        group.remove();
        return group;
      });
      this.canvas.saveSymbol(importData, isFromSameFigure);
    }
    event.preventDefault();
  }

  private insertReferenceSigns(refSignsFromCopy: ReferenceSignMarkerPrototype[], isFromSameFigure: boolean) {
    if (!this.currentFigureGUID) {
      return;
    }
    const event: InsertElementsEvent = {
      figureGuid: this.currentFigureGUID,
      offset: isFromSameFigure ? PASTE_OFFSET : 0,
      userConsent: UserConsent.NOT_ASKED,
      referenceSignMarker: refSignsFromCopy
    }

    // Send Marker-Data to backend
    FigureModule.insertElements(event)
      .then((res) => {
        // Open Dialog if consent needed
        this.canvas.syncStoreToView(true);
        if (res.askForConsent) {
          this.openDialog(event);
        }
      })
      .catch(useDefaultErrorHandling);
  }

  private openDialog(event: InsertElementsEvent) {
    // Create Dialog params
    const dialogparams = {
      titleKey: 'copyReferenceSignMarkerDialog.title',
      questionKey: 'copyReferenceSignMarkerDialog.description',
      options: [
        {
          labelKey: 'copyReferenceSignMarkerDialog.confirm', callback: () => {
            event.userConsent = UserConsent.ACCEPTED;
            ReferenceSignModule.setReferenceSignLoading();
            FigureModule.insertElements(event)
              .then((res) => {
                ReferenceSignModule.setReferenceSigns(res.updatedReferenceSignList);
                ReferenceSignModule.setReferenceSignNotLoading();
                this.canvas.syncStoreToView(true);
              })
              .catch(useDefaultErrorHandling);
          }
        },
        {
          labelKey: 'copyReferenceSignMarkerDialog.abort', callback: () => {
            event.userConsent = UserConsent.DENIED;
            FigureModule.insertElements(event).catch(useDefaultErrorHandling);
          }
        },
      ]
    }
    this.dialog.open(dialogparams);
  }

  private loadFigure(guid: string | null) {
    FigureModule.fetchApplicationFigure(guid)
      .then((newFigure: ApplicationFigureEdit | null) => {
        if(guid) {
          this.updateFigureURL(guid);
        }
      })
      .catch(useDefaultErrorHandling);
  }

  private updateFigureURL(guid: string) {
    const path = this.$route.path;
    // Set the guid of the now opened figure
    const newPath = path.replace(/\/figure.*/gm, `/figure/${guid}`);
    if (path !== newPath) {
      this.$router.push(newPath);
    }
  }

  private onActiveElementChange(element: Element | null) {
    this.activeElement = element;
  }

  get hasFocus(): boolean {
    return this.activeElement === this.figureEditor;
  }

  onClickOutside() {
    this.canvas.clearSelection();
  }
}

export default toNative(FigureEditor);
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors.scss';
@import 'src/assets/styles/constants.scss';

// Suppress Chrome displaying a visible outline when focussing the element
:focus-visible {
  outline: none;
}

.figureEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: fit-content;
    border-bottom: 1px solid $pengine-grey;
    max-height: 54px;
    position: relative;

    .menubar {
      background-color: white;
    }
  }

  .content {
    width: 100%;
    flex-grow: 1;
    background-color: $pengine-grey;
    display: grid;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    overflow-y: hidden;
  }
}
</style>
