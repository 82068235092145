<template>
  <div class="search-bar">

    <b-navbar>
      <template #start>
        <div class="search">
          <input ref="search" v-model="searchTerm" type="text" @keydown.enter.prevent="find(searchTerm)"
                 :placeholder="$t('menubar.search.placeholder.search')"/>
          <input v-model="replaceWith" type="text" @keydown.enter.prevent="replace(replaceWith)"
                 :placeholder="$t('menubar.search.placeholder.replace')"/>
          <button class="button search-button" :title="$t('menubar.search.find.title')" @click="find(searchTerm)"
                  :disabled="!searchTerm || isReplacing">
            {{ $t('menubar.search.find.label') }}
          </button>
          <button class="button replace-button" :title="$t('menubar.search.replace.title')" @click="replace(replaceWith, searchTerm)"
                  :disabled="!searchTerm || isReplacing">
            {{ $t('menubar.search.replace.label') }}
          </button>
          <button class="button replace-all-button" :title="$t('menubar.search.replaceAll.title')" @click="replaceAll(replaceWith,
          searchTerm)"
                  :disabled="!searchTerm || isReplacing">
            {{ $t('menubar.search.replaceAll.label') }}
          </button>
        </div>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <b-button class="close-button icon-button" :title="$t('menubar.search.close.title')" @click="close()">
              <i :class="'exi exi-close'"></i>
            </b-button>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import EditorModule from '@/store/modules/EditorModule';
import {NamedEditor} from '@/components/ApplicationEditor.vue';

@Component({})
class SearchBar extends Vue {

  @Prop({required: true}) editor!: NamedEditor;
  @Prop({default: undefined}) private closeCallback!: () => void;
  @Prop({default: false}) private toggleIsActive!: boolean;
  @Ref('search') private search!: HTMLInputElement;
  private searchTerm = '';
  private replaceWith = '';
  private skipFindings = 0;

  private get isReplacing(): boolean {
    return EditorModule.isReplacing;
  }

  private get triggerClearSearchCounter(): number {
    return EditorModule.triggerClearSearchCounter;
  }

  private get searchTermOnKeyDown(): string {
    return EditorModule.searchTermOnKeyDown;
  }

  @Watch('searchTermOnKeyDown', {immediate: true})
  private searchTermChanged() {
    this.searchTerm = this.searchTermOnKeyDown;
  }

  @Watch('triggerClearSearchCounter', {immediate: true})
  private triggerClearSearchChanged() {
    this.clearSearch();
  }

  public clearSearch(): void {
    this.editor.commands.clearSearch();
  }

  private close(): void {
    if (this.closeCallback) {
      this.closeCallback();
    } else {
      console.log('closeCallback function not set!');
    }
  }

  private find(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.skipFindings = 0;
    this.editor.commands.find({searchTerm: this.searchTerm});
  }

  private removeSelection(): void {
    const selection = this.editor.state.selection;
    this.editor.commands.setTextSelection({from: selection.from, to: selection.from});
  }

  private beforeReplacing(searchTerm: string) {
    EditorModule.setIsReplacing(true);
    this.searchTerm = searchTerm;
    // Remove selection in editor, because it may kill everything on replacement when selected multiple blocks
    this.removeSelection();
  }

  private afterReplacing(success: boolean) {
    EditorModule.setIsReplacing(false);
    if (success) {
      EditorModule.triggerSave();
    }
  }

  private replace(replace: string, searchTerm: string): void {
    this.beforeReplacing(searchTerm);
    this.editor.commands.replace({replace, searchTerm, callback: this.afterReplacing});
  }

  private replaceAll(replace: string, searchTerm: string): void {
    this.beforeReplacing(searchTerm);
    this.editor.commands.replaceAll({replace, searchTerm, callback: this.afterReplacing});
  }

  public setFocus(): void {
    this.search.focus();
  }
}

export default toNative(SearchBar);
export {SearchBar};
</script>

<style lang="scss">
@import 'src/assets/styles/constants';
@import 'src/assets/styles/colors';

.search-bar {
  text-align: left;
  width: 100%;

  .navbar {
    height: fit-content;
    min-height: inherit;
    align-items: stretch;
    display: flex;

    .navbar-brand {
      min-height: initial;
      display: none;
    }

    .navbar-menu {
      padding: 0;
      align-items: stretch;
      -webkit-box-align: stretch;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      -webkit-box-flex: 1;

      .navbar-start {
        justify-content: flex-start;
        margin-right: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-box-align: stretch;

        input {
          margin: 3px 6px 6px;
          height: 26px;
          width: 278px;

          &:enabled:hover, &:enabled:focus {
            margin-right: 5px;
          }
        }

        .button {
          border: none;
          background-color: transparent;
          height: $search-bar-height;

          &:hover, &:focus {
            background-color: transparent;
          }

          &:hover:enabled {
            color: $pengine-blue-dark;
          }

          &.search-button:after {
            content: "";
            background: $pengine-grey-dark;
            position: absolute;
            bottom: 4px;
            right: 0;
            height: 75%;
            width: 1px;
          }

          &.search-button {
            padding-left: 12px;
            padding-right: 19px;
          }

          &.replace-button {
            padding-left: 17px;
            padding-right: 10px;
          }

          .exi {
            display: inline-block;
          }

          .button-icon, .button-label {
            float: left;
          }

          .button-label {
            padding-left: 8px;
            font-family: $text-font;
            font-size: 14px;
          }
        }
      }

      .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-box-align: stretch;

        .navbar-item {
          padding: 0;

          .close-button {
            padding-top: 0px;
            padding-bottom: 0px;
            background-color: transparent;
            height: $search-bar-height;
            border: none;

            i {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }
  }

  .buttons {
    margin-top: auto;
    margin-bottom: 0px;
  }
}
</style>
