<template>
  <span class="generate-container">
    <span v-if="!isGenerateAllLoading">
      <b-button type="is-info" @click="onClick()" :title="$t(`menubar.tooltip.generateAll`)"
                :disabled="isLoading || isGenerateUnRedoLoading">
        <i class="exi exi-synchronize"/>
        {{ $t(`menubar.generateAll`) }}
      </b-button>
    </span>
    <span v-else>
      <button id="spinner" :title="$t('general.loading')" class="icon-button loading-button">
        <i class="exi exi-small-spinner-unmasked rotating"/>
      </button>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Inject, toNative, Vue, Watch} from 'vue-facing-decorator';
import EditorModule from '@/store/modules/EditorModule';
import {ApplicationDocument} from '@/api/models/application.model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {EditorState} from '@tiptap/pm/state';
import {SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component
class GenerateAll extends Vue {

  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Inject({from: 'applicationEditor.activeEditorState'}) state!: EditorState;
  private applicationDocumentGuid: string | null | undefined;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get isGenerateUnRedoLoading(): boolean {
    return EditorModule.isGenerateUnRedoLoading;
  }

  get isGenerateAllLoading(): boolean {
    return EditorModule.isGenerateAllLoading;
  }

  get currentApplicationDocument(): ApplicationDocument | null {
    return ApplicationModule.currentApplicationDocument;
  }

  @Watch('currentApplicationDocument', {immediate: true})
  private currentApplicationDocumentChanged(applicationDocument: ApplicationDocument | null) {
    this.applicationDocumentGuid = applicationDocument ? applicationDocument.guid : null;
  }

  onClick(): void {
    if (!this.applicationDocumentGuid) {
      return;
    }
    this.autoGeneratorAll(this.applicationDocumentGuid);
    setTimeout(() => document.getElementById('spinner')?.focus(), 60);
  }

  autoGeneratorAll(applicationDocumentGuid: string): boolean {
    if (applicationDocumentGuid != null) {
      this.saveEditorChangesFn(false)
        .catch(useDefaultErrorHandling)
        .then(() => EditorModule.generateAllBlocks(applicationDocumentGuid));
    }
    return true;
  }
}

export default toNative(GenerateAll);
</script>

<style lang="scss" scoped>

.generate-container {
  display: flex;
  flex-flow: row-reverse wrap;
  text-align: right;

  button:disabled {
    background-color: transparent;
    border-color: transparent;
  }

  button:focus-visible {
    color: black;
  }
}

</style>
