import {Extension} from '@tiptap/vue-3';
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {Node as PmNode} from '@tiptap/pm/model';
import {EditorState, Plugin} from '@tiptap/pm/state'
import EditorModule from '@/store/modules/EditorModule';
import {findLowestDepth, isLogicalBlock} from '@/components/applicationEditor/utils/node.util';
import i18n from '@/i18n';

const addDecorations = (state: EditorState): DecorationSet => {
  const decorations: Decoration[] = [];

  const rootNode = state.doc;
  const guidsOfUnsavedNodes = EditorModule.guidsOfUnsavedNodes;

  if (guidsOfUnsavedNodes.length > 0) {
    rootNode.descendants((node: PmNode, pos: number) => {
      if (guidsOfUnsavedNodes.includes(node.attrs.guid)) {
        // Some sub-block may be marked as unsaved in guidsOfUnsavedNodes, e.g. of type "explanationTechnicalFieldText".
        // We have to find the first *logical* parent block (e.g. "explanationTechnicalField") and add a decoration to it.
        const resolvedPos = rootNode.resolve(pos);
        const parentDepth = findLowestDepth(resolvedPos, isLogicalBlock);
        if (parentDepth !== -1) {
          const start = resolvedPos.before(parentDepth);
          const end = resolvedPos.after(parentDepth);
          const decoration = Decoration.node(start, end, {
            class: 'block-failed-to-update', 'title': i18n.global.t('menubar.tooltip.unSaved') as string
          });
          decorations.push(decoration);
        }
        // don't descend into child nodes
        return false;
      }
    });
  }

  return DecorationSet.create(rootNode, decorations);
};

/**
 * Extension to add docoration (red frame) to the logical block of block(s) indicating that they failed to update.
 */
export const UnsavedBlockVisualizationExtension = Extension.create(
  {
    name: 'unsavedBlockVisualizationExtension',

    addProseMirrorPlugins() {
      return [
        new Plugin(
          {
            props: {
              decorations: (state: EditorState): DecorationSet | null | undefined => {
                return addDecorations(state);
              },
            },
          }),
      ];
    }
  });
