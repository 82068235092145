<template>
  <div class="symbol-list">
    <div class="symbol-header">
      <h2>{{ symbolHeader }}</h2>
      <hr/>
    </div>
    <div class="table-container" :style="isDisabled ? {opacity: 0.5} : ''">
      <div v-for='entry in symbolsList' :key='entry.name'>
        <drag :data="entry" class="symbol" @dragstart="dragstart" :disabled="isDisabled">
          <span ref="labels" class="cell-text symbol-label" :style="width">
          <i :class="`exs-md exs-${entry.name}`"/>
          </span>
          <span class="cell-text symbol-name">
            {{ $t('symbolsList.' + entry.label) }}
          </span>
          <template v-slot:drag-image>
            <div class="symbol-drag-image">
              <i :class="`exs-md exs-${entry.name}`"/>
            </div>
          </template>
        </drag>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Component, Prop, Ref, toNative, Vue} from 'vue-facing-decorator';
import {Drag} from 'vue-easy-dnd';
import {DraggableSymbolEntry} from '@/store/models/figure.model';

@Component(
  {
    name: 'draggableSymbolList',
    components: {
      Drag
    },
  })
class DraggableSymbolList extends Vue {

  @Prop({required: true}) private disabled!: boolean;
  @Prop({required: true}) private symbolHeader!: string;
  @Prop({required: true}) private symbolsList!: DraggableSymbolEntry[];

  @Ref('labels') private labels!: Array<HTMLElement> | undefined;

  private width = '';

  get isDisabled(): boolean {
    return this.disabled;
  }

  private dragstart(): void {
    setTimeout(() => {
      // Center the drag image at the mouse position
      const drag = window.document.querySelector('body > .symbol-drag-image') as HTMLElement;
      drag.style.cssText += ' margin-top: -' + (drag.offsetHeight / 2) + 'px;'
      drag.style.cssText += ' margin-left: -' + (drag.offsetWidth / 2) + 'px;'
    }, 0);
  }

  mounted(): void {
    const adaptWidths = () => setTimeout(() => {
      // Wait another frame if the label references aren't ready yet
      if (this.labels === undefined) {
        adaptWidths();
        return;
      }
      let maxWidth = 48;
      // Iterate all labels to see which one is the most wide
      this.labels.forEach((label: HTMLElement) => {
        if (label.offsetWidth > maxWidth) {
          maxWidth = label.offsetWidth;
        }
      });
      this.width = 'width: ' + maxWidth + 'px;';
    }, 0);

    adaptWidths();
  }
}

export default toNative(DraggableSymbolList);
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.symbol-list {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: visible;

  .symbol-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-flow: row nowrap;
    padding-left: 5px;
    border-bottom: 1px solid $pengine-grey;

    h2 {
      padding-left: 12px;
    }
  }

  .table-container {
    overflow: auto;
  }

  /* Styling for the read-only draggable symbols */
  .symbol {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    height: $row-height;
    color: $pengine-grey-dark-dark;
    font-size: $font-size-normal;
    align-items: center;
    text-align: center;

    .symbol-label {
      padding-left: $reference-sign-label-padding-left;
      min-width: $reference-sign-label-width;
    }

    .symbol-name {
      padding-left: $reference-sign-name-padding-left;
      max-width: $reference-sign-name-max-width;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .symbol-drag-image {
    background-color: white;
    padding: .5em 1em;

    .symbol-drag-image-label {
      color: $pengine-orange-dark-dark;
    }
  }
}
</style>
