import i18n from '@/i18n';
import {LocalizedMessage} from '@/api/models/exception.model';

export function GetLocalizedExceptionMessage(errorKey: string, values?: any[]): string {
  if (values === undefined) {
    return i18n.global.t(`clientLocalizedKey.${errorKey}`);
  }
  return i18n.global.t(`clientLocalizedKey.${errorKey}`, values);
}

export function GetLocalizedExceptionMessageFor(msg: LocalizedMessage): string {
  return GetLocalizedExceptionMessage(msg.key, msg.values);
}