import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1705dbb7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "figure",
  class: "figure"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FigureEditor = _resolveComponent("FigureEditor")!
  const _component_pane = _resolveComponent("pane")!
  const _component_ReferenceSignList = _resolveComponent("ReferenceSignList")!
  const _component_DraggableSymbolList = _resolveComponent("DraggableSymbolList")!
  const _component_splitpanes = _resolveComponent("splitpanes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_splitpanes, {
      "dbl-click-splitter": false,
      "push-other-panes": false,
      onSplitterClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSplitterClick())),
      onResize: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onResize($event))),
      class: "default-theme figure-main-splitpanes"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_pane, {
          ref: "leftPane",
          size: _ctx.leftPaneSizeComputed,
          class: "figure-editor-pane"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FigureEditor, {
              "application-guid": _ctx.applicationGuid,
              "figure-guid": this.internalFigureGuid,
              figures: _ctx.figures
            }, null, 8, ["application-guid", "figure-guid", "figures"])
          ]),
          _: 1
        }, 8, ["size"]),
        _createVNode(_component_pane, {
          size: _ctx.rightPaneSizeComputed,
          "min-size": "0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_splitpanes, {
              horizontal: "",
              class: "default-theme figure-main-splitpanes"
            }, {
              default: _withCtx(() => [
                (_ctx.isReferenceSignListVisible )
                  ? (_openBlock(), _createBlock(_component_pane, {
                      key: 0,
                      "min-size": "0",
                      class: "reference-sign-list-pane"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ReferenceSignList, {
                          "application-guid": _ctx.applicationGuid,
                          "application-editor-instance": null,
                          "edit-reference-signs-mode": _ctx.EditReferenceSignMode.EDIT_ENABLED,
                          "drag-mode": _ctx.DragMode.DRAG_DROP_ENABLED,
                          "show-apply-reference-sign-button": false,
                          "show-hightlight-reference-sign-button": false
                        }, null, 8, ["application-guid", "edit-reference-signs-mode", "drag-mode"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_pane, {
                  "min-size": "0",
                  class: "reference-sign-list-pane"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DraggableSymbolList, {
                      symbolHeader: _ctx.$t('symbols'),
                      symbolsList: _ctx.symbolsList,
                      disabled: this.internalFigureGuid === null
                    }, null, 8, ["symbolHeader", "symbolsList", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_pane, {
                  "min-size": "0",
                  class: "reference-sign-list-pane"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DraggableSymbolList, {
                      symbolHeader: _ctx.$t('helpLines'),
                      symbolsList: _ctx.helpLinesList,
                      disabled: this.internalFigureGuid === null
                    }, null, 8, ["symbolHeader", "symbolsList", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["size"])
      ]),
      _: 1
    })
  ]))
}