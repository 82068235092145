import {Extension} from '@tiptap/vue-3';
import {EditorState, Plugin} from '@tiptap/pm/state';
import {Node as PmNode} from '@tiptap/pm/model';
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {findAllDirectChildren, NodeRange} from '@/components/applicationEditor/utils/node.util';

const cssClassName = {
  lastBlock: 'last-block',
  lastInlineBlock: 'last-inline-block',
};

const getLastBlockDecorations = (node: PmNode, offset: number): Decoration[] => {
  const decorations: Decoration[] = [];

  // create decorators for the last logical block child within each structural block in the document hierarchy
  const childRanges = findAllDirectChildren(node, node => !!node.attrs.semanticType && !node.isTextblock, offset);
  let lastChildRange: NodeRange | undefined;
  const lastInlineChildRanges: NodeRange[] = [];
  childRanges.forEach((childRange, index) => {
    const child = childRange.node;
    getLastBlockDecorations(child, childRange.start + 1).forEach(decoration => decorations.push(decoration));

    // collect the last child within a series of inline blocks...
    // ...when changing from inline to block mode...
    if ((lastChildRange?.node.type.name === 'structuralInlineBlockNode') && (childRange.node.type.name !== 'structuralInlineBlockNode')) {
      lastInlineChildRanges.push(lastChildRange);
    }
    // ...or if the block after the last child is an inline block itself
    if ((lastChildRange?.node.type.name === 'structuralInlineBlockNode') && (childRange.node.attrs?.inlineMode === "implicit")) {
      lastInlineChildRanges.push(lastChildRange);
    }

    lastChildRange = childRange;
  })

  // mark the "last block" among it's siblings
  if (lastChildRange) {
    const className = cssClassName.lastBlock;
    const decoration = Decoration.node(lastChildRange.start, lastChildRange.end, {class: className});
    decorations.push(decoration);
  }

  // addtionally, mark the last inline block within a series of inline blocks
  lastInlineChildRanges.forEach((lastInlineChildRange => {
    const className = cssClassName.lastInlineBlock;
    const decoration = Decoration.node(lastInlineChildRange.start, lastInlineChildRange.end, {class: className});
    decorations.push(decoration);
  }))

  return decorations;
};

const calculateLastBlocks = (state: EditorState): DecorationSet | undefined => {
  const root: PmNode = state.doc;
  if (root.childCount == 0) {
    return undefined;
  }
  const decorations = getLastBlockDecorations(root.child(0), 1);
  return DecorationSet.create(state.doc, decorations);
};

/**
 * Appends the class "last-block" to each logical structural node that is the last child among it's siblings.
 * Additionally, appends the class "last-inline-block" to the last block of each series of inline blocks.
 *
 * This affects the whole hierarchy instead of the lowest logical blocks.
 */
export const LastBlockExtension = Extension.create(
  {
    name: 'lastBlockExtension',

    addProseMirrorPlugins() {
      return [
        new Plugin(
          {
            props: {
              decorations: (state: EditorState): DecorationSet | null | undefined => {
                if (!this.editor) {
                  return null;
                }
                return calculateLastBlocks(state);
              }
            }
          })
      ];
    }
  });
