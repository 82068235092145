import {DirectiveBinding, ObjectDirective} from 'vue';

interface ClickOutsideElement extends HTMLElement {
  clickOutsideHandler?: (event: MouseEvent) => void;
}

const clickOutsideDirective: ObjectDirective = {
  mounted(el: ClickOutsideElement, binding: DirectiveBinding) {
    el.clickOutsideHandler = (event: MouseEvent) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event);
      }
    };

    window.addEventListener('click', el.clickOutsideHandler);
  },
  unmounted(el: ClickOutsideElement) {
    if (el.clickOutsideHandler) {
      window.removeEventListener('click', el.clickOutsideHandler);
      delete el.clickOutsideHandler;
    }
  },
};

export default clickOutsideDirective;
