import {Node as PmNode} from '@tiptap/pm/model';
import {EditorState} from '@tiptap/pm/state';
import SpellcheckModule from '@/store/modules/SpellcheckModule';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {MarkupAnnotationItem, SpellcheckData, SpellcheckParams, TextAnnotationItem} from '@/api/models/spellcheck.model';
import {findNodeRangeOfGuid, NodeRange} from '@/components/applicationEditor/utils/node.util';
import {ApplicationEditor as ApplicationEditorClass} from '@/components/ApplicationEditor.vue';
import {Range, SpellingMistake} from '@/store/models/spellcheck.model';
import {useDefaultErrorHandling} from '@/errorHandling';

export function getWordFromSpellingMistake(mistake: SpellingMistake): string {
  const word = mistake.context.text.substr(mistake.context.offset, mistake.context.length);
  // replaces multiple consecutive spaces with just one
  return word.replace(/\s+/g, ' ');
}

/**
 * Check if a node is a text-node that contains actual text.
 */
function isRealText(node: PmNode, parent: PmNode) {
  if (node.isText) {
    // Placeholders (" ") should be ignored - if the parent has a text length > 1 it has more than one child containing text -
    // we then must not ignore this block as other blocks with text might follow
    const text = node.text;
    if(parent.textContent.length > 1){
      return true;
    } else {
      return text && text !== ' ';
    }
  }
  // everything else is markup
  return false;
}

/**
 * We traverse all nodes between range.start and range.end.
 * For all the text-nodes we find (excluding placeholders) we create a TextAnnotationItem each.
 * All the positions inbetween the text content (all other nodes and placeholders) are filled up with MarkupAnnotationItems for the
 * errors markers to be at the right location.
 *
 * @param doc
 * @param range
 * @return SpellcheckData as JSON-string
 */
function compileSpellcheckData(doc: PmNode, range: Range): string | null {
  const spellcheckData = new SpellcheckData();

  let markupSincePos: number = range.start;

  doc.nodesBetween(range.start, range.end, (node, pos,parent) => {
    // ignore all surrounding nodes
    if (pos >= range.start) {
      if (node.type.name === 'hardBreak') {
        // Special treatment for line breaks
        spellcheckData.add(new TextAnnotationItem('\n'));
        markupSincePos = pos + 1;
      } else if (parent != null && isRealText(node, parent)) {
        // create TextAnnotationItems for real text (text-nodes that aren't empty)
        if (pos > markupSincePos) {
          // everything in between real text is a MarkupAnnotationItem
          const markupText = ' '.repeat(pos - markupSincePos);
          spellcheckData.add(new MarkupAnnotationItem(markupText));
        }

        const text = node.text || '';
        spellcheckData.add(new TextAnnotationItem(text));
        // next markup starts right after the text
        markupSincePos = pos + text.length;
      }
    }
  });

  if (spellcheckData.isEmpty()) {
    // if no text content was found
    return null;
  }
  return JSON.stringify(spellcheckData);
}

/**
 * Performs spellingcheck by calling the spell-check rest API
 * @param applicationEditor The ApplicationEditor object
 * @param state             The current EditorState
 * @param logicalBlockGuid  The block to perform the spellcheck on
 * @return true If the command could execute, else false.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function performSpellcheck(applicationEditor: ApplicationEditorClass, state: EditorState, logicalBlockGuid: string): boolean {
  // Check if application document (locale) exists
  const currentApplicationDocument = ApplicationModule.currentApplicationDocument;
  let locale = currentApplicationDocument?.locale;
  const applicationDocumentGuid = currentApplicationDocument?.guid;

  if (locale == null || applicationDocumentGuid == null) {
    return false;
  }

  const nodeRange: NodeRange | null = findNodeRangeOfGuid(state.doc, logicalBlockGuid);
  if (!nodeRange) {
    return false;
  }

  // If no text was found (null) we still have to remove the old matches for this logical block by calling SpellcheckModule.spellcheck().
  const spellcheckData: string | null = compileSpellcheckData(state.doc, nodeRange);

  // TODO: Remove this hard mapping after clearifying the locale question
  if (locale === 'de') {
    locale = 'de-DE';
  } else if (locale === 'en') {
    locale = 'en-US';
  }

  const spellcheckParams: SpellcheckParams = {
    applicationDocumentGuid,
    locale: locale,
    spellcheckData,
    logicalBlockGuid,
    nodePosOffset: 0    // no offset
  };

  SpellcheckModule.spellcheck(spellcheckParams).catch(useDefaultErrorHandling);
  return true;
}
