import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import RootNodeView from '@/components/applicationEditor/nodes/rootNode/views/RootNode.vue';

/**
 * The root node in the application document which contains all the chapters.
 */
export const RootNode = TiptapNode.create(
  {
    name: 'rootNode',
    topNode: true,
    content: 'applicationDocument',
    group: 'block',

    parseHTML() {
      return [
        {
          tag: 'rootNode',
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['rootNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(RootNodeView);
    },

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });
