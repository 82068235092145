import {axiosInstance} from '@/api';
import {FigureSymbol, HelpLine} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {
  CreateHelpLineEvent,
  CreateHelpLinesEvent,
  HelpLineCreatedVmUpdate,
  HelpLinesCreatedVmUpdate, HelpLineUpdatedVmUpdate,
  UpdateHelpLineEvent
} from '@/api/models/helpLine.model';

const HELP_LINE_PATH = '/helpline';

export const CreateHelpLines = async (events: CreateHelpLinesEvent): Promise<HelpLine[]> => {
  const res = await axiosInstance.post(HELP_LINE_PATH, events);
  return (res?.data as HelpLinesCreatedVmUpdate).helpLines;
};

export const CreateHelpLine = async (event: CreateHelpLineEvent): Promise<HelpLine> => {
  const res = await axiosInstance.post(HELP_LINE_PATH, event);
  return (res?.data as HelpLineCreatedVmUpdate).helpLine;
};

export const UpdateHelpLine = async (event: UpdateHelpLineEvent): Promise<FigureSymbol> => {
  const res = await axiosInstance.put(HELP_LINE_PATH, event);
  return (res?.data as HelpLineUpdatedVmUpdate).helpLine;
};
