export interface SearchQuery {
    // For sending to the server
    applicationDocumentGuid: string;
    searchSpaces: Array<SearchSpace>;
    searchTerms: Array<string>;
}

export interface SearchResult {
    guid: string; // GUID of the entity to display in the search dialog
    searchSpace: SearchSpace;
    name: string;
    text: string;
    locale: string;
}

export enum SearchMainTabs {
    APPLICATION_DOCUMENTS = 'APPLICATION_DOCUMENTS',
    LIBRARY = 'LIBRARY'
}

export enum SearchApplicationDocuments {
    MAIN_CLAIM_AND_EXPLANATION = 'MAIN_CLAIM_AND_EXPLANATION',
    ALL_FIGURES = 'ALL_FIGURES'
}

export enum SearchSpace {
    TERM = 'TERM',                 // = "Begriffe"
    TEXT_BLOCK_A = 'TEXT_BLOCK_A', // general = "Gattungen allgemein"
    TEXT_BLOCK_B = 'TEXT_BLOCK_B', // figures = "Gattungen Figuren"
    OTHER = 'OTHER'                // = "Sonstige"
}

export enum SearchLocale {
    DE = 'de-DE',
    EN = 'en-US'
}
