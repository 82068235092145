import {axiosInstance} from '@/api';
import {SpellcheckApiResult, SpellcheckDictionaryWord, SpellcheckIgnoreWord} from '@/api/models/spellcheck.model';

export const SPELLCHECK_PATH = '/spellcheck';

export const SendSpellcheckRequest = async (applicationDocumentGuid: string, locale: string, data: string): Promise<SpellcheckApiResult> => {
  // Don't use noRepeatAxiosInstance because this may lead to errors (for quick undo/redo).
  const res = await axiosInstance.post(SPELLCHECK_PATH + '/' + applicationDocumentGuid + '/' + locale, data);
  return res?.data;
}

export const GetWordsFromDictionary = async (locale: string): Promise<SpellcheckDictionaryWord[]> => {
  const res = await axiosInstance.get(SPELLCHECK_PATH + '/dictionary/' + locale);
  return res?.data;
}

export const AddWordToDictionary = async (locale: string, word: string): Promise<SpellcheckDictionaryWord> => {
  const res = await axiosInstance.post(SPELLCHECK_PATH + '/dictionary/' + locale, word);
  return res?.data;
}

export const UpdateWordInDictionary = async (guid: string, word: string): Promise<SpellcheckDictionaryWord> => {
  const res = await axiosInstance.put(SPELLCHECK_PATH + '/dictionary/' + guid, word);
  return res?.data;
}

export const DeleteWordFromDictionary = async (guid: string): Promise<void> => {
  await axiosInstance.delete(SPELLCHECK_PATH + '/dictionary/' + guid);
  return Promise.resolve();
}

export const GetWordsFromIgnoreList = async (applicationDocumentGuid: string): Promise<SpellcheckIgnoreWord[]> => {
  const res = await axiosInstance.get(SPELLCHECK_PATH + '/' + applicationDocumentGuid + '/ignore');
  return res?.data;
}

export const AddWordToIgnoreList = async (appDocGuid: string, errorType: string, word: string): Promise<SpellcheckIgnoreWord> => {
  const res = await axiosInstance.post(SPELLCHECK_PATH + '/' + appDocGuid + '/ignore/' + errorType, word);
  return res?.data;
}

export const DeleteAllWordsFromIgnoreList = async (appDocGuid: string): Promise<void> => {
  await axiosInstance.delete(SPELLCHECK_PATH + '/' + appDocGuid + '/ignore');
  return Promise.resolve();
}
