import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import ApplicationDocumentView from '@/components/applicationEditor/nodes/applicationDocument/views/ApplicationDocumentView.vue';
import {getArrayFromDomAttribute, getBooleanFromDomAttribute} from '@/components/applicationEditor/utils/converter.util';

/**
 * The root node in the application document which contains all the chapters.
 */
export const ApplicationDocumentNode = TiptapNode.create(
  {
    name: 'applicationDocument',
    content: 'coverSheet? (structuralBlockNode|structuralInlineBlockNode)*',
    group: 'block',

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
        logicalDepth: {default: -1},
      };
    },

    parseHTML() {
      return [
        {
          tag: 'applicationDocument',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              semanticType: domElement.getAttribute('semanticType'),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks')),
              logicalDepth: domElement.getAttribute('logicalDepth'),
            };
          }
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['applicationDocument', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(ApplicationDocumentView);
    }

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });
