<template>
  <node-view-wrapper as="span"
                     :class="getClass()"
                     :data-placeholder-text="getPlaceholder()"
                     :style="getStyle()"
                     :title="getTitle()"
                     @dragstart="preventDragStart($event)"
                     @drop="preventDrop($event)"
                     :id="getGuid()">
    <node-view-content as="span"></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {SemanticType} from '@/api/models/editor.model';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a text node as an inline span.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class TextBlockNodeView extends Vue {
  @Prop() node!: PmNode;


  private getClass(): string {
    return `${this.node.attrs.semanticType} block`;
  }

  private getPlaceholder(): string {
    return ApplicationModule.templateText(`${this.node.attrs.semanticType}.placeholder`, this.node.attrs);
  }

  private getTitle(): string {
    return ApplicationModule.templateText(`${this.node.attrs.semanticType}.tooltip`, this.node.attrs);
  }

  private getStyle(): string {
    //break words in figure short-despriction in case they are too long
    if (`${this.node.attrs.semanticType}` === SemanticType.SHORT_DESCRIPTION_FIGURE_TEXT) {
      return "word-break: break-word"
    }
    return ""
  }

  private preventDrop(event: Event): void {
    event.preventDefault();
  }

  private preventDragStart(event: Event): void {
    event.preventDefault();
  }

  private getGuid(): string {
    return this.node.attrs.guid;
  }
}

export default toNative(TextBlockNodeView);
</script>


<style lang="scss" scoped>

// make span to inline-block, so that it does not wrap its content
span.show-placeholder {
  display: inline-block;
  line-height: normal;
  margin-bottom: 0;
}

</style>

