import {Store} from 'vuex';

type Callback<T> = (field: string, oldValue: T, newValue: T) => void;

const isEqual = (a: any, b: any): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }
  return a === b;
};

const getField = (state: any, field: string) => {
  return field.split('.').reduce((acc, part) => acc && acc[part], state);
};

const FieldLogger = <T>(field: string, callback: Callback<T>) => (store: Store<any>) => {
  store.watch(
    (state) => getField(state, field),
    (newValue, oldValue) => {
      if (!isEqual(newValue, oldValue)) {
        callback(field, oldValue, newValue);
      }
    },
    {
      deep: true, // Watches nested properties, useful for objects and arrays
      immediate: true // Calls the callback immediately with the current value
    }
  );
};

export default FieldLogger;
