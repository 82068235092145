<template>
  <node-view-wrapper as="span"
                     class="placeholder-text"
                     contenteditable="false"
                     @dragstart="preventDragStart($event)"
                     @drop="preventDrop($event)">
    <node-view-content as="span"></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, toNative, Vue} from 'vue-facing-decorator';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a placeholder node as an inline span.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class PlaceholderView extends Vue {

  private preventDrop(event: Event): void {
    event.preventDefault();
  }

  private preventDragStart(event: Event): void {
    event.preventDefault();
  }
}

export default toNative(PlaceholderView);
</script>

<style lang="scss" scoped>

.placeholder-text {
  color: gray;
  cursor: default;
  height: 0;
  font-style: italic;
}

</style>
