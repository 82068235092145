<template>
  <!-- Outer span is required thus you always need one root element -->
  <node-view-wrapper as="span" :class="'inline-mode'">
    <!-- if it's the first inline block within a series of inline blocks, we have to create some spacing before -->
    <span v-if="isImplicitInline()" class="pre-inline-block-spacing"></span>

    <!-- inline mode -->
    <!-- wrapper-span is only used for calculations -->
    <span class="wrapper-span">
        <!-- span-start & span-end are also only used for calculations-->
      <span class="span-start"></span>
        <node-view-content as="span"
                           :class="[getSemanticType(), 'block', {'no-logical-block': isNoLogicalBlock()}]"
                           :id="getGuid()">
        </node-view-content>
      <span class="span-end"></span>
    </span>

    <!-- if it's the last inline block within a series of inline blocks, we have to create some spacing after -->
    <span class="post-inline-block-spacing"></span>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import {InlineMode} from '@/api/models/editor.model';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a structural, logical inline node.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class StructuralInlineBlockNodeView extends Vue {


  @Prop() node!: PmNode;

  private isNoLogicalBlock(): boolean {
    return !this.node.attrs.logicalBlock;
  }

  private isImplicitInline(): boolean {
    const inlineMode = this.node.attrs.inlineMode;
    return (inlineMode === InlineMode.IMPLICIT);
  }

  private getGuid(): string {
    return this.node.attrs.guid;
  }

  private getSemanticType(): string {
    return this.node.attrs.semanticType;
  }
}

export default toNative(StructuralInlineBlockNodeView);
</script>

<style lang="scss" scoped>

// add some space between inline blocks
.inline-mode:not(.last-inline-block) {
  padding-right: 6px;
}

.inline-mode {
  // Doesn't change the position of the text.
  // It is there just to make the parent of an inline-block a little bigger.
  // This is done in the case, when a block has unsaved changes and it gets
  // the red border. When the user hovers the block inbetween the text and
  // the border, a tooltip should appear with a message.
  // This increases the hover area to make it possible.
  padding-bottom: 8px;
  padding-top: 4px;

  // add some space before a series of inline blocks
  .pre-inline-block-spacing {
    display: block;
    padding-top: 3px;
  }

  // per default there is no space after an inline block (get's active when there are additional classes set by decorations)
  .post-inline-block-spacing {
    display: none;
  }
}

// if the block is the last block within a series of inline blocks...
.inline-mode.last-inline-block {

  // ...and it is not the last block among its parent's children
  &:not(.last-block) {
    .post-inline-block-spacing {
      display: block; // ...activate the space...
      padding-top: 19px; // ...and add some space between this and the next block.
    }
  }

  // but if it is the last block among its parent's children,
  // there is just a little space between this block and the bottom border of its parent
  &.last-block {
    .post-inline-block-spacing {
      display: block;
      padding-top: 3px;
    }
  }
}

</style>
