import {ApplicationDocument, ApplicationDocumentCreatedVmUpdate, ApplicationDocumentDeletedVmUpdate} from '@/api/models/application.model';
import {axiosBlobInstance, queuedAxiosInstance} from '@/api';
import {getYYYYMMDDHHMMSS} from '@/util/time.util';

import {CloneApplicationDocumentEvent, WorklistApplicationDocument} from '@/api/models/worklist.model';
import {applicationDocumentPreprocessor} from '@/api/services/application.api';

export const APPLICATION_PATH = '/application';

// Run this on Application Documents from the server before storing them
const worklistApplicationDocumentPreprocessor = (applicationDocument: WorklistApplicationDocument): WorklistApplicationDocument => {
  return {
    ...applicationDocument,
    creationDate: new Date(applicationDocument.creationDate),
    date: applicationDocument.date == null ? null : new Date(applicationDocument.date as unknown as string)
  };
};

export const GetApplicationDocuments = async (): Promise<WorklistApplicationDocument[]> => {
  const res = await queuedAxiosInstance.get(`${APPLICATION_PATH}`);
  return (res?.data as WorklistApplicationDocument[]).map(worklistApplicationDocumentPreprocessor);
};

export const CreateDocxDocument = async (applicationDocument: WorklistApplicationDocument, force: boolean, singleFile: boolean): Promise<void> => {
  const timestamp = getYYYYMMDDHHMMSS();
  const url = `${APPLICATION_PATH}/${applicationDocument.guid}/docx?force=${force}&timestamp=${timestamp}&singleFile=${singleFile}`;
  const blob = await axiosBlobInstance.get(url);
  const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const link = document.createElement('a');
  link.href = URL.createObjectURL(new Blob([blob?.data], {type: mimeType}));
  link.download = applicationDocument.application.referenceNo + '_' + timestamp + (singleFile ? '.docx' : '.zip');
  link.click();
  URL.revokeObjectURL(link.href);
};

export const CloneApplicationDocument = async (event: CloneApplicationDocumentEvent): Promise<ApplicationDocument> => {
  const res = await queuedAxiosInstance.post(`${APPLICATION_PATH}/${event.guid}/clone`, event);
  return applicationDocumentPreprocessor((res?.data as ApplicationDocumentCreatedVmUpdate).applicationDocument);
}

export const DeleteApplicationDocument = async (guid: string): Promise<ApplicationDocumentDeletedVmUpdate> => {
  const res = await queuedAxiosInstance.delete(`${APPLICATION_PATH}/${guid}`);
  return res?.data as ApplicationDocumentDeletedVmUpdate;
};
