import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import {getArrayFromDomAttribute, getBooleanFromDomAttribute} from '@/components/applicationEditor/utils/converter.util';
import NonLogicalStructuralBlockNodeView
  from '@/components/applicationEditor/nodes/baseElements/views/NonLogicalStructuralBlockNodeView.vue';

/**
 * A node describing a structural block in its inline form
 */
export const NonLogicalStructuralBlockNode = TiptapNode.create(
  {
    name: 'nonLogicalStructuralBlockNode',
    content: '(structuralInlineBlockNode|nonLogicalStructuralBlockNode|structuralBlockNode)*|(textBlockNode|tableEntryNode|placeholderNode)+',
    group: 'block',
    isolating: true,
    defining: true,

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        header: {default: undefined},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
        maxLength: {default: null},
        claimNo: {default: null},
        tableEntryNo: {default: null},
        logicalDepth: {default: -1},
      };
    },

    parseHTML() {
      return [
        {
          tag: 'nonLogicalStructuralBlockNode',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              semanticType: domElement.getAttribute('semanticType'),
              header: domElement.getAttribute('header'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks')),
              maxLength: domElement.getAttribute("maxLength"),
              claimNo: domElement.getAttribute("claimNo"),
              tableEntryNo: domElement.getAttribute("tableEntryNo"),
              logicalDepth: domElement.getAttribute('logicalDepth'),
            };
          }
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['nonLogicalStructuralBlockNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(NonLogicalStructuralBlockNodeView);
    }

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });