import {defaultRequestTimeout, queuedAxiosInstance, undoRedoCommandMaxTimeout, undoRedoCommandStepTimeout} from '@/api';
import {APPLICATION_PATH} from '@/api/services/application.api';
import {CommandStackViewModel, CommandStackVmUpdate} from '@/api/models/editor.model';

export const COMMAND_PATH = 'command';

/**
 * Undo/Redo-Steps may take longer since we undo/redo multiple requests. Therefore, we increase the timeout per step.
 * @param stepCount
 */
function calcUndoRedoTimeout(stepCount: number) {
  return Math.min(defaultRequestTimeout + ((stepCount - 1) * undoRedoCommandStepTimeout), undoRedoCommandMaxTimeout);
}

export const GetCommandStackForApplicationDocument = async (applicationDocumentGuid: string): Promise<CommandStackViewModel> => {
  const res = await queuedAxiosInstance.get(`${APPLICATION_PATH}/${applicationDocumentGuid}/${COMMAND_PATH}/stack`);
  return res?.data as CommandStackViewModel;
};

/**
 * Undo the last command for the given {@link ApplicationDocument}
 *
 * @param applicationDocumentGuid the GUID of the {@link ApplicationDocument} the command belongs to
 * @return A {@link CommandStackVmUpdate} object to indicate that a command has been undone
 */
export const UndoCommand = async (applicationDocumentGuid: string): Promise<CommandStackVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${APPLICATION_PATH}/${applicationDocumentGuid}/${COMMAND_PATH}/undo`);
  return res?.data as CommandStackVmUpdate;
};

/**
 * Undo all commands until a given command, for the given {@link ApplicationDocument}
 *
 * @param applicationDocumentGuid the GUID of the {@link ApplicationDocument} the command belongs to
 * @param commandGuid             the GUID of the command till that all commands should be undone
 * @param stepCount               the (approximate) number of steps that will be undone
 * @return A {@link CommandStackVmUpdate} object to indicate that commands have been undone
 */
export const UndoCommandByGuid = async (applicationDocumentGuid: string,
                                        commandGuid: string,
                                        stepCount: number): Promise<CommandStackVmUpdate> => {
  const timeout = calcUndoRedoTimeout(stepCount);
  const res = await queuedAxiosInstance.put(`${APPLICATION_PATH}/${applicationDocumentGuid}/${COMMAND_PATH}/undo/${commandGuid}`,
                                      undefined, {timeout});
  return res?.data as CommandStackVmUpdate;
};

/**
 * Redo the last command on the stack for the given {@link ApplicationDocument}
 *
 * @param applicationDocumentGuid the GUID of the {@link ApplicationDocument} the command belongs to
 * @return A {@link CommandStackVmUpdate} object to indicate that a command has been redone
 */
export const RedoCommand = async (applicationDocumentGuid: string): Promise<CommandStackVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${APPLICATION_PATH}/${applicationDocumentGuid}/${COMMAND_PATH}/redo`);
  return res?.data as CommandStackVmUpdate;
};

/**
 * Redo all commands until a given command, for the given {@link ApplicationDocument}
 *
 * @param applicationDocumentGuid the GUID of the {@link ApplicationDocument} the command belongs to
 * @param commandGuid             the GUID of the command till that all commands should be redone
 * @param stepCount               the (approximate) number of steps that will be redone
 * @return A {@link CommandStackVmUpdate} object to indicate that commands have been redone
 */
export const RedoCommandByGuid = async (applicationDocumentGuid: string,
                                        commandGuid: string,
                                        stepCount: number): Promise<CommandStackVmUpdate> => {
  const timeout = calcUndoRedoTimeout(stepCount);
  const res = await queuedAxiosInstance.put(`${APPLICATION_PATH}/${applicationDocumentGuid}/${COMMAND_PATH}/redo/${commandGuid}`,
                                      undefined, {timeout});
  return res?.data as CommandStackVmUpdate;
};
