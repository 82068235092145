import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';

import {AuthState, UserData} from '@/store/models/auth.model';
import {GetUserProfile} from '@/api/services/auth.api';

@Module({dynamic: true, namespaced: true, store, name: 'auth'})
class AuthModule extends VuexModule implements AuthState {

  // initial state
  private _isLoading = false;
  private _user: UserData | null = null;

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public get user(): UserData | null {
    return this._user;
  }
  get isAiAssistantEnabled(): boolean {
    if(this._user === null) {
      return false;
    }
    return this._user.aiAssistantEnabled;
  }


  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading;
  }

  @Mutation
  public setUser(user: UserData | null): void {
    this._user = user;
  }

  @Action
  public getUser(): Promise<UserData> {
    this.setIsLoading(true);
    return GetUserProfile().then((user: UserData) => {
      this.setIsLoading(false);
      this.setUser(user);
      return user;
    }).catch((error) => {
      console.error(error);
      this.setIsLoading(false);
      this.setUser(null);
      throw error;
    });
  }

}

export default getModule(AuthModule);
