<template>
  <div class="app-main" ref="app">
    <SplashScreenDialog ref="splashScreen"/>
    <CookiesBanner ref="cookiesBanner"/>
    <Header :splashScreen="splashScreenMounted"/>
    <router-view :class="'router-view' + (isHeaderPinned ? ' with-pinned-header' : '')"/>
  </div>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import Header from '@/components/header/Header.vue';
import {RouteLocationNormalized} from 'vue-router';
import router from '@/router';
import {checkAuthorized, LanguageParamAfterRedirect, RecoverStateAfterRedirect} from '@/api/services/auth.api';
import {LOGIN_STATE_KEY, loginState, UserData} from '@/store/models/auth.model';
import AuthModule from '@/store/modules/AuthModule';
import store from '@/store';
import {parse} from 'flatted';
import HeaderModule from '@/store/modules/HeaderModule';
import PermissionModule from '@/store/modules/PermissionModule';
import CookiesBanner, {CookiesBanner as CookiesBannerClass} from '@/components/CookiesBanner.vue';
import {checkIfCookieExists, cookies} from '@/util/cookies.util';
import SplashScreenDialog, {SplashScreenDialog as SplashScreenDialogClass} from '@/components/SplashScreenDialog.vue';
import {addBrowserClasses} from '@/util/css.util';
import ApplicationModule from '@/store/modules/ApplicationModule';
import UserProfileModule from '@/store/modules/UserProfileModule';
import {nextTick} from 'vue';
import {useDefaultErrorHandling} from '@/errorHandling';
import log from 'loglevel';

declare global {
  interface Window {
    log: typeof log;
  }
}

@Component(
  {
    components: {
      SplashScreenDialog,
      CookiesBanner,
      Header,
    },
  })
class App extends Vue {
  @Ref('app') private app!: HTMLElement;
  @Ref('splashScreen') private splashScreen!: SplashScreenDialogClass;
  @Ref('cookiesBanner') private cookiesBanner!: CookiesBannerClass;

  public static DEFAUL_LOCALE = 'de';

  public static NUM_LOGS_TO_KEEP = 3;

  // We need this extra variable, because the components (eg. Header) would not recognize changes in the splashScreen ref.
  private splashScreenMounted: SplashScreenDialogClass | null = null;

  get successfulResponses(): number {
    return PermissionModule.successfulResponses;
  }

  @Watch('successfulResponses', {immediate: true})
  private successfulResponsesChanged(successfulResponses: number) {
    if (!this.splashScreen) {
      return;
    }
    this.splashScreen.close();
  }

  get isCookieConsentGiven(): boolean {
    return checkIfCookieExists(cookies.cookieConsent);
  }

  get isHeaderPinned(): boolean {
    const routeName = this.$router.currentRoute.value.name as string;
    return HeaderModule.pinnedForRoute[routeName] == true;
  }

  private setTitle(key: string): void {
    document.title = this.$t(key) + ' - Patent Engine';
  }

  @Watch('$i18n.locale')
  private onLocaleChange(): void {
    this.setTitle(this.$router.currentRoute.value.name as string);
  }

  private setLoginInformationInLocalStorage(): Promise<void> {
    return checkAuthorized().then(() => {
      window.localStorage.setItem(LOGIN_STATE_KEY, loginState.loggedIn);
      return this.trySetAppLocaleByQueryingBackend();
    }).catch(() => {
      // Give the community user a chance to log in again
      if (window.localStorage.getItem(LOGIN_STATE_KEY) === loginState.loggedIn) {
        // On next reload with no log in set to logged out instead. Dont make him basic yet, as that would conflict
        // with a successful login.
        window.localStorage.setItem(LOGIN_STATE_KEY, loginState.loggedOut);

        // trigger login does not work properly.
        this.trySetAppLocaleByQueryingBackend();
      } else if (window.localStorage.getItem(LOGIN_STATE_KEY) === loginState.loggedOut) {
        // User did not log in, make him basic and let him see edition page.
        window.localStorage.setItem(LOGIN_STATE_KEY, loginState.basic);
      }
    });
  }

  private trySetAppLocaleByQueryingBackend(): Promise<void> {
    return AuthModule.getUser().then((user: UserData) => {
      this.$i18n.locale = user.locale;

      Promise.all([
          ApplicationModule.getApplicationViewSplitState(user.id),
          UserProfileModule.getSingleDocumentEditorSplitState(user.id),
          UserProfileModule.getTwoDocumentEditorHorizontalSplitState(user.id),
          UserProfileModule.getTwoDocumentEditorVerticalSplitState(user.id),
          UserProfileModule.getActiveDocumentEditorSplitState(user.id)
        ]
      )
        .then(() => {
          Promise.resolve();
        })
        .catch((error) => {
          throw error;
        });
    }).catch(() => {
      this.$i18n.locale = App.DEFAUL_LOCALE;
    })
  }

  private setStoreState(): void {
    const urlParams = new URLSearchParams(window.location.search);

    this.removeLanguageParameterFromUrl(urlParams);
    // PENGINE-525: We dont't store or restore the Vuex state for now!
    // this.recoverStateAndRemoveRecoverStateParameterFromUrl(urlParams);
    this.removeQuestionmarkFromUrlIfNoParametersAreLeft();
  }

  private recoverStateAndRemoveRecoverStateParameterFromUrl(urlParams: URLSearchParams) {
    // a redirect hash is set
    if (urlParams.has(RecoverStateAfterRedirect)) {
      // get the state for this hash from the session storage
      const state = parse(window.sessionStorage.getItem(urlParams.get(RecoverStateAfterRedirect) as string) as string);
      if (state) {
        window.sessionStorage.setItem(urlParams.get(RecoverStateAfterRedirect) as string, '');
        urlParams.delete(RecoverStateAfterRedirect);
        window.history.replaceState({}, document.title, `${window.location.href.split('?')[0]}?${urlParams.toString()}`);
        store.replaceState(state);
      }
    }
  }

  private removeQuestionmarkFromUrlIfNoParametersAreLeft() {
    const urlAndParams = window.location.href.split('?');
    if (urlAndParams.length > 1 && urlAndParams[1] === '') {
      window.history.replaceState({}, document.title, `${urlAndParams[0]}`);
    }
  }

  private removeLanguageParameterFromUrl(urlParams: URLSearchParams) {
    if (urlParams.has(LanguageParamAfterRedirect)) { // used by keycloak
      urlParams.delete(LanguageParamAfterRedirect);
      window.history.replaceState({}, document.title, `${window.location.href.split('?')[0]}?${urlParams.toString()}`);
    }
  }

  private initializeLogging() {
    const timestamp = new Date().toISOString();
    const logPrefix = `patentengine-logs-${timestamp}`;

    const cleanOldLogs = (keepEntries: number) => {
      const logKeys = [];

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith('patentengine-logs-')) {
          logKeys.push(key);
        }
      }

      // Sortiere die Log-Einträge nach Timestamp
      logKeys.sort((a, b) => {
        const aTimestamp = new Date(a.replace('patentengine-logs-', '')).getTime();
        const bTimestamp = new Date(b.replace('patentengine-logs-', '')).getTime();
        return bTimestamp - aTimestamp;
      });

      // Entferne alle Einträge außer den letzten n
      while (logKeys.length >= keepEntries) {
        const oldestKey = logKeys.pop();
        if (oldestKey) {
          localStorage.removeItem(oldestKey);
        }
      }
    };

    cleanOldLogs(App.NUM_LOGS_TO_KEEP);

    const LocalStoragePlugin = (log: any, options: any) => {
      const originalFactory = log.methodFactory;

      log.methodFactory = function (methodName: any, logLevel: any, loggerName: any) {
        const rawMethod = originalFactory(methodName, logLevel, loggerName);

        return function (...messages: any[]) {
          // rawMethod(...messages);
          const currentLevel = log.getLevel();

          if (logLevel >= currentLevel) {
            const logMessages = JSON.parse(localStorage.getItem(options.prefix) || '[]');

            const maxEntries = options.maxEntries || 100;

            logMessages.push({ level: methodName, messages, timestamp: new Date().toISOString() });

            if (logMessages.length > maxEntries) {
              logMessages.shift();
            }

            localStorage.setItem(options.prefix, JSON.stringify(logMessages));
          }
        };
      };

      // log.setLevel(log.getLevel()); // Apply the new methodFactory
    }

    // Initialize the plugin
    LocalStoragePlugin(log, {
      store: localStorage,
      prefix: logPrefix,
      maxStorageBytes: 2 * 1024 * 1024 // use 2 MB of storage max
    });

    log.setLevel('error');
    log.setDefaultLevel('error');
    log.info('Starting...');
  }

  mounted(): void {
    // Initialize logging
    this.initializeLogging();
    window.log = log;

    // This will add class names of the detected browser(s) to the app HTML element. (is-chrome, is-firefox, is-safari, ...)
    addBrowserClasses(this, this.app);

    // We need this extra variable, because the components (eg. Header) would not recognize changes in the splashScreen ref.
    this.splashScreenMounted = this.splashScreen;
    PermissionModule.setSplashScreen(this.splashScreenMounted);

    PermissionModule.getPermissions().catch(useDefaultErrorHandling);

    // We start with the current value in the history
    this.$router.isReady()
      .then(() => this.setTitle(this.$router.currentRoute.value.name as string));

    // After changing the route we use its name
    router.afterEach((to: RouteLocationNormalized/*, from: Route*/) => {
      PermissionModule.getPermissions().catch(useDefaultErrorHandling); // Recheck permissions

      // Use next tick to handle router history correctly
      // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609 <-- dead link
      nextTick(() => {
        this.setTitle(to.name as string);
      });
    });

    this.setStoreState();

    this.setLoginInformationInLocalStorage().catch(useDefaultErrorHandling);


    // ignore browser native undo/redo events
    // this is done primarily for the application editor so it's history-bound undo/redo is the only way to undo/redo changes
    window.addEventListener('beforeinput', (event: Event) => {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const inputType = event['inputType'];
      switch (inputType) {
        case 'historyUndo':
          event.preventDefault()
          return true;
        case 'historyRedo':
          event.preventDefault()
          return true
        default:
          return false
      }
    });

    // since some browsers do not map CTRL+Z/Y to a 'beforeinput' event in all cases (which is handled by the listener above),
    // we have to intercept the respective keydown inputs here to make sure to ignore all browser native undo/redos.
    // naturally, this does not affect undo/redos done via the browser's context menu,
    // but those trigger 'beforeinput' events anyways - at least for newer browser versions...
    window.addEventListener('keydown', (event: Event) => {
      const inputEvent = event as KeyboardEvent;
      if (inputEvent.key == undefined) {
        return false;
      }
      const keyLowerCase = inputEvent.key.toLowerCase();
      if ((inputEvent.ctrlKey || inputEvent.metaKey) && ((keyLowerCase == 'z') || (keyLowerCase == 'y'))) {
        event.preventDefault();
        return true;
      }

      return false;
    });

    if (!this.isCookieConsentGiven) {
      this.cookiesBanner.open();
    }
  }
}

export default toNative(App);
</script>

<style lang="scss">
html, body, #app {
  height: 100%;
}
</style>