import {Store} from 'vuex';

type ActionCallback = (action: any, state: any) => void;

const ActionLogger = (moduleName: string, actions: string[], beforeCallback: ActionCallback, afterCallback: ActionCallback) => (store: Store<any>) => {
  const shouldLogAction = (actionType: string) => {
    const actionName = actionType.split('/')[1];
    return actionType.startsWith(`${moduleName}/`) && (actions.length === 0 || actions.includes(actionName));
  };

  store.subscribeAction({
                          before: (action, state) => {
                            if (shouldLogAction(action.type)) {
                              beforeCallback(action, state);
                            }
                          },
                          after: (action, state) => {
                            if (shouldLogAction(action.type)) {
                              afterCallback(action, state);
                            }
                          }
                        });
};

export default ActionLogger;