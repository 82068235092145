import {AbstractBlockViewModel, DocumentUpdate, LocalVmUpdateName, StructuralBlockViewModel, VmUpdateName} from '@/api/models/editor.model';
import {Editor} from "@tiptap/vue-3";
import {Node, ResolvedPos} from "@tiptap/pm/model";
import {NodeRange} from '@/components/applicationEditor/utils/node.util';
import {Selection} from "prosemirror-state";
import {TextSelection, Transaction} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';
import {findNextTextblock, SearchDirection} from '@/components/applicationEditor/utils/prosemirror.util';
import {removeMarks} from '@/components/applicationEditor/utils/mark.util';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

/**
 * Search for a specific block in the block structure provided by it's root.
 * @param block the block of the block structure to search in
 * @param predicate the predicate to check for a match
 */
function searchBlockByPredicate(block: AbstractBlockViewModel | null,
                                predicate: (block: AbstractBlockViewModel) => boolean): AbstractBlockViewModel | null {
  if (!block) {
    return null;
  }

  // Check the block
  if (predicate(block)) {
    return block;
  }

  // Descent recursively into children if they exist
  if (Object.getOwnPropertyNames(block).includes('children')) {
    const structuralBlockViewModel: StructuralBlockViewModel = block as StructuralBlockViewModel;
    const found = structuralBlockViewModel.children
      .map((child) => searchBlockByPredicate(child, predicate))
      .find((found) => (found));
    return (found) ? found : null; // map undefined to null
  }
  return null;
}

/**
 * Search for a block with a specific semantic type in the block structure provided by it's root.
 * @param block the block of the block structure to search in
 * @param semanticType the semantic type to search for
 */
export function searchForBlockBySemanticType(block: AbstractBlockViewModel, semanticType: string): AbstractBlockViewModel | null {
  return searchBlockByPredicate(block, (block) => (block.semanticType === semanticType));
}

/**
 * Search for a block by it's GUID in the block structure provided by it's root.
 * @param block the block of the block structure to search in
 * @param guid the GUID of the block to find
 */
export function searchBlockByGuid(block: AbstractBlockViewModel | null, guid: string): AbstractBlockViewModel | null {
  return searchBlockByPredicate(block, (block) => (block.guid === guid));
}

/***
 * Finds all blocks from the targetGuid to its ancestors.
 * The first element in the list is the block with the given targetGuid GUID.
 * The last element is the ancestor.
 *
 * @param ancestor this is usually the root block when called externaly. The block must be an ancestor of the targetGuid.
 * @param targetGuid the GUID of the leaf block that is potentially deeply nested inside the ancestor
 * @return a list of all blocks in the ancestor chain (the lineage) or an empty list if the targetGuid can not be found
 */
export function lineageForBlock(ancestor: StructuralBlockViewModel, targetGuid: string): AbstractBlockViewModel[] {
  if (ancestor.guid === targetGuid) {
    return [ancestor]
  }
  if (ancestor.children === undefined) {
    return [];
  }
  return ancestor.children.flatMap(value => {
    let subChain = lineageForBlock(value as StructuralBlockViewModel, targetGuid);
    if (subChain.length > 0) {
      subChain = [...subChain, ancestor];
    }
    return subChain;
  })
}

/**
 * Sets all values of the relevant fields of a target block to those of the source block.
 * @param source the source block to get the values from
 * @param target the target block to set the values in
 */
function updateBlockContents(source: AbstractBlockViewModel, target: AbstractBlockViewModel) {
  const sourceProperties = Object.getOwnPropertyNames(source);
  const targetProperties = Object.getOwnPropertyNames(target);

  // adopt values of source properties into target
  targetProperties
    // filter immutable properties
    .filter((prop) => !['__ob__', 'semanticType', 'guid', 'children'].includes(prop))
    // filter properties not included in source
    .filter((prop) => sourceProperties.includes(prop))
    // adopt properties from source
    .forEach((prop) => (target as never)[prop] = (source as never) [prop]);
}

/**
 * Searches for the original block in the provided root block and updates it's contents
 * @param blockUpdates the block with the updated content
 * @param rootBlock the root block to search in for the orignal block
 */
export function updateBlock(blockUpdates: AbstractBlockViewModel, rootBlock: AbstractBlockViewModel | null) {
  const originalBlock: AbstractBlockViewModel | null = searchBlockByGuid(rootBlock, blockUpdates.guid);
  if (originalBlock) {
    updateBlockContents(blockUpdates, originalBlock);
  }
}

export function updateBlockOnAutoFill(blockText: string, originalBlock: AbstractBlockViewModel | null) {
  if (originalBlock) {
    updateBlockContentsOnAutoFill(blockText, originalBlock);
  }
}

function updateBlockContentsOnAutoFill(blockText: string, target: AbstractBlockViewModel) {
  (target as any).richText = blockText;
}

/**
 * Searches fot the original block in the provided root block and updates it's contents and its children
 * @param blockUpdates the block with the updated content
 * @param rootBlock the root block to search in for the orignal block
 */
export function replaceBlock(blockUpdates: AbstractBlockViewModel, rootBlock: AbstractBlockViewModel | null) {
  const originalBlock: AbstractBlockViewModel | null = searchBlockByGuid(rootBlock, blockUpdates.guid);
  if (originalBlock) {
    const sourceProperties = Object.getOwnPropertyNames(blockUpdates);
    Object.getOwnPropertyNames(originalBlock)
      // filter immutable properties
      .filter((prop) => !['__ob__', 'guid'].includes(prop))
      // filter properties not included in source
      .filter((prop) => sourceProperties.includes(prop))
      // adopt properties from source
      .forEach((prop) => (originalBlock as never)[prop] = (blockUpdates as never) [prop]);
  }
}

/**
 * Checks the parents and further ancestors and returns the first logical block found.
 * If the given block itself is a logical block, the given block is returned.
 * @param block the nearest logical ancestor
 */
export function findNearestLogicalAncestor(block: AbstractBlockViewModel): AbstractBlockViewModel {
  if (!block.parent || block.logicalBlock) {
    return block;
  }
  return findNearestLogicalAncestor(block.parent);
}


/**
 * Helper method to retrieve the latest DocumentUpdate in the list of updates
 * @param update The array of document updates
 */
export function latestUpdate(update: DocumentUpdate[]): DocumentUpdate {
  if (update.length < 1) {
    // Ensure a new DocumentUpdate gets pushed (EditorModule::pushDocumentUpdate)
    // into the update list after clearing (EditorModule::clearUpdatedNodes) the list.
    throw new Error('Invalid State: \'update\' should not be empty!');
  }
  return update[update.length - 1];
}

/**
 * Helper method to push a new DocumentUpdate to the end of the given update array.
 * @param update The updates where the new update will be appended.
 * @param kind The name of the update for deugging purposes
 */
export function pushNew(update: DocumentUpdate[], kind: VmUpdateName | LocalVmUpdateName): DocumentUpdate[] {
  update.push(new DocumentUpdate(kind));
  return update;
}

export type AffectedNodes = {
  headNode: ResolvedPos | null,
  anchorNode: ResolvedPos | null,
  nodesBetween: ResolvedPos[],
  fromNode: ResolvedPos | null,
  toNode: ResolvedPos | null,
  selection: Selection
}

function resolveClosestTextBlockFromStructuralElements(structuralBlock: ResolvedPos, doc: Node, blockAtRangeStart: boolean): ResolvedPos | null {
  const searchDirection = blockAtRangeStart ? SearchDirection.RIGHT : SearchDirection.LEFT;
  let pos = -1;
  if (searchDirection === SearchDirection.RIGHT) {
    pos = findNextTextblock(doc, structuralBlock.pos, searchDirection, false, true);
  } else {
    pos = findNextTextblock(doc, structuralBlock.pos, searchDirection, true, true);
  }
  if (pos === -1) {
    return null;
  }
  return doc.resolve(pos);
}

/**
 * Resolves all textBlockNode for a given selection. Should head anchor point to structuralBlock, all child textBlockNodes between them
 * are used
 * @param selection
 * @param view
 */
export function getAffectedTextNodes(selection: Selection, view: EditorView): AffectedNodes | null {
  let headNode = selection.$head;
  let anchorNode = selection.$anchor;
  const headFirst = selection.head < selection.anchor;
  const doc = view.state.doc;
  // anything but a textBlockNode is considered a structural element
  if (headNode.node().type.name !== "textBlockNode") {
    const node = resolveClosestTextBlockFromStructuralElements(headNode, doc, headFirst);
    if (!node) {
      return null;
    }
    headNode = node;
    selection = TextSelection.create(doc, selection.anchor, headFirst ? headNode.start() : headNode.end())
  }
  if (anchorNode.node().type.name !== "textBlockNode") {
    const node = resolveClosestTextBlockFromStructuralElements(anchorNode, doc, !headFirst);
    if (!node) {
      return null;
    }
    anchorNode = node;
    selection = TextSelection.create(doc, headFirst ? anchorNode.end() : anchorNode.start(), selection.head);
  }
  const anchorFirst = anchorNode.start() < headNode.start();
  const fromNode = anchorFirst ? anchorNode : headNode;
  const toNode = anchorFirst ? headNode : anchorNode;

  const nodes: ResolvedPos[] = [];
  doc.nodesBetween(selection.from, selection.to, (node, pos, parent) => {
    if (node.type.name === "textBlockNode" && !node.attrs.isReadOnly) {
      // Ugly to have this magic number, but
      // seems unavoidable https://discuss.prosemirror.net/t/different-pos-results-with-nodesbetween-and-doc-resolve/3882
      // Hint: using .after and resolving agains returns the same node, sigh...
      nodes.push(doc.resolve(pos + 1));
    }
  });
  const filteredNodes = nodes
    .filter(node => !node.node().eq(headNode.node()) && !node.node().eq(anchorNode.node()));


  return {
    headNode: headNode.node().attrs.isReadOnly ? null : headNode,
    anchorNode: anchorNode.node().attrs.isReadOnly ? null : anchorNode,
    nodesBetween: filteredNodes,
    fromNode: fromNode.node().attrs.isReadOnly ? null : fromNode,
    toNode: toNode.node().attrs.isReadOnly ? null : toNode,
    selection: selection
  }
}

/**
 * Expects a range inside a single textBlockNode.
 * The function will remove the characters accordingly and preserve the whitespace if necessary
 * @param transaction
 * @param range
 */
export function cut(transaction: Transaction, range: NodeRange): Transaction {
  if (range.node.type.name !== "textBlockNode") {
    console.log("Not a TextBlockNode?!");
  }

  if (range.node.nodeSize > range.end - range.start) {
    transaction = transaction.delete(range.start, range.end);
  }

  const resolvedNode = transaction.doc.resolve(range.start);
  const nodeAfterDelete = resolvedNode.node();

  if (nodeAfterDelete && nodeAfterDelete.type.name === "textBlockNode") {
    const fullyDeletedNode = nodeAfterDelete.content.size === 0;
    const isInlineBlock = resolvedNode.node(resolvedNode.depth - 1).attrs.inlineMode === "explicit";
    const isNotFirstBlockInLogicalBlock = resolvedNode.index(resolvedNode.depth - 1) > 0;
    const nodeDoesNotStartWithSeparationCharacter = (isNotFirstBlockInLogicalBlock || isInlineBlock)
      && !textIncludesSeparationCharacter(nodeAfterDelete.textContent);
    const onlyContainsLineBreak = nodeAfterDelete.childCount === 1 && nodeAfterDelete.child(0).type.name === "hardBreak";
    if (fullyDeletedNode || (nodeDoesNotStartWithSeparationCharacter && range.start === resolvedNode.start()) || onlyContainsLineBreak) {
      transaction = transaction.insertText(" ", range.start);
    }
    // We do not allow, that a textBlockNode only contains a line break.
    // Thereby a space character is added (above) and the line break deleted
    if (onlyContainsLineBreak) {
      transaction = transaction.delete(resolvedNode.start(), resolvedNode.start() + 1);
    }
  }
  return transaction;
}

/**
 * Inserts/Replaces text inside a given textBlockNode
 * @param transaction
 * @param range
 * @param text
 * @param view
 */
export function insert(transaction: Transaction, range: NodeRange, text: string, view: EditorView) {
  if (range.node.type.name !== "textBlockNode") {
    console.log("Not a TextBlock?!");
  }

  const resolvedNode = transaction.doc.resolve(range.start);
  const nodeStart = resolvedNode.start();
  const childIndex = resolvedNode.index(resolvedNode.depth - 1);
  const isInlineBlock = resolvedNode.node(resolvedNode.depth - 1).attrs.inlineMode === "explicit";
  const blockNeedsSeparationCharacter = (isInlineBlock && childIndex === 0) || childIndex > 0
  const textInBlock = resolvedNode.node().textContent;


  // Ensure that the initial whitespace is removed when entering text to a block
  if (resolvedNode.node().content.size === 1 && textIncludesSeparationCharacter(textInBlock) && !textIncludesSeparationCharacter(text)) {
    if (range.start === nodeStart) {
      range.end = range.end + 1;
      transaction = transaction.setSelection(TextSelection.create(transaction.doc, range.end));
    } else if (range.start === nodeStart + 1 && !blockNeedsSeparationCharacter) {
      range.start = range.start - 1;
    }
  }

  // Every following block has to start with a separation character
  const deletingStartOfBlock = range.start === resolvedNode.start(resolvedNode.depth);
  if (blockNeedsSeparationCharacter && deletingStartOfBlock && !textIncludesSeparationCharacter(text)) {
    text = " " + text;
  }

  transaction = transaction.insertText(text, range.start, range.end);
  const markType = view.state.schema.marks.aiGenerated;
  removeMarks(transaction, range.start, transaction.mapping.map(range.end), [markType]);


  return transaction;
}

function moveCaretToPreviousBlock(resolvedNode: ResolvedPos, view: EditorView, selection: any): Transaction | null {
  const childIndex = resolvedNode.index(resolvedNode.depth - 1);
  // First block in logical block
  if (childIndex === 0) {
    const prevTextBlock = findNextTextblock(view.state.doc, selection.from, SearchDirection.LEFT, false);
    if (prevTextBlock <= 0) {
      return null;
    }
    const tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, prevTextBlock));
    return tr;
  }
  // Has a block on the same level
  else {
    const startOfChildNode = resolvedNode.start(resolvedNode.depth);
    const startOfParentNode = resolvedNode.start(resolvedNode.depth - 1);

    const parent = resolvedNode.node(resolvedNode.depth - 1);
    const childBefore = parent.childBefore(startOfChildNode - startOfParentNode - 1);

    if (!childBefore.node) {
      console.log("ERROR: Unable to resolve sibling!!");
      return null;
    }

    const startOfSiblingNode = startOfParentNode + childBefore.offset;
    const endOfSiblingNode = startOfSiblingNode + childBefore.node.nodeSize - 1;
    const tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, endOfSiblingNode));
    return tr;
  }
}

/**
 * Figures whether a backspace should position the caret in the previous block.
 * Directly delivers the necessary transaction to do so
 * @param view
 * @param event
 */
export function moveToPreviousBlockRequired(view: EditorView, event: KeyboardEvent): Transaction | null {
  if (event.key !== "Backspace") {
    return null;
  }
  const selection = view.state.selection;

  if (selection.from != selection.to) {
    return null;
  }

  const resolvedNode = view.state.doc.resolve(selection.from);
  const nodeStart = resolvedNode?.start(resolvedNode.depth);
  const blockIsNotFirstInLogicalBlock = resolvedNode.index(resolvedNode.depth - 1) > 0;
  const blockIsFirstInLogicalBlock = resolvedNode.index(resolvedNode.depth - 1) === 0;
  const blockIsInlineBlock = resolvedNode.node(resolvedNode.depth - 1).attrs.inlineMode === "explicit";
  const blockHoldsOnlyOneCharacter = resolvedNode.node().content.size === 1;
  const textInBlock = resolvedNode.node().textContent;
  const blockRequiresSeparationCharacter = blockIsNotFirstInLogicalBlock || (blockIsFirstInLogicalBlock && blockIsInlineBlock)
    || blockHoldsOnlyOneCharacter;

  // Caret is at the starting position of the node, ...
  if (nodeStart === selection.from
    // ...or the first character of the node is to be deleted and no further separation character is in the block
    || (nodeStart + 1 === selection.from && textIncludesSeparationCharacter(textInBlock) && !textInBlock.startsWith(',')
      && blockRequiresSeparationCharacter && !textIncludesSeparationCharacter(textInBlock.substring(1)))) {
    return moveCaretToPreviousBlock(resolvedNode, view, selection);
  }
  return null;
}


/**
 * The issue is the following:
 * In Firefox, we generally have more caret positions available, such that if a whitespace occurs, we can navigate directly in front and
 * after it. Even if a block border or a css after-Element (in form of a RefSign) would follow.
 * Prosemirror is not able to differ between these additional positions. Additionally, we have the problem that RefSign-Label are not
 * visible to prosemirror, such that we need to walk the DOM to figure whether we are near such an edge.
 * Solution:
 * Even by walking the DOM we experience sketchy/unexpected results. Due to the difficulty of even realizing in which situation we're
 * in, the easiest way seemed to be push the caret to the next valid PM position as we approach the edge of a DOM node.
 * Caveat:
 * Ctrl+Arrow-<Left/Right> may be influenced at DOM node edges
 * @param view
 * @param event
 */
export function handleReferenceSignMarker(view: EditorView, event: KeyboardEvent) {
  const pos = view.state.selection.head;

  const forwards = event.key === "ArrowRight";
  const backwards = event.key === "ArrowLeft" || event.key === "Backspace";

  let htmlElement = view.domAtPos(pos, -1).node;
  const htmlElementOfPrevPrevCursorPos = view.domAtPos(pos - 2, 1).node;
  let caretPosInHtmlElement = view.posAtDOM(htmlElement, 0, 0);
  if (htmlElement.parentElement && backwards) {
    const previousElementSibling = findPreviousSibling(htmlElement);
    const isReferenceSign = !!previousElementSibling?.dataset?.refsignFormat;
    const startOfBlock = htmlElement !== htmlElementOfPrevPrevCursorPos;
    if (isReferenceSign) {
      if (startOfBlock) {
        const anchor = view.state.selection.anchor;
        let tr: Transaction;
        if (anchor != pos) {
          tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, anchor, pos - 1));
        } else {
          tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, pos - 1));
        }
        view.dispatch(tr);
        return true;
      }
    }
  }

  // When going forwards, the bias as to be changed
  htmlElement = view.domAtPos(pos, 1).node;
  caretPosInHtmlElement = view.posAtDOM(htmlElement, 0, 0);
  if (htmlElement.parentElement && forwards && !event.ctrlKey) {
    const endOfBlock = caretPosInHtmlElement === pos;
    if (endOfBlock) {
      const anchor = view.state.selection.anchor;
      let tr: Transaction;
      if (anchor != pos || event.shiftKey) {
        tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, anchor, pos + 1));
      } else {
        tr = view.state.tr.setSelection(TextSelection.create(view.state.doc, pos + 1));
      }
      view.dispatch(tr);
      return true;
    }
  }

  if (event.key === "Delete") {
    const node: any = (view as any).docView.domFromPos(pos)?.node;
    if (node) {
      // Actually needs the previousElement and not the nextElement. Don't ask questions...
      const previousElementSibling = findPreviousSibling(node);
      const blockPosition = view.posAtDOM(node, 0);
      const startOfBlock = blockPosition === pos;
      if (previousElementSibling?.dataset?.refsignFormat && startOfBlock) {
        const hasSelection = view.state.selection.from != view.state.selection.to;
        return true;
      }
    }
  }
}

export function findPreviousSibling(node: any): HTMLElement | undefined {
  // In the case we are deeper (eg. in a spellcheck word, bold text, ...) we have to go up first
  if (!node) {
    return;
  }
  let previousElementSibling = node?.previousSibling;
  while (!previousElementSibling) {
    node = node?.parentElement;
    if (!node) {
      break;
    }
    previousElementSibling = node.previousSibling;
  }
  return previousElementSibling;
}


function textIncludesSeparationCharacter(text: string) {
  // list of separation characters could be extended, e.g. if commas shall be preserved
  return text.startsWith(' ') || text.startsWith(',');
}

/**
 * Finds the next textblock starting at a cords based position
 * @param editor tiptap editor
 * @param clientX starting position X (compatible to mouse event clientX)
 * @param clientY starting position Y (compatible to mouse event clientY)
 * @param offsetX the current distance from the start position in X direction
 * @param offsetY the current distance from the start position in Y direction
 * @param stepX if not found at the current position, the value used to adjust the X position for the next test
 * @param stepY if not found at the current position, the value used to adjust the Y position for the next test
 * @param max maximum distance to test regarding the original position
 * @return The document position of the first found textblock, -1 if no block is found
 */
function findNextTextblockPosition(editor: Editor, clientX: number, clientY: number, offsetX: number, offsetY: number,
                                   stepX: number, stepY: number, max: number): number {
  if (!editor || clientX < 0 || clientY < 0 || Math.abs(offsetX) > max || Math.abs(offsetY) > max) {
    return -1;
  }
  const posAtCords = editor.view.posAtCoords({left: clientX + offsetX, top: clientY + offsetY});
  if (!posAtCords) {
    return -1;
  }
  const resolvedPos = editor.state.doc.resolve(posAtCords.pos);
  const foundType = resolvedPos.node().type.name;
  if (foundType === 'textBlockNode') {
    return posAtCords.pos;
  }
  return findNextTextblockPosition(editor, clientX, clientY, offsetX + stepX, offsetY + stepY, stepX, stepY, max);
}

function findNextTextblockPositionToLeft(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, -step, 0, max);
}

function findNextTextblockPositionToRight(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, step, 0, max);
}

function findNextTextblockPositionUpwards(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, 0, -step, max);
}

function findNextTextblockPositionDownwards(editor: Editor, clientX: number, clientY: number, step: number, max: number): number {
  return findNextTextblockPosition(editor, clientX, clientY, 0, 0, 0, step, max);
}

/**
 * Finds the next textblock in the surrounding of a cords based position
 * @param clientX starting position X (compatible to mouse event clientX)
 * @param clientY starting position Y (compatible to mouse event clientY)
 * @return The document position of the first found textblock, -1 if no block is found
 */
export function findNextTextblockPositionByCords(editor: Editor, clientX: number, clientY: number): number {
  // first look to the left
  let found = findNextTextblockPositionToLeft(editor, clientX, clientY, 3, 500);
  if (found > 0) {
    return found;
  }
  // if not found  look to the right
  found = findNextTextblockPositionToRight(editor, clientX, clientY, 3, 50);
  if (found > 0) {
    return found;
  }
  // look upwards
  found = findNextTextblockPositionDownwards(editor, clientX, clientY, 6, 100);
  if (found > 0) {
    return found;
  }
  // look downwards
  return findNextTextblockPositionUpwards(editor, clientX, clientY, 6, 100);
}