<template>
  <span class="licenses">

    <!-- Use the modal component, pass in the prop -->
    <modal-dialog ref="licensesDialog" :showHeader="true" :showFooter="false">
      <template v-slot:body>

        <img id="patent-engine-logo" src="../assets/logo/PE_mit_Logo.svg" alt="Patent-Engine Logo"/>
        <span id="version">{{ getVersion() }}</span>

        <h1>{{ $t('licenses.title') }}</h1>
        <p>{{ $t('licenses.licenses') }}</p>

        <div class="loaded-html" v-html="licenseHtml"></div>

      </template>
    </modal-dialog>
  </span>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import i18n from '@/i18n';

@Component(
  {
    components: {
      ModalDialog
    }
  })
class LicensesDialog extends Vue {
  @Ref('licensesDialog') private dialog!: ModalDialogClass;

  licenseHtml = i18n.global.t('licenses.loading') as string;

  open(): void {
    this.dialog.openModal();
  }

  close(): void {
    this.dialog.closeModal();
  }

  mounted(): void {
    this.$http.get("/frontend_licenses.html")
      .then(htmlString => this.licenseHtml = htmlString.data)
      .catch(it => this.licenseHtml = this.$t('licenses.load_error') as string)
  }

  getVersion(): string {
    return `v${process.env.VUE_APP_VERSION}`;
  }
}

export default toNative(LicensesDialog);
export {LicensesDialog};
</script>

<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants.scss';

.loaded-html table {
  color: $text-color;
  border: 1px solid $pengine-grey-light;
  border-radius: $input-border-radius;
  text-align: left;

  details {
    width: 700px;
  }

  pre {
    white-space: pre-wrap;
  }

  a:link {
    color: $pengine-blue-dark;
  }

  a:visited {
    color: #551A8B;
  }

  th {
    background-color: $pengine-grey-dark;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: normal;
    color: white;
  }

  td {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: normal;
  }

  tr:nth-child(even) {
    background-color: $pengine-grey-light;
  }
}

.licenses {
  .modal-dialog {
    $dialog-width: 1080px;

    &-dialog {
      width: 1100px;
      min-width: 800px;
      max-height: 100%;

      .modal-dialog-header {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0 0 20px 0;

        .icon-button {
          margin-top: 0px;
          margin-right: 0px;
          padding: 0px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .modal-dialog-body {
        align-items: center;
        max-height: 100%;
        overflow-y: hidden;

        #patent-engine-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 300px;
        }

        #version {
          font-size: 16px;
          margin-top: -6px;
        }

        .loaded-html {
          overflow-y: hidden;

          table {
            display: block;
            max-height: 250px;
            margin-bottom: 10px;
            overflow-y: auto;
          }
        }
      }
    }
  }
}

</style>
