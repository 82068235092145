/**
 * Helper to read a string out of a blob.
 *
 * @param blob Blob
 * @return unwrapped string as promise
 */
export const readBlobAsText = (blob: Blob): Promise<string> => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new Error("Problem parsing blob."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as string);
    };

    temporaryFileReader.readAsText(blob);
  });
};

/**
 * Helper to read a JSON object out of a blob.
 *
 * @param blob Blob
 * @return object from JSON as promise
 */
export const readBlobAsJson = async (blob: Blob): Promise<any> => {
  const text = await readBlobAsText(blob);
  return JSON.parse(text);
}
