import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-542858ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cookies-banner" }
const _hoisted_2 = { class: "cookie-message" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "cookie-options" }
const _hoisted_6 = { class: "is-flex flex-center" }
const _hoisted_7 = { class: "is-flex flex-center" }
const _hoisted_8 = { class: "cookie-option" }
const _hoisted_9 = { class: "cookie-buttons" }
const _hoisted_10 = { class: "button-container" }
const _hoisted_11 = { class: "button-container" }
const _hoisted_12 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_checkbox = _resolveComponent("b-checkbox")!
  const _component_modal_dialog = _resolveComponent("modal-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_modal_dialog, {
      ref: "dialog",
      "show-close": false
    }, {
      header: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('cookies.cookieBannerTitle')), 1)
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('cookies.cookieBannerMessage')) + " ", 1),
          _createElementVNode("a", {
            target: "_blank",
            rel: "noopener noreferrer",
            href: `${_ctx.$t('regulatoryInformation.languagePath')}/data-privacy-policy.html`
          }, _toDisplayString(_ctx.$t('regulatoryInformation.privacyPolicy')), 9, _hoisted_3),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('cookies.cookieBannerFill')) + " ", 1),
          _createElementVNode("a", {
            target: "_blank",
            rel: "noopener noreferrer",
            href: `${_ctx.$t('regulatoryInformation.languagePath')}/imprint.html`
          }, _toDisplayString(_ctx.$t('regulatoryInformation.imprint')), 9, _hoisted_4),
          _createTextVNode(". ")
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_b_checkbox, {
              name: "allowEssentials",
              value: true,
              disabled: ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('cookies.cookieBannerEssential')), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_b_checkbox, {
              class: "checkbox-container",
              name: "allowFunctionals",
              modelValue: _ctx.functionalCookies,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.functionalCookies) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('cookies.cookieBannerFunctional')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("button", {
              class: "column button-action",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.acceptAll && _ctx.acceptAll(...args)))
            }, _toDisplayString(_ctx.$t('cookies.cookieBannerAcceptAll')), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              id: "acceptCookies",
              class: "column button-accept-choice",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.acceptChoices && _ctx.acceptChoices(...args))),
              title: _ctx.$t('cookies.cookieBannerAcceptChoices')
            }, _toDisplayString(_ctx.$t('cookies.cookieBannerAcceptChoices')), 9, _hoisted_12)
          ])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}