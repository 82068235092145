export const convertInstantToDateString = (time: string | number | Date): string => {
  return new Date(time).toLocaleDateString();
}

export const convertInstantToTimeString = (time: string | number | Date) => {
  return new Date(time).toLocaleTimeString();
}

export const calculateDateIn90DaysFromNow = (): Date => {
  const date = new Date();
  date.setDate(date.getDate() + 90);
  return date;
}

const pad = (number: number, length: number) => {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

export const getYYYYMMDDHHMMSS = (): string => {
  const date = new Date();
  const yyyy = date.getFullYear().toString();
  const MM = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  const hh = pad(date.getHours(), 2);
  const mm = pad(date.getMinutes(), 2)
  const ss = pad(date.getSeconds(), 2)

  return yyyy + MM + dd + '_' + hh + mm + ss;
};
