import {ApplicationDocument} from '@/api/models/application.model';
import {ActionContext, ActionTree, GetterTree, MutationTree} from 'vuex';

export interface ApplicationState {
  isLoading: boolean;
  currentApplicationDocument: ApplicationDocument | null;
  templateTexts: { [key: string]: string };
}

export type ApplicationActionTree = ActionTree<ApplicationState, ApplicationState>;
export type ApplicationMutationTree = MutationTree<ApplicationState>;
export type ApplicationGetterTree = GetterTree<ApplicationState, ApplicationState>;
export type ApplicationContext = ActionContext<ApplicationState, ApplicationState>;

export enum ApplicationViewSplitMode {
  DOCUMENT_ONLY = 'DOCUMENT_ONLY',
  DOCUMENT_AND_FIGURES = 'DOCUMENT_AND_FIGURES'
}

export type DocumentOnlySplitState = {
  mode: ApplicationViewSplitMode.DOCUMENT_ONLY;
}

export type DocumentAndFiguresSplitState = {
  mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES;
  ratio: number;
}

export const InitialDocumentOnlySplitState: DocumentOnlySplitState = {
  mode: ApplicationViewSplitMode.DOCUMENT_ONLY
}

export const InitialDocumentAndFiguresSplitState: DocumentAndFiguresSplitState = {
  mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES,
  ratio: 0.50
}

export type ApplicationViewSplitState = DocumentOnlySplitState
                                      | DocumentAndFiguresSplitState;


export type SharedSizeAttrs = {
  mode: ApplicationViewSplitMode;
  leftPaneSize: number;
  leftPaneMinimized: boolean;
  documentEditorPaneSize: number;
  rightPaneSize: number;
  rightPaneMinimized: boolean;
}

export type DocumentOnlySplitStateSizes = SharedSizeAttrs & {
  mode: ApplicationViewSplitMode.DOCUMENT_ONLY;
}

export type DocumentAndFigureSplitStateSizes = SharedSizeAttrs & {
  mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES;
  ratio: number;
  figureEditorPaneSize: number;
}

export type PaneSizes = DocumentOnlySplitStateSizes | DocumentAndFigureSplitStateSizes;

export const initialDocumentOnlySplitStateSizes: DocumentOnlySplitStateSizes = {
  mode: ApplicationViewSplitMode.DOCUMENT_ONLY,
  leftPaneSize: 15,
  leftPaneMinimized: false,
  documentEditorPaneSize: 70,
  rightPaneSize: 15,
  rightPaneMinimized: false
}

export const initialDocumentAndFiguresSplitStateSizes: DocumentAndFigureSplitStateSizes = {
  mode: ApplicationViewSplitMode.DOCUMENT_AND_FIGURES,
  leftPaneSize: 15,
  leftPaneMinimized: false,
  documentEditorPaneSize: 35,
  ratio: 0.5,
  figureEditorPaneSize: 35,
  rightPaneSize: 15,
  rightPaneMinimized: false
}