import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "application-figure" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "label" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "label" }
const _hoisted_7 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_taginput = _resolveComponent("b-taginput")!
  const _component_b_field = _resolveComponent("b-field")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_modal, {
      ref: "dialog",
      showHeader: true,
      showFooter: false,
      width: _ctx.contextWidth,
      margin: _ctx.contextMargin
    }, {
      body: _withCtx(() => [
        _createElementVNode("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('searchStemForm')) + " ", 1),
          _createElementVNode("i", {
            class: "exi exi-info",
            title: _ctx.$t('searchStemFormTitle')
          }, null, 8, _hoisted_3)
        ]),
        _createVNode(_component_b_field, { class: "stem-forms" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_taginput, {
              size: "is-small",
              modelValue: _ctx.stemForms,
              closable: _ctx.stemForms.length > 1,
              icon: "label",
              "on@update:modelValue": _ctx.stemFormsChanged,
              placeholder: _ctx.$t('stemForm')
            }, null, 8, ["modelValue", "closable", "on@update:modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createElementVNode("label", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('excludeStemFormMatches')) + " ", 1),
          _createElementVNode("i", {
            class: "exi exi-info",
            title: _ctx.$t('excludeStemFormMatchesTitle')
          }, null, 8, _hoisted_5)
        ]),
        _createVNode(_component_b_field, { class: "exclude-stem-form-matches" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_taginput, {
              size: "is-small",
              readonly: "",
              modelValue: _ctx.excludeStemFormMatches,
              "onUpdate:modelValue": _ctx.onExclusionRemoved
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        }),
        _createElementVNode("label", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.$t('stemFormMatches')) + " ", 1),
          _createElementVNode("i", {
            class: "exi exi-info",
            title: _ctx.$t('stemFormMatchesTitle')
          }, null, 8, _hoisted_7)
        ]),
        _createVNode(_component_b_field, { class: "stem-form-matches" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_taginput, {
              size: "is-small",
              readonly: "",
              modelValue: _ctx.stemFormMatches,
              "onUpdate:modelValue": _ctx.onMatchRemoved
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["width", "margin"])
  ]))
}