import {ReferenceSign} from '@/api/models/referenceSign.model';
import {ReferenceSignListEntry} from '@/store/models/referenceSign.model';

// See backend for detailed explanation: ReferenceSignValidationsImpl.java
export const REG_EXP_INVALID_REFERENCE_SIGN_LABEL = /^((\s.*)|(.*\s)|(.*[\u180E].*)|(.*[\u2000-\u200F].*)|(.*[\u2028-\u2029].*)|(.*[\u202A-\u202E].*)|(.*[\u200F].*)|(.*[\u205F].*)|(.*[\u3000].*))$/;

/**
 * Checks if a string is a valid reference sign label. Being 'null' is valid as well.
 * @param referenceSign the reference sign string to check
 */
export const isReferenceSignLabelStringValid = (referenceSign: string | null): boolean => {
  return (referenceSign === null) || !REG_EXP_INVALID_REFERENCE_SIGN_LABEL.test(referenceSign as string);
};

/**
 * Checks if the label of a ReferenceSignListEntry is valid.
 * Therefore, either the explizit label is set and valid or the implizit label must be set.
 *
 * @param referenceSignListEntry the ReferenceSignListEntry to check
 * @return true, if the label (explizit or implizit) is valid
 */
export const isReferenceSignListEntryLabelValid = (referenceSignListEntry: ReferenceSignListEntry): boolean => {

  // In a ReferenceSignListEntry, labelExplizit can be set, undefined or null
  const explizitLabel: string | null | undefined = referenceSignListEntry.labelExplizit;

  // If it is not set, then it's implzit label must be set
  if (!(explizitLabel) || (explizitLabel.length === 0)) {
    return true;
  }

  // If it is set, then it must be valid
  return isReferenceSignLabelStringValid(explizitLabel.trim());
};

/**
 * Checks if the label of a ReferenceSign is valid.
 *
 * @param referenceSign the ReferenceSign to check
 * @return true, if the label is valid
 */
export const isReferenceSignLabelValid = (referenceSign: ReferenceSign): boolean => {
  // It may be undefined but if it's defined, then it must be valid
  return (referenceSign.label === undefined) || isReferenceSignLabelStringValid(referenceSign.label);
};

/**
 * Checks if the Reference Sign has not an empty name.
 *
 * @param referenceSign the Reference Sign entity to check
 * @return true, if the given Reference Sign name is not empty
 */
export const isReferenceSignNameNotEmpty = (referenceSign: ReferenceSign | ReferenceSignListEntry): boolean => {
  return !!referenceSign && !!(referenceSign.name) && (referenceSign.name.trim().length > 0);
};

/**
 * Checks if the Reference Sign's label is unique among the provided list of reference signs.
 *
 * @param referenceSign the Reference Sign to check
 * @param referenceSignList the Reference Signs list of the Application Document
 * @return returns true, if the label is unique
 */
export const isReferenceSignLabelUnique = (referenceSign: ReferenceSign,
                                           referenceSignList: Array<ReferenceSign>): boolean => {
  // If label is not set there is nothing to check
  if (referenceSign.label) {
    const predicate = (other: ReferenceSign) => (referenceSign.guid !== other.guid) && (referenceSign.label === other.label);
    return !referenceSignList.find(predicate);
  }
  return true;
}

/**
 * Checks if the Reference Sign's label is unique among the provided list of reference sign list entries.
 *
 * @param referenceSignListEntry the Reference Sign to check
 * @param referenceSignListEntryList the list of ReferenceSignListEntries
 * @return returns true, if the label is unique
 */
export const isReferenceSignListEntryLabelUnique = (referenceSignListEntry: ReferenceSignListEntry,
                                                    referenceSignListEntryList: Array<ReferenceSignListEntry>): boolean => {
  // Trim the label for comparision
  const trimmedLabelExplicit: string | undefined = referenceSignListEntry.labelExplizit?.trim();

  // If the (trimmed) label is not set or empty there is nothing to check
  if (trimmedLabelExplicit && trimmedLabelExplicit.length > 0) {
    const predicate = (other: ReferenceSignListEntry) =>
      (referenceSignListEntry.guid !== other.guid) && (trimmedLabelExplicit === other.labelExplizit);
    return !referenceSignListEntryList.find(predicate);
  }
  return true;
}

/**
 * Checks if the Reference Sign's name is unique among the provided list of reference signs.
 *
 * @param referenceSign the Reference Sign to check as ReferenceSign | ReferenceSignListEntry
 * @param referenceSignList the Reference Signs list of the Application Document (as ReferenceSign | ReferenceSignListEntry)
 * @return returns true, if the name is unique
 */
export const isReferenceSignNameUnique = (referenceSign: ReferenceSign | ReferenceSignListEntry,
                                          referenceSignList: Array<ReferenceSign | ReferenceSignListEntry>): boolean => {
  const predicate = (other: ReferenceSign | ReferenceSignListEntry) =>
    (referenceSign.guid !== other.guid) && (referenceSign.name.toLowerCase() === other.name.toLowerCase());
  return !referenceSignList.find(predicate);
};
