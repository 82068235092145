import {
  CreateReferenceSignEvent,
  ReferenceSign,
  ReferenceSignCreatedVmUpdate,
  ReferenceSignUpdatedVmUpdate,
  UpdateReferenceSignEvent
} from '@/api/models/referenceSign.model';
import {axiosInstance, cancelToken} from '@/api';
import {CancelTokenSource} from 'axios';

const REFERENCE_SIGN_PATH = '/referencesign';

export const GetReferenceSign = async (guid: string): Promise<ReferenceSign> => {
    const res = await axiosInstance.get(`${REFERENCE_SIGN_PATH}/${guid}`);
    return res?.data as ReferenceSign;
};

export const CreateReferenceSign = async (event: CreateReferenceSignEvent): Promise<ReferenceSign> => {
    const res = await axiosInstance.post(REFERENCE_SIGN_PATH, event);
    return (res?.data as ReferenceSignCreatedVmUpdate).referenceSign;
};

let updateReferenceSignCancelToken: CancelTokenSource | null = null;

export const UpdateReferenceSign = async (event: UpdateReferenceSignEvent): Promise<ReferenceSign> => {
  updateReferenceSignCancelToken?.cancel("");
  updateReferenceSignCancelToken = cancelToken.source();
  const res = await axiosInstance.put(REFERENCE_SIGN_PATH, event, { cancelToken: updateReferenceSignCancelToken.token });
  return (res?.data as ReferenceSignUpdatedVmUpdate).referenceSign;
};

export const DeleteReferenceSign = async (guid: string): Promise<void> => {
  await axiosInstance.delete(`${REFERENCE_SIGN_PATH}/${guid}`);
};

export const GenerateStemForms = async (guid: string): Promise<ReferenceSign> => {
  const res = await axiosInstance.post(`${REFERENCE_SIGN_PATH}/${guid}/generateStemForms`)
  return res?.data as ReferenceSign;
}
