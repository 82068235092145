import {Mark, mergeAttributes} from '@tiptap/vue-3';

export const ReferenceSignMarkExtension = Mark.create(
  {
    name: "referenceSignMark",
    group: "inline",

    addAttributes() {
      return {
        'data-refsign-guid': {default: 0},
        'data-refsign-format': {default: "plain"},
        'data-refsign-label': {default: ""},
      };
    },

    parseHTML() {
      return [
        {
          tag: 'reference-sign',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              'data-refsign-guid': domElement.getAttribute('data-refsign-guid'),
              'data-refsign-format': domElement.getAttribute('data-refsign-format'),
              'data-refsign-label': domElement.getAttribute('data-refsign-label')
            }
          }
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['reference-sign', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    }
  });
