import {Extension} from '@tiptap/vue-3';
import {EditorState} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';
import {
  PatentEngineHistory,
  PatentengineHistoryPlugin,
  PatentengineHistoryPluginKey
} from '@/components/applicationEditor/plugins/PatentengineHistoryPlugin';
import EditorModule from '@/store/modules/EditorModule';
import {useDefaultErrorHandling} from '@/errorHandling';

/**
 * Tries an undo action. Needs to fit the command interface. https://prosemirror.net/docs/ref/#commands
 * @param state the EditorState for the action.
 * @param view The view on which the command was executed.
 * @return true if the command could execute, else false.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const undo = ({state, view}: { state: EditorState, view: EditorView }): boolean => {
  // if we are are currently doing something else, don't undo anything
  if (EditorModule.isLoading) {
    return false;
  }
  // check if undo/redo is enabled for document
  const plugin = PatentengineHistoryPluginKey.get(state);
  if (plugin !== undefined) {
    // undo local changes, if there are any
    const patentEngineHistoryPlugin = plugin as PatentengineHistoryPlugin;
    if (patentEngineHistoryPlugin.undoStepsAvailable(state, view.dispatch) > 0) {
      return patentEngineHistoryPlugin.undo(state, view.dispatch);
    } else {
      // there are no local changes, request the server to do an undo step
      const historyState = patentEngineHistoryPlugin.getState(state) as PatentEngineHistory;

      const undoStack = EditorModule.undoStack;
      const index = undoStack.length - 1;
      if (index >= 0) {
        const undoItem = undoStack[index];
        EditorModule.undo({applicationDocumentGuid: historyState.applicationDocumentGuid, undoStepGuid: undoItem.guid}).catch(useDefaultErrorHandling);
        return true;
      }
    }
  }
  return false;
}

/**
 * Tries an redo action. Needs to fit the command interface. https://prosemirror.net/docs/ref/#commands
 * @param state the EditorState for the action.
 * @param view The view on which the command was executed.
 * @return true if the command could execute, else false.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const redo = ({state, view}: { state: EditorState, view: EditorView }): boolean => {
  // if we are are currently doing something else, don't redo anything
  if (EditorModule.isLoading) {
    return false;
  }
  // check if undo/redo is enabled for document
  const plugin = PatentengineHistoryPluginKey.get(state);
  if (plugin !== undefined) {
    // if there are remote redos, do them first
    const patentEngineHistoryPlugin = plugin as PatentengineHistoryPlugin;
    const redoStack = EditorModule.redoStack;
    const historyState = patentEngineHistoryPlugin.getState(state) as PatentEngineHistory;
    // EDIT: jdommer (PENGINESUP-608)
    // If changes have been made to the frontend state, hide all remote Redos. This change is complementary to the one in History.vue
    // but as the Keylisteners are registered here, this change is necessary
    if (redoStack.length > 0 && EditorModule.localUndoCount <= 0) {
      const redoItem = redoStack[0];
      EditorModule.redo({applicationDocumentGuid: historyState.applicationDocumentGuid, redoStepGuid: redoItem.guid}).catch(useDefaultErrorHandling);
      return true;
    } else if (patentEngineHistoryPlugin.redoStepsAvailable(state, view.dispatch) > 0) {
      // redo local changes only if there are no remote redos available
      return patentEngineHistoryPlugin.redo(state, view.dispatch);
    }
  }
  return false;
}

/**
 * Extension for hooking in undo-/redo functionality to patent engine
 */
export const PatentEngineHistoryExtension = Extension.create(
  {
    name: 'patentEngineHistoryExtension',

    addKeyboardShortcuts() {
      return {
        'Mod-z': () => undo(this.editor),
        'Mod-y': () => redo(this.editor),
        'Shift-Mod-z': () => redo(this.editor),
        'Mod-я': () => undo(this.editor),
        'Shift-Mod-я': () => redo(this.editor),
      };
    }
  });
