import {Editor} from '@tiptap/vue-3';
import {Node as PmNode} from "@tiptap/pm/model";

/**
 * Copy listener for the SearchResultDialog to determine which content should be placed as which meta data in the clipboard.
 *
 * @param searchResultGuid GUID of the search result
 * @param copyAll          A function that provides the value of copyAll (true: Copy all text of the search result; false: only use selected
 * text)
 * @param getAllContent    A function that provides the complete text of the search result
 *
 * @return the created copy listener
 */
export function registerSearchResultDialogCopyListener(searchResultGuid: string, copyAll: () => boolean,
                                                       getAllContent: () => string): (event: ClipboardEvent) => void {
  const copyListener = (event: ClipboardEvent): void => {
    if (event.clipboardData == null) {
      return;
    }
    let textToCopy;
    if (copyAll()) {
      textToCopy = getAllContent();
    } else if (window.getSelection() === null) {
      textToCopy = '';
    } else {
      textToCopy = (window.getSelection() as Selection).toString();
    }

    event.clipboardData.setData('pengine/guid', searchResultGuid);
    event.clipboardData.setData('text/plain', textToCopy);
    event.clipboardData.setData('text/html',
                                '<meta name="guid" content="' + searchResultGuid + '"/>' + textToCopy.replace(/\r?\n/g, "<br />"));
    event.preventDefault();
  };
  document.addEventListener('copy', copyListener);
  return copyListener;
}

/**
 * Copy listener for the ApplicationEditor to determine which content should be placed as which meta data in the clipboard.
 * 1. It will remove consecutive spaces caused by empty text blocks.
 *    This is only necessary in plain/text, because HTML would be intrepreted correctly.
 * @param editor The current editor
 *
 * @return the created copy listener
 */
export function registerApplicationEditorCopyListener(editor: Editor): (event: ClipboardEvent) => void {
  const copyListener = (event: ClipboardEvent): void => {
    if (event.clipboardData == null
      || !editor.isFocused
      || !editor.state
      || !editor.state.selection
      || editor.state.selection.from === editor.state.selection.to) {
      return;
    }

    // Get the resolved position to determine where the first node starts
    const resolvedPosStart = editor.state.doc.resolve(editor.state.selection.from);

    // If that range already includes the to position there can not be another empty block -> do nothing
    if (resolvedPosStart.end() >= editor.state.selection.to) {
      return;
    }

    // else also determine the resolved pos at to.
    const resolvedPosEnd = editor.state.doc.resolve(editor.state.selection.to);

    // If there is nothin between them, we also have nothing else to do
    if (resolvedPosStart.end() + 2 >= resolvedPosEnd.start()) {
      return;
    }

    // We must now iterate over all nodes in between and collect their texts
    let text = '';
    let lineBreakAfterNextStructBlock = false;
    editor.state.doc.nodesBetween(editor.state.selection.from, editor.state.selection.to, (node: PmNode, pos: number) => {
      if (node.type.name === 'textBlockNode') {
        if (node.textContent != ' ' || node.nodeSize != 3) {
          if (resolvedPosStart.node() === node) {
            text += node.textContent.substring(editor.state.selection.from - resolvedPosStart.start());
          } else if (resolvedPosEnd.node() === node) {
            text += node.textContent.substring(0, editor.state.selection.to - resolvedPosEnd.start());
          } else {
            text += node.textContent;
          }
        }
        lineBreakAfterNextStructBlock = true;
      } else if (node.type.name === 'structuralBlockNode' || node.type.name === 'tableEntryNode') {
        if (lineBreakAfterNextStructBlock) {
          lineBreakAfterNextStructBlock = false;
          text += '\n';
        }
      }

      return true;
    });

    event.clipboardData.setData('text/plain', text);
    event.preventDefault();
  };

  return copyListener;
}
