import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "app-main",
  ref: "app"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplashScreenDialog = _resolveComponent("SplashScreenDialog")!
  const _component_CookiesBanner = _resolveComponent("CookiesBanner")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SplashScreenDialog, { ref: "splashScreen" }, null, 512),
    _createVNode(_component_CookiesBanner, { ref: "cookiesBanner" }, null, 512),
    _createVNode(_component_Header, { splashScreen: _ctx.splashScreenMounted }, null, 8, ["splashScreen"]),
    _createVNode(_component_router_view, {
      class: _normalizeClass('router-view' + (_ctx.isHeaderPinned ? ' with-pinned-header' : ''))
    }, null, 8, ["class"])
  ], 512))
}