import {Extension} from '@tiptap/vue-3';
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {ResolvedPos} from '@tiptap/pm/model';
import {EditorState, Plugin, PluginKey} from '@tiptap/pm/state'
import {
  findAllAncestorRanges,
  findAllDescendants,
  findDepthOfLogicalBlock,
  findDescendants,
  isLogicalBlock
} from '@/components/applicationEditor/utils/node.util';

const cssClassName = {
  selectedDescendant: 'selected-descendant-of-logical-block',
  evenDescendant: 'even-descendant-of-logical-block',
  oddDescendant: 'odd-descendant-of-logical-block',
  current: 'current-logical-block',
  sibling: 'sibling-of-logical-block',
  parent: 'parent-of-logical-block',
};

/**
 * Calculates the visualization for the given state. The visualaization depends on the lowest selected logical block (LSLB) and has
 * in total 6 different styles:
 * 1a) the selected (leaf) node has the style: selected-descendant-of-logical-block
 * 1b) all descendants of the LSLB that are leaf nodes (more precisely isTextblock-nodes), excluding the selected (leaf) node have
 * alternating styles: even-descendant-of-logical-block and odd-descendant-of-logical-block
 * 2a) the LSLB has the style: current-logical-block
 * 2b) the heighest logical blocks that are descendants of the direct parent of the LSLB, excluding the LSLB have the style:
 *     sibling-of-logical-block
 * 3) all ancestors (apart from the RootNode) of the LSLB that are logical blocks have the style: parent-of-logical-block
 *
 * Example:
 *
 * node 01 (LB)                          parent-of-logical-block
 *   node 02 (LB)
 *   node 03
 *     node 04 (LB)                      sibling-of-logical-block
 *       node 05 (LB)
 *     node 06
 *       node 07 (LB)                    sibling-of-logical-block
 *         node 08 (LB)
 *     node 09 (LB)                      current-logical-block
 *       node 10 (LB)
 *         node 11 (leaf)
 *       node 12
 *         node 13 (leaf)                even-descendant-of-logical-block
 *         node 14 (leaf)                odd-descendant-of-logical-block
 *         node 15 (leaf)  << Cursor     selected-descendant-of-logical-block
 *         node 16 (leaf)                odd-descendant-of-logical-block
 *     node 17 (LB)                      sibling-of-logical-block
 *         node 18 (LB)
 *
 * @param state the current ProseMirror-State
 */
const calculateVisualization = (state: EditorState): DecorationSet => {
  const decorations: Decoration[] = [];
  const anchor = state.selection.anchor;
  const position = state.selection.$anchor;

  // Decorate all descendants of the LSLB (style 1a und 1b)
  let depth = findDepthOfLogicalBlock(position);
  if (depth >= 0) {
    const directChildren = findAllDescendants(position.node(depth), (node) => (node.isTextblock && !node.isText),
                                              position.start(depth));
    let counter = 0;
    directChildren.forEach((range) => {
      counter++;
      let className = (counter % 2 === 0) ? cssClassName.evenDescendant : cssClassName.oddDescendant;
      if (range.start <= anchor && anchor <= range.end) {
        className = cssClassName.selectedDescendant;
      }
      const decoration = Decoration.node(range.start, range.end, {class: className});
      decorations.push(decoration);
    })
  }

  // Decorate the LSLB and its siblings (style 2a and 2b)
  depth = getLogicalParentDepth(position, depth);
  if (depth >= 0) {
    const logicalBlockSiblings = findDescendants(position.node(depth),
                                                 (node) => !isLogicalBlock(node),
                                                 (node) => isLogicalBlock(node),
                                                 position.start(depth));
    logicalBlockSiblings.forEach((range) => {
      const className = (range.start <= anchor && anchor <= range.end) ? cssClassName.current : cssClassName.sibling;
      const decoration = Decoration.node(range.start, range.end, {class: className});
      decorations.push(decoration);
    })
  }

  // Decorate all ancestors of the LSLB (style 3)
  const ancestors = findAllAncestorRanges(position, (node) => isLogicalBlock(node), depth, 2);
  ancestors.forEach((range) => {
    const className = cssClassName.parent;
    const decoration = Decoration.node(range.start, range.end, {class: className});
    decorations.push(decoration);
  });

  return DecorationSet.create(state.doc, decorations)
};

const getLogicalParentDepth = (positon: ResolvedPos, depth: number) => {
  let logicalParentDepth = depth - 1;
  while (logicalParentDepth >= -1 && !isLogicalBlock(positon.node(logicalParentDepth))) {
    logicalParentDepth -= 1;
  }
  return logicalParentDepth;
};

export const VisualizationExtension = (isOneEditorFocused: () => boolean) => Extension.create(
  {
    name: 'visualizationExtension',

    addStorage() {
      return {
        isOneEditorFocused: isOneEditorFocused
      };
    },

    addProseMirrorPlugins() {
      return [
        new Plugin(
          {
            props: {
              decorations: (state: EditorState): DecorationSet | null | undefined => {
                if (!this.editor) {
                  return null
                }
                if (!this.storage.isOneEditorFocused()) {
                  return null;
                }
                return calculateVisualization(state);
              },
            },
            key: new PluginKey("VisualizationPlug")
          })
      ];
    }
  });
