import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import {Node as PmNode} from "@tiptap/pm/model";
import {getArrayFromDomAttribute, getBooleanFromDomAttribute} from '@/components/applicationEditor/utils/converter.util';
import StructuralInlineBlockNodeView from '@/components/applicationEditor/nodes/baseElements/views/StructuralInlineBlockNodeView.vue';

/**
 * The node for a structural block that is in inline mode depending on the inline attribute.
 */
export const StructuralInlineBlockNode = TiptapNode.create(
  {
    name: 'structuralInlineBlockNode',
    content: '(nonLogicalStructuralBlockNode|structuralBlockNode)*|(textBlockNode|tableEntryNode|placeholderNode)+',
    group: 'block',
    isolating: true,
    defining: true,

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        header: {default: undefined},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
        inlineMode: {default: ''},
        inlineable: {default: false},
        logicalDepth: {default: -1}
      };
    },

    parseHTML() {
      return [
        {
          tag: 'structuralInlineBlockNode',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              semanticType: domElement.getAttribute('semanticType'),
              header: domElement.getAttribute('header'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks')),
              inlineMode: domElement.getAttribute('inlineMode'),
              inlineable: true, // inline blocks are inlineable per definition,
              logicalDepth: domElement.getAttribute('logicalDepth'),
            };
          }
        }
      ];
    },

    renderHTML({node, HTMLAttributes}: { node: PmNode; HTMLAttributes: Record<string, any> }) {
      return ['structuralInlineBlockNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(StructuralInlineBlockNodeView);
    }

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });
