import {DOMParser as PmDOMParser, Fragment, Node as PmNode, Schema, Slice} from '@tiptap/pm/model';
import {EditorView as PmEditorView} from '@tiptap/pm/view';
import {TextSelection} from '@tiptap/pm/state';
import HTML2ProsemirrorParser from '@/copyAndPaste/html/HTML2ProsemirrorParser';
import {findLowestDepth, findNodeRangeOfGuid} from '@/components/applicationEditor/utils/node.util';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import EditorModule from '@/store/modules/EditorModule';

// used to filter all control characters from pasted text.
// exception are made for:
// 1. linefeed (\n): u000A
// 2. carriage return (\r): u000D
//
// warning suppressed on purpose. we actually want to filter control characters so we need them within the regexp
// eslint-disable-next-line no-control-regex
const REG_EXP_SANITIZE_PASTED_TEXT = /[\u0000-\u0009]|[\u000B-\u000C]|[\u000E-\u001F]/g;


/**
 * Check for PENGINE specific meta data and adds to the currently selected node
 * @param view view of the Prosemirror editor to find the related node
 * @param text the text that might contain library references
 */
const addLibraryReferencesIfAvailable = (view: PmEditorView, text: string): void => {
  const anchor = view.state.selection.$anchor
  const node: PmNode = anchor.node(anchor.depth);
  if (!node.isTextblock) {
    return;
  }

  const domParser = new DOMParser();
  const doc = domParser.parseFromString(text, "text/html");
  const metas = doc.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === 'guid') {
      const libraryGuid = metas[i].getAttribute('content');
      if (!libraryGuid) {
        continue;
      }
      if (node.attrs.libraryReferences.includes(libraryGuid)) {
        continue;
      }
      node.attrs.libraryReferences.push(libraryGuid);
    }
  }
}

function clipboardDataToMap(event: ClipboardEvent): Map<string, string> {
  const itemMap = new Map<string, string>();
  if (event.clipboardData) {
    for (let index = 0; index < event.clipboardData.items.length; index++) {
      const item = event.clipboardData.items[index];
      const type = item.type;
      itemMap.set(type, event.clipboardData.getData(type));
    }
  }

  return itemMap;
}

function transformPastedHTML(html: string): Document | null {
  const parser = new HTML2ProsemirrorParser();
  // the parser parses all linebreaks within the HTML even between tags,
  // resulting in unwanted text nodes containing these linebreaks.
  // Linebreaks within HTML in context of the Patent-Engine are <br> tags, everything else should be replaced.
  // 1. There is a line break between some words. Replace one line break with space " " after the word.
  // 2. Remove all remaining additional linebreaks.
  const htmlWithRemovedLinebreaks = html
    // Catches latin alphabet characters as well as some special like "aeiouçéüß".
    // For more info see: https://stackoverflow.com/questions/22017723/regex-for-umlaut
    .replaceAll(/([\u00C0-\u017Fa-zA-Z'])[\r\n]/g, '$1 ')
    // Catches non-alphabetical characters that might come at the end of a word.
    .replaceAll(/([,.!?\\;:-])[\r\n]/g, '$1 ')

    .replaceAll(/[\r\n]/g, '');

  const document = parser.parseHTML(htmlWithRemovedLinebreaks);
  if (document) {
    document.body.innerHTML = document.body.innerHTML.replaceAll(REG_EXP_SANITIZE_PASTED_TEXT, "");
    return document;
  }
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function transformPastedText(text: string, plain: boolean): string {
  return text.replaceAll(REG_EXP_SANITIZE_PASTED_TEXT, "");
}

function plainTextToSlice(text: string, schema: Schema): Slice {
  const parser = PmDOMParser.fromSchema(schema);

  // Overwrite default prose mirror text parsing. Prosemirror creates a <p> elements for newlines.
  // We don't want that on copy & paste as our richttext only contains <br>, according to the hardbreak-plugin
  const element = document.createElement("div");
  const splittedText = text.split(/(?:\r\n?|\n)/);
  for (let i = 0; i < splittedText.length; i++) {
    const block = splittedText[i];
    if (block.length > 0) {
      element.appendChild(document.createTextNode(block));
    }
    if (i !== splittedText.length - 1) {
      element.appendChild(document.createElement("br"));
    }
  }
  return parser.parseSlice(element, {preserveWhitespace: true});
}

function removeWhitespace(node: Node) {
  for (let i = node.childNodes.length; i-- > 0;) {
    const child = node.childNodes[i];
    // Seems that MS Word adds only "&nbsp;" white space, so we use the unicode representation of the symbol.
    // Note: This will exclude normal " " white space! 
    if (child.nodeType === 3 && (child as Text).data.match(/^\u00A0*$/)) {
      node.removeChild(child);
    }
    if (child.nodeType === 1) {
      removeWhitespace(child);
    }
  }
}

function htmlToSlice(html: Document | null, schema: Schema): Slice {
  if (html === null) {
    return Slice.empty;
  }
  const parser = PmDOMParser.fromSchema(schema);
  // remove empty whitespace nodes from the document - microsoft word inserts them en masse when copying to the clipboard
  // and consequently we need to filter them out somewhere. We do it here as a quick and dirty fix.
  removeWhitespace(html.body);
  return parser.parseSlice(html, {preserveWhitespace: true});
}


function sliceFromClipboard(clipboardData: Map<string, string>, view: PmEditorView) {
  const htmlKey = 'text/html';
  const plainTextKey = 'text/plain';

  const htmlPasted = clipboardData.has(htmlKey);
  const plainTextPasted = clipboardData.has(plainTextKey);

  let slice: Slice | null = null;
  if (htmlPasted) {
    const html = clipboardData.get(htmlKey)!;
    const transformedHtml = transformPastedHTML(html);
    slice = htmlToSlice(transformedHtml, view.state.schema)
  } else if (plainTextPasted) {
    const plainText = clipboardData.get(plainTextKey)!;
    const transformedPlainText = transformPastedText(plainText, true);
    slice = plainTextToSlice(transformedPlainText, view.state.schema);
    addLibraryReferencesIfAvailable(view, plainText);
  } else {
    throw new Error('Could not handle paste content');
  }
  return slice;
}

export const handlePaste = (view: PmEditorView, event: ClipboardEvent): boolean => {

  if (!(document.activeElement === view.dom)){
    return true;
  }

  const clipboardData = clipboardDataToMap(event);

  // first find the position where we want to insert our text.
  // Given following text blocks with content:
  // ABC DEF GHJK LMN
  // and the selection going from B to M (both inclusive)
  // we want to paste our text in the first block ABC replacing BC with the new content.
  // The blocks containing DEF and GHJK will be emptied of text
  // and from block LMN we will replace LM with empty text.
  // So we first need to find the absolute position from B to C (inclusive) within the document
  const from = view.state.selection.$from;
  const to = view.state.selection.$to;

  const fromDepth = findLowestDepth(from, (node => node.type.name === 'textBlockNode'))
  const toDepth = findLowestDepth(to, (node => node.type.name === 'textBlockNode'));

  let insertRangeFrom = -1;
  let insertRangeTo = -1;
  if (fromDepth !== -1 && toDepth === -1) {
    insertRangeFrom = from.pos;
    insertRangeTo = from.end(fromDepth);
  } else if (fromDepth !== -1 && toDepth !== -1) {
    const fromNode = from.node(fromDepth);
    const toNode = to.node(toDepth);
    if (fromNode.attrs.guid === toNode.attrs.guid) {
      insertRangeFrom = from.pos;
      insertRangeTo = to.pos;
    } else {
      insertRangeFrom = from.pos;
      insertRangeTo = from.end(fromDepth);
    }
  }

  const pasteTarget = from.node(fromDepth);
  const trailingNode = to.node(toDepth);

  // extract a slice we can paste from the clipboard
  const slice = sliceFromClipboard(clipboardData, view);

  const transaction = view.state.tr;

  const spaceFragment = Fragment.from(view.state.schema.text(' '));
  const emptyText = new Slice(spaceFragment, 0, 0);

  let insertContent = slice;

  if (pasteTarget.attrs.guid === trailingNode.attrs.guid) {
    if (
      pasteTarget.attrs.maxLength &&
      slice.content.size + pasteTarget.content.size > pasteTarget.attrs.maxLength
    ) {
      const sliceLength = pasteTarget.attrs.maxLength - pasteTarget.content.size;
      insertContent = new Slice(slice.content.cut(0, sliceLength), 0, 0);
    }

    // only the selection is only within one text block, replace its content
    transaction.replace(insertRangeFrom, insertRangeTo, insertContent);
    // set the cursor right after the inserted text
    transaction.setSelection(
      TextSelection.create(transaction.doc, insertRangeFrom + insertContent.size, insertRangeFrom + insertContent.size));
    EditorModule.addChange(pasteTarget.attrs.guid);
  } else {
    if (pasteTarget.attrs.maxLength && (slice.content.size > pasteTarget.attrs.maxLength)) {
      const textLeftInBlock = pasteTarget.content.size - (insertRangeTo - insertRangeFrom);
      const sliceLength = pasteTarget.attrs.maxLength - textLeftInBlock;
      insertContent = new Slice(slice.content.cut(0, sliceLength), 0, 0);
    }
    // compile a list of textBlockNodes that must be emptied during the paste
    const nodes: PmNode[] = [];
    transaction.doc.nodesBetween(from.pos, to.pos, (node: PmNode, pos: number, parent: PmNode | null, index: number) => {
      if (node.type.name === 'textBlockNode'
        && (node.attrs.guid !== pasteTarget.attrs.guid && node.attrs.guid !== trailingNode.attrs.guid)) {
        nodes.push(node);
      }
      return true;
    })

    if (pasteTarget.attrs.isReadOnly === false) {
      // replace text in the first selected text block
      transaction.replace(insertRangeFrom, insertRangeTo, insertContent);
      EditorModule.addChange(pasteTarget.attrs.guid);
    }

    transaction.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, ProsemirrorTransactionMeta.PASTE);

    // now empty all blocks between the first selected text block and the last selected text block
    for (const nodeToEmpty of nodes) {
      const range = findNodeRangeOfGuid(transaction.doc, nodeToEmpty.attrs.guid);
      if (range && range.node.attrs.isReadOnly === false) {
        // replace the content of each text block in between with emtpy text
        transaction.replace(range.start + 1, range.end - 1, emptyText);
        EditorModule.addChange(range.node.attrs.guid);
      }
    }

    if (trailingNode.attrs.isReadOnly === false) {
      // replace text in the last selected text block
      const newStart = transaction.mapping.map(to.start(toDepth));
      const newPos = transaction.mapping.map(to.pos);
      transaction.replace(newStart, newPos, emptyText);
      EditorModule.addChange(trailingNode.attrs.guid);
    }

    // calculate new end position and set cursor
    const newToPos = transaction.mapping.map(to.pos);
    transaction.setSelection(TextSelection.create(transaction.doc, newToPos));
  }
  transaction.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
  view.dispatch(transaction);
  event.preventDefault();
  return true;
}