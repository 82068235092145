<template>
  <div id="zoomWrapper" ref="zoomWrapper">
    <div id="zoomTarget" ref="zoomTarget">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import EditorModule from '@/store/modules/EditorModule';
import ResizeObserver from 'resize-observer-polyfill';
import {debounce} from 'lodash';

const ZOOM_SPEED = 0.05;
const ZOOM_MAX_SCALE = 4;

@Component
class ScrollZoom extends Vue {
  @Ref('zoomWrapper') private zoomWrapper!: HTMLElement;
  @Ref('zoomTarget') private zoomTarget!: HTMLElement;

  private editorResizeObserver!: ResizeObserver;
  private container!: HTMLElement;
  private editor!: HTMLElement;
  private editorSize = {w: 0, h: 0};
  private currentZoomLevel = 1.0;
  private mouseX: number = 0;
  private mouseY: number = 0;

  mounted() {
    this.container = this.zoomWrapper.parentElement!;
    this.editor = this.container.querySelector(".editor") as HTMLElement;

    this.editorResizeObserver = new ResizeObserver(debounce(this.initZoomWrapperSize, 100));
    this.editorResizeObserver.observe(this.editor);

    this.container.addEventListener("wheel", this.onScrolled.bind(this));
    this.container.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.container.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  unmounted() {
    this.container.removeEventListener("wheel", this.onScrolled.bind(this));
    this.container.removeEventListener('mousemove', this.onMouseMove.bind(this));
    this.container.removeEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  get zoomLevel() {
    return EditorModule.zoomLevel;
  }

  @Watch("zoomLevel")
  zoomLevelUpdated(zoomLevel: number) {
    this.applyZoomLevel(zoomLevel, true);
  }

  private initZoomWrapperSize() {
    if (!this.zoomWrapper) {
      return;
    }
    const editorSizeNew = {w: this.editor.offsetWidth, h: this.editor.offsetHeight};

    if (this.currentZoomLevel !== EditorModule.zoomLevel
      || editorSizeNew.w !== this.editorSize.w
      || editorSizeNew.h !== this.editorSize.h) {
      this.editorSize = editorSizeNew;
      this.applyZoomLevel(EditorModule.zoomLevel);
    }
  }

  private onScrolled(e: WheelEvent) {
    if (!e.ctrlKey) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    let delta = e.deltaY || -e.detail;
    delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

    let zoomLevel = EditorModule.zoomLevel - (delta * ZOOM_SPEED);
    zoomLevel = Math.max(0.5, Math.min(ZOOM_MAX_SCALE, zoomLevel));

    EditorModule.setZoomLevel(zoomLevel);
  }

  private applyZoomLevel(zoomLevel: number, scroll = false) {
    // Calculate the current scroll position and dimensions
    const scrollLeft = this.container.scrollLeft;
    const scrollTop = this.container.scrollTop;
    const wrapperWidth = this.zoomWrapper.getBoundingClientRect().width;
    const wrapperHeight = this.zoomWrapper.getBoundingClientRect().height;

    this.zoomWrapper.style.width = `${this.editorSize.w * zoomLevel}px`;
    this.zoomWrapper.style.height = `${this.editorSize.h * zoomLevel}px`;

    if (scroll) {
      const scrollPercentageX = wrapperWidth > 0 ? (scrollLeft + this.mouseX) / wrapperWidth : 0;
      const scrollPercentageY = wrapperHeight > 0 ? (scrollTop + this.mouseY) / wrapperHeight : 0;

      // Calculate new scroll positions to keep the mouse position constant
      const newScrollLeft = scrollPercentageX * this.editorSize.w * zoomLevel - this.mouseX;
      const newScrollTop = scrollPercentageY * this.editorSize.h * zoomLevel - this.mouseY;

      this.container.scroll({left: newScrollLeft, top: newScrollTop, behavior: 'instant'});
    }
    this.zoomTarget.style.transform = `scale(${zoomLevel})`;

    this.currentZoomLevel = zoomLevel;
  }

  private onMouseMove(event: MouseEvent) {
    const containerRect = this.container.getBoundingClientRect();
    this.mouseX = event.clientX - containerRect.left;
    this.mouseY = event.clientY - containerRect.top;
  }

  private onMouseLeave() {
    const containerRect = this.container.getBoundingClientRect();
    this.mouseX = containerRect.width / 2;
    this.mouseY = containerRect.height / 2;
  }
}

export default toNative(ScrollZoom);
</script>

<style lang="scss" scoped>

#zoomWrapper {
  margin: 30px auto;
  position: relative;
  width: fit-content;
  height: fit-content;

  #zoomTarget {
    transform-origin: top left;
    width: fit-content;
    height: fit-content;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

</style>