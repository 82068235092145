import {axiosInstance} from '@/api';
import {ReferenceSignMarker} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';
import {
  CreateReferenceSignMarkerEvent,
  CreateReferenceSignMarkersEvent,
  ReferenceSignMarkerCreatedVmUpdate,
  ReferenceSignMarkersCreatedVmUpdate, ReferenceSignMarkerUpdatedVmUpdate, UpdateReferenceSignMarkerEvent
} from '@/api/models/referenceSignMarker.model';

const REFERENCE_SIGN_MARKER_PATH = '/referencesignmarker';

export const CreateReferenceSignMarkers = async (events: CreateReferenceSignMarkersEvent): Promise<ReferenceSignMarker[]> => {
  const res = await axiosInstance.post(REFERENCE_SIGN_MARKER_PATH, events);
  return (res?.data as ReferenceSignMarkersCreatedVmUpdate).referenceSignMarkers;
};

export const CreateReferenceSignMarker = async (event: CreateReferenceSignMarkerEvent): Promise<ReferenceSignMarker> => {
  const res = await axiosInstance.post(REFERENCE_SIGN_MARKER_PATH, event);
  return (res?.data as ReferenceSignMarkerCreatedVmUpdate).referenceSignMarker;
};

export const UpdateReferenceSignMarker = async (event: UpdateReferenceSignMarkerEvent): Promise<ReferenceSignMarker> => {
  const res = await axiosInstance.put(REFERENCE_SIGN_MARKER_PATH, event);
  return (res?.data as ReferenceSignMarkerUpdatedVmUpdate).referenceSignMarker;
};