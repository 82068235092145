import {
  SpellcheckCategoryID,
  SpellcheckContext,
  SpellcheckMatch,
  SpellcheckParams,
  SpellcheckReplacement,
  SpellcheckRule
} from '@/api/models/spellcheck.model';
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {EditorState, Transaction} from '@tiptap/pm/state';
import {findNodeRangeOfGuid, isNodeContentIdentical, NodeRange} from '@/components/applicationEditor/utils/node.util';
import i18n from '@/i18n';

/**
 * This object acts as a wrapper around match and params, providing custom getters.
 */
export class SpellingMistake {

  constructor(params: SpellcheckParams, match: SpellcheckMatch) {
    this._logicalBlockGuid = params.logicalBlockGuid;
    this._nodePosOffset = params.nodePosOffset;
    this._match = match;
  }

  private readonly _logicalBlockGuid: string;
  private readonly _nodePosOffset: number;
  private readonly _match: SpellcheckMatch; // Keep a reference on the original match
  private _word?: string; // The original word, calculcated on demand

  get logicalBlockGuid(): string {
    return this._logicalBlockGuid;
  }

  get nodePosOffset(): number {
    return this._nodePosOffset;
  }

  get match(): SpellcheckMatch {
    return this._match;
  }

  get type(): SpellcheckCategoryID {
    return this.match.rule.category.id;
  }

  get replacements(): string[] {
    return this.match.replacements.map((replacement: SpellcheckReplacement) => replacement.value);
  }

  get message(): string {
    return this.match.message;
  }

  get shortMessage(): string {
    // If there was a title (shortMessage) delivered from the spellcheck server: use it
    if (this.match.shortMessage) {
      return this.match.shortMessage;
    }
    // Else use the category name
    if (this.match.rule.category.name) {
      return this.match.rule.category.name;
    }
    // Else we just print "unknown mistake"
    return i18n.global.t('spellcheck.category.unknown') as string;
  }

  get url(): string {
    return this.match.rule.urls?.map((url) => url.value)[0] || '';
  }

  get rule(): SpellcheckRule {
    return this.match.rule;
  }

  get offset(): number {
    return this.match.offset;
  }

  get length(): number {
    return this.match.length;
  }

  get context(): SpellcheckContext {
    return this.match.context;
  }

  get word(): string {
    if (this._word == undefined) {
      this._word = this.calculateWord();
    }
    return this._word;
  }

  private calculateWord(): string {
    return this.match.context.text.substr(this.match.context.offset, this.match.context.length)
      .replaceAll(/  +/g, ' ');
  }
}

/**
 * Immutable plugin state.
 * All modifying methods return a new instance of the state.
 */
export class PatentengineSpellcheckState {
  private readonly _editorId: string;
  private readonly _decorationSet: DecorationSet;

  constructor(editorId: string, decorationSet: DecorationSet = DecorationSet.empty) {
    this._editorId = editorId;
    this._decorationSet = decorationSet;
  }

  get editorId(): string {
    return this._editorId;
  }

  /**
   * Accessor for DecorationSet
   */
  get decorationSet(): DecorationSet {
    return this._decorationSet;
  }

  /**
   * Find decoration (containing a SpellingMistake) at a specific position.
   */
  public findDecoration(pos: number): Decoration | undefined {
    return this._decorationSet.find(pos, pos)
      .map(decoration => decoration as Decoration)
      .shift();
  }

  /**
   * Clear decorations.
   */
  public clear(): PatentengineSpellcheckState {
    if (this._decorationSet !== DecorationSet.empty) {
      return new PatentengineSpellcheckState(this._editorId);
    }
    return this;
  }

  /**
   * Update the decorations according to the document changes in the transaction.
   *
   * Special case:
   * If some decorations are removed by the map() function, we check if the affected logical blocks are identical in the
   * old and new state. If that's the case these decorations are restored.
   * This is important if an update from the backend (e.g. save block) replaces a text block with an identical content. The map()
   * function then removes the related decorations, but we would like to keep them!
   */
  public mapDecorations(tr: Transaction, oldState: EditorState, newState: EditorState): PatentengineSpellcheckState {
    const removedMistakes: SpellingMistake[] = [];

    let newDecorationsSet = this.decorationSet.map(tr.mapping, newState.doc, {
      onRemove: (decorationSpec) => removedMistakes.push(decorationSpec as SpellingMistake)
    });

    const mistakesToRestore = this.calculateMistakesToRestore(oldState, newState, removedMistakes);

    if (mistakesToRestore.size) {
      // we find the decorations from the original decorationSet by spec reference (i.e. mistake)
      const decorationsToRestore = this.decorationSet.find(undefined, undefined, mistake =>
        mistakesToRestore.has(mistake as SpellingMistake));
      newDecorationsSet = newDecorationsSet.add(newState.doc, decorationsToRestore);
    }

    return new PatentengineSpellcheckState(this._editorId, newDecorationsSet);
  }

  private calculateMistakesToRestore(oldState: EditorState, newState: EditorState, removedMistakes: SpellingMistake[]): Set<SpellingMistake> {
    const affectedBlockGuids: string[] = removedMistakes
      .map(mistake => mistake.logicalBlockGuid);

    const identicalBlockGuids = [...new Set(affectedBlockGuids)]
      .filter(guid => isNodeContentIdentical(oldState, newState, guid));

    const mistakesToRestore = removedMistakes
      .filter(mistake => identicalBlockGuids.includes(mistake.logicalBlockGuid));
    return new Set(mistakesToRestore);
  }

  /**
   * Add a decoration for a new SpellingMistake.
   */
  public add(state: EditorState, mistake: SpellingMistake): PatentengineSpellcheckState {
    const newDecoration = this.createDecoration(state, mistake);
    if (newDecoration) {
      return new PatentengineSpellcheckState(this._editorId, this._decorationSet.add(state.doc, [newDecoration]));
    }
    return this;
  }

  /**
   * Remove the decoration for a SpellingMistake.
   */
  public remove(mistake: SpellingMistake): PatentengineSpellcheckState {
    const decorationsToRemove = this._decorationSet.find(undefined, undefined,
                                                         spec => spec === mistake);
    return new PatentengineSpellcheckState(this._editorId, this._decorationSet.remove(decorationsToRemove));
  }

  /**
   * Build a new state with the given SpellingMistakes.
   * All decorations are reused if they refer to the identical SpellingMistake.
   */
  public replaceAll(state: EditorState, mistakes: SpellingMistake[]): PatentengineSpellcheckState {
    const newDecorations: Decoration[] = [];
    const reusableDecorations = new Map<SpellingMistake, Decoration>();
    const nodeRangeMap: Map<string, NodeRange | null> = new Map();

    this._decorationSet.find()
      .forEach(decoration => reusableDecorations.set(decoration.spec as SpellingMistake, decoration));

    mistakes.forEach(mistake => {
      const reusableDecoration = reusableDecorations.get(mistake);
      if (reusableDecoration) {
        newDecorations.push(reusableDecoration);
      } else {
        const guid = mistake.logicalBlockGuid;
        let nodeRange = nodeRangeMap.get(guid);
        if (nodeRange == undefined) {
          nodeRange = findNodeRangeOfGuid(state.doc, guid);
          nodeRangeMap.set(guid, nodeRange);
        }
        if (nodeRange) {
          newDecorations.push(this.createDecorationInternal(mistake, nodeRange));
        }
      }
    });

    return new PatentengineSpellcheckState(this._editorId, DecorationSet.create(state.doc, newDecorations));
  }

  private createDecoration(state: EditorState, mistake: SpellingMistake): Decoration | undefined {
    const nodeRange = findNodeRangeOfGuid(state.doc, mistake.logicalBlockGuid);
    if (nodeRange) {
      return this.createDecorationInternal(mistake, nodeRange);
    }
  }

  private createDecorationInternal(mistake: SpellingMistake, nodeRange: NodeRange): Decoration {
    const startPosition = nodeRange.start + mistake.nodePosOffset + mistake.offset;
    const endPosition = startPosition + mistake.length;
    return Decoration.inline(startPosition, endPosition, {
      class: 'spelling-error ' + this.getMistakeClass(mistake)
    }, mistake);
  }

  private getMistakeClass(mistake: SpellingMistake): string {
    if (!mistake) {
      return '';
    }
    return mistake.type.toLowerCase().replace('_', '-');
  }

  changeEditor(newActiveEditor: string) {
    return new PatentengineSpellcheckState(newActiveEditor, this._decorationSet);
  }
}

export interface Range {
  start: number;
  end: number;
}
