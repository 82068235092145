import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {
  Brick,
  BrickType,
  CreateBrickEvent,
  CreateTermDefinitionEvent,
  LibraryEntity,
  TermDefinition,
  UpdateBrickEvent,
  UpdateTermDefinitionEvent
} from '@/api/models/library.model';
import {
  CreateBrick,
  CreateTermDefinition,
  DeleteBrick,
  DeleteTermDefinition,
  SearchBricks,
  SearchTermDefinitions,
  UpdateBrick,
  UpdateTermDefinition
} from '@/api/services/library.api';
import {LibraryState} from '@/store/models/library.model';

@Module({dynamic: true, namespaced: true, store, name: 'library'})
class LibraryModule extends VuexModule implements LibraryState {

  // Initial state
  private _isLoading = false;
  private _deletingLibraryEntities: string[] = [];
  private _bricks: Brick[] = [];
  private _termDefinitions: TermDefinition[] = [];
  private _libraryListSorting = "";
  private _libraryListSortingDirection = "";

  get isLoading(): boolean {
    return this._isLoading;
  }

  get deletingLibraryEntities(): string[] {
    return this._deletingLibraryEntities;
  }

  get bricks(): Brick[] {
    return this._bricks;
  }

  get termDefinitions(): TermDefinition[] {
    return this._termDefinitions;
  }

  get libraryListSorting(): string {
    return this._libraryListSorting;
  }

  get libraryListSortingDirection(): string {
    return this._libraryListSortingDirection;
  }

  // Called after loading or updating a library entity to replace the old one in the list or add it if it didn't exist yet
  static replaceOrPushLibraryEntity(libraryEntities: LibraryEntity[], libraryEntity: LibraryEntity | null) {
    if (libraryEntity) {
      const index = libraryEntities.findIndex((oldLibraryEntity) => oldLibraryEntity.guid === libraryEntity.guid);
      if (index === -1) {
        libraryEntities.push(libraryEntity);
      } else {
        libraryEntities[index] = libraryEntity;
      }
    }
  }

  @Mutation
  private setLibraryLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  private setLibraryEntityDeleting(libraryEntity: LibraryEntity): void {
    this._deletingLibraryEntities.push(libraryEntity.guid as string);
  }

  @Mutation
  private fetchBrickListEnd(bricks: Brick[] | null) {
    if (bricks) {
      this._bricks = bricks;
    }
    this._isLoading = false;
  }

  @Mutation
  setLibraryListSorting(sorting: string) {
    this._libraryListSorting = sorting;
  }

  @Mutation
  setLibraryListSortingDirection(direction: string) {
    this._libraryListSortingDirection = direction;
  }

  @Mutation
  private fetchTermDefinitionListEnd(termDefinitions: TermDefinition[] | null) {
    if (termDefinitions) {
      this._termDefinitions = termDefinitions;
    }
    this._isLoading = false;
  }

  @Mutation
  private createUpdateBrickEnd(newUpdatedBrick: Brick | null) {
    if (newUpdatedBrick) {
      const brickOriginal = this._bricks.find((brickToTest) => brickToTest.guid === newUpdatedBrick.guid);
      if (brickOriginal) {
        brickOriginal.name = newUpdatedBrick.name;
        brickOriginal.keywords = newUpdatedBrick.keywords;
        brickOriginal.text = newUpdatedBrick.text;
      } else {
        this._bricks.push(newUpdatedBrick);
      }
    }
    this._isLoading = false;
  }

  @Mutation
  private createUpdateTermDefinitionEnd(updatedTermDefinition: TermDefinition | null) {
    if (updatedTermDefinition) {
      const termDefinitionOriginal = this._termDefinitions.find((tdToTest) => tdToTest.guid === updatedTermDefinition.guid);
      if (termDefinitionOriginal) {
        termDefinitionOriginal.name = updatedTermDefinition.name;
        termDefinitionOriginal.keywords = updatedTermDefinition.keywords;
        termDefinitionOriginal.text = updatedTermDefinition.text;
        termDefinitionOriginal.locale = updatedTermDefinition.locale;
      } else {
        this._termDefinitions.push(updatedTermDefinition);
      }
    }
    this._isLoading = false;
  }

  @Mutation
  private deleteBrickEnd(brick: Brick | null) {
    if (brick) {
      const brickOriginal = this._bricks.find((brickToTest) => brickToTest.guid === brick.guid);
      if (brickOriginal) {
        this._bricks.splice(this._bricks.indexOf(brickOriginal), 1);
      }
      const deletionIndex = this._deletingLibraryEntities.indexOf(brick.guid as string);
      if (deletionIndex > -1) {
        this._deletingLibraryEntities.splice(deletionIndex, 1);
      }
    }
    this._isLoading = false;
  }

  @Mutation
  private deleteTermDefinitionEnd(termDefinition: TermDefinition | null) {
    if (termDefinition) {
      const termDefinitionOriginal = this._termDefinitions.find((tdToTest) => tdToTest.guid === termDefinition.guid);
      if (termDefinitionOriginal) {
        this._termDefinitions.splice(this._termDefinitions.indexOf(termDefinitionOriginal), 1);
      }
      const deletionIndex = this._deletingLibraryEntities.indexOf(termDefinition.guid as string);
      if (deletionIndex > -1) {
        this._deletingLibraryEntities.splice(deletionIndex, 1);
      }
    }
    this._isLoading = false;
  }

  @Action
  async searchBricks(params: {brickType: BrickType; searchTerms?: string[]}): Promise<void> {
    this.setLibraryLoading();
    return SearchBricks(params.brickType, params.searchTerms).then((bricks) => {
      this.fetchBrickListEnd(bricks);
    }).catch((error) => {
      this.fetchBrickListEnd(null);
      throw error;
    });
  }

  @Action
  async searchTermDefinitions(searchTerms?: string[]): Promise<void> {
    this.setLibraryLoading();
    return SearchTermDefinitions(searchTerms).then((termDefinitions) => {
      this.fetchTermDefinitionListEnd(termDefinitions);
    }).catch((error) => {
      this.fetchTermDefinitionListEnd(null);
      throw error;
    });
  }

  @Action
  async createBrick(createBrickEvent: CreateBrickEvent): Promise<Brick> {
    this.setLibraryLoading();
    return CreateBrick(createBrickEvent).then((result: Brick) => {
      this.createUpdateBrickEnd(result);
      return result;
    }).catch((error) => {
      this.createUpdateBrickEnd(null);
      throw error;
    });
  }

  @Action
  async createTermDefinition(createTermDefinitionEvent: CreateTermDefinitionEvent): Promise<TermDefinition> {
    this.setLibraryLoading();
    return CreateTermDefinition(createTermDefinitionEvent).then((result: TermDefinition) => {
      this.createUpdateTermDefinitionEnd(result);
      return result;
    }).catch((error) => {
      this.createUpdateTermDefinitionEnd(null);
      throw error;
    });
  }

  @Action
  async updateBrick(updateBrickEvent: UpdateBrickEvent): Promise<Brick> {
    this.setLibraryLoading();
    return UpdateBrick(updateBrickEvent).then((result: Brick) => {
      this.createUpdateBrickEnd(result);
      return result;
    }).catch((error) => {
      this.createUpdateBrickEnd(null);
      throw error;
    });
  }

  @Action
  async updateTermDefinition(updateTermDefinitionEvent: UpdateTermDefinitionEvent): Promise<TermDefinition> {
    this.setLibraryLoading();
    return UpdateTermDefinition(updateTermDefinitionEvent).then((result: TermDefinition) => {
      this.createUpdateTermDefinitionEnd(result);
      return result;
    }).catch((error) => {
      this.createUpdateTermDefinitionEnd(null);
      throw error;
    });
  }

  @Action
  async deleteBrick(brick: Brick): Promise<void> {
    this.setLibraryLoading();
    this.setLibraryEntityDeleting(brick);
    return DeleteBrick(brick.guid).then(() => {
      this.deleteBrickEnd(brick);
    }).catch((error) => {
      this.deleteBrickEnd(null);
      throw error;
    });
  }

  @Action
  async deleteTermDefinition(termDefinition: TermDefinition): Promise<void> {
    this.setLibraryLoading();
    this.setLibraryEntityDeleting(termDefinition);
    return DeleteTermDefinition(termDefinition.guid).then(() => {
      this.deleteTermDefinitionEnd(termDefinition);
    }).catch((error) => {
      this.deleteTermDefinitionEnd(null);
      throw error;
    });
  }
}

export default getModule(LibraryModule);
