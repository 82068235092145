import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {Llm} from '@/api/models/llm.model';
import {GetAllLlms} from '@/api/services/aiAssisstant.api';
import {LlmState} from '@/store/models/llm.model';

@Module({dynamic: true, namespaced: true, store, name: 'llm'})
class LlmModule extends VuexModule implements LlmState {

  // initial state
  private _isLoading = false;
  private _llms: Llm[] = [];
  private _selectedLlm: Llm | null  = null;

  get selectedLlm(): Llm | null {
    return this._selectedLlm;
  }

  @Mutation
  public setSelectedLlm(value: Llm | null) {
    this._selectedLlm = value
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  get llms(): Llm[] {
    return this._llms;
  }

  @Mutation
  private setLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  private getLlmsEnd(results: Array<Llm> | null) {
    if (results != null) {
      this._llms = results;
    }
    this._isLoading = false;
  }


  @Action
  async getLlms(): Promise<Array<Llm>> {
    this.setLoading();
    return GetAllLlms().then((results: Array<Llm>) => {
      this.getLlmsEnd(results);
      return results;
    }).catch((error) => {
      this.getLlmsEnd(null);
      throw error;
    });
  }

}

export default getModule(LlmModule);