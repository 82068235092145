import {Extension} from '@tiptap/vue-3';
import {extensions} from '@tiptap/core';

export const DesiredCoreCommandsExtension = () => {

  const desiredCoreExtensions = [extensions.Commands, extensions.FocusEvents];

  return Extension.create({
                            name: 'desiredCoreCommands',

                            addExtensions() {
                              return desiredCoreExtensions;
                            },
                          });
}