import {axiosInstance} from '@/api';
import {
  CreateSymbolsEvent,
  SymbolDeletedVmUpdate,
  SymbolsCreatedVmUpdate,
  SymbolUpdatedVmUpdate,
  UpdateSymbolEvent
} from '@/api/models/figureSymbol.model';
import {FigureSymbol} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';

const SYMBOL_PATH = '/symbol';

export const CreateSymbols = async (events: CreateSymbolsEvent): Promise<FigureSymbol[]> => {
  const res = await axiosInstance.post(SYMBOL_PATH, events);
  return (res?.data as SymbolsCreatedVmUpdate).symbols;
};

export const UpdateSymbol = async (event: UpdateSymbolEvent): Promise<FigureSymbol> => {
  const res = await axiosInstance.put(SYMBOL_PATH, event);
  return (res?.data as SymbolUpdatedVmUpdate).symbol;
};

export const DeleteSymbol = async (guid: string): Promise<SymbolDeletedVmUpdate> => {
  const res = await axiosInstance.delete(`${SYMBOL_PATH}/${guid}`);
  return res?.data as SymbolDeletedVmUpdate;
};