import {ReferenceSign} from '@/api/models/referenceSign.model';
import {FigureSymbolType} from '../../../../shared/drawingbasemodule/src/api/models/drawingbase.model';

export interface ReferenceSignState {
    isLoading: boolean;
    referenceSigns: Array<ReferenceSign>;
}

export interface ReferenceSignListEntry {
    guid: string;
    edit: boolean;
    pending: boolean;
    labelExplizit?: string;
    labelResulting: string;
    name: string;
    referenceSign: ReferenceSign; // Reference to the original object
    type: FigureSymbolType;
    numberMatchesOnReferenceSign: number;
}

export enum ReferenceSignInputType {
    LABEL = 'label',
    NAME = 'name'
}

export const DEFAULT_TABLE_BOTTOM = 80;