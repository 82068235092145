<template>
  <div>
    <slot :commands="commands" :focused="focus"></slot>
  </div>
</template>


<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {Plugin, PluginKey} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';
import {NamedEditor} from '@/components/ApplicationEditor.vue';
import Menu from '@/components/header/Menu';

@Component({})
class PatentEngineMenuBar extends Vue {

  @Prop({required: true}) private editor!: NamedEditor | null;

  private focused = false;

  get commands() {
    return this.computedEditor?.commands;
  }

  get focus() {
    return this.computedEditor?.isFocused;
  }

  get computedEditor() {
    return this.editor;
  }

  private MenuBar(options: any): Plugin {
    return new Plugin(
      {
        key: new PluginKey('menu_bar2'),

        view(editorView: EditorView) {
          return new Menu({editorView, options} as any);
        }
      });
  }
}

export default toNative(PatentEngineMenuBar);
</script>


<style scoped lang="scss">

</style>