import {RouteRecordRaw} from 'vue-router';
import Worklist from '@/views/Worklist.vue';
import Application from '@/views/Application.vue';
import Figure from '@/views/Figure.vue';
import LibraryManagement from '@/views/LibraryManagement.vue';

const routes: Array<RouteRecordRaw> = [
    {path: '/', name: 'worklist', component: Worklist},
    {path: '/application/:applicationGuid', name: 'editor', component: Application, props: true},
    {path: '/application/:applicationGuid/figure/:figureGuid', name: 'figure', component: Figure, props: true},
    {path: '/library-management', name: 'library-management', component: LibraryManagement},
    {path: '/:pathMatch(.*)*', redirect: '/'}, // catch all use case
];

export default routes;
