import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import {getArrayFromDomAttribute, getBooleanFromDomAttribute} from '@/components/applicationEditor/utils/converter.util';
import StructuralBlockNodeView from '@/components/applicationEditor/nodes/baseElements/views/StructuralBlockNodeView.vue';

const notInlineableSemanticTypeList: string[] = [
  "SHORT_DESCRIPTION_FIGURE_LIST",
  "REFERENCE_SIGN_LIST"
];

const isInlineableSemanticType = (semanticType: string | null): boolean => {
  const semanticTypeEquals = (semanticTypeToCheck: string) => semanticTypeToCheck === semanticType;
  return !(notInlineableSemanticTypeList.findIndex(semanticTypeEquals) > -1);
}

/**
 * The node for a structural block (that is not set to inline).
 */
export const StructuralBlockNode = TiptapNode.create(
  {
    name: 'structuralBlockNode',
    content: '(structuralBlockNode|structuralInlineBlockNode|nonLogicalStructuralBlockNode)*|(textBlockNode|tableEntryNode|placeholderNode)+',
    group: 'block',
    isolating: true,
    defining: true,

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        header: {default: undefined},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
        logicalDepth: {default: -1},
        inlineable: {default: false},
      };
    },

    parseHTML() {
      return [
        {
          tag: 'structuralBlockNode',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              semanticType: domElement.getAttribute('semanticType'),
              header: domElement.getAttribute('header'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks')),
              inlineable: isInlineableSemanticType(domElement.getAttribute('semanticType')),
              logicalDepth: domElement.getAttribute('logicalDepth'),
            };
          }
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['structuralBlockNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(StructuralBlockNodeView);
    }

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });
