import {ComponentPublicInstance} from "vue";

/**
 * This will calculate the absolut position of a given HTML element.
 *
 * @param element HTMLElement (DOM-Node) to start with
 * @return x,y coordinates {top: number; left: number}
 */
export const cumulativeOffset = (element: HTMLElement): { top: number; left: number } => {
  let top = 0, left = 0;
  let nextElement: HTMLElement | null = element;

  do {
    top += nextElement.offsetTop || 0;
    left += nextElement.offsetLeft || 0;
    nextElement = nextElement.offsetParent as HTMLElement;
  }
  while (nextElement);

  return {
    top: top,
    left: left
  };
};

/**
 * Retrieves the reference from an upper component in the hierarchy identified by the given reference name starting from the given
 * component.
 * @param component The component to start the search from.
 * @param referenceName The reference name to search for.
 * @return The reference(s) corresponding to the reference name given as a parameter if any and undefined otherwise.
 */
export const findRefByName = <T = any>(component: ComponentPublicInstance<T>, referenceName: string) => {
  let obj: ComponentPublicInstance<T> | null = component;
  while (obj) {
    if (obj.$refs[referenceName]) {
      return obj.$refs[referenceName];
    }
    obj = obj.$parent as ComponentPublicInstance<T>;
  }
  return undefined;
}