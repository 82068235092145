import {
  AbstractBlockViewModel,
  InlineMode,
  isStructuralBlockViewModel,
  isTextBlockViewModel,
  SemanticType,
  StructuralBlockViewModel,
  TextBlockViewModel
} from '@/api/models/editor.model';
import ApplicationModule from '@/store/modules/ApplicationModule';

/**
 * A list of properties that should be ignored during conversion of block properties to XML attributes
 */
const NON_ATTRIBUTE_PROPERTIES: Array<string> = ['__ob__', 'children', 'richText', 'parent', 'properties'];

/**
 * A set of semantic types which insert a placeholder, if they do not have children
 */
export const semanticTypeWithPlaceholder = new Set<SemanticType>([
                                                            SemanticType.SHORT_DESCRIPTION_FIGURE_LIST,
                                                            SemanticType.REFERENCE_SIGN_LIST]);

/**
 * Converts all properties to XML attributes (excluding some defined non-attribute properies)
 * and returns a string ready to be used inside an XML tag.
 * @param block the block to get the attributes from
 */
function convertBlockAttributes(block: AbstractBlockViewModel): string {

  // Predicate to check if the property is a relevant attribute
  const isAttributeProperty = (property: string) => !(NON_ATTRIBUTE_PROPERTIES.includes(property));

  const objectProperties = Object.getOwnPropertyNames(block)
  const plainPropertiesAsAttribues = objectProperties.filter(isAttributeProperty)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .map((property: string) => ` ${property}="${block[property]}"`)
    .reduce((prev: string, current: string) => prev + current, '');

  if (objectProperties.includes('properties')) {
    const keyAndValues = block['properties'] as object;
    const allKeys = Object.keys(keyAndValues);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const keyAndValueMapAttributes = allKeys.map((key: string) => ` ${key}="${keyAndValues[key]}"`)
      .reduce((prev: string, current: string) => prev + current, '');
    return plainPropertiesAsAttribues + ' ' + keyAndValueMapAttributes;
  }
  return plainPropertiesAsAttribues;
}

/**
 * Determines the tag name for a given AbstractBlockViewModel. It will choose the appropriate tag by inspecting the block properties
 * @param block The block to find the given tag name for
 */
function getTagName(block: AbstractBlockViewModel): string {
  const properties = Object.getOwnPropertyNames(block);
  if (block.semanticType == "COVER_SHEET") {
    return "coverSheet";
  } else if (block.semanticType == "APPLICATION_DOCUMENT") {
    return "applicationDocument";
  } else if (properties.includes('richText')) {
    return 'textBlockNode';
  } else if (properties.includes('children')) {
    if ((properties.includes('properties') && Object.keys(block['properties']).includes('tableEntryNo'))) {
      return 'tableEntryNode';
    } else if (properties.includes('inlineMode') && ((block as StructuralBlockViewModel).inlineMode !== InlineMode.NONE)) {
      return 'structuralInlineBlockNode';
    } else if (!block.logicalBlock) {
      return 'nonLogicalStructuralBlockNode';
    } else {
      return 'structuralBlockNode';
    }
  } else {
    throw new Error("Unknown type of Block");
  }
}

/**
 * Gets the Xml start tag for a AbstractBlockViewModel. It will choose the appropriate tag and then attach the attributes provided in
 * the blocks properties map.
 * @param block The block to find the start tag for.
 */
function getStartTagFor(block: AbstractBlockViewModel): string {
  const tagname = getTagName(block);
  return `<${tagname} ${convertBlockAttributes(block)}>`;
}

/**
 * Gets the Xml end tag for a AbstractBlockViewModel analog to function getStartTagFor
 * @param block The block to find the end tag for.
 */
function getEndTagFor(block: AbstractBlockViewModel): string {
  const tagname = getTagName(block);
  return `</${tagname}>`;
}

/**
 * Converts an instance of BlockViewModel (including it's children) to XML that can be used for ProseMirror.
 * @param block the block to convert to XML
 */
function convertBlockToProseMirrorXml(block: AbstractBlockViewModel | null): string {
  let proseMirrorXml = '';
  if (block) {

    // Create start tag
    proseMirrorXml = getStartTagFor(block);

    // Either add text content as CDATA or add children
    if (isTextBlockViewModel(block)) {
      const richText = (block as TextBlockViewModel).richText;
      // A node with an empty string can't be selected/filled by the user.
      proseMirrorXml += (richText.length === 0) ? ' ' : richText;
    } else if (isStructuralBlockViewModel(block)) {
      // If semantic type has no children and has declared a placeholder, insert it here

      if (semanticTypeWithPlaceholder.has(block.semanticType) && (block as StructuralBlockViewModel).children.length === 0) {
        const translationKey = ApplicationModule.templateText(`${block.semanticType}.placeholder`);
        proseMirrorXml += `<placeholderNode> ${translationKey}</placeholderNode>`;

      } else {
        proseMirrorXml = (block as StructuralBlockViewModel).children
          .map((child: AbstractBlockViewModel) => convertBlockToProseMirrorXml(child))
          .reduce((prev: string, current: string) => prev + current, proseMirrorXml);
      }
    }

    // Create end tag
    proseMirrorXml += getEndTagFor(block);
  }
  return proseMirrorXml;
}

/**
 * Converts the hole document to an XML string that can be used for ProseMirror.
 * @param rootBlock the root block of the document to convert
 */
export function toProseMirrorXml(rootBlock: AbstractBlockViewModel | null): string {
  const doc = `${convertBlockToProseMirrorXml(rootBlock)}`
  return doc;
}

/**
 * Converts a string with a list of comma seperated items, e.g. "item1,item2" to an string array.
 * For an empty/whitespace-only string or null, an empty array is returned.
 * @param attributeString the string to convert
 */
export function getArrayFromDomAttribute(attributeString: string | null): string[] {
  if (!attributeString || (attributeString.trim().length === 0)) {
    return [];
  }
  return attributeString.split(',');
}

/**
 * Converts all given strings into an string array. A string with a list of comma seperated items is splitted. Null, "null" and blank
 * strings are ignored, such that the result may be an empty array.
 * @param args the string to convert
 */
export function getArrayFromStrings(...args: (string | null)[]): string[] {
  const stringArray: string[] = [];
  args.forEach(str => {
    if (str && str !== "null" && str.trim().length > 0) {
      stringArray.push(...str.split(','));
    }
  })
  return stringArray;
}

/**
 * Converts a string to a boolean value.
 * If the (trimmed) string equals "true", then true is returned. In all other cases (e.g. parameter is null), false is returned.
 * @param attributeString the string to convert
 */
export function getBooleanFromDomAttribute(attributeString: string | null): boolean {
  return !!attributeString && (attributeString.trim() === 'true');
}
