import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-525c7482"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tree = _resolveComponent("Tree", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.node.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: `document-structure-tree-${_ctx.node.guid}`,
          class: _normalizeClass(["document-structure-node", _ctx.node.active ? 'document-structure-node-selected' : '']),
          style: _normalizeStyle(_ctx.indent),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleChildren && _ctx.toggleChildren(...args)))
        }, [
          (_ctx.node.hasChildren())
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass('exi exi-tree-node-' + (_ctx.node.expanded ? 'expanded' : 'collapsed'))
              }, null, 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["inner-node-container", _ctx.node.isEmpty ? 'emptyNode' : ''])
          }, _toDisplayString(_ctx.getName()), 3)
        ], 14, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.node.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.node.children, (child) => {
            return (_openBlock(), _createBlock(_component_Tree, {
              key: child.guid,
              node: child,
              depth: _ctx.depth + 1,
              onOnClick: _cache[1] || (_cache[1] = (node) => _ctx.emitOnClick(node))
            }, null, 8, ["node", "depth"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}