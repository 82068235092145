import {DirectiveBinding, ObjectDirective} from 'vue';

interface MouseMarginDirectiveBinding {
  onMouseLeave: () => void;
  margin: number;
  shouldCheck: () => boolean;
}

const MouseMarginDirective: ObjectDirective  = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    const { onMouseLeave, margin = 20, shouldCheck }: MouseMarginDirectiveBinding = binding.value;

    const handleMouseLeave = (event: MouseEvent) => {
      const rect = el.getBoundingClientRect();
      const inMarginArea = (
        event.clientX >= rect.left - margin &&
        event.clientX <= rect.right + margin &&
        event.clientY >= rect.top - margin &&
        event.clientY <= rect.bottom + margin
      );

      if (!inMarginArea && shouldCheck()) {
        onMouseLeave();
      }
    };

    (el as any).__vueMouseLeaveHandler__ = handleMouseLeave;
    document.addEventListener('mousemove', handleMouseLeave);
  },
  unmounted(el: HTMLElement) {
    document.removeEventListener('mousemove', (el as any).__vueMouseLeaveHandler__);
    delete (el as any).__vueMouseLeaveHandler__;
  }
};

export default MouseMarginDirective;
