import {EditorState, Plugin, PluginKey} from '@tiptap/pm/state'
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {Node as PmNode} from "@tiptap/pm/model";
import {findLogicalBlockNodeRange} from '@/components/applicationEditor/utils/node.util';

/**
 * This plugin traverses the selected logical node of the document and adds the class "show-placeholder" to every empty/blank node.
 */
export class PlaceholderPlugin extends Plugin {

  constructor() {
    super({
            key: new PluginKey("PlaceholderPlugin"),
            props: {
              decorations: (state: EditorState) => {
                return this.addPlaceholderClasses(state);
              }
            }
          });
  }

  public addPlaceholderClasses(state: EditorState) {
    const decorations: Decoration[] = [];

    // The placeholders are only visible in the selected block (with the selection anchor), so we only need to calculate and create
    // placeholders for this one logical block. We use nodesBetween() instead of descendants() to traverse only the specified range.
    const selectionRange = findLogicalBlockNodeRange(state.selection.$anchor);

    if (selectionRange) {
      state.doc.nodesBetween(selectionRange.start, selectionRange.end, (node: PmNode, pos: number) => {
        if (node.isTextblock) {
          // This textblock node (isTextblock==true) contains one or more nodes with the actual text (isText==true) and hard-breaks.
          // textContent concatenates the text from all its child nodes.
          // childCount<=1 excludes hard-breaks: in this case there are at least 2 child nodes, one with empty text, one with the hard-break.
          const isEmpty = node.childCount <= 1 && this.isBlank(node.textContent);
          if (isEmpty) {
            const decoration = Decoration.node(pos, pos + node.nodeSize, {class: "show-placeholder"});
            decorations.push(decoration);
          }
          // don't descend into child (text) nodes
          return false;
        }
      });
    }

    return DecorationSet.create(state.doc, decorations);
  }

  private isBlank(text: string) {
    return text === "" || text === " ";
  }
}
