import {mergeAttributes, Node as TiptapNode, textInputRule, VueNodeViewRenderer} from '@tiptap/vue-3';
import {
  getArrayFromDomAttribute,
  getArrayFromStrings,
  getBooleanFromDomAttribute
} from '@/components/applicationEditor/utils/converter.util';
import TextBlockNodeView from '@/components/applicationEditor/nodes/baseElements/views/TextBlockNodeView.vue';

// TODO: rhe - there is surely a way to do this one here better, i.e. using just one regexp for all.
// This requires further examination of the input rule handler function
const STARTS_WITH_SPACE_AND_DOT = /^ \.$/;
const STARTS_WITH_SPACE_AND_EXCLAMATION_MARK = /^ !$/;
const STARTS_WITH_SPACE_AND_QUESTION_MARK = /^ \?$/;
const STARTS_WITH_SPACE_AND_COMMA = /^ ,$/;
const STARTS_WITH_SPACE_AND_SEMICOLON = /^ ;$/;

/**
 * A node describing a text block, i.e. the editable content within the editor
 */
export const TextBlockNode = TiptapNode.create(
  {
    name: 'textBlockNode',
    content: 'inline*',
    group: 'block',
    defining: true,
    isolating: true, // don't remove! It keeps the structure of the document intact and prevents prosemirror from merging nodes

    addInputRules() {
      return [
        textInputRule({find: STARTS_WITH_SPACE_AND_DOT, replace: '.'}),
        textInputRule({find: STARTS_WITH_SPACE_AND_EXCLAMATION_MARK, replace: '!'}),
        textInputRule({find: STARTS_WITH_SPACE_AND_QUESTION_MARK, replace: '?'}),
        textInputRule({find: STARTS_WITH_SPACE_AND_COMMA, replace: ','}),
        textInputRule({find: STARTS_WITH_SPACE_AND_SEMICOLON, replace: ';'}),
      ]
    },

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
        isReadOnly: {default: false},
        i18nParams: {default: []},
        libraryReferences: {default: []},
        maxLength: {default: null},
        claimNo: {default: null},
        tableEntryNo: {default: null}
      };
    },

    parseHTML() {
      return [
        {
          tag: 'textBlockNode',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              semanticType: domElement.getAttribute('semanticType'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks')),
              isReadOnly: getBooleanFromDomAttribute(domElement.getAttribute("isReadonly")),
              i18nParams: getArrayFromStrings(domElement.getAttribute('claimNo')),
              libraryReferences: getArrayFromDomAttribute(domElement.getAttribute('libraryReferences')),
              maxLength: domElement.getAttribute('maxLength'),
              claimNo: domElement.getAttribute('claimNo'),
              tableEntryNo: domElement.getAttribute('tableEntryNo'),
            };
          }
        }
      ];
    },

    renderHTML({node, HTMLAttributes}) {
      return ['textBlockNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(TextBlockNodeView);
    }

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });
