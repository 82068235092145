<template>
  <div class="header-bar">

    <b-navbar>
      <template #start>

        <div v-for="button in buttons" :key="button.id">
          <span v-if="button === 'separator'" class="devider"/>
          <b-button v-else-if="!button.dropdownList" :class="button.class" :title="evaluateTooltip(button.tooltip)"
                    @click="button.func && button.func()">
            <div class="button-icon"><i :class="'exi ' + evaluateButtonIcon(button.icon)"/></div>
            <div class="button-label">{{ evaluateButtonLabel(button.label) }}</div>
          </b-button>
          <b-dropdown :mobile-modal="false" v-else aria-role="list" :close-on-click="false" :ref="'dropdown' + button.id">
            <template #trigger>
              <b-button :class="button.class" :title="evaluateTooltip(button.tooltip)" @click="button.func && button.func()">
                <div class="button-icon"><i :class="'exi ' + evaluateButtonIcon(button.icon)"/></div>
                <div class="button-label">{{ evaluateButtonLabel(button.label) }}</div>
              </b-button>
            </template>

            <b-dropdown-item v-for="dropdownItem in button.dropdownList" :key="dropdownItem.id"
                             aria-role="listitem" v-on:click="onDropdownItemClicked(dropdownItem, button.id)">
              <b-button v-if="dropdownItem.type === 'button'" :class="dropdownItem.class"
                        :title="evaluateTooltip(dropdownItem.tooltip)">
                <div class="button-icon"><i :class="'exi ' + dropdownItem.icon"/></div>
                <div class="button-label">{{ evaluateDropdownLabel(dropdownItem.label) }}</div>
              </b-button>
              <b-field class="switch-field" v-if="dropdownItem.type === 'slider'"
                       :title="dropdownItem.value ? evaluateTooltip(dropdownItem.labelOn) :
                       evaluateTooltip(dropdownItem.label)">
                <div class="switch-label">{{
                    dropdownItem.value ? evaluateDropdownLabel(dropdownItem.labelOn) :
                      evaluateDropdownLabel(dropdownItem.label)
                  }}
                </div>
                <div class="switch-slider">
                  <div class="switch-slider-flex">
                    <b-switch v-model="dropdownItem.value" :type="dropdownItem.options.type ? dropdownItem.options.type : ''"></b-switch>
                  </div>
                </div>
              </b-field>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <b-button class="pin-button icon-button" :title="$t('header.' + (barPinned ? 'un' : '') + 'pin')" @click="pin()">
              <i :class="'exi ' + (barPinned ? 'exi-chevron-up' : 'exi-pin')"></i>
            </b-button>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {HeaderButton, HeaderDropdownItem} from '@/components/header/header.model';
import {BuefyDropdown} from '@/components/common/buefy.model';

@Component({})
class HeaderBar extends Vue {

  @Prop({default: undefined}) private pinCallback!: () => void;
  @Prop({required: true}) private tabName!: string;
  @Prop({default: () => []}) private buttons!: (HeaderButton | 'separator')[];
  @Prop({default: false}) private barPinned!: boolean;
  @Prop({default: false}) private toggleIsActive!: boolean;

  pin(): void {
    if (this.pinCallback) {
      this.pinCallback();
    } else {
      console.log('pinCallback function not set!');
    }
  }

  evaluateButtonLabel(label: string | (() => string)): string {
    let labelKey = null;

    if (typeof label === 'string') {
      labelKey = label;
    }
    if (typeof label === 'function') {
      labelKey = label();
    }
    return this.$t(`header.tab.${this.tabName}.${labelKey}.label`) as string;
  }

  evaluateTooltip(tooltip: string | (() => string) | undefined): string {
    if (tooltip === undefined) {
      return '';
    }
    let tooltipKey = null;

    if (typeof tooltip === 'string') {
      tooltipKey = tooltip;
    }
    if (typeof tooltip === 'function') {
      tooltipKey = tooltip();
    }
    return this.$t(`header.tab.${this.tabName}.${tooltipKey}.tooltip`) as string;
  }

  evaluateButtonIcon(icon?: string | (() => string)): string {
    if (icon === undefined) {
      return '';
    }
    if (typeof icon === 'string') {
      return icon;
    }
    if (typeof icon === 'function') {
      return icon();
    }
    return '';
  }

  evaluateDropdownLabel(label: string): string {
    return this.$t(`header.tab.${this.tabName}.${label}.label`) as string;
  }

  onDropdownItemClicked(dropdownItem: HeaderDropdownItem, buttonId: number): void {
    if (!dropdownItem.func) {
      return;
    }
    // If it is a button, we want to close the dropdown after clicking
    if (dropdownItem.type === 'button') {
      const dropdown = (this.$refs['dropdown' + buttonId] as any[])[0] as BuefyDropdown;
      dropdown.toggle();
    }
    dropdownItem.func(dropdownItem);
  }
}

export default toNative(HeaderBar);
</script>

<style lang="scss">
@import 'src/assets/styles/constants';
@import 'src/assets/styles/colors';

.header-bar {

  &-pinned {
    .navbar {
      border-bottom-color: $pengine-grey-dark-dark;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
  }

  .navbar {
    height: $header-bar-nav-buttons-height;
    min-height: inherit;
    /*border-bottom-color: $pengine-grey-dark-dark;
    border-bottom-width: 1px;
    border-bottom-style: solid;*/
    align-items: stretch;
    display: flex;

    .navbar-brand {
      min-height: initial;
      display: none;
    }

    .navbar-menu {
      padding: 0;
      align-items: stretch;
      -webkit-box-align: stretch;
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      -webkit-box-flex: 1;

      .navbar-start {
        justify-content: flex-start;
        margin-right: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        -webkit-box-align: stretch;

        .button {
          border: none !important;
          background-color: transparent;
          padding-right: 0px;
          height: $header-bar-nav-buttons-height;

          &:hover, &:focus {
            background-color: transparent;
          }

          &:hover {
            color: $pengine-blue-dark;
          }

          span {
            display: flex;
            align-items: center;

            .exi {
              display: inline-block;
              width: 24px;
              height: 24px;
            }

            .button-icon, .button-label {
              float: left;
            }

            .button-label {
              padding-left: 8px;
              font-family: $text-font;
              font-size: 14px;
            }
          }

          &.group:after {
            content: "";
            background: $pengine-grey-dark;
            position: absolute;
            bottom: 6px;
            left: 0;
            height: 75%;
            width: 1px;
          }

        }

        .dropdown {
          .dropdown-menu {
            .dropdown-content {
              width: fit-content;

              .dropdown-item {
                height: 35px;
                // padding-right: 16px;
                padding: 0px;

                &:hover { // This also applies for non button items (eg. slider)
                  color: $pengine-blue-dark;
                }

                .button {
                  padding: 8px 16px 2px 16px;
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                }

                .switch-field {
                  padding: 8px 16px 2px 16px;

                  .switch-label {
                    padding-right: 8px;
                  }

                  .switch-slider {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    .switch-slider-flex {
                      display: flex;
                      pointer-events: none;
                    }
                  }

                  .switch {
                    margin-right: -8px;
                  }
                }
              }
            }
          }
        }
      }

      .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: stretch;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        -webkit-box-align: stretch;

        // Pin button
        .navbar-item {
          padding-top: 0px;
          padding-bottom: 0px;
          height: calc(#{$header-bar-nav-buttons-height} - 2px);
        }
      }
    }
  }

  .pin-button {
    padding-top: 0px;
    padding-bottom: 0px;
    height: $header-bar-nav-buttons-height;
    border: none;
  }

  .devider {
    display: block;
    height: 90%;
    margin-left: 12px;
    background-color: $pengine-grey;
    width: 1px;
  }
}
</style>
