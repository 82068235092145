import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {
  ApplicationState,
  ApplicationViewSplitMode,
  ApplicationViewSplitState,
  DocumentAndFiguresSplitState,
  InitialDocumentAndFiguresSplitState,
  InitialDocumentOnlySplitState,
  initialDocumentOnlySplitStateSizes,
  PaneSizes
} from '@/store/models/application.model';
import {
  ApplicationDocument,
  ApplicationDocumentUpdatedVmUpdate,
  CreateApplicationDocumentEvent,
  TemplateText,
  UpdateApplicationDocumentEvent,
  UpdateDocumentAndFiguresSplitViewState
} from '@/api/models/application.model';
import {
  CreateApplicationDocument,
  GetApplicationDocument,
  GetApplicationViewSplitState,
  PutToggleApplicationViewSplitState,
  PutUpdateDocumentAndFiguresViewSplitState,
  UpdateApplicationDocument
} from '@/api/services/application.api';
import EditorModule from '@/store/modules/EditorModule';
import {replacePlaceholders} from '@/store/util/frontend.util';
import {CloneApplicationDocument} from '@/api/services/worklist.api';
import {CloneApplicationDocumentEvent} from '@/api/models/worklist.model';

@Module({dynamic: true, namespaced: true, store, name: 'application'})
class ApplicationModule extends VuexModule implements ApplicationState {

  // initial state
  private _isLoading = false;
  private _currentApplicationDocument: ApplicationDocument | null = null; // Application Document currently loaded for the editor
  private _currentAppDocStartPos: number | null = null; // Where the text really starts
  private _templateTexts: { [key: string]: string } = {};// hashmap
  private _applicationViewSplitState: ApplicationViewSplitState = InitialDocumentOnlySplitState;
  private _paneSizes: PaneSizes = initialDocumentOnlySplitStateSizes;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get applicationViewSplitState(): ApplicationViewSplitState {
    return this._applicationViewSplitState;
  }

  public get currentApplicationDocument(): ApplicationDocument | null {
    return this._currentApplicationDocument;
  }

  public get currentAppDocStartPos(): number | null {
    return this._currentAppDocStartPos;
  }

  public get templateTexts(): { [key: string]: string } {
    return this._templateTexts;
  }

  public get paneSizes(): PaneSizes {
    return this._paneSizes;
  }

  public get templateText() {
    return (key: string, properties?: { [key: string]: string }) => {
      const templateText = this._templateTexts[key];
      if (templateText) {
        return properties ? replacePlaceholders(templateText, properties) : templateText;
      }
      return "...";
    };
  }

  public get templateTextByKey() {
    return (key: string) => {
     return this._templateTexts[key];
    };
  }

  @Mutation
  setApplicationDocumentLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  setApplicationDocumentReady(): void {
    this._isLoading = false;
  }

  @Mutation
  setPaneSizes(paneSizes: PaneSizes){
    this._paneSizes = paneSizes;
  }
  @Mutation
  public setCurrentAppDocStartPos(currentAppDocStartPos: number | null): void {
    this._currentAppDocStartPos = currentAppDocStartPos;
  }

  @Mutation
  private fetchApplicationDocumentEnd(applicationDocument: ApplicationDocument | null) {
    this._currentApplicationDocument = applicationDocument;
    this._currentAppDocStartPos = null;
    this._isLoading = false;
  }

  @Mutation
  private setTemplateTexts(templateTexts: TemplateText[]) {
    this._templateTexts = {};
    for (const templateText of templateTexts) {
      this._templateTexts[templateText.key] = templateText.textEntry;
    }
  }

  @Mutation
  private createApplicationDocumentEnd(createdApplication: ApplicationDocument | null) {
    if (createdApplication) {
      this._currentApplicationDocument = createdApplication;
      this._currentAppDocStartPos = null;
    }
    this._isLoading = false;
  }

  @Mutation
  private updateApplicationDocumentEnd(updatedApplication: ApplicationDocument | null) {
    if (updatedApplication) {
      this._currentApplicationDocument = updatedApplication;
      this._currentAppDocStartPos = null;
    }
    this._isLoading = false;
  }

  @Mutation
  private getApplicationViewSplitStateEnd(applicationViewSplitState: ApplicationViewSplitState){
    this._applicationViewSplitState = applicationViewSplitState;
  }

  @Action
  async fetchApplicationDocument(guid: string): Promise<void> {
    return GetApplicationDocument(guid).then((applicationDocument) => {
      this.setTemplateTexts(applicationDocument.templateTexts)
      this.fetchApplicationDocumentEnd(applicationDocument);
    }).catch((error: any) => {
      this.setTemplateTexts([]);
      this.fetchApplicationDocumentEnd(null)
      throw error;
    });
  }

  @Action
  async reloadApplicationDocument(): Promise<void> {
    if (this._currentApplicationDocument?.guid) {
      const guid = this._currentApplicationDocument.guid;
      this.fetchApplicationDocument(guid).catch((error) => {
        throw error;
      });
    }
  }

  @Action
  async createApplicationDocument(event: CreateApplicationDocumentEvent): Promise<string> {
    this.setApplicationDocumentLoading();
    return CreateApplicationDocument(event).then((createdApplicationDocument: ApplicationDocument) => {
      this.setTemplateTexts(createdApplicationDocument.templateTexts);
      this.createApplicationDocumentEnd(createdApplicationDocument);
      return createdApplicationDocument.guid as string;
    }).catch((error: any) => {
      this.setTemplateTexts([]);
      this.createApplicationDocumentEnd(null);
      throw error;
    });
  }

  @Action
  async updateApplicationDocument(event: UpdateApplicationDocumentEvent): Promise<void> {
    this.setApplicationDocumentLoading();
    const res = UpdateApplicationDocument(event).then((vmUpdate: ApplicationDocumentUpdatedVmUpdate) => {
      this.setTemplateTexts(vmUpdate.applicationDocument.templateTexts);
      this.updateApplicationDocumentEnd(vmUpdate.applicationDocument);
      EditorModule.pushDocumentUpdate("updateApplicationDocument");
      EditorModule.updateBlocks(vmUpdate.affectedBlocks);
    }).catch((error: any) => {
      this.updateApplicationDocumentEnd(null);
      throw error;
    })
    .finally(() => {
      this.setApplicationDocumentReady();
    });
    return res;
  }

  @Action
  async cloneApplicationDocument(event: CloneApplicationDocumentEvent): Promise<string> {
    this.setApplicationDocumentLoading();
    const res = CloneApplicationDocument(event)
      // For now just treat it the same as if a new application has been created
      .then((createdApplicationDocument: ApplicationDocument) => {
        this.setTemplateTexts(createdApplicationDocument.templateTexts);
        this.createApplicationDocumentEnd(createdApplicationDocument);
        return createdApplicationDocument.guid as string;
      }).catch((error: any) => {
        this.setTemplateTexts([]);
        this.createApplicationDocumentEnd(null);
        throw error;
      });
    return res;
  }

  @Action
  async getApplicationViewSplitState(userId: string | undefined): Promise<ApplicationViewSplitState> {

    if(userId == undefined){
      this.getApplicationViewSplitStateEnd(InitialDocumentOnlySplitState);
      return InitialDocumentOnlySplitState;
    }
    return GetApplicationViewSplitState(userId).then((applicationViewSplitState: ApplicationViewSplitState) => {
      this.getApplicationViewSplitStateEnd(applicationViewSplitState);
      return applicationViewSplitState;
    })
    .catch((error) => {
      this.getApplicationViewSplitStateEnd(InitialDocumentOnlySplitState)
      throw error;
    });
  }

  @Action
  async toggleApplicationSplitViewState(userId: string): Promise<ApplicationViewSplitState> {
    this.setApplicationDocumentLoading();
    const modeSwitchingMap = new Map<ApplicationViewSplitMode, ApplicationViewSplitMode>([
      [ApplicationViewSplitMode.DOCUMENT_ONLY, ApplicationViewSplitMode.DOCUMENT_AND_FIGURES],
      [ApplicationViewSplitMode.DOCUMENT_AND_FIGURES, ApplicationViewSplitMode.DOCUMENT_ONLY]
    ]);
    const newMode = modeSwitchingMap.get(this._applicationViewSplitState.mode);
    if(newMode === undefined){
      throw 'undefined state transition';
    }
    return PutToggleApplicationViewSplitState(userId, newMode).then((applicationViewSplitState: ApplicationViewSplitState) => {
      this.getApplicationViewSplitStateEnd(applicationViewSplitState);
      return applicationViewSplitState;
    }).catch((error) => {
      this.getApplicationViewSplitStateEnd(InitialDocumentOnlySplitState)
      throw error;
    }).finally(() => {
      this.setApplicationDocumentReady();
    });
  }

  @Action
  async updateDocumentAndFigureSplitViewState(param: UpdateDocumentAndFiguresSplitViewState): Promise<ApplicationViewSplitState> {
    this.setApplicationDocumentLoading();
    if(this._applicationViewSplitState.mode !== ApplicationViewSplitMode.DOCUMENT_AND_FIGURES){
      throw `Illegal state - currently not in '${ApplicationViewSplitMode.DOCUMENT_AND_FIGURES}'-state`;
    }

    const newState: DocumentAndFiguresSplitState = {
      ...InitialDocumentAndFiguresSplitState,
      ratio: param.ratio
    };

    // we switch the local state regardless of what the backend returns and let the ui layer handle potential errors
    this.getApplicationViewSplitStateEnd(newState);
    return PutUpdateDocumentAndFiguresViewSplitState({userId: param.userId, state: newState});
  }
}

export default getModule(ApplicationModule);
