<template>
  <span v-if="deleteableNodes().length > 0">
    <span v-if="!isLoading">
      <b-button type="is-info delete-button"
                v-for="node in deleteableNodes()"
                :key="node.attrs.guid"
                @mousedown.left="deleteBlock(node.attrs.guid)"
                :title="getTooltip(node)">
        <i class="exi exi-delete"/>
        {{ getType(node) }}
      </b-button>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Inject, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import {findAllAncestorNodes} from '@/components/applicationEditor/utils/node.util';
import EditorModule from '@/store/modules/EditorModule';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {EditorState} from '@tiptap/pm/state';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component
class DeleteBlock extends Vue {

  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Inject({from: 'applicationEditor.activeEditorState'}) state!: EditorState;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  deleteableNodes(): PmNode[] {
    const nodes: PmNode[] = [];

    // Find all ancestors that are optional (thus can be deleted)
    findAllAncestorNodes(this.state.selection.$anchor, (ancestor) => ancestor.attrs.optionalBlock
      && !ancestor.attrs.conditionalBlock)
      .reverse()
      .forEach((ancestor: PmNode) => nodes.push(ancestor));

    return nodes;
  }

  getType(node: PmNode): string {
    return ApplicationModule.templateText(`${node.attrs.semanticType}.type`);
  }

  getTooltip(node: PmNode): string {
    return this.$t(`menubar.delete`, [ApplicationModule.templateText(`${node.attrs.semanticType}.type`)]).toString();
  }

  deleteBlock(nodeGuid: string): boolean {

    this.saveEditorChangesFn(false)
      .catch(useDefaultErrorHandling)
      .then(() => EditorModule.deleteBlock(nodeGuid));

    return true;
  }
}

export default toNative(DeleteBlock);
</script>
