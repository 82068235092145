export interface PatentEngineException {
    // Received when an exception in the server occured
    code: number;
    message: string;
    localizedMessage: LocalizedMessage;
}

export interface LocalizedMessage { // = ClientLocalizedMessage
    key: LocalizedMessageKey;
    // eslint-disable-next-line
    values: any[];
}

export enum LocalizedMessageKey {
    REFERENCE_SIGN_NOT_UNIQUE_IN_APPLICATION_DOCUMENT = 'REFERENCE_SIGN_NOT_UNIQUE_IN_APPLICATION_DOCUMENT',
    REFERENCE_SIGN_LABEL_IS_INVALID = 'REFERENCE_SIGN_LABEL_IS_INVALID',
    REFERENCE_SIGN_NAME_IS_EMPTY = 'REFERENCE_SIGN_NAME_IS_EMPTY',
    REFERENCE_SIGN_NAME_IS_TOO_LONG = 'REFERENCE_SIGN_NAME_IS_TOO_LONG',
    MODEL_NOT_FOUND = 'MODEL_NOT_FOUND',
    INVALID_ENTITY = 'INVALID_ENTITY',
    DOCUMENT_EXPORT_ERROR = 'DOCUMENT_EXPORT_ERROR',
    FIGURE_RENDERING_ERROR = 'FIGURE_RENDERING_ERROR',
    PAYLOAD_TOO_LARGE_FIGURE_RENDERING_ERROR = 'PAYLOAD_TOO_LARGE_FIGURE_RENDERING_ERROR',
    FIGURE_RENDERING_DELETION_ERROR = 'FIGURE_RENDERING_DELETION_ERROR',
    INVALID_RICH_TEXT = 'INVALID_RICH_TEXT',
    COMMAND_BLOCK_CREATE = 'COMMAND_BLOCK_CREATE',
    COMMAND_BLOCK_UPDATE = 'COMMAND_BLOCK_UPDATE',
    COMMAND_BLOCK_DELETE = 'COMMAND_BLOCK_DELETE',
    COMMAND_BLOCK_GENERATE = 'COMMAND_BLOCK_GENERATE',
    COMMAND_BLOCK_GENERATE_ALL = 'COMMAND_BLOCK_GENERATE_ALL',
    COMMAND_BLOCK_INLINE_UPDATE = 'COMMAND_BLOCK_INLINE_UPDATE',
    COMMAND_BLOCK_PARAGRAPH_UPDATE = 'COMMAND_BLOCK_PARAGRAPH_UPDATE',
    UNDO_STACK_ERROR = 'UNDO_STACK_ERROR',
    REDO_STACK_ERROR = 'REDO_STACK_ERROR',
    TEST_ERROR = 'TEST_ERROR',
    SPELLCHECK_ERROR = 'SPELLCHECK_ERROR',
    SPELLCHECK_FAILED = 'SPELLCHECK_FAILED',
    PASTE_SYMBOLS_FROM_OTHER_APPLICATION = 'PASTE_SYMBOLS_FROM_OTHER_APPLICATION',
    COMMAND_AI_AUTOFILL = 'COMMAND_AI_AUTOFILL'
}
