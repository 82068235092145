<template>
  <span class="about">

    <!-- Use the modal component, pass in the prop -->
    <modal-dialog ref="aboutDialog" :showHeader="true" :showFooter="false" :width="500">
      <template v-slot:body>

        <img id="patent-engine-logo" src="../assets/logo/PE_mit_Logo.svg" alt="Patent-Engine Logo"/>

        <span id="version">{{ getVersion() }}</span>

        <div id="about-dialogue-content">

          <div id="info">
            <div class="about-title">{{ $t('about.info.title') }}:</div>
            <div class="content" v-html="getInfoContentHtml()"></div>
          </div>

          <div id="contact ">
              <div class="about-title">{{ $t('about.contact.title') }}: </div>
          <div class="content">
            <div>DESIGN TO IP GmbH</div>
            <div id="address">
              Klaus-Bungert-Straße 1 <br>
              40468 Düsseldorf
            </div>
            <div>
              <div> {{ $t('about.phone') }}: +49 211 42 99 22 70</div>
             <div>{{ $t('about.fax') }}: +49 211 58 58 83 99</div>
             <div> {{ $t('about.email') }}: info@designtoip.de</div>
            </div>
          </div>
          </div>

          <div id="help">
              <div class="about-title">{{ $t('about.help.title') }}: </div>
            <div class="content">
              <div>{{ $t('about.help.content') }}</div>
              <div id="support-email">{{ $t('about.email') }}: test-support@designtoip.de</div>
            </div>
            <div>
            </div>
          </div>

        </div>

        <div id="design-to-ip-container">
          <a href="https://designtoip.de/" target="_blank" rel="noreferrer noopener">
            <img id="design-to-ip-logo" src="../assets/logo/design-toip-logo.png" alt="Design-To-IP Logo"/>
          </a>
          <span id="copyright">©{{ new Date().getFullYear() }} DESIGN TO IP GmbH</span>
        </div>
      </template>
    </modal-dialog>
  </span>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';

@Component(
  {
    components: {
      ModalDialog
    }
  })
class AboutDialog extends Vue {
  @Ref('aboutDialog') private dialog!: ModalDialogClass;

  open(): void {
    this.dialog.openModal();
  }

  close(): void {
    this.dialog.closeModal();
  }

  getVersion(): string {
    return `v${process.env.VUE_APP_VERSION}`;
  }

  getInfoContentHtml(): string {
    return this.$t('about.info.content') as string;
  }
}

export default toNative(AboutDialog);
export {AboutDialog};
</script>


<style lang="scss">
@import 'src/assets/styles/colors';

.about {
  .modal-dialog {
    &-dialog {

      .modal-dialog-header {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0 0 20px 0;

        .icon-button {
          margin-top: 0px;
          margin-right: 0px;
          padding: 0px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .modal-dialog-body {
        align-items: center;
        text-align: left;

        #patent-engine-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 300px;
        }

        #version {
          font-size: 16px;
          margin-top: -6px;
        }


        #design-to-ip-container {
          display: flex;
          flex-flow: column wrap;
          justify-content: start;
          align-items: start;

          width: 32%; // so that the copyright fits in one line

          #design-to-ip-logo {
            width: 85%;
          }

          #copyright {
            color: $pengine-grey-dark;
            width: 100%;
            font-size: 60%;
          }
        }

        #about-dialogue-content {
          .content {
            font-weight: normal;
          }

          .about-title {
            padding-bottom: 5px;
            padding-top: 25px;
            text-transform: uppercase;
            font-size: 16px;
          }

          #address, #support-email {
            padding-bottom: 10px;
            padding-top: 10px;
          }
        }

      }
    }
  }
}

</style>
