<template>
  <div class="cookies-banner">
    <modal-dialog ref="dialog" :show-close="false">
      <template v-slot:header>
        <h1>{{ $t('cookies.cookieBannerTitle') }}</h1>
      </template>
      <template v-slot:body>
        <div class="cookie-message">
          {{ $t('cookies.cookieBannerMessage') }}
          <a target="_blank" rel="noopener noreferrer" v-bind:href="`${$t('regulatoryInformation.languagePath')}/data-privacy-policy.html`">{{
              $t('regulatoryInformation.privacyPolicy')
            }}</a>
          {{ $t('cookies.cookieBannerFill') }}
          <a target="_blank" rel="noopener noreferrer" v-bind:href="`${$t('regulatoryInformation.languagePath')}/imprint.html`">{{
              $t('regulatoryInformation.imprint')
            }}</a>.
        </div>
      </template>
      <template v-slot:footer>
        <div class="cookie-options">
          <div class="is-flex flex-center">
            <b-checkbox
              name="allowEssentials"
              :value="true"
              disabled
            />
            {{ $t('cookies.cookieBannerEssential') }}
          </div>
          <div class="is-flex flex-center">
            <b-checkbox
              class="checkbox-container"
              name="allowFunctionals"
              v-model="functionalCookies"
            />
            <div class="cookie-option">
              {{ $t('cookies.cookieBannerFunctional') }}
            </div>
          </div>
        </div>
        <div class="cookie-buttons">
          <div class="button-container">
            <button class="column button-action" @click="acceptAll">
              {{ $t('cookies.cookieBannerAcceptAll') }}
            </button>
          </div>
          <div class="button-container">
            <button
              id="acceptCookies"
              class="column button-accept-choice"
              @click="acceptChoices"
              :title="$t('cookies.cookieBannerAcceptChoices')">
              {{ $t('cookies.cookieBannerAcceptChoices') }}
            </button>
          </div>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script lang="ts">
import {Component, Ref, toNative, Vue} from 'vue-facing-decorator';
import {checkIfCookieExists, cookies, createEssentialCookie, createFunctionalCookie, deleteCookie,} from '@/util/cookies.util';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';

/**
 * A modal banner containing the cookies' settings of the application.
 */
@Component(
  {
    components: {
      ModalDialog
    }
  })
class CookiesBanner extends Vue {
  @Ref('dialog') private dialog!: ModalDialogClass;
  /**
   * Stores the internal state of the functional cookies checkbox.
   * Initially not selected.
   * @private
   */
  private functionalCookies = false;

  /**
   * Opens the banner.
   */
  open(): void {
    this.setFunctionalCookies();
    this.dialog.openModal();
  }

  /**
   * Closes the banner.
   */
  close(): void {
    this.dialog.closeModal();
  }

  /**
   * Handles the click on the accept user choices button.
   * @private
   */
  private acceptChoices(): void {
    if (this.functionalCookies) {
      createFunctionalCookie();
    } else {
      deleteCookie(cookies.functionals.keycloak.functionalIdentifier);
      deleteCookie(cookies.functionals.keycloak.technicalCookie);
    }

    createEssentialCookie();
    this.close();
  }

  /**
   * Handles the click on the accept all button.
   * @private
   */
  private acceptAll(): void {
    createFunctionalCookie();
    createEssentialCookie();
    this.close();
  }

  /**
   * Sets the functional cookies checkbox based on the existence of the cookie or not.
   * @private
   */
  private setFunctionalCookies(): void {
    this.functionalCookies = checkIfCookieExists(cookies.functionals.keycloak.functionalIdentifier);
  }

  /**
   * Called after the instance has been mounted.
   * @public
   */
  mounted(): void {
    this.setFunctionalCookies();
  }
}

export default toNative(CookiesBanner);
export {CookiesBanner};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/constants';

.cookies-banner {
  .modal-dialog {
    $dialog-width: 1000px;

    &-dialog {
      width: $dialog-width;
      max-height: 100%;
      overflow-x: auto !important;

      .modal-dialog-body {
        width: $dialog-width;
      }
    }

    &-header {
      width: $dialog-width;

      h1 {
        margin: 0 auto;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    &-header {
      width: $dialog-width;
    }

    &-body {
      width: $dialog-width;

      .cookie-message {
        text-align: justify;
        font-size: $font-size-normal;
        padding-bottom: 30px;
        opacity: 1;

        a {
          color: black;
          text-decoration: underline;
        }
      }
    }

    &-footer {
      width: $dialog-width;
      padding-top: 12px;
      padding-bottom: 16px;

      .cookie-options {
        padding-bottom: 30px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        white-space: nowrap;

        .control-label:hover {
          color: white;
        }
      }

      .cookie-buttons {
        padding-right: 5px;

        button {
          width: 156px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }
  }
}
</style>

<style lang="scss">
</style>