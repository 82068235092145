export const LOGIN_STATE_KEY = 'loginState';

export const loginState = Object.freeze({
                                          basic: 'basic',
                                          loggedIn: 'loggedIn',
                                          loggedOut: 'loggedOut',
                                        });

export interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  locale: string;
  tenant: string;
  aiAssistantEnabled: boolean;
}

export interface AuthState {
  isLoading: boolean;
  user: UserData | null;
}
