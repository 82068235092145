import {queuedAxiosInstance} from '@/api';
import {
  AbstractBlockViewModel,
  BlockCreatedVmUpdate,
  BlockDeletedVmUpdate,
  BlockInlineVmUpdate,
  BlockReviewedVmUpdate,
  BlocksGeneratedVmUpdate,
  BlocksUpdatedVmUpdate,
  CreateBlockEvent,
  GenerateBlockEvent,
  isTextBlockViewModel,
  StructuralBlockViewModel,
  TextBlockViewModel,
  UpdateBlockInlineEvent,
  UpdateBlocksEvent
} from '@/api/models/editor.model';
import {APPLICATION_PATH} from '@/api/services/application.api';

const APPLICICATION_DOCUMENT_ROOT_BLOCK_SUB_PATH = 'rootblock';
const BLOCK_PATH = 'block';

/**
 * Preprocessor to cast all kinds of blocks to their correct class/interface, including it's chidlren if they exist.
 * @param block the block to cast
 */
export const blockPreprocessor = (block: AbstractBlockViewModel, parent: AbstractBlockViewModel | null): AbstractBlockViewModel => {

  // set the possible parent of the block
  block.parent = parent;


  if (isTextBlockViewModel(block)) {
    return block as TextBlockViewModel;
  }

  // ...otherwise it is assumed a structual block
  const structuralBlock: StructuralBlockViewModel = block as StructuralBlockViewModel;
  structuralBlock.children = structuralBlock.children.map((child) => blockPreprocessor(child, structuralBlock));
  return structuralBlock;
};

/**
 * Preprocessor to cast all kinds of blocks to their correct class/interface, including it's chidlren if they exist.
 * @param blocks a list of blocks to cast
 */
export const blockListPreprocessor = (blocks: Array<AbstractBlockViewModel>): Array<AbstractBlockViewModel> => {
  return blocks.map((block: AbstractBlockViewModel) => blockPreprocessor(block, null));
}

/**
 * Fetch the root block of the application document (and it's descendants) from backend
 * @param applicationDocumentGuid the GUID of the application document to get the root block for
 */
export const GetApplicationDocumentRootBlock = async (applicationDocumentGuid: string): Promise<AbstractBlockViewModel> => {
  const res = await queuedAxiosInstance.get(`${APPLICATION_PATH}/${applicationDocumentGuid}/${APPLICICATION_DOCUMENT_ROOT_BLOCK_SUB_PATH}`);
  return blockPreprocessor(res?.data as AbstractBlockViewModel, null);
};

/**
 * Request the creation of a block in the backend
 * @param event the event describing the block to create
 */
export const CreateBlock = async (event: CreateBlockEvent): Promise<BlockCreatedVmUpdate> => {
  const res = await queuedAxiosInstance.post(`${BLOCK_PATH}`, event);
  return {...res?.data, block: blockPreprocessor(res?.data.block, null)} as BlockCreatedVmUpdate;
}

/**
 * Request an update of multiple blocks in the backend
 * @param event the event describing the updates to the blocks
 */
export const UpdateBlocks = async (event: UpdateBlocksEvent): Promise<BlocksUpdatedVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${BLOCK_PATH}`, event);
  return {
    ...res.data,
    blocks: res.data.blocks.map((block: AbstractBlockViewModel) => blockPreprocessor(block, null)),
    affectedBlocks: blockListPreprocessor(res.data.affectedBlocks),
    applicationDocumentAffected: res.data.applicationDocumentAffected
  };
}

export const UpdateBlockInline = async (event: UpdateBlockInlineEvent): Promise<BlockInlineVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${BLOCK_PATH}/inline`, event);
  return {
    ...res.data,
    blocks: res.data.blocks.map((block: AbstractBlockViewModel) => blockPreprocessor(block, null)),
  } as BlockInlineVmUpdate;
}

/**
 * Request an generation of a block in the backend
 * @param blockGuid the GUID of the block to generate
 * @param event the event describing the block to generate
 */
export const GenerateBlock = async (blockGuid: string, event: GenerateBlockEvent): Promise<BlocksGeneratedVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${BLOCK_PATH}/${blockGuid}/generate`, event);
  return {
    ...res?.data,
    name: res?.data.name,
    commandStack: res?.data.commandStack,
    addedBlocks: res?.data.addedBlocks,
    updatedBlocks: res?.data.updatedBlocks,
    deletedBlocks: res?.data.deletedBlocks
  } as BlocksGeneratedVmUpdate;
}

/**
 * Request the deletion of a block in the backend
 * @param blockGuid the GUID of the block to delete
 */
export const DeleteBlock = async (blockGuid: string): Promise<BlockDeletedVmUpdate> => {
  const res = await queuedAxiosInstance.delete(`${BLOCK_PATH}/${blockGuid}`);
  return {...res?.data, updatedBlocks: blockListPreprocessor(res?.data.updatedBlocks)} as BlockDeletedVmUpdate;
}

/**
 * Request setting the reviewNeeded flag of a block in the backend to false
 * @param blockGuid the GUID of the block to review
 */
export const ReviewBlock = async (blockGuid: string): Promise<BlockReviewedVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${BLOCK_PATH}/${blockGuid}/review`);
  return res?.data as BlockReviewedVmUpdate;
}
