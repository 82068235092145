import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {
  AddWordToDictionary,
  AddWordToIgnoreList,
  DeleteAllWordsFromIgnoreList,
  DeleteWordFromDictionary,
  GetWordsFromDictionary,
  GetWordsFromIgnoreList,
  SendSpellcheckRequest,
  UpdateWordInDictionary
} from '@/api/services/spellcheck.api';
import {
  SpellcheckApiResult,
  SpellcheckCategoryID,
  SpellcheckDictionaryWord,
  SpellcheckIgnoreWord,
  SpellcheckParams
} from '@/api/models/spellcheck.model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {HeaderDropdownItem} from '@/components/header/header.model';
import {SpellingMistake} from '@/store/models/spellcheck.model';

// How many requests are allowed concurrently?
const SPELLCHECK_REQUESTS_LIMIT = 8;

/**
 * The store module to handle actions and mutations concerning the editor state.
 */
@Module({dynamic: true, namespaced: true, store, name: 'spellcheck'})
class SpellcheckModule extends VuexModule {

  private _isLoadingForManagement = false;
  private _localeForManagement: string | null = null;
  private _isActive = true;
  private _spellingMistakes: SpellingMistake[] = [];
  // If there is something to refresh in the client view
  private _spellcheckResultUpdated = true;
  private _spellcheckImmediateBlockRequests: string[] = []; // logical block GUIDs we want to check sequentially as soon as possible
  private _spellcheckDelayedBlockRequests: Map<string, Date> = new Map(); // logical block GUIDs we want to check at the specified time
  private _spellcheckPendingRequestParams: SpellcheckParams[] = []; // parameters of pending requests
  private _deleteAllWordsFromIgnoreListCounter = 0;

  private _temporaryDictionaryWords: SpellcheckDictionaryWord[] = []; // New, not yet saved words
  private _dictionaryWords: SpellcheckDictionaryWord[] = [];
  private _dictionaryWordsForManagement: SpellcheckDictionaryWord[] = [];
  private _ignoredWords: SpellcheckIgnoreWord[] = [];
  private _newDictionaryWord: SpellcheckDictionaryWord | null = null;
  private _newIgnoredWord: SpellcheckIgnoreWord | null = null;
  private _reloadForManagement = 0;

  get isLoadingForManagement(): boolean {
    return this._isLoadingForManagement;
  }

  get localeForManagement(): string | null {
    return this._localeForManagement;
  }

  get reloadForManagement(): number {
    return this._reloadForManagement;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  get spellingMistakes(): SpellingMistake[] {
    return this._spellingMistakes;
  }

  /**
   * GUIDs of logical blocks we want to check sequentially as soon as possible
   */
  get spellcheckBlockRequests(): string[] {
    return this._spellcheckImmediateBlockRequests;
  }

  get temporaryDictionaryWords(): SpellcheckDictionaryWord[] {
    return this._temporaryDictionaryWords;
  }

  get dictionaryWords(): SpellcheckDictionaryWord[] {
    return this._dictionaryWords;
  }

  get dictionaryWordsForManagement(): SpellcheckDictionaryWord[] {
    return this._dictionaryWordsForManagement;
  }

  get newDictionaryWord(): SpellcheckDictionaryWord | null {
    return this._newDictionaryWord;
  }

  get newIgnoredWord(): SpellcheckIgnoreWord | null {
    return this._newIgnoredWord;
  }

  get deleteAllWordsFromIgnoreListCounter(): number {
    return this._deleteAllWordsFromIgnoreListCounter;
  }

  /**
   * Check if this spellcheck should be skipped.
   * This is the case, if
   * - the spellcheck is marked as skippable (because another equivalent request started while this request was pending) or
   * - another spellcheck is planned for the same block (either in the immediate or the delayed queue).
   */
  get isSkipSpellcheck(): (param: SpellcheckParams) => boolean {
    return (param: SpellcheckParams) => {
      return param.skip ||
        this._spellcheckImmediateBlockRequests.includes(param.logicalBlockGuid) ||
        this._spellcheckDelayedBlockRequests.has(param.logicalBlockGuid);
    };
  }

  /**
   * Add a spellcheck to the list of pending requests and mark equivalent pending requests as skippable.
   */
  @Mutation
  private addPendingSpellcheck(params: SpellcheckParams) {
    // mark any another pending requests for the same block as skippable
    this._spellcheckPendingRequestParams
      .filter(it => it.logicalBlockGuid === params.logicalBlockGuid)
      .forEach(it => {
        it.skip = true;
      });
    this._spellcheckPendingRequestParams.push(params);
  }

  /**
   * Remove a spellcheck from the list of pending requests.
   */
  @Mutation
  private removePendingSpellcheck(params: SpellcheckParams) {
    this._spellcheckPendingRequestParams = this._spellcheckPendingRequestParams.filter(
      it => it.applicationDocumentGuid !== params.applicationDocumentGuid);
  }

  @Mutation
  private setSpellcheckLoadingForManagement() {
    this._isLoadingForManagement = true;
  }

  @Mutation
  public setLanguageForManagement(locale: string): void {
    this._localeForManagement = locale;
    this._dictionaryWordsForManagement = [];
    this._temporaryDictionaryWords = [];
  }

  /**
   * Set whole queue of GUIDs of logical blocks we want to spellcheck sequentially as soon as possible
   */
  @Mutation
  public setSpellcheckBlockRequests(blockGuids: string[]): void {
    this._spellcheckImmediateBlockRequests = blockGuids;
  }

  /**
   * Add GUID of one logical block to the spellcheck queue
   */
  @Mutation
  public addSpellcheckBlockRequest(blockGuid: string): void {
    if (!this._spellcheckImmediateBlockRequests.includes(blockGuid)) {
      this._spellcheckImmediateBlockRequests.push(blockGuid);
    }
  }

  /**
   * Remove next entry from the spellcheck queue
   */
  @Mutation
  private removeNextSpellcheckBlockRequest(): void {
    this._spellcheckImmediateBlockRequests.shift();
  }

  /**
   * Get GUID of next block that should be checked from the spellcheck queue.
   * This may delay until some pending spellcheck request has finished.
   */
  @Action
  public async popSpellcheckBlockRequest(): Promise<string | undefined> {
    function timeout(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    await timeout(1);
    // if too many requests are pending, wait until one has completed
    while (this._spellcheckPendingRequestParams.length >= SPELLCHECK_REQUESTS_LIMIT) {
      await timeout(20);
    }

    const result: string | undefined = this.spellcheckBlockRequests[0];
    if (result) {
      this.removeNextSpellcheckBlockRequest();
    }
    return result;
  }

  /**
   * Add GUID of one logical block we want to check later to delayed spellcheck map
   */
  @Mutation
  public addDelayedSpellcheckBlockRequest(param: {logicalBlockGuid: string; delayMs: number}): void {
    const scheduledDate = new Date(new Date().getTime() + param.delayMs);
    this._spellcheckDelayedBlockRequests.set(param.logicalBlockGuid, scheduledDate);
  }

  /**
   * Remove GUID from delayed spellcheck map
   */
  @Mutation
  private removeDelayedSpellcheckBlockRequest(logicalBlockGuid: string): void {
    this._spellcheckDelayedBlockRequests.delete(logicalBlockGuid);
  }

  /**
   * Move delayed requests that are now due from delayed spellcheck map to immediate spellcheck queue
   */
  @Action
  public handleDelayedSpellcheckBlockRequests(): void {
    const now = new Date();
    this._spellcheckDelayedBlockRequests.forEach((scheduledDate, blockGuid) => {
      if (now >= scheduledDate) {
        this.removeDelayedSpellcheckBlockRequest(blockGuid);
        this.addSpellcheckBlockRequest(blockGuid);
      }
    });
  }

  @Mutation
  public setSpellcheckUpdated(updated: boolean): void {
    this._spellcheckResultUpdated = updated;
  }

  @Mutation // When we load a fresh application document use this to clear everything
  public resetSpellcheckData(keepTemporary?: boolean): void {
    this._spellingMistakes = [];
    this._spellcheckImmediateBlockRequests = [];
    this._spellcheckDelayedBlockRequests = new Map();
    this._spellcheckPendingRequestParams = [];
    if (!keepTemporary) {
      this._temporaryDictionaryWords = [];
    }
    this._reloadForManagement = 0;
  }

  @Mutation
  public toggleSpellcheck(item?: HeaderDropdownItem): void {
    if (item) {
      item.value = !item.value;
      this._isActive = item.value;
    } else {
      this._isActive = !this._isActive;
    }
  }

  /**
   * Spellcheck response has arrived.
   * Result may be skipped.
   */
  @Action
  private spellcheckEnd(param: { result: SpellcheckApiResult; spellcheckParams: SpellcheckParams }): void {
    // Skip if there is another spellcheck scheduled for the same block
    const spellcheckParams = param.spellcheckParams;
    if (this.isSkipSpellcheck(spellcheckParams)) {
      return;
    }

    // merge the mistakes: remove all from the same block and add new ones
    const newMistakes = param.result.matches.map(match => new SpellingMistake(spellcheckParams, match));
    const allMistakes = this.spellingMistakes
      .filter(mistake => mistake.logicalBlockGuid !== spellcheckParams.logicalBlockGuid)
      .concat(newMistakes);
    this.setSpellingMistakes(allMistakes);
  }

  @Mutation
  private setSpellingMistakes(spellingMistakes: SpellingMistake[]): void {
    this._spellingMistakes = spellingMistakes;
    this._spellcheckResultUpdated = true;
  }

  @Mutation
  private getWordsFromDictionaryEnd(params: { words: SpellcheckDictionaryWord[]; forManagement?: boolean } | null): void {
    if (params == null) {
      return;
    }
    // Overwrite all words
    if (params.forManagement) {
      // Also sort the words for the management
      this._dictionaryWordsForManagement = params.words
        .sort((wordA, wordB) => wordA.word.localeCompare(wordB.word));
    } else {
      this._dictionaryWords = params.words;
    }
    this._isLoadingForManagement = false;
  }

  @Mutation
  private addTemporaryDictionaryWordEnd(spellcheckDictionaryWord: SpellcheckDictionaryWord): void {
    this._temporaryDictionaryWords.push(spellcheckDictionaryWord);
  }

  @Mutation
  private removeTemporaryDictionaryWordEnd(spellcheckDictionaryWord: SpellcheckDictionaryWord): void {
    this._temporaryDictionaryWords = this._temporaryDictionaryWords.filter((tempWord: SpellcheckDictionaryWord) => {
      return tempWord.guid != spellcheckDictionaryWord.guid;
    });
  }

  @Mutation
  private addWordToDictionaryEnd(spellcheckDictionaryWord: SpellcheckDictionaryWord | null): void {
    if (spellcheckDictionaryWord != null && !this._dictionaryWords.includes(spellcheckDictionaryWord)) {
      this._dictionaryWords.push(spellcheckDictionaryWord);
      this._newDictionaryWord = spellcheckDictionaryWord;
      // We are working on the same language in the spellcheck management view we should update this one too
      if (this._localeForManagement === spellcheckDictionaryWord.language) {
        this._dictionaryWordsForManagement.push(spellcheckDictionaryWord);
        // Sort it after adding a word
        this._dictionaryWordsForManagement = this._dictionaryWordsForManagement
          .sort((wordA, wordB) => wordA.word.localeCompare(wordB.word));
        // And if it was a temporary word that now was saved - remove it here
        this._temporaryDictionaryWords = this._temporaryDictionaryWords.filter((tempWord: SpellcheckDictionaryWord) => {
          return tempWord.word.trim() != spellcheckDictionaryWord.word;
        });
      }
    }
    this._isLoadingForManagement = false;
  }

  @Mutation
  private updateWordInDictionaryEnd(spellcheckDictionaryWord: SpellcheckDictionaryWord | null): void {
    if (spellcheckDictionaryWord != null) {
      this._dictionaryWordsForManagement = [
        ...this._dictionaryWordsForManagement.map((dictionaryWordForM: SpellcheckDictionaryWord) => {
          if (dictionaryWordForM.guid === spellcheckDictionaryWord.guid) {
            return {...dictionaryWordForM, word: spellcheckDictionaryWord.word};
          }
          return dictionaryWordForM;
        })
      ].sort((wordA, wordB) => wordA.word.localeCompare(wordB.word));
      // Spellcheck whole document again
      this._reloadForManagement++;
    }
    this._isLoadingForManagement = false;
  }

  @Mutation
  private deleteWordFromDictionaryEnd(guid: string | null): void {
    if (guid != null) {
      this._dictionaryWordsForManagement = [
        ...this._dictionaryWordsForManagement.filter((dictionaryWord) => {
          return dictionaryWord.guid != guid;
        })
      ]
      // Spellcheck whole document again
      this._reloadForManagement++;
    }
    this._isLoadingForManagement = false;
  }

  @Mutation
  private getWordsFromIgnoreListEnd(words: SpellcheckIgnoreWord[] | null): void {
    if (words == null) {
      return;
    }
    // Overwrite all words
    this._ignoredWords = words;
  }

  @Mutation
  private addWordToIgnoreListEnd(spellcheckIgnoreWord: SpellcheckIgnoreWord | null): void {
    if (spellcheckIgnoreWord != null && !this._ignoredWords.includes(spellcheckIgnoreWord)) {
      this._ignoredWords.push(spellcheckIgnoreWord);
      this._newIgnoredWord = spellcheckIgnoreWord;
    }
  }

  @Mutation
  private deleteAllWordsFromIgnoreListEnd(success: boolean): void {
    if (success) {
      this._ignoredWords = [];
      this._newIgnoredWord = null;
      this._reloadForManagement++;
    }
  }

  @Mutation
  public triggerDeleteAllWordsFromIgnoreList(): void {
    this._deleteAllWordsFromIgnoreListCounter++;
  }

  @Mutation
  public removeMistakesForDictionaryWord(dictionaryWord: SpellcheckDictionaryWord): void {
    this._spellingMistakes = this._spellingMistakes.filter((mistake: SpellingMistake): boolean =>
      // We only check matches of type TYPOS
      (mistake.rule.category.id.toLowerCase() != SpellcheckCategoryID.TYPOS) || (dictionaryWord.word != mistake.word)
    );
  }

  @Mutation
  public removeMistakesForIgnoredWord(ignoredWord: SpellcheckIgnoreWord): void {
    this._spellingMistakes = this._spellingMistakes.filter((mistake: SpellingMistake): boolean =>
      // We only check matches of the same type
      (mistake.rule.category.id != ignoredWord.errorType) || (ignoredWord.word != mistake.word)
    );
  }

  @Mutation
  public removeMistake(mistakeToRemove: SpellingMistake) {
    this._spellingMistakes = this._spellingMistakes.filter((mistake: SpellingMistake): boolean => mistake !== mistakeToRemove);
  }

  @Mutation
  public removeMistakes(predicate: (mistake: SpellingMistake) => boolean) {
    this._spellingMistakes = this._spellingMistakes.filter(predicate);
  }

  /**
   * Send a spellcheck request to the backend now.
   * This request may be skipped.
   */
  @Action
  public spellcheck(params: SpellcheckParams): Promise<void> {
    // Skip if there is another spellcheck scheduled for the same block
    if (this.isSkipSpellcheck(params)) {
      return Promise.resolve();
    }

    this.addPendingSpellcheck(params);

    // If spellcheckData is null (for an empty block), we assume no matches. This removes the old matches for params.logicalBlockGuid.
    const spellcheckPromise = params.spellcheckData === null
      ? Promise.resolve({matches: []} as SpellcheckApiResult)
      : SendSpellcheckRequest(params.applicationDocumentGuid, params.locale, params.spellcheckData);

    return spellcheckPromise
      .then((result) => {
        this.removePendingSpellcheck(params);
        this.spellcheckEnd({result: result, spellcheckParams: params});
      })
      .catch((error) => {
        this.removePendingSpellcheck(params);
      });
  }

  @Action
  getWordsFromDictionary(params: { locale: string; forManagement?: boolean }): Promise<void> {
    this.setSpellcheckLoadingForManagement();
    return GetWordsFromDictionary(params.locale)
      .then((words: SpellcheckDictionaryWord[]) => {
        this.getWordsFromDictionaryEnd({words, forManagement: params.forManagement});
      })
      .catch((error) => {
        this.getWordsFromDictionaryEnd(null);
      });
  }

  @Action
  addTemporaryDictionaryWord(spellcheckDictionaryWord: SpellcheckDictionaryWord): void {
    const trimmedWord = spellcheckDictionaryWord.word.replace(/\s+/g, ' ');
    this.addTemporaryDictionaryWordEnd({...spellcheckDictionaryWord, word: trimmedWord});
  }

  @Action
  removeTemporaryDictionaryWord(spellcheckDictionaryWord: SpellcheckDictionaryWord): void {
    this.removeTemporaryDictionaryWordEnd(spellcheckDictionaryWord);
  }

  @Action
  addWordToDictionary(params: { locale: string; word: string }): Promise<void> {
    this.setSpellcheckLoadingForManagement();
    const trimmedWord = params.word.replace(/\s+/g, ' ');
    return AddWordToDictionary(params.locale, trimmedWord)
      .then((spellcheckDictionaryWord: SpellcheckDictionaryWord) => {
        this.addWordToDictionaryEnd(spellcheckDictionaryWord);
      })
      .catch((error) => {
        this.addWordToDictionaryEnd(null);
      });
  }

  @Action
  updateWordInDictionary(params: { guid: string; word: string }): Promise<void> {
    this.setSpellcheckLoadingForManagement();
    const trimmedWord = params.word.replace(/\s+/g, ' ');
    return UpdateWordInDictionary(params.guid, trimmedWord)
      .then((spellcheckDictionaryWord: SpellcheckDictionaryWord) => {
        this.updateWordInDictionaryEnd(spellcheckDictionaryWord);
      })
      .catch((error) => {
        this.updateWordInDictionaryEnd(null);
      });
  }

  @Action
  deleteWordFromDictionary(guid: string): Promise<void> {
    this.setSpellcheckLoadingForManagement();
    return DeleteWordFromDictionary(guid)
      .then(() => {
        this.deleteWordFromDictionaryEnd(guid);
      })
      .catch((error) => {
        this.deleteWordFromDictionaryEnd(null);
      });
  }

  @Action
  getWordsFromIgnoreList(applicationDocumentGuid: string): Promise<void> {
    return GetWordsFromIgnoreList(applicationDocumentGuid)
      .then((words: SpellcheckIgnoreWord[]) => {
        this.getWordsFromIgnoreListEnd(words);
      })
      .catch((error) => {
        this.getWordsFromIgnoreListEnd(null);
      });
  }

  @Action
  addWordToIgnoreList(params: { appDocGuid: string; errorType: string; word: string }): Promise<void> {
    const trimmedWord = params.word.replace(/\s+/g, ' ');
    return AddWordToIgnoreList(params.appDocGuid, params.errorType, trimmedWord)
      .then((spellcheckIgnoreWord: SpellcheckIgnoreWord) => {
        this.addWordToIgnoreListEnd(spellcheckIgnoreWord);
      })
      .catch((error) => {
        this.addWordToIgnoreListEnd(null);
      });
  }

  @Action
  deleteAllWordsFromIgnoreList(): Promise<void> {
    const appDocGuid = ApplicationModule.currentApplicationDocument?.guid;
    if (!appDocGuid) {
      return Promise.resolve();
    }
    return DeleteAllWordsFromIgnoreList(appDocGuid)
      .then(() => {
        this.deleteAllWordsFromIgnoreListEnd(true);
      })
      .catch(() => {
        this.deleteAllWordsFromIgnoreListEnd(false);
      });
  }
}

export default getModule(SpellcheckModule);
