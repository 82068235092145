// directives/active-element.ts
import {DirectiveBinding, ObjectDirective} from 'vue';

const ActiveElementDirective: ObjectDirective = {
  mounted(el, binding: DirectiveBinding) {
    const handler = () => {
      binding.value(document.activeElement);
    };

    (el as any).__vueActiveElementHandler__ = handler;

    document.addEventListener('focusin', handler);
    document.addEventListener('focusout', handler);
  },
  unmounted(el) {
    document.removeEventListener('focusin', (el as any).__vueActiveElementHandler__);
    document.removeEventListener('focusout', (el as any).__vueActiveElementHandler__);
    delete (el as any).__vueActiveElementHandler__;
  }
};

export default ActiveElementDirective;
