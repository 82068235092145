import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3';
import {getArrayFromDomAttribute, getBooleanFromDomAttribute} from '@/components/applicationEditor/utils/converter.util';
import TableEntryView from '@/components/applicationEditor/nodes/baseElements/views/TableEntryView.vue';

/**
 * A node describing an entry in two column table, commonly used for list of reference signs and figure description
 */
export const TableEntryNode = TiptapNode.create(
  {
    name: 'tableEntryNode',
    content: '(structuralBlockNode|nonLogicalStructuralBlockNode|textBlockNode)*',
    group: 'block',
    isolating: true,
    defining: true,

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        tableEntryNo: {
          default: '',
          parseHTML: element => element.getAttribute('tableEntryNo'),
          renderHTML: attributes => ({'tableEntryNo': attributes.tableEntryNo})
        },
        claimNo: {default: ''},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
        isReadOnly: {default: false},
        logicalDepth: {default: -1},
      };
    },

    parseHTML() {
      return [
        {
          tag: 'tableEntryNode',
          getAttrs: (domNode: Node | string) => {

            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              semanticType: domElement.getAttribute('semanticType'),
              tableEntryNo: domElement.getAttribute('tableEntryNo'),
              claimNo: domElement.getAttribute('claimNo'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks')),
              isReadOnly: domElement.getAttribute("isReadOnly"),
              logicalDepth: domElement.getAttribute('logicalDepth'),
            };
          }
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['tableEntryNode', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(TableEntryView);
    }

    /* TODO: stopEvent does not exist on Node
    stopEvent(event: Event): boolean {
      return false;
    }*/
  });
