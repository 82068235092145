import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-50333d6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "zoomWrapper",
  ref: "zoomWrapper"
}
const _hoisted_2 = {
  id: "zoomTarget",
  ref: "zoomTarget"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512)
  ], 512))
}