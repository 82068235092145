<template>
  <div class="search-result-dialog">

    <modal-dialog ref="dialog" :showFooter="!readOnly" :onClose="removeCopyListener">
      <template v-slot:header>
        <h1 v-if="readOnly || isSearchSpaceSetForOpening">
          {{ $t(getSearchSpaceLocalization(searchSpace)) }}
        </h1>
        <h1 v-else>{{ $t('createLibraryEntry') }}</h1>
      </template>

      <template v-slot:body>
        <div v-if="!readOnly && !isSearchSpaceSetForOpening" class="search-spaces">

          <div class="column labels">
            <label for="type">{{ $t('type') }}
              <span class="required"> {{ $t('general.required') }}</span>
            </label>
          </div>
          <div class="column">
            <select ref="searchSpaceSelect" id="type" v-model="searchSpace" @change="selectOnChangeSearchSpace"
                    :disabled="isLoading">
              <option v-for="(_searchSpace, index) in searchSpaces" :key="index" :value="_searchSpace">
                {{ $t(`searchSpaceSingular.${_searchSpace}.name`) }}
              </option>
            </select>
          </div>

        </div>

        <div class="label-and-field" v-if="!readOnly">
          <div class="column labels">
            <label for="name">{{ $t('general.name') }}
              <span class="required"> {{ $t('general.required') }}</span>
            </label>
          </div>
          <div class="column">
            <b-input ref="nameInput" id="name" class="name-input" v-model="name" :maxlength="nameMaxLength"
                     :has-counter="false" :disabled="isLoading">
            </b-input>
          </div>
        </div>

        <div v-if="!readOnly" class="search-locales">

          <div class="column labels">
            <label for="local">{{ $t('locale') }}
              <span class="required"> {{ $t('general.required') }}</span>
            </label>
          </div>
          <div class="column">
            <select ref="localeSelect" id="local" v-model="locale" @change="selectOnChangeLocale" :disabled="isLoading">
              <option v-for="(_locale, index) in searchLocales" :key="index" :value="_locale">
                {{ $t('searchLocale.' + _locale) }}
              </option>
            </select>
          </div>

        </div>

        <b-taglist id="keywords" v-if="readOnly" :disabled="isLoading">
          <b-tag v-for="keyword in keywords" :key="keyword">{{ keyword }}</b-tag>
        </b-taglist>

        <div v-else class="label-and-field keywords">
          <label for="keywords" :title="$t('keywordsTitle')">{{ $t('keywords') }}</label>
          <b-taginput
            class="tag-input"
            size="is-small"
            :modelValue="keywords"
            icon="label"
            @update:modelValue="keywordsChanged"
            :placeholder="$t('newEntry')"
            :title="$t('keywordsTitle')" :disabled="isLoading"/>
        </div>

        <div v-if="readOnly" class="name"><h2>{{ name }}</h2></div>
        <div v-if="readOnly" class="copy-button">
          <b-button class="icon-button" @click="clickCopy()"
                    :title="$t(`searchSpaceSingular.${searchSpace}.copy`)">
            <span class="icon is-small">
              <i class="exi exi-copy"></i>
            </span>
          </b-button>
        </div>
        <div v-if="readOnly" class="readonly-description start">{{ text }}</div>
        <textarea v-else
                  id="text"
                  v-model="text"
                  class="start"
                  :placeholder="$t('descriptionText')"
                  :has-counter="false" :disabled="isLoading"/>
      </template>

      <template v-slot:footer>
        <div class="button-row">
          <div class="button-container">
            <button
              class="column button-action"
              v-on:click.once="createSaveLibraryEntry()"
              :disabled="!isValid() || !hasChanges() || isLoading"
              :key="key"
              :title="getCreateSaveButtonTitle()">
              <i v-if="isLoading" class="exi exi-small-spinner-unmasked rotating"/>
              {{ guid === '' ? $t('general.create') : $t('general.save') }}
            </button>
          </div>
          <div class="button-container">
            <button class="column button-cancel" @click="dialog.closeModal()">{{ $t('general.cancel') }}</button>
          </div>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Watch} from 'vue-facing-decorator';
import ModalDialog, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import {SearchLocale, SearchResult, SearchSpace} from '@/api/models/search.model';
import SearchModule from '@/store/modules/SearchModule';
import {PatentEngineConstraints} from '@/constraints';
import {Brick, BrickType, CreateBrickEvent, CreateTermDefinitionEvent, LibraryEntity, TermDefinition} from '@/api/models/library.model';
import LibraryModule from '@/store/modules/LibraryModule';
import {DisplaySuccessMessage} from '@/api';
import {registerSearchResultDialogCopyListener} from '@/util/copy.util';
import {useDefaultErrorHandling} from '@/errorHandling';

// TODO: Writing value to dom does not work anymore: onInput="this.parentNode.dataset.replicatedValue = this.value"

@Component(
  {
    components: {
      ModalDialog
    }
  })
class SearchResultDialog extends ModalDialogClass {
  @Prop({default: false}) private readOnly!: boolean;
  @Ref('dialog') private dialog!: ModalDialogClass;
  @Ref('nameInput') private nameInput!: HTMLInputElement;
  @Ref('searchSpaceSelect') private searchSpaceSelect!: HTMLSelectElement;
  @Ref('localeSelect') private localeSelect!: HTMLSelectElement;

  private searchSpace: SearchSpace = SearchSpace.TERM;
  private keywords: Array<string> = [];
  private name = '';
  private nameMaxLength = PatentEngineConstraints.FIELD_LENGTH_NAME_LONG;
  private text = '';
  private locale = SearchLocale.DE.toString();
  private key = 0;

  // For change detection - save button should only be enabled if there are changes.
  private originalLibraryEntity: LibraryEntity = {guid: "", name: "", keywords: [], text: "", locale: ""};

  // Only for exiting an existing entry
  private guid = '';

  private searchSpaces = Object.values(SearchSpace);
  private searchLocales = Object.values(SearchLocale);

  private isSearchSpaceSetForOpening = false;
  private copyListener: ((event: ClipboardEvent) => void) | undefined;
  private copyAll = false; // Will be true when using the icon button to trigger copying

  // Load the search result details
  openForSearchResult(searchResult: SearchResult): void {
    this.name = '';
    this.keywords = [];
    this.text = '';
    this.guid = '';
    this.locale = searchResult.locale;
    SearchModule.getSearchResultDetails(searchResult).then(() => {
      this.searchSpace = searchResult.searchSpace;
      this.dialog.openModal();
      if (!this.copyListener) {
        this.copyListener = registerSearchResultDialogCopyListener(searchResult.guid, this.isCopyAll, this.getText);
      }
    });
  }

  // Set given keywords and text and open the dialog for reading library entry
  openForLibEntryReading(libraryEntity: LibraryEntity): void {
    this.openForLibEntryReadingOrEditing(libraryEntity);
  }

  // Set given keywords and text and open the dialog for creating a new library entry
  openForLibEntryCreation(keywords: Array<string>, text: string, searchSpace?: SearchSpace, locale?: string): void {
    this.keywords = keywords;
    this.name = '';
    this.text = text;
    this.guid = '';

    if (searchSpace) {
      this.searchSpace = searchSpace;
      this.isSearchSpaceSetForOpening = true;
    } else {
      this.searchSpace = SearchModule.searchSpace;
      this.isSearchSpaceSetForOpening = false;
    }

    if (locale) {
      this.locale = locale;
    }

    this.dialog.openModal();
    setTimeout(() => {
      this.nameInput.focus();
    }, 100);
  }

  // Get name, keywords and text from an existing library entry and open the dialog for editing
  openForLibEntryEditing(libraryEntity: LibraryEntity): void {
    this.openForLibEntryReadingOrEditing(libraryEntity);
  }

  private mapBrickTypeToSearchSpace(brickType: BrickType): SearchSpace | undefined {
    switch (brickType) {
      case BrickType.A:
        return SearchSpace.TEXT_BLOCK_A;
      case BrickType.B:
        return SearchSpace.TEXT_BLOCK_B;
      case BrickType.OTHER:
        return SearchSpace.OTHER;
      default:
        return undefined;
    }
  }

  private getSearchSpaceLocalization(searchSpace: SearchSpace): string {
    if (this.guid === '' || this.readOnly) {
      if (this.guid === '' && !this.readOnly) {
        return `searchSpaceSingular.${searchSpace}.create`;
      } else {
        return `searchSpaceSingular.${searchSpace}.name`;
      }
    } else {
      return `searchSpaceSingular.${searchSpace}.edit`;
    }
  }

  private openForLibEntryReadingOrEditing(libraryEntity: LibraryEntity): void {
    this.keywords = libraryEntity.keywords;
    this.name = libraryEntity.name;
    this.text = libraryEntity.text;
    this.guid = libraryEntity.guid;
    this.locale = libraryEntity.locale;
    this.originalLibraryEntity = libraryEntity;

    const brickType = (libraryEntity as Brick).type;
    this.isSearchSpaceSetForOpening = true;
    if (brickType) {
      const searchSpace = this.mapBrickTypeToSearchSpace(brickType);
      if (searchSpace === undefined) {
        // If there is a new unknown type, just ignore
        this.isSearchSpaceSetForOpening = false;
      } else {
        this.searchSpace = searchSpace;
      }
    } else {
      this.searchSpace = SearchSpace.TERM;
    }

    this.dialog.openModal();
    setTimeout(() => {
      if (!this.readOnly) {
        this.nameInput.focus();
      }
    }, 100);
  }

  get getSearchSpace(): SearchSpace {
    return SearchModule.searchSpace;
  }

  private isCopyAll(): boolean {
    return this.copyAll;
  }

  private getText(): string {
    return this.text;
  }

  @Watch('getSearchSpace')
  private searchSpaceChanged(searchSpace: SearchSpace): void {
    // If we are just reading search result details we don't need to react
    if (this.readOnly || !this.isSearchSpaceSetForOpening) {
      return;
    }
    this.searchSpace = searchSpace;
  }

  get searchResultDetails(): Brick | TermDefinition {
    return SearchModule.resultDetails;
  }

  @Watch('searchResultDetails')
  private searchResultDetailsChanged(searchResultDetails: Brick | TermDefinition): void {
    // If we are creating a new library entry we don't want to react to this
    if (!this.readOnly) {
      return;
    }

    this.keywords = searchResultDetails.keywords;
    this.guid = searchResultDetails.guid;
    this.name = searchResultDetails.name;
    this.text = searchResultDetails.text;
    this.locale = searchResultDetails.locale;
  }

  get isLoading(): boolean {
    return LibraryModule.isLoading;
  }

  private keywordsChanged(values: Array<string>): void {
    this.keywords = values;
  }

  private isValid(): boolean {
    return !!this.name && !!this.text;
  }

  private hasChanges(): boolean {
    return this.text !== this.originalLibraryEntity.text || !this.sameKeywords() || this.name !== this.originalLibraryEntity.name ||
      this.locale !== this.originalLibraryEntity.locale;
  }

  private sameKeywords(): boolean {
    if (this.keywords.length !== this.originalLibraryEntity.keywords.length) {
      return false;
    }
    for (let idx = 0; idx < this.keywords.length; ++idx) {
      if (this.keywords[idx] !== this.originalLibraryEntity.keywords[idx]) {
        return false;
      }
    }
    return true;
  }

  private getCreateSaveButtonTitle(): string {
    if (!this.isValid()) {
      return this.$t('general.mandatoryFieldsMustBeFilledIn') as string;
    } else if (!this.hasChanges()) {
      return this.$t('general.cannotSaveWithoutChanges') as string;
    } else {
      return '';
    }
  }

  private createSaveLibraryEntry(): void {
    // In case of a term
    if (this.searchSpace === SearchSpace.TERM) {
      const createEvent: CreateTermDefinitionEvent = {
        name: this.name,
        keywords: this.keywords,
        text: this.text,
        locale: this.locale
      };
      if (this.guid === '') {
        LibraryModule.createTermDefinition(createEvent)
          .then(() => {
            this.dialog.closeModal();
            DisplaySuccessMessage('libraryEntryCreatedSuccessfully', [this.name])
          })
          .catch(useDefaultErrorHandling)
          .finally(() => { this.key++; });
      } else {
        LibraryModule.updateTermDefinition({...createEvent, guid: this.guid})
          .then(() => this.dialog.closeModal())
          .catch(useDefaultErrorHandling)
          .finally(() => { this.key++; });
      }
      return;
    }

    const activeBrickType = this.getActiveBrickType();
    // Only if the brick type is not unknown
    if (activeBrickType !== undefined) {
      const createEvent: CreateBrickEvent = {
        type: activeBrickType,
        name: this.name,
        keywords: this.keywords,
        text: this.text,
        locale: this.locale
      };
      if (this.guid === '') {
        LibraryModule.createBrick(createEvent)
          .then(() => {
            this.dialog.closeModal()
            DisplaySuccessMessage('brickEntryCreatedSuccessfully', [this.name])
          })
          .catch(useDefaultErrorHandling)
          .finally(() => { this.key++; });
      } else {
        LibraryModule.updateBrick({...createEvent, guid: this.guid})
          .then(() => this.dialog.closeModal())
          .catch(useDefaultErrorHandling)
          .finally(() => { this.key++; });
      }
    }
  }

  private getActiveBrickType(): BrickType | undefined {
    switch (this.searchSpace) {
      case SearchSpace.TEXT_BLOCK_A:
        return BrickType.A;
      case SearchSpace.TEXT_BLOCK_B:
        return BrickType.B;
      case SearchSpace.OTHER:
        return BrickType.OTHER;
      default:
        return undefined;
    }
  }

  private clickCopy(): void {
    this.copyAll = true;
    document.execCommand('copy');
    this.copyAll = false;
  }

  private removeCopyListener = (): void => {
    if (this.copyListener) {
      document.removeEventListener('copy', this.copyListener);
      this.copyListener = undefined;
    }
  }

  private selectOnChangeSearchSpace(): void {
    this.searchSpaceSelect.focus();
  }

  private selectOnChangeLocale(): void {
    this.localeSelect.focus();
  }
}

export default toNative(SearchResultDialog);
export {SearchResultDialog};
</script>

<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.search-result-dialog {
  .modal-dialog {
    $dialog-width: 780px;

    &-dialog {
      width: $dialog-width;
      max-height: 100%;
      overflow-x: auto !important;

      .modal-dialog-body {
        width: $dialog-width;
      }
    }

    &-header {
      h1 {
        margin: 0 auto;
        font-size: $font-size-h1;
        font-weight: normal;
        color: $text-color;
        line-height: normal;
        cursor: default;
      }

      .icon-button {
        margin-top: -12px;
        margin-right: -12px;
        padding: 6px !important;
      }
    }

    &-body {
      $field-height: 30px;
      $select-width: 184px;
      padding: 10px 32px 10px;
      min-height: 240px;
      // max-height: 560px;
      // overflow: hidden;
      margin-bottom: 20px;

      h2 {
        cursor: default;
      }

      label, input, textarea, select {
        font-size: $font-size-modal !important;
        text-align: start;
        margin-top: -((36 - $field-height)/2);
      }

      select {
        width: $select-width;
        height: $field-height;
      }

      input::placeholder, select::placeholder, textarea::placeholder {
        color: $pengine-grey;
      }

      input.tag-input {
        height: 24px !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }

      .column {
        float: left;
        padding-left: 0px;

        margin-bottom: 10px;

        &:first-of-type {
          margin-top: 5px;
        }

        input.name-input {
          width: 400px;
          height: $field-height;
          padding: 4px;
          font-size: $font-size-input;

          &:hover, &:focus {
            padding-left: 3px !important;
          }
        }
      }

      .keywords {
        text-align: left !important;
        margin-bottom: 10px;

        label {
          .exi { // eXXcellent Icons
            display: inline-block;
            // padding-top: 2px;
            margin-bottom: -3px;
          }
        }
      }

      .taginput {
        margin-top: 5px;

        .taginput-container {
          padding-top: 5px;
          padding-bottom: 5px;

          .tag:not(body) {
            background-color: $pengine-blue-dark !important;
            color: white;
            margin-bottom: 1px;

            a {
              &:before, &:after {
                background-color: white;
              }
            }
          }

          .autocomplete {
            padding-left: 0px;

            .control {
              input {
                padding-left: 24px !important;
              }

              .icon {
                width: 24px;
                height: 24px;
                font-size: 22px;
              }
            }
          }
        }
      }

      .taginput input {
        &:hover, &:focus {
          padding-left: 8px !important;
        }
      }
    }

    &-footer {
      padding-top: 0px;
      padding-bottom: 16px;

      .button-row {
        padding-right: 5px;

        button {
          width: 128px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
          margin-bottom: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@import 'src/assets/styles/constants';

.search-result-dialog {
  .tags {
    margin-left: auto;
    margin-right: 0;
  }

  .search-spaces, .label-and-field {
    .column {
      padding-bottom: 0px;
    }
  }

  .labels {
    text-align: left;
    min-width: 85px;
    width: 100px;
  }

  .button {
    display: flex;
    justify-content: space-between;
  }

  .tags .tag {
    margin-bottom: 0;
    padding-top: 1px;
    background-color: $pengine-grey-dark !important;
    color: white;
    cursor: default;
  }

  .name {
    text-align: left;
  }

  .name, textarea {
    margin-top: 8px !important;
    word-wrap: break-word;
  }

  .copy-button {
    margin-top: -32px;

    .icon-button {
      padding-right: 5px;
      float: right;
      border: none;
    }
  }

  .start {
    text-align: start; /* Other browsers e.g. Chrome */
    -moz-text-align-last: start; /* Firefox 12+ */
    white-space: pre-line;
    text-align-last: start;
    line-height: 1.3em;
  }

  .readonly-description {
    cursor: default;
    max-height: 330px;
    overflow-y: auto;
    margin-bottom: 16px;
    padding-right: 4px;
    font-size: $font-size-modal;
    margin-top: 8px;
  }

  textarea {
    width: 100%;
    height: 330px;
    padding: 4px;
    resize: none;

    &:hover, &:focus {
      padding: 3px !important;
    }
  }

}
</style>
