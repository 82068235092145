<template>
  <node-view-wrapper as="p"
                     :class="[getSemanticType(), 'block', {'no-logical-block': isNoLogicalBlock()}]"
                     :title="getTitle()"
                     :id="getGuid()"
                     :style="indent">
    <span v-if="hasEntryNo()" contenteditable="false">{{ getEntryNo() }}&nbsp;</span>
    <node-view-content as="span" class="description"></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import EditorModule from '@/store/modules/EditorModule';
import {StructuralBlockNodeView} from '@/components/applicationEditor/nodes/baseElements/views/StructuralBlockNodeView.vue';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of an entry within a list/table like reference sign list, adding the provided entry no before it's content.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class TableEntryView extends Vue {

  @Prop() node!: PmNode;

  private getSemanticType(): string {
    return this.node.attrs.semanticType;
  }

  private hasEntryNo(): boolean {
    return this.node.attrs.tableEntryNo && this.node.attrs.tableEntryNo.length > 0;
  }

  private getEntryNo(): string {
    return this.node.attrs.tableEntryNo;
  }

  private getTitle(): string {
    return ApplicationModule.templateText(`${this.node.attrs.semanticType}.tooltip`, this.node.attrs);
  }

  private getGuid(): string {
    return this.node.attrs.guid;
  }

  private isNoLogicalBlock(): boolean {
    return !this.node.attrs.logicalBlock;
  }

  /**
   * Gives every 'block-mode' element's child div the correct indent (padding & margin) and border.
   */
  get indent() {
    const depth: number = this.node.attrs.logicalDepth;
    const maxDepth = EditorModule.blockMaxDepth;
    return StructuralBlockNodeView.borderedIndent(depth, maxDepth)
  }
}

export default toNative(TableEntryView);
</script>

<style lang="scss" scoped>

p span:first-of-type {
  flex-shrink: 0;
  min-width: 50px;
}

p.block:not(.last-block) {
  margin-bottom: 17px;
}

p.block {
  margin-bottom: 2px;
}

</style>
