<template>
  <!-- Outer span is required thus you always need one root element -->
  <node-view-wrapper as="span" :class="['block-mode']">
    <!-- block mode -->
    <div :class="[getSemanticType(), 'block', 'text-container']"
         :id="getGuid()" :style="indent">
      <p contenteditable="false" v-show="hasHeaderText()" class="header">{{ getHeaderText() }}</p>
      <node-view-content></node-view-content>
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import EditorModule from '@/store/modules/EditorModule';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a structural, logical block node.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class StructuralBlockNodeView extends Vue {

  @Prop() node!: PmNode;

  private hasHeaderText(): boolean {
    return !!this.node.attrs.header;
  }

  private getHeaderText(): string {
    return this.hasHeaderText() ? this.node.attrs.header : '';
  }

  private getGuid(): string {
    return this.node.attrs.guid;
  }

  private getSemanticType(): string {
    return this.node.attrs.semanticType;
  }

  /**
   * Gives every 'block-mode' element's child div the correct indent (padding & margin) and border.
   */
  get indent() {
    const depth: number = this.node.attrs.logicalDepth;
    const maxDepth = EditorModule.blockMaxDepth;
    return StructuralBlockNodeView.borderedIndent(depth, maxDepth)
  }

  public static borderedIndent(depth: number, maxDepth: number) {
    const borderWidth = 1;
    const outerPaddingBaseValue = 3;  // The indent for the block's border (scaled by its depth)
    const innerPadding = 2;           // The padding between border and text

    const depthDiff = maxDepth - depth;

    const marginLeft = (-outerPaddingBaseValue * depthDiff) - borderWidth - innerPadding;
    const paddingHorizontal = (outerPaddingBaseValue * depthDiff) + innerPadding;
    const width = 740;

    return {
      'margin-left': `${marginLeft}px !important`,
      'padding-left': `${paddingHorizontal}px`,
      'padding-right': `${paddingHorizontal}px`,
      width: `${width}px !important`
    }
  }
}

export default toNative(StructuralBlockNodeView);
export {StructuralBlockNodeView};
</script>

<style lang="scss" scoped>

.block-mode {
  display: inline-block;
  box-sizing: content-box !important; // important for the width sizing of the div's
  padding-right: 12px;
}

.block-mode:not(.last-block) {
  margin-bottom: 17px;
}

.block-mode > div {
  margin-bottom: 2px;
  margin-top: 2px;
}

</style>
