import {mergeAttributes, Node as TiptapNode, VueNodeViewRenderer} from '@tiptap/vue-3'
import {getArrayFromDomAttribute, getBooleanFromDomAttribute} from '@/components/applicationEditor/utils/converter.util';
import CoverSheetView from '@/components/applicationEditor/nodes/coversheet/views/CoverSheetView.vue';

/**
 * The node for the cover sheet which has no children but will get visualized as a HTML table.
 */
export const CoverSheetNode = TiptapNode.create(
  {
    name: 'coverSheet',
    content: 'block*',
    group: 'block',
    selectable: false,
    atom: true,

    addAttributes() {
      return {
        guid: {default: '0'},
        semanticType: {default: ''},
        logicalBlock: {default: false},
        generatedBlock: {default: false},
        optionalBlock: {default: false},
        conditionalBlock: {default: false},
        creatableBlocks: {
          default: [],
          parseHTML: element => getArrayFromDomAttribute(element.getAttribute('creatableBlocks')),
          renderHTML: attributes => ({'creatableBlocks': attributes.creatableBlocks.join(",")})
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: 'coverSheet',
          getAttrs: (domNode) => {
            const domElement = domNode as Element;
            return {
              guid: domElement.getAttribute('guid'),
              logicalBlock: getBooleanFromDomAttribute(domElement.getAttribute('logicalBlock')),
              semanticType: domElement.getAttribute('semanticType'),
              generatedBlock: getBooleanFromDomAttribute(domElement.getAttribute('generatedBlock')),
              optionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('optionalBlock')),
              conditionalBlock: getBooleanFromDomAttribute(domElement.getAttribute('conditionalBlock')),
              creatableBlocks: getArrayFromDomAttribute(domElement.getAttribute('creatableBlocks'))
            };
          }
        }
      ];
    },

    renderHTML({HTMLAttributes}) {
      return ['coverSheet', mergeAttributes(HTMLAttributes, this.options.HTMLAttributes), 0];
    },

    addNodeView() {
      return VueNodeViewRenderer(CoverSheetView);
    }
  });
