import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_view_content = _resolveComponent("node-view-content")!
  const _component_node_view_wrapper = _resolveComponent("node-view-wrapper")!

  return (_openBlock(), _createBlock(_component_node_view_wrapper, {
    as: "span",
    class: "placeholder-text",
    contenteditable: "false",
    onDragstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.preventDragStart($event))),
    onDrop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.preventDrop($event)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_node_view_content, { as: "span" })
    ]),
    _: 1
  }))
}