import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';

import store from '../index';
import {HeaderState} from '@/store/models/header.model';

@Module({dynamic: true, namespaced: true, store, name: 'header'})
class HeaderModule extends VuexModule implements HeaderState {

  // initial state
  private _isLoading = false;
  private _pinnedForRoute: { [key: string]: boolean | undefined } = {};
  private _activeTab = 0;

  get isLoading(): boolean {
    return this._isLoading;
  }

  get pinnedForRoute(): { [key: string]: boolean | undefined } {
    return this._pinnedForRoute;
  }

  get activeTab(): number {
      return this._activeTab;
  }

  @Mutation
  public setHeaderLoading(): void {
    this._isLoading = true;
  }

  @Mutation
  public setPinnedForRoute(params: { routeName: string; barPinned: boolean }): void {
    const newPinnedForRoute = {...this._pinnedForRoute};
    newPinnedForRoute[params.routeName] = params.barPinned;
    this._pinnedForRoute = newPinnedForRoute;
  }

  @Mutation
  public setActiveTab(newActiveTab: number): void {
    this._activeTab = newActiveTab;
  }

  @Action
  async setHeaderBarStatusForRoute(params: { routeName: string; barPinned: boolean }): Promise<void> {
    this.setPinnedForRoute(params);
  }
}

export default getModule(HeaderModule);
