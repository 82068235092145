/**
 * The node object in the document structure node which contains all node attributes.
 */
import {AbstractBlockViewModel} from '@/api/models/editor.model';

export class DocumentStructureNode {

  private _name = "";
  private _index: string[] = [];
  private _guid = "";
  private _expanded = false;
  private _active = false;
  private _visible = true;
  private _isEmpty = true;
  private _logicalBlock = false;
  private _parentNode: DocumentStructureNode | null = null;
  private _children: DocumentStructureNode[] = [];
  private _reviewNeeded = false;
  private _properties: { [key: string]: string } = {};

  constructor(abstractBlockViewModel?: AbstractBlockViewModel, oldNode?: DocumentStructureNode | undefined) {
    if (abstractBlockViewModel) {
      this.name = abstractBlockViewModel.semanticType;
      this.guid = abstractBlockViewModel.guid;
      this.logicalBlock = abstractBlockViewModel.logicalBlock;
      this.reviewNeeded = abstractBlockViewModel.reviewNeeded;

      this._properties = abstractBlockViewModel.properties;

      const claimNo = abstractBlockViewModel.properties['claimNo']
      if (claimNo !== undefined) {
        this.index = [claimNo.toString()];
      }
    }

    if (oldNode) {
      this.expanded = oldNode.expanded;
      this.active = oldNode.active;
      this.visible = oldNode.visible;
    }
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  hasChildren(): boolean {
    return this._children.length > 0;
  }

  isCoversheet(): boolean {
    return this._name === "coverSheet";
  }

  addChildNode(node: DocumentStructureNode): void {
    this._children.push(node);
  }

  getPropertyValueByKey(key: string): string {
    return this._properties[key];
  }

  get logicalBlock(): boolean {
    return this._logicalBlock;
  }

  set logicalBlock(value: boolean) {
    this._logicalBlock = value;
  }

  get children(): DocumentStructureNode[] {
    return this._children;
  }

  set children(value: DocumentStructureNode[]) {
    this._children = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get index(): string[] {
    return this._index;
  }

  set index(value: string[]) {
    this._index = value;
  }

  get guid(): string {
    return this._guid;
  }

  set guid(value: string) {
    this._guid = value;
  }

  set parentNode(value: DocumentStructureNode | null) {
    this._parentNode = value;
  }

  get parentNode(): DocumentStructureNode | null {
    return this._parentNode;
  }

  get expanded(): boolean {
    return this._expanded;
  }

  set expanded(value: boolean) {
    this._expanded = value;
  }

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }


  get isEmpty(): boolean {
    return this._isEmpty;
  }

  set isEmpty(value: boolean) {
    this._isEmpty = value;
  }

  get reviewNeeded(): boolean {
    return this._reviewNeeded;
  }

  set reviewNeeded(value: boolean) {
    this._reviewNeeded = value;
  }

  get properties(): { [p: string]: string } {
    return this._properties;
  }

  set properties(value: { [p: string]: string }) {
    this._properties = value;
  }
}
