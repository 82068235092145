import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_view_content = _resolveComponent("node-view-content")!
  const _component_node_view_wrapper = _resolveComponent("node-view-wrapper")!

  return (_openBlock(), _createBlock(_component_node_view_wrapper, {
    as: "span",
    class: _normalizeClass([this.node.attrs.semanticType, 'block', 'no-logical-block', {'inline-non-logical': _ctx.isInline()}]),
    id: _ctx.getGuid()
  }, {
    default: _withCtx(() => [
      _createVNode(_component_node_view_content, { as: "span" })
    ]),
    _: 1
  }, 8, ["class", "id"]))
}