import {Mark, mergeAttributes} from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    aiGenerated: {
      setAiGenerated: () => ReturnType,
      toggleAiGenerated: () => ReturnType,
      unsetAiGenerated: () => ReturnType,
    }
  }
}

export const AiGenerated = Mark.create(
  {
    name: 'aiGenerated',

    addOptions() {
      return {
        HTMLAttributes: {},
      }
    },

    parseHTML() {
      return [
        {
          tag: 'span.ai-generated',
        },
      ]
    },

    renderHTML({HTMLAttributes}) {
      return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {class: 'ai-generated'}), 0]
    },

    addCommands() {
      return {
        setAiGenerated: () => ({commands}) => {
          return commands.setMark(this.name)
        },
        toggleAiGenerated: () => ({commands}) => {
          return commands.toggleMark(this.name)
        },
        unsetAiGenerated: () => ({commands}) => {
          return commands.unsetMark(this.name)
        },
      }
    },
  });
