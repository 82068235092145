/**
 * This class holds variables for constraints on entity fields
 */
export class PatentEngineConstraints {

  /**
   * Length for labels
   */
  public static FIELD_LENGTH_LABEL = 30;

  /**
   * Length for names
   */
  public static FIELD_LENGTH_NAME = 50;

  /**
   * Length for long names
   */
  public static FIELD_LENGTH_NAME_LONG = 100;

  /**
   * Length for short names
   */
  public static FIELD_LENGTH_NAME_SHORT = 30;

  /**
   * Length for descriptions
   */
  public static FIELD_LENGTH_DESCRIPTION = 150;

  /**
   * Length for addresses
   */
  public static FIELD_LENGTH_ADDRESS = 255;

  /**
   * Length for long rich text
   */
  public static FIELD_LENGTH_TEXT_LONG = 3000;

  /**
   * The maximum length of generic term strings in the worklist
   */
  public static MAX_GENERIC_TERM_TEXT_LENGTH = 80;

  /**
   * The maximum length of a spellcheck dictionary word
   */
  public static FIELD_LENGTH_SPELLCHECK_WORD = 100;

  /**
   * The maximum length for an ai template text
   */
  public static AI_TEMPLATE_TEXT_LENGTH = 8192;
}
