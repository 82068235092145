function hashCodeForString(val: string): number {
  let hash = 0, i, chr;
  if (val.length === 0) {
    return hash;
  }
  for (i = 0; i < val.length; i++) {
    chr = val.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

/**
 * Calculates hash code for one or multiple values of any type.
 * The values are converted to strings and concatenated, then the hash value for the whole string is calculated.
 *
 * @param vals array of input values
 * @returns hash code
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hashCode(...vals: any): number {
  let str = "";
  for (const val of vals) {
    str += val + "_";
  }
  return hashCodeForString(str);
}
