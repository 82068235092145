import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '@/store';
import {
  DocumentEditorSplitState,
  initialDoubleEditorSplitStateHorizontal,
  initialDoubleEditorSplitStateVertical,
  initialModeAndSplit,
  initialSingleEditorSplitState,
  isModeAndSplitEqual,
  ModeAndSplit,
  SingleDocumentEditorSplitState,
  TwoDocumentEditorSplitState,
  TwoDocumentEditorsSplitMode
} from '@/api/models/editor.model';
import {
  GetActiveDocumentEditorSplitState,
  GetDocumentEditorSplitStateForOneEditor,
  GetDocumentEditorSplitStateForTwoEditors,
  ToggleDocumentEditorSplitState,
  ToggleDocumentEditorSplitStateParam,
  UpdateDocumentEditorSplitStateForTwoEditors,
  UpdateDocumentEditorSplitStateForTwoEditorsParam
} from '@/api/services/userprofile.api';

@Module({dynamic: true, namespaced: true, store, name: 'userProfile'})
class UserProfileModule extends VuexModule {

  private _singleDocumentEditorSplitState: SingleDocumentEditorSplitState = initialSingleEditorSplitState;

  private _twoDocumentEditorSplitStateHorizontal: TwoDocumentEditorSplitState = initialDoubleEditorSplitStateHorizontal;

  private _twoDocumentEditorSplitStateVertical: TwoDocumentEditorSplitState = initialDoubleEditorSplitStateVertical;

  private _modeAndSplit: ModeAndSplit = initialModeAndSplit;

  public get singleDocumentEditorSplitState(): SingleDocumentEditorSplitState {
    return this._singleDocumentEditorSplitState;
  }

  @Mutation
  public setSingleDocumentEditorSplitState(value: SingleDocumentEditorSplitState) {
    this._singleDocumentEditorSplitState = value;
  }

  public get twoDocumentEditorSplitStateHorizontal(): TwoDocumentEditorSplitState {
    return this._twoDocumentEditorSplitStateHorizontal;
  }

  @Mutation
  public setTwoDocumentEditorSplitStateHorizontal(value: TwoDocumentEditorSplitState) {
    this._twoDocumentEditorSplitStateHorizontal = value;
  }

  get twoDocumentEditorSplitStateVertical(): TwoDocumentEditorSplitState {
    return this._twoDocumentEditorSplitStateVertical;
  }

  @Mutation
  public setTwoDocumentEditorSplitStateVertical(value: TwoDocumentEditorSplitState) {
    this._twoDocumentEditorSplitStateVertical = value;
  }

  get activeDocumentEditorSplitState(): DocumentEditorSplitState {
    return this.singleDocumentEditorSplitState;
  }

  public get modeAndSplit(): ModeAndSplit {
    return this._modeAndSplit;
  }

  @Mutation
  public setActiveModeAndSplit(value: ModeAndSplit) {
    this._modeAndSplit = value;
  }

  @Action
  public async getSingleDocumentEditorSplitState(userId: string): Promise<SingleDocumentEditorSplitState> {
    return GetDocumentEditorSplitStateForOneEditor(userId)
      .then((s: SingleDocumentEditorSplitState) => {
        this.setSingleDocumentEditorSplitState(s);
        return this._singleDocumentEditorSplitState;
      })
      .catch((error) => {
        this.setSingleDocumentEditorSplitState(initialSingleEditorSplitState);
        throw error;
      });
  }

  @Action
  public async getTwoDocumentEditorHorizontalSplitState(userId: string): Promise<TwoDocumentEditorSplitState> {
    return GetDocumentEditorSplitStateForTwoEditors(userId, TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY)
      .then((splitState: TwoDocumentEditorSplitState) => {
        this.setTwoDocumentEditorSplitStateHorizontal(splitState);
        return this._twoDocumentEditorSplitStateHorizontal;
      })
      .catch((error) => {
        this.setTwoDocumentEditorSplitStateHorizontal(initialDoubleEditorSplitStateHorizontal)
        throw error;
      });
  }

  @Action
  public async getTwoDocumentEditorVerticalSplitState(userId: string): Promise<TwoDocumentEditorSplitState> {
    return GetDocumentEditorSplitStateForTwoEditors(userId, TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY)
      .then((splitState: TwoDocumentEditorSplitState) => {
        this.setTwoDocumentEditorSplitStateVertical(splitState);
        return this._twoDocumentEditorSplitStateVertical;
      })
      .catch((error) => {
        this.setTwoDocumentEditorSplitStateVertical(initialDoubleEditorSplitStateVertical);
        throw error;
      });
  }

  @Action
  public async toggleActiveDocumentEditorSplitState(param: ToggleDocumentEditorSplitStateParam): Promise<void> {
    return ToggleDocumentEditorSplitState(param)
      .then((newSplitState: DocumentEditorSplitState) => {
        if(!isModeAndSplitEqual(this._modeAndSplit, newSplitState)){
          this.setActiveModeAndSplit(newSplitState);
        }
      return Promise.resolve();
    }).catch((error) => {
      return error;
    });
  }

  @Action
  public async getActiveDocumentEditorSplitState(userId: string): Promise<void> {
    return GetActiveDocumentEditorSplitState(userId)
      .then((newSplitState: DocumentEditorSplitState) => {
      this.setActiveModeAndSplit(newSplitState);
      return Promise.resolve();
    }).catch((error) => {
      return error;
    });
  }

  @Action
  public async updateDocumentEditorSplitStateForTwoEditors(param: UpdateDocumentEditorSplitStateForTwoEditorsParam): Promise<void> {
    return UpdateDocumentEditorSplitStateForTwoEditors(param.userId, param.splitState)
      .then((newSplitState: TwoDocumentEditorSplitState) => {
        switch (newSplitState.split){
          case TwoDocumentEditorsSplitMode.SPLIT_HORIZONTALLY: this.setTwoDocumentEditorSplitStateHorizontal(newSplitState);
            break;
          case TwoDocumentEditorsSplitMode.SPLIT_VERTICALLY: this.setTwoDocumentEditorSplitStateVertical(newSplitState);
            break;
        }
      return newSplitState;
    }).catch((error) => {
      return error;
    });
  }
}

export default getModule(UserProfileModule);