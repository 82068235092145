<template>
  <span class="confirmation-dialog">

    <!-- Use the modal component, pass in the prop -->
    <modal ref="dialog">
      <template v-slot:header>
        <h1>{{ $t(titleKey, titleValues) }}</h1>
      </template>
      <template v-slot:body>
        {{ $t(questionKey, questionValues) }}
      </template>
      <template v-slot:footer>
        <div class="button-row">
          <div class="button-container" v-for="option in options" :key="option.labelKey">
            <button :class="'column ' + option.class ? option.class : ''" v-on:click.once="option.callback(); dialog.closeModal();"
                    :ref="'option' + option.id">
              {{ $t(option.labelKey) }}
            </button>
          </div>
        </div>
      </template>
    </modal>

  </span>
</template>

<script lang="ts">
import {Component, Ref, toNative} from 'vue-facing-decorator';
import Modal, {ModalDialog as ModalDialogClass} from '@/components/common/ModalDialog.vue';
import {ConfirmationDialogOption, ConfirmationDialogParameters} from '@/store/models/confirmationDialog.model';

@Component(
  {
    components: {
      Modal
    }
  })
class ConfirmationDialog extends ModalDialogClass {
  @Ref('dialog') private dialog!: ModalDialogClass;

  private titleKey = '';
  private titleValues?: Array<string> = [];
  private questionKey = '';
  private questionValues?: Array<string> = [];
  private options: Array<ConfirmationDialogOption> = [];

  open(params: ConfirmationDialogParameters): void {
    this.titleKey = params.titleKey;
    this.titleValues = params.titleValues;
    this.questionKey = params.questionKey;
    this.questionValues = params.questionValues;
    let optionId = 0;
    this.options = params.options.map((option: ConfirmationDialogOption): ConfirmationDialogOption => {
      if (option.autofocus) {
        this.setFocus(optionId);
      }
      return {
        ...option,
        id: optionId++,
        callback: (): void => {
          if (option.callback) {
            option.callback();
          }
          this.dialog.closeModal();
        }
      }
    });
    this.dialog.openModal();
  }

  // Set focus on the button with the given option id
  private setFocus(optionId: number): void {
    setTimeout(() => {
      // eslint-disable-next-line
      (this.$refs['option' + optionId] as any)[0].focus();
    }, 100);
  }
}

export default toNative(ConfirmationDialog);
export {ConfirmationDialog};
</script>

<style lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.confirmation-dialog {
  .modal-dialog {
    &-dialog {

      .modal-dialog-body {
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: initial;
      }
    }

    &-header {
      h1 {
        margin: 0 auto;
      }
    }

    &-footer {
      padding-top: 0px;
      padding-bottom: 16px;

      .button-row {
        padding-right: 5px;

        button {
          width: 128px;
          height: 30px;
          padding: 4px;
          margin-left: 8px;
          margin-right: 8px;
        }

        .button-container {
          float: right;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.cancel {
  float: right;
  margin-right: 8px;
}

.ok {
  float: left;
  margin-left: 8px;
}
</style>
