<template>
  <span class="paste-container" v-if="nodes().length > 0">
    <span v-if="!isLoading">
      <b-button type="is-info"
                v-for="node in nodes()"
                :key="node.attrs.guid"
                @mousedown.left="onClick()"
                :title="$t(`menubar.pasteText`)">
        <i class="exi exi-paste"/>
      </b-button>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue, Watch} from 'vue-facing-decorator';
import {Editor, SingleCommands} from '@tiptap/vue-3';
import {Node as PmNode} from "@tiptap/pm/model";
import {findAllAncestorNodes} from '@/components/applicationEditor/utils/node.util';
import EditorModule from '@/store/modules/EditorModule';
import {ApplicationDocument} from '@/api/models/application.model';
import ApplicationModule from '@/store/modules/ApplicationModule';

@Component
class Paste extends Vue {

  @Prop() editor!: Editor;
  @Prop() commands!: SingleCommands;
  private applicationDocumentGuid: string | null | undefined;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get currentApplicationDocument(): ApplicationDocument | null {
    return ApplicationModule.currentApplicationDocument;
  }

  @Watch('currentApplicationDocument', {immediate: true})
  private currentApplicationDocumentChanged(applicationDocument: ApplicationDocument | null) {
    this.applicationDocumentGuid = applicationDocument ? applicationDocument.guid : null;
  }

  private nodes(): PmNode[] {
    const nodes: PmNode[] = [];

    // Find all semantic types that are not logical blocks
    findAllAncestorNodes(this.editor.state.selection.$anchor, (ancestor) => ancestor.isTextblock)
      .reverse()
      .forEach((ancestor: PmNode) => nodes.push(ancestor));

    return nodes;
  }

  private onClick(): void {
    if (!this.applicationDocumentGuid) {
      return;
    }
    document.execCommand('paste');
  }
}

export default toNative(Paste);
</script>

<style lang="scss" scoped>

.paste-container {
  display: flex;
  flex-flow: row-reverse wrap;
  text-align: right;
}

</style>
