import {
  ApplicationFigureCreatedVmUpdate,
  ApplicationFigureDeletedVmUpdate,
  ApplicationFigureEdit,
  ApplicationFigurePreview,
  ApplicationFigureScalingUpdatedVmUpdate,
  ApplicationFigureShowFigNumberUpdatedVmUpdate,
  ApplicationFigureSymbolsCreatedVmUpdate,
  ApplicationFigureSymbolsDeletedVmUpdate,
  ApplicationFigureSymbolsUpdatedVmUpdate,
  ApplicationFigureThumbnail,
  ApplicationFigureUpdatedVmUpdate,
  CreateApplicationFigureSymbolsEvent,
  DeleteApplicationFigureSymbolsEvent,
  UpdateApplicationFigureEvent,
  UpdateApplicationFigureSymbolsEvent,
} from '@/api/models/applicationFigure.model';
import {axiosFigurePreviewInstance, axiosInstance} from '@/api';

const APPLICATION_FIGURE_PATH = '/figure';

export const GetApplicationFigure = async (guid: string): Promise<ApplicationFigureThumbnail> => {
  const res = await axiosInstance.get(`${APPLICATION_FIGURE_PATH}/${guid}`);
  return res?.data as ApplicationFigureThumbnail;
};

export const GetApplicationFigureEdit = async (guid: string): Promise<ApplicationFigureEdit> => {
  const res = await axiosInstance.get(`${APPLICATION_FIGURE_PATH}/${guid}/edit`);
  return res?.data as ApplicationFigureEdit;
};

export type ScalingUpdate = { guid: string; scaling: number };
export type ScalingResult = Pick<ApplicationFigureEdit, "scaling">;
export type FigNumberResult = Pick<ApplicationFigureEdit, "showFigureNumber">;

export const UpdateApplicationFigureScaling = async (payload: ScalingUpdate): Promise<ApplicationFigureScalingUpdatedVmUpdate> => {
  const formData = new FormData();
  formData.append('scaling', payload.scaling.toString())
  const res = await axiosInstance.post(`${APPLICATION_FIGURE_PATH}/${payload.guid}/edit/scaling`, formData);
  return res?.data as ApplicationFigureScalingUpdatedVmUpdate;
}

export const UpdateApplicationFigureShowNumber = async (guid: string, showFigureNumber: boolean): Promise<ApplicationFigureShowFigNumberUpdatedVmUpdate> => {
  const formData = new FormData();
  formData.append('showFigureNumber', showFigureNumber.toString());
  const res = await axiosInstance.post(`${APPLICATION_FIGURE_PATH}/${guid}/edit/figure_number`, formData);
  return res?.data as ApplicationFigureShowFigNumberUpdatedVmUpdate;
}

export const GetApplicationFigurePreview = async (guid: string): Promise<ApplicationFigurePreview> => {
  const res = await axiosFigurePreviewInstance.get(`${APPLICATION_FIGURE_PATH}/${guid}/preview`);
  return res?.data as ApplicationFigurePreview;
};

export const CreateApplicationFigure = async (formData: FormData): Promise<ApplicationFigureThumbnail> => {
  const res = await axiosFigurePreviewInstance.post(APPLICATION_FIGURE_PATH, formData, {
    timeout: 180000 // Maximum 3 minutes for maximum of 3 MB
  });
  return (res?.data as ApplicationFigureCreatedVmUpdate).applicationFigure;
};

export const UpdateApplicationFigure = async (formData: FormData): Promise<ApplicationFigureThumbnail> => {
  const res = await axiosFigurePreviewInstance.put(APPLICATION_FIGURE_PATH, formData, {
    timeout: 180000 // Maximum 3 minutes for maximum of 3 MB
  });
  return (res?.data as ApplicationFigureUpdatedVmUpdate).applicationFigure;
};

export const UpdateApplicationFigureMetadata = async (event: UpdateApplicationFigureEvent): Promise<ApplicationFigureThumbnail> => {
  const res = await axiosFigurePreviewInstance.put(APPLICATION_FIGURE_PATH, event);
  return (res?.data as ApplicationFigureUpdatedVmUpdate).applicationFigure;
};

export const DeleteApplicationFigure = async (guid: string): Promise<string> => {
  const res = await axiosInstance.delete(`${APPLICATION_FIGURE_PATH}/${guid}`);
  return (res?.data as ApplicationFigureDeletedVmUpdate).guid;
};

export const CreateApplicationFigureSymbols = async (guid: string, event: CreateApplicationFigureSymbolsEvent): Promise<ApplicationFigureSymbolsCreatedVmUpdate> => {
  const res = await axiosInstance.post(`${APPLICATION_FIGURE_PATH}/${guid}/businessOperations/create`, event);
  return (res?.data as ApplicationFigureSymbolsCreatedVmUpdate);
};

export const UpdateApplicationFigureSymbols = async (guid: string, event: UpdateApplicationFigureSymbolsEvent): Promise<ApplicationFigureSymbolsUpdatedVmUpdate> => {
  const res = await axiosInstance.post(`${APPLICATION_FIGURE_PATH}/${guid}/businessOperations/update`, event);
  return (res?.data as ApplicationFigureSymbolsUpdatedVmUpdate);
};

export const DeleteApplicationFigureSymbols = async (guid: string, event: DeleteApplicationFigureSymbolsEvent): Promise<ApplicationFigureSymbolsDeletedVmUpdate> => {
  const res = await axiosInstance.post(`${APPLICATION_FIGURE_PATH}/${guid}/businessOperations/delete`, event);
  return (res?.data as ApplicationFigureSymbolsDeletedVmUpdate);
};
