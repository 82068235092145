import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "header-bar" }
const _hoisted_2 = {
  key: 0,
  class: "devider"
}
const _hoisted_3 = { class: "button-icon" }
const _hoisted_4 = { class: "button-label" }
const _hoisted_5 = { class: "button-icon" }
const _hoisted_6 = { class: "button-label" }
const _hoisted_7 = { class: "button-icon" }
const _hoisted_8 = { class: "button-label" }
const _hoisted_9 = { class: "switch-label" }
const _hoisted_10 = { class: "switch-slider" }
const _hoisted_11 = { class: "switch-slider-flex" }
const _hoisted_12 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_switch = _resolveComponent("b-switch")!
  const _component_b_field = _resolveComponent("b-field")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_navbar_item = _resolveComponent("b-navbar-item")!
  const _component_b_navbar = _resolveComponent("b-navbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_navbar, null, {
      start: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
          return (_openBlock(), _createElementBlock("div", {
            key: button.id
          }, [
            (button === 'separator')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2))
              : (!button.dropdownList)
                ? (_openBlock(), _createBlock(_component_b_button, {
                    key: 1,
                    class: _normalizeClass(button.class),
                    title: _ctx.evaluateTooltip(button.tooltip),
                    onClick: ($event: any) => (button.func && button.func())
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("i", {
                          class: _normalizeClass('exi ' + _ctx.evaluateButtonIcon(button.icon))
                        }, null, 2)
                      ]),
                      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.evaluateButtonLabel(button.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "title", "onClick"]))
                : (_openBlock(), _createBlock(_component_b_dropdown, {
                    key: 2,
                    "mobile-modal": false,
                    "aria-role": "list",
                    "close-on-click": false,
                    ref_for: true,
                    ref: 'dropdown' + button.id
                  }, {
                    trigger: _withCtx(() => [
                      _createVNode(_component_b_button, {
                        class: _normalizeClass(button.class),
                        title: _ctx.evaluateTooltip(button.tooltip),
                        onClick: ($event: any) => (button.func && button.func())
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("i", {
                              class: _normalizeClass('exi ' + _ctx.evaluateButtonIcon(button.icon))
                            }, null, 2)
                          ]),
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.evaluateButtonLabel(button.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "title", "onClick"])
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(button.dropdownList, (dropdownItem) => {
                        return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                          key: dropdownItem.id,
                          "aria-role": "listitem",
                          onClick: ($event: any) => (_ctx.onDropdownItemClicked(dropdownItem, button.id))
                        }, {
                          default: _withCtx(() => [
                            (dropdownItem.type === 'button')
                              ? (_openBlock(), _createBlock(_component_b_button, {
                                  key: 0,
                                  class: _normalizeClass(dropdownItem.class),
                                  title: _ctx.evaluateTooltip(dropdownItem.tooltip)
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("i", {
                                        class: _normalizeClass('exi ' + dropdownItem.icon)
                                      }, null, 2)
                                    ]),
                                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.evaluateDropdownLabel(dropdownItem.label)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["class", "title"]))
                              : _createCommentVNode("", true),
                            (dropdownItem.type === 'slider')
                              ? (_openBlock(), _createBlock(_component_b_field, {
                                  key: 1,
                                  class: "switch-field",
                                  title: dropdownItem.value ? _ctx.evaluateTooltip(dropdownItem.labelOn) :
                       _ctx.evaluateTooltip(dropdownItem.label)
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(dropdownItem.value ? _ctx.evaluateDropdownLabel(dropdownItem.labelOn) :
                      _ctx.evaluateDropdownLabel(dropdownItem.label)), 1),
                                    _createElementVNode("div", _hoisted_10, [
                                      _createElementVNode("div", _hoisted_11, [
                                        _createVNode(_component_b_switch, {
                                          modelValue: dropdownItem.value,
                                          "onUpdate:modelValue": ($event: any) => ((dropdownItem.value) = $event),
                                          type: dropdownItem.options.type ? dropdownItem.options.type : ''
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "type"])
                                      ])
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["title"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1536))
          ]))
        }), 128))
      ]),
      end: _withCtx(() => [
        _createVNode(_component_b_navbar_item, { tag: "div" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_b_button, {
                class: "pin-button icon-button",
                title: _ctx.$t('header.' + (_ctx.barPinned ? 'un' : '') + 'pin'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pin()))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("i", {
                    class: _normalizeClass('exi ' + (_ctx.barPinned ? 'exi-chevron-up' : 'exi-pin'))
                  }, null, 2)
                ]),
                _: 1
              }, 8, ["title"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}