<template>
  <span v-if="!isLoading && canBeChangedToInline">
    <span v-if="isExplicitInline">
      <b-button type="is-info"
                @mousedown.left="inlineBlock(false)"
                :title="$t(`menubar.tooltip.paragraph`, [$t('menubar.create')])">
        <i class="exi exi-paragraph-spacing"/>
        {{ $t(`menubar.nodes.paragraph`) }}
      </b-button>
    </span>
    <span v-else>
      <b-button type="is-info"
                @mousedown.left="inlineBlock(true)"
                :title="$t(`menubar.tooltip.inline`, [$t('menubar.create')])">
        <i class="exi exi-align-justify"/>
        {{ $t(`menubar.nodes.inline`) }}
      </b-button>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Inject, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode, ResolvedPos} from '@tiptap/pm/model';
import {
  findDepthOfLogicalBlock,
  findDescendants,
  findLogicalBlock,
  isExplicitInlineBlock,
  isLogicalBlock
} from '@/components/applicationEditor/utils/node.util';
import EditorModule from '@/store/modules/EditorModule';
import {SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {EditorState} from '@tiptap/pm/state';
import {useDefaultErrorHandling} from '@/errorHandling';


/**
 * Component to add a button to set the currently selected block to inline
 */
@Component
class InlineBlock extends Vue {

  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Inject({from: 'applicationEditor.activeEditorState'}) state!: EditorState;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get editorState() {
    return this.state;
  }

  get canBeChangedToInline(): boolean {
    if (this.editorState === undefined) {
      return false;
    }
    const resolvedPos: ResolvedPos = this.editorState.selection.$anchor;

    return this.isInlineable(resolvedPos) && !this.isLeftMostInlineableLogicalBlockWithinParent(resolvedPos);
  }

  get isExplicitInline(): boolean {
    if (this.editorState === undefined) {
      return false;
    }
    const selectionFrom: number = this.editorState.selection.from;
    const logicalParent = findLogicalBlock(this.editorState.doc, selectionFrom);
    return (logicalParent) ? isExplicitInlineBlock(logicalParent) : false;
  }

  private isInlineable(resolvedPos: ResolvedPos): boolean {
    const depth = findDepthOfLogicalBlock(resolvedPos);
    const logicalBlock = resolvedPos.node(depth);
    return logicalBlock?.attrs.inlineable;
  }

  private isLeftMostInlineableLogicalBlockWithinParent(resolvedPos: ResolvedPos): boolean {
    let isLeftMost = false;
    const pos = resolvedPos.pos;
    const logicalBlockDepth = findDepthOfLogicalBlock(resolvedPos);
    const parentDepth = logicalBlockDepth - 1;
    if (parentDepth >= 0) {
      const parent = resolvedPos.node(parentDepth);
      const logicalBlockSiblings = findDescendants(parent,
                                                   (node) => !isLogicalBlock(node),
                                                   (node) => isLogicalBlock(node),
                                                   resolvedPos.start(parentDepth));

      let previousSibling: PmNode | undefined = undefined;
      logicalBlockSiblings.forEach((sibling, index) => {
        if (pos >= sibling.start && pos <= sibling.end) {
          // either the node is the left most child or the sibling to the left is inlinable
          isLeftMost = !previousSibling || !previousSibling.attrs.inlineable;
        } else {
          previousSibling = sibling.node;
        }
      });
    }
    return isLeftMost;
  }

  inlineBlock(inline: boolean): boolean {
    const resolve = this.state.selection.$from;
    const depth = findDepthOfLogicalBlock(resolve);

    if (depth >= 0) {
      const logicalNode = resolve.node(depth);

        this.saveEditorChangesFn(false)
          .catch(useDefaultErrorHandling)
          .then(() => {
            EditorModule.setBlockInline({
              guid: logicalNode.attrs.guid,
              isInline: inline
            });
          });
    }
    return true;
  }
}

export default toNative(InlineBlock);
</script>
