<template>
  <div class="history-block">
    <!-- UNDO -->
    <div v-if="undoVisible && !isUndoLoading" class="container">
      <b-button @click="undoClicked()" :title="undoButtonTitle" :disabled="isLoading || isGenerateUnRedoLoading">
        <i class="exi exi-undo"/>
      </b-button>
      <b-dropdown :mobile-modal="false" aria-role="list" scrollable
                  :max-height="setMaxNumberOfLines(10)"
                  position="is-bottom-left"
                  @active-change="toggleActiveChange"
                  @mouseleave="maxIndexForHighlighting=-1">
        <template #trigger>
          <b-button class="dropdown-button" :title="$t(`menubar.tooltip.undoList`)" :disabled="isLoading || isGenerateUnRedoLoading">
            <i class="exi exi-chevron-down"/>
          </b-button>
        </template>
        <b-dropdown-item @click="undoClicked(index)"
                         @mouseover="maxIndexForHighlighting=index"
                         aria-role="listitem"
                         v-for="(action, index) of undoStack"
                         :title="action.actionName"
                         :class="(index<=maxIndexForHighlighting) ? 'highlight' : ''"
                         :key="index">
          {{ action.actionName }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-else class="container-placeholder">
      <button v-if="isUndoLoading" :title="$t('general.loading')" class="icon-button loading-button">
        <i class="exi exi-small-spinner-unmasked rotating"/>
      </button>
    </div>

    <!-- REDO -->
    <div v-if="redoVisible && !isRedoLoading" class="container">
      <b-button @click="redoClicked()" :title="redoButtonTitle" :disabled="isLoading || isGenerateUnRedoLoading">
        <i class="exi exi-redo"/>
      </b-button>
      <b-dropdown :mobile-modal="false" aria-role="list" scrollable
                  :max-height="setMaxNumberOfLines(10)"
                  position="is-bottom-left"
                  :disabled="isLoading || isGenerateUnRedoLoading"
                  @active-change="toggleActiveChange"
                  @mouseleave="maxIndexForHighlighting=-1">
        <template #trigger>
          <b-button class="dropdown-button" :title="$t(`menubar.tooltip.redoList`)">
            <i class="exi exi-chevron-down"/>
          </b-button>
        </template>
        <b-dropdown-item @click="redoClicked(index)"
                         @mouseover="maxIndexForHighlighting=index"
                         aria-role="listitem"
                         v-for="(action, index) of redoStack"
                         :title="action.actionName"
                         :class="(index<=maxIndexForHighlighting) ? 'highlight' : ''"
                         :key="index">
          {{ action.actionName }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-else class="container-placeholder">
      <button v-if="isRedoLoading" :title="$t('general.loading')" class="icon-button loading-button">
        <i class="exi exi-small-spinner-unmasked rotating"/>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, toNative, Vue, Watch} from 'vue-facing-decorator';
import EditorModule from '../../../store/modules/EditorModule';
import {translateBlockLocalizedMessage, UndoRedoAction} from '@/components/applicationEditor/menubar/UndoRedoAction';
import {PatentEngineHistory, PatentengineHistoryPluginKey} from '@/components/applicationEditor/plugins/PatentengineHistoryPlugin';
import {CommandStackItemViewModel, SemanticType} from '@/api/models/editor.model';
import {ApplicationEditor as ApplicationEditorClass, NamedEditor} from '@/components/ApplicationEditor.vue';
import {calcGuidOfLogicalBlock, findNodeByGuid} from '@/components/applicationEditor/utils/node.util';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {Command} from '@tiptap/vue-3';
import {useDefaultErrorHandling} from '@/errorHandling';

/**
 * Component to manage the history including undo and redo.
 */
@Component
class History extends Vue {

  @Prop({required: true}) editor!: NamedEditor;
  @Prop() commands!: { [key: string]: Command };
  @Prop() applicationeditor!: ApplicationEditorClass;

  private undoStack: Array<UndoRedoAction> = [];
  private redoStack: Array<UndoRedoAction> = [];
  // To remember the last logical block we had focus in, before opening the undo/redo dropdown, so we can set back the focus
  private logicalBlockGuidBeforeDropdown = '';
  //index in list of which elements to highlight
  private maxIndexForHighlighting = -1;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get isGenerateUnRedoLoading(): boolean {
    return EditorModule.isGenerateUnRedoLoading;
  }

  private setMaxNumberOfLines(lines: number): number {
    return 20 * lines;
  }

  get undoButtonTitle(): string {
    const actionName = this.undoStack[0].actionName;
    return this.$t(`menubar.tooltip.undo`, [actionName]) as string;
  }

  get undoVisible(): boolean {
    return (this.undoStack.length !== 0);
  }

  get isUndoLoading(): boolean {
    return EditorModule.isUndoLoading;
  }

  private get localUndoCount(): number {
    return EditorModule.localUndoCount;
  }

  private get localRedoCount(): number {
    return EditorModule.localRedoCount;
  }

  private get remoteUndoStack(): Array<CommandStackItemViewModel> {
    return EditorModule.undoStack;
  }

  private get remoteRedoStack(): Array<CommandStackItemViewModel> {
    return EditorModule.redoStack;
  }

  /**
   * We only update the undo and redo stack whenever the local or remote history has changed.
   * This improves performance because updating for every ProseMirror event re-renders the component on every keystroke.
   * @private
   */
  @Watch('$i18n.locale')
  @Watch('localUndoCount', {immediate: true})
  @Watch('localRedoCount', {immediate: true})
  @Watch('remoteUndoStack', {immediate: true, deep: true})
  @Watch('remoteRedoStack', {immediate: true, deep: true})
  private historyChanged(): void {
    this.undoStack = this.calcUndoStack();
    this.redoStack = this.calcRedoStack();
  }

  private calcUndoStack(): Array<UndoRedoAction> {
    // construct an undo stack
    // on top of the undo stack are items representing local undos, then followed by items representing remote undos
    // i.e.
    //
    // local undo 1
    // local undo 2
    // remote undo 1
    // remote undo 2
    // remote undo 3
    const localStack = new Array<UndoRedoAction>();
    const history = this.getHistory();
    if (history) {
      const numberOfUndos = history.undoCount;
      const lastChangedBlockGuid = history.changedBlockGuid;
      const changedNode = findNodeByGuid(this.editor.state.doc, lastChangedBlockGuid);
      const semanticType = (changedNode) ? changedNode.attrs.semanticType : SemanticType.APPLICATION_DOCUMENT;
      for (let i = 0; i < numberOfUndos; i++) {
        const translatedNode = ApplicationModule.templateText(semanticType + '.name', changedNode?.attrs);
        const actionName = this.$t('menubar.tooltip.localChanges', [translatedNode]);
        localStack.push(new UndoRedoAction(actionName.toString(), i));
      }
    }
    const remoteUndoStack = new Array<UndoRedoAction>();
    for (let i = EditorModule.undoStack.length - 1; i >= 0; i--) {
      const remoteItem: CommandStackItemViewModel = EditorModule.undoStack[i];
      const action = new UndoRedoAction(translateBlockLocalizedMessage(remoteItem.description), i, remoteItem.guid);
      remoteUndoStack.push(action);
    }
    const result = new Array<UndoRedoAction>(...localStack);
    result.push(...remoteUndoStack);
    return result;
  }

  // actionIndex is undefined if only the button is clicked
  private undoClicked(actionIndex?: number) {
    if (this.isLoading || this.isGenerateUnRedoLoading) {
      return;
    }

    this.cancelSaveOnBlur();

    const undoActions = this.undoStack;
    if (actionIndex === undefined) {
      // just remove the first item and undo it
      if (this.undoStack.length > 0) {
        const lastAction = undoActions[0];
        this.undo(lastAction);
      }
    } else {
      // if an specific undo action is clicked we need to undo all local actions first, so they will be stored at
      // the "redo"-branch of our local history plugin.
      // then we need to trigger only one item at the server, that will do a cumulative undo for all changes until that point.
      for (let i = 0; i <= actionIndex; i++) {
        this.undo(undoActions[i], (i == actionIndex));
      }
    }
  }

  private undo(undoAction: UndoRedoAction, last = true) {
    if (undoAction.guid) {
      if (last) {
        EditorModule.undo({applicationDocumentGuid: this.$route.params.applicationGuid as string, undoStepGuid: undoAction.guid}).catch(useDefaultErrorHandling);
      }
    } else {
      this.editor.commands.focus();
      const history = this.getHistory();
      if (history) {
        history.undo(this.editor.state, this.editor.view.dispatch);
      }
    }
  }

  get redoButtonTitle(): string {
    const actionName = this.redoStack[0].actionName;
    return this.$t(`menubar.tooltip.redo`, [actionName]) as string;
  }

  get redoVisible(): boolean {
    return (this.redoStack.length !== 0);
  }

  get isRedoLoading(): boolean {
    return EditorModule.isRedoLoading;
  }

  private calcRedoStack(): Array<UndoRedoAction> {
    // construct a redo stack
    // on top of the redo stack are items representing remote redos, then followed by items representing local redos.
    // The order of the redos is that redos for undos that happenend most recently are on top of the stack
    // i.e.
    //
    // remote undo 1
    // remote undo 2
    // remote undo 3
    // local undo 1
    // local undo 2

    // EDIT: jdommer (PENGINESUP-608)
    // Accesing redos after making local changes should not be possible, as the transaction will clash being applied to the wrong state.
    // Thereby, the Redo-Stack will no further be displayed as soon as a local undo operation is present.
    const history = this.getHistory();

    if (history && history.undoCount > 0 && history.redoCount > 0) {
      const localStack = new Array<UndoRedoAction>();

      const lastChangedBlockGuid = history?.changedBlockGuid;
      const changedNode = findNodeByGuid(this.editor.state.doc, lastChangedBlockGuid);
      const semanticType = (changedNode) ? changedNode.attrs.semanticType : SemanticType.APPLICATION_DOCUMENT;
      const numberOfRedos = history.redoCount;
      for (let i = 0; i < numberOfRedos; i++) {
        // add local items to undo stack, identified with calculated id
        const translatedNode = ApplicationModule.templateText(semanticType + '.name', changedNode?.attrs);
        const actionName = this.$t('menubar.tooltip.localChanges', [translatedNode]);
        localStack.push(new UndoRedoAction(actionName.toString(), i + EditorModule.redoStack.length));

      }
      return new Array<UndoRedoAction>(...localStack);
    }


    if(history && history.undoCount > 0) {
      return new Array<UndoRedoAction>();
    }

    // END: jdommer (PENGINESUP-608)

    const remoteRedoStack = new Array<UndoRedoAction>();
    for (let i = 0; i < EditorModule.redoStack.length; i++) {
      const remoteItem: CommandStackItemViewModel = EditorModule.redoStack[i];
      const action = new UndoRedoAction(translateBlockLocalizedMessage(remoteItem.description), i, remoteItem.guid);
      remoteRedoStack.push(action);
    }
    const localStack = new Array<UndoRedoAction>();

    const lastChangedBlockGuid = history?.changedBlockGuid;
    const changedNode = findNodeByGuid(this.editor.state.doc, lastChangedBlockGuid);
    const semanticType = (changedNode) ? changedNode.attrs.semanticType : SemanticType.APPLICATION_DOCUMENT;

    if (history) {
      const numberOfRedos = history.redoCount;
      for (let i = 0; i < numberOfRedos; i++) {
        // add local items to undo stack, identified with calculated id
        const translatedNode = ApplicationModule.templateText(semanticType + '.name', changedNode?.attrs);
        const actionName = this.$t('menubar.tooltip.localChanges', [translatedNode]);
        localStack.push(new UndoRedoAction(actionName.toString(), i + EditorModule.redoStack.length));
      }
    }
    const result = new Array<UndoRedoAction>(...remoteRedoStack);
    result.push(...localStack);
    return result;
  }

  // actionIndex is undefined if only the button is clicked
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private redoClicked(action?: number) {
    if (this.isLoading || this.isGenerateUnRedoLoading) {
      return;
    }

    this.cancelSaveOnBlur();

    const redoActions = this.redoStack;
    if (action === undefined) {
      if (this.redoStack.length > 0) {
        const action = redoActions[0];
        if (action.guid) {
          this.redoRemoteActionFollowedByLocalActions(action, [])
        } else {
          this.redoLocal(action);
        }
      }
    } else {
      const redoStack = this.redoStack;
      const targetAction = redoStack[action];
      if (targetAction.guid) {
        this.redoRemoteActionFollowedByLocalActions(targetAction, []);
      } else {
        // when clicking on a local redo we first need to call the server to redo changes on the serverside and as soon
        // as we have updated our representation of the text we need to apply the local changes we have saved
        const firstLocalChangeIdx = redoStack.findIndex(item => item.guid == null);

        if (firstLocalChangeIdx == -1) {
          // This case should not be impossibe to happen, because the redo stack already has guid that evaluates to false.
          // However if the targetAction.guid is 'undefined', redoStack.findIndex(item => item.guid == null)
          // won't find any matches and will return -1.
          return;
        } else if (firstLocalChangeIdx == 0) {
          // The local change comes first and is on top of the redo stack.
          // There are no server changes to be done. Note: very rare case.
          const actionIndex = redoStack.findIndex(item => item == targetAction);
          const localActions = redoStack.slice(0, actionIndex + 1);

          localActions.forEach(this.redoLocal);
        } else {
          const lastRemoteAction = redoStack[firstLocalChangeIdx - 1];
          const localActions = redoStack.slice(firstLocalChangeIdx, redoStack.length);

          this.redoRemoteActionFollowedByLocalActions(lastRemoteAction, localActions);
        }
      }
    }
  }

  private redoRemoteActionFollowedByLocalActions(remoteRedoAction: UndoRedoAction, localRedoActions: UndoRedoAction[]) {
    EditorModule.redo({applicationDocumentGuid: this.$route.params.applicationGuid as string, redoStepGuid: remoteRedoAction.guid!})
      .then((vmUpdate) => {
        localRedoActions.forEach(this.redoLocal)
      })
      .catch(useDefaultErrorHandling);
  }

  private redoLocal(redoAction: UndoRedoAction) {
    this.editor.commands.focus();
    const history = this.getHistory();
    if (history) {
      history.redo(this.editor.state, this.editor.view.dispatch);
    }
  }

  private cancelSaveOnBlur() {
    if (this.applicationeditor) {
      this.applicationeditor.cancelSaveOnBlur();
    }
  }

  private toggleActiveChange(active: boolean) {
    // Do nothing if there are no unsaved changes
    if (!this.editor || EditorModule.guidsOfChangedNodes.length <= 0) {
      return;
    }
    if (active) {
      // Remember which textblock was active (had focus) before we open the dropdown
      this.logicalBlockGuidBeforeDropdown = calcGuidOfLogicalBlock(this.editor.state.doc, this.editor.state.selection.to);

      // Block the saving
      this.cancelSaveOnBlur();
    } else {
      const currentLogicalBlockGuid = calcGuidOfLogicalBlock(this.editor.state.doc, this.editor.state.selection.to);

      // If the combobox closes because the user clicked somewhere (not in another block),
      // then the old selection still applies (the block has not changed), so just give back the focus.
      // When clicked into the editor in the previously active block, editor.focus() does nothing.
      // When clicked into another block, the condition will not be fullfilled and nothing will be changed.
      if (this.logicalBlockGuidBeforeDropdown && this.logicalBlockGuidBeforeDropdown === currentLogicalBlockGuid) {
        this.editor.commands.focus();
      }
    }
  }

  private getHistory(): PatentEngineHistory | null | undefined {
    return PatentengineHistoryPluginKey.getState(this.editor.state);
  }
}

export default toNative(History);
</script>

<style lang="scss" scoped>
@import "src/assets/styles/colors";

div {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
}

.history-block {
  .exi {
    margin: 0 !important;
  }

  // Remove button border in any case
  button, button.dropdown-button {
    border: 0px solid transparent !important;

    &:hover:enabled, &:focus:enabled {
      border: 0px solid transparent !important;
    }
  }

  // Styling for normal buttons
  button:not(.dropdown-button) {
    padding-right: 0px !important;
  }

  // Styling for dropdown buttons
  .dropdown-button {
    padding: 0 4px;
    height: 35px;

    .exi {
      width: 8px !important;
      height: 8px !important;
    }
  }

  .dropdown-item {
    font-size: 14px;
    font-weight: normal;
    padding: 1px 8px;

    display: block;
    text-align: start;
    vertical-align: middle;
    line-height: 18px;
    min-height: 20px;

    width: 550px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .highlight {
    background-color: whitesmoke;
    color: #0a0a0a;
  }

  .container-placeholder {
    width: 42px;
  }
}

</style>
