import i18n from '@/i18n';

/**
 * Replace the placeholder(s) in text with the adequate value from the properties.
 * @param text: text containing the placeholder
 * @param properties: maps each key (property of the node) to its actual value.
 */
export function replacePlaceholders(text: string, properties: { [key: string]: string }): string {
  // Regex to find the property(ies) between braces inside the text
  const reBrackets = /\{(.+?)\}/g;
  let foundKey;
  while ((foundKey = reBrackets.exec(text)) != null) {
    const foundProperty = properties[foundKey[1]];
    if (foundProperty) {
      const deletedClaim = foundKey[1] == "claimNo" && foundProperty == "-1";
      //replace the property with the actual value from the properties map where the key matches the extracted "foundKey"
      text = text.replace('{' + foundKey[1] + '}', deletedClaim ? i18n.global.t('deletedClaim') as string : foundProperty);
    }
  }
  return text;
}