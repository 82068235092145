<template>
  <span class="generate-container" v-if="generatedNodes().length > 0">
    <span v-if="!isGenerateBlockLoading">
      <b-button type="is-info"
                v-for="node in generatedNodes()"
                :key="node.attrs.guid"
                @mousedown.left="onClick(node)"
                :title="getToolTip(node)"
                :disabled="isLoading || isGenerateUnRedoLoading">
        <i class="exi exi-synchronize"/>
        {{ $t(`menubar.generate`) }}
      </b-button>
    </span>
    <span v-else>
      <button :title="$t('general.loading')" class="icon-button loading-button">
        <i class="exi exi-small-spinner-unmasked rotating"/>
      </button>
    </span>
  </span>
</template>

<script lang="ts">
import {Component, Inject, toNative, Vue} from 'vue-facing-decorator';
import {Node as PmNode} from '@tiptap/pm/model';
import EditorModule from '@/store/modules/EditorModule';
import {findAllAncestorNodes} from '@/components/applicationEditor/utils/node.util';
import ApplicationModule from '@/store/modules/ApplicationModule';
import {SaveChangesFn} from '@/components/ApplicationEditor.vue';
import {EditorState} from '@tiptap/pm/state';
import {useDefaultErrorHandling} from '@/errorHandling';

@Component
class GenerateBlock extends Vue {

  @Inject({from: 'applicationEditor.saveChanges'}) saveEditorChangesFn!: SaveChangesFn;
  @Inject({from: 'applicationEditor.activeEditorState'}) state!: EditorState;

  get isLoading(): boolean {
    return EditorModule.isLoading;
  }

  get isGenerateUnRedoLoading(): boolean {
    return EditorModule.isGenerateUnRedoLoading;
  }

  generatedNodes(): PmNode[] {
    const nodes: PmNode[] = [];

    // Find all semantic types that can be generated
    findAllAncestorNodes(this.state.selection.$anchor, (ancestor) => ancestor.attrs.generatedBlock
      && ancestor.attrs.semanticType !== undefined)
      .reverse()
      .forEach((ancestor: PmNode) => nodes.push(ancestor));

    return nodes;
  }

  get isGenerateBlockLoading(): boolean {
    return EditorModule.isGenerateBlockLoading;
  }

  onClick(node: PmNode): void {
    this.autoGenerator(node.attrs.guid)
  }

  autoGenerator(nodeGuid: string): boolean{
    this.saveEditorChangesFn(false)
      .catch(useDefaultErrorHandling)
      .then(() => EditorModule.generateBlock(nodeGuid));

    return true;
  }

  getToolTip(node: PmNode): string {
    return this.$t(`menubar.autoGenerator`, [ApplicationModule.templateText(`${node.attrs.semanticType}.type`)]).toString();
  }
}

export default toNative(GenerateBlock);
</script>

<style lang="scss" scoped>

.generate-container {
  display: flex;
  flex-flow: row-reverse wrap;
  text-align: right;

  button:disabled {
    background-color: transparent;
    border-color: transparent;
  }
}

</style>
