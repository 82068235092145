<template>
  <node-view-wrapper class="applicationDocument" spellcheck="false">
    <node-view-content></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import {Component, toNative, Vue} from 'vue-facing-decorator';
import {NodeViewContent, NodeViewWrapper} from '@tiptap/vue-3';

/**
 * The visualization of a {@link RootNode}.
 */
@Component(
  {
    components: {
      NodeViewWrapper,
      NodeViewContent
    }
  })
class ApplicationDocumentView extends Vue {
}

export default toNative(ApplicationDocumentView);

</script>

<style lang="scss">
@import 'src/assets/styles/constants';

.applicationDocument {
  font-family: $editor-font;
}

.ProseMirror {
  white-space: break-spaces;
}

.ProseMirror:focus {
  outline: none;
}
</style>
