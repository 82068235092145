/**
 * Util class to handle HTTP-streams (BigEndian-byte-streams of json objects) from the backend.
 * Processing of this stream works as follows:
 * the incoming stream-chunk contains: a 4 byte integer which describes the length of this chunk
 * and the chunk itself, which is an utf-8 encoded json object.
 * */
export class DynamicByteBuffer {
  buffer: Uint8Array;
  private capacity: number;
  private length: number;

  constructor(initialCapacity: number = 1024) {
    this.capacity = initialCapacity;
    this.buffer = new Uint8Array(this.capacity);
    this.length = 0;
  }

  append(data: Uint8Array): void {
    if (this.length + data.length > this.capacity) {
      this.grow(this.length + data.length);
    }
    this.buffer.set(data, this.length);
    this.length += data.length;
  }

  private grow(minCapacity: number): void {
    while (this.capacity < minCapacity) {
      this.capacity *= 2;
    }
    const newBuffer = new Uint8Array(this.capacity);
    newBuffer.set(this.buffer);
    this.buffer = newBuffer;
  }

  getBytes(): Uint8Array {
    return this.buffer.subarray(0, this.length);
  }

  readInt32BigEndian(): number | null {
    if (this.length >= 4) {
      const view = new DataView(this.buffer.buffer, this.buffer.byteOffset, this.length);
      return view.getInt32(0, false);
    } else {
      return null;
    }
  }

  getLength(): number {
    return this.length;
  }

  cutBytes(x: number): Uint8Array {
    if (x > this.length) {
      throw new Error("Not enough bytes in the buffer");
    }

    const cutBuffer = new Uint8Array(x);
    cutBuffer.set(this.buffer.subarray(0, x));  // Copy the first x bytes

    // Shift the remaining bytes to the start of the buffer
    this.buffer.copyWithin(0, x, this.length);
    this.length -= x;

    return cutBuffer;
  }
}