<template>
  <div class="logo-container">
    <b-dropdown
      v-if="!isLoading"
      :mobile-modal="false"
      :triggers="['hover']"
      position="is-bottom-left"
      aria-role="menu">
      <template #trigger>
        <img alt="DESIGN TO IP Logo" class="logo" src="../../assets/logo/PE_mit_Logo.svg">
      </template>

      <!-- privacy policy -->
      <!--      <b-dropdown-item aria-role="listitem"
                             :title="$t('regulatoryInformation.privacyPolicy')"
                             target="_blank"
                             rel="noopener noreferrer"
                             v-bind:href="`${$t('regulatoryInformation.languagePath')}/data-privacy-policy.html`">
              {{ $t('regulatoryInformation.privacyPolicy') }}
            </b-dropdown-item>-->

      <!-- terms of use -->
      <!--      <b-dropdown-item aria-role="listitem"
                             :title="$t('regulatoryInformation.termsOfUse')"
                             target="_blank"
                             rel="noopener noreferrer"
                             v-bind:href="`${$t('regulatoryInformation.languagePath')}/terms-of-use.html`">
              {{ $t('regulatoryInformation.termsOfUse') }}
            </b-dropdown-item>-->

      <!-- service level agreement -->
      <!--<b-dropdown-item aria-role="listitem"
                       :title="$t('regulatoryInformation.sla')"
                       target="_blank"
                       rel="noopener noreferrer"
                       v-bind:href="`${$t('regulatoryInformation.languagePath')}/service-level-agreement.html`">
        {{ $t('regulatoryInformation.sla') }}
      </b-dropdown-item>-->

      <!-- imprint -->
      <!--      <b-dropdown-item aria-role="listitem"
                             :title="$t('regulatoryInformation.imprint')"
                             target="_blank"
                             rel="noopener noreferrer"
                             v-bind:href="`${$t('regulatoryInformation.languagePath')}/imprint.html`">
              {{ $t('regulatoryInformation.imprint') }}
            </b-dropdown-item>-->
    </b-dropdown>

    <!-- loading icon -->
    <button v-if="isLoading"
            :title="$t('general.loading')"
            class="icon-button loading-button">
      <i class="exi exi-small-spinner-unmasked rotating"/>
    </button>
  </div>
</template>

<script lang="ts">
import {Component, toNative, Vue} from 'vue-facing-decorator';
import AuthModule from '@/store/modules/AuthModule';

@Component({})
class LogoMenu extends Vue {
  get isLoading(): boolean {
    return AuthModule.isLoading;
  }
}

export default toNative(LogoMenu);
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';

.logo-container {
  .logo {
    padding-top: 8px;
    padding-right: 24px;
    height: 54px;
    cursor: default;
  }
}

a.dropdown-item {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
}

a.dropdown-item:hover {
  color: $pengine-blue-dark;
}
</style>