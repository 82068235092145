import {Fragment, Node as PmNode, ResolvedPos} from '@tiptap/pm/model';
import {EditorState} from '@tiptap/pm/state';
import {EditorView} from '@tiptap/pm/view';
import {AbstractBlockViewModel} from '@/api/models/editor.model';
import {findLowestDepth} from '@/components/applicationEditor/utils/node.util';
import {cloneDeep} from 'lodash';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

function truncate(str: string, n: number) {
  return (str.length > n) ? `${str.substr(0, n - 1)}...` : str
}

function prettyPrintNode(node?: PmNode): Array<string> {
  const result: Array<string> = [];
  if (node === undefined) {
    result.push('undefined')
  } else {
    result.push(` type: '${node.type.name}'`)
    result.push(` size: '${node.nodeSize.toString().padStart(2, ' ')}'`)
    result.push(` isTextblock: '${node.isTextblock ? 1 : 0}'`)
    result.push(` isInline: '${node.isInline ? 1 : 0}'`)
    result.push(` isText: '${node.isText ? 1 : 0}'`)
    result.push(` isBlock: '${node.isBlock ? 1 : 0}'`)
    result.push(` guid: '${(node.attrs.guid ? node.attrs.guid : '').padStart(36, ' ')}'`)
    result.push(` semanticType: '${node.attrs.semanticType ? node.attrs.semanticType.padStart(19, ' ') : 'unknown'}'`)
    result.push(` content: '${truncate(node.textContent, 80)}'`)
  }
  return result
}

function prettyPrintNodeShort(node?: PmNode): Array<string> {
  const result: Array<string> = [];
  if (node === undefined) {
    result.push('undefined')
  } else {
    result.push(` type: '${node.attrs.semanticType}'`)
    result.push(` size: '${node.nodeSize}`)
    result.push(` isTextblock: '${node.isTextblock ? 1 : 0}'`)
    result.push(` isInline: '${node.isInline ? 1 : 0}'`)
    result.push(` isText: '${node.isText ? 1 : 0}'`)
    result.push(` isBlock: '${node.isBlock ? 1 : 0}'`)
    result.push(` guid: '${(node.attrs.guid ? node.attrs.guid : '')}'`)
    result.push(` content: '${truncate(node.textContent, 80)}'`)
  }
  return result
}

function prettyPrintFragment(fragment: Fragment): Array<string> {
  const result: Array<string> = [];
  if (fragment === undefined) {
    result.push('undefined')
  } else {
    result.push(` type: 'fragment'`)
    result.push(` size: ${fragment.size}`)
    result.push(` childCount: ${fragment.childCount}`)
  }
  return result
}

function prettyPrintPos(res: ResolvedPos): Array<string> {
  const result: Array<string> = [];
  result.push(`(${res.start(res.depth).toString().padStart(2, ' ')},${res.end(res.depth).toString().padStart(2, ' ')})`)
  return result
}

interface MapAndLevel<T> {
  map: Array<T>;
  level: number;
}

function indentedMessagesFrom(messages: Array<MapAndLevel<string>>): Array<string> {
  return messages.map(mapAndLevel => ' '.repeat(mapAndLevel.level * 4) + mapAndLevel.map.join(""))
}

function debugTraverseNodeInorder<T>(node: PmNode, level: number, map: (node: PmNode) => Array<T>): Array<MapAndLevel<T>> {
  let result: Array<MapAndLevel<T>> = []

  const mapAndLevel: MapAndLevel<T> = {
    map: map(node),
    level: level
  }
  result.push(mapAndLevel)
  for (let i = 0; i < node.childCount; i++) {
    result = result.concat(debugTraverseNodeInorder(node.content.child(i), level + 1, map))
  }
  return result
}

function debugTraverseFragmentInorder<T>(fragment: Fragment, mapFragment: (node: Fragment) => Array<T>, mapNode: (node: PmNode) => Array<T>): Array<MapAndLevel<T>> {
  let result: Array<MapAndLevel<T>> = []
  const level = 0
  const mapAndLevel: MapAndLevel<T> = {
    map: mapFragment(fragment),
    level: level
  }
  result.push(mapAndLevel)
  for (let i = 0; i < fragment.childCount; i++) {
    result = result.concat(debugTraverseNodeInorder(fragment.child(i), level + 1, mapNode))
  }
  return result
}


function debugPrintProseMirrorStructure(rootNode?: PmNode): Array<string> {
  const result: Array<string> = []
  if (rootNode === undefined) {
    result.push('undefined')
  } else {
    for (let i = 0; i < (rootNode.nodeSize - 1); ++i) {
      const res = rootNode.resolve(i)
      const depth = res.depth
      const nodeAtPos = res.node(depth)
      const line = `i=${i.toString().padStart(3, ' ')} ${prettyPrintPos(res).join('')} ${prettyPrintNode(nodeAtPos).join('')}`
      result.push(line)
    }
  }
  return result;
}

function debugPrintStructureAtAnchorLevel(level: number): ({state, view}: { state: EditorState, view: EditorView }) => boolean {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return function ({state, view}: { state: EditorState, view: EditorView }): boolean {
    const selection = state.selection
    const anchor = selection.$anchor
    const node = anchor.node(anchor.depth - level)
    let messages: Array<string> = []
    messages.push(`anchorNode: ${prettyPrintNode(node)}`)
    messages = messages.concat(debugPrintProseMirrorStructure(node))
    messages.forEach(function (msg) {
      console.log(msg)
    });
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugPrintStructureAtAnchor({state, view}: { state: EditorState, view: EditorView }): boolean {
  const selection = state.selection
  const anchor = selection.$anchor
  const node = anchor.node(anchor.depth)
  let messages: Array<string> = []
  messages.push(`anchorNode: ${prettyPrintNode(node)}`)
  messages = messages.concat(debugPrintProseMirrorStructure(node))
  messages.forEach(function (msg) {
    console.log(msg)
  });
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugDumpElementsAtAllDocumentPositions({state, view}: { state: EditorState, view: EditorView }): boolean {
  const messages = debugPrintProseMirrorStructure(state.doc)
  messages.forEach(function (msg) {
    console.log(msg)
  });
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugDumpAllNodesOfDocument({state, view}: { state: EditorState, view: EditorView }): boolean {
  const messages = debugTraverseNodeInorder(state.doc, 0, prettyPrintNodeShort)
  const indented = indentedMessagesFrom(messages)
  indented.forEach(function (msg) {
    console.log(msg)
  })
  return false;
}

/**
 * Prints the given document as string
 * @param state The state containing the document
 */
function nodeToString(node: PmNode): string {
  const messages = debugTraverseNodeInorder(node, 0, prettyPrintNodeShort)
  const indented = indentedMessagesFrom(messages)
  return indented.reduce((prev, cur) => prev + '\n' + cur, "");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugDumpRootNodeOfDocument({state, view}: { state: EditorState, view: EditorView }): boolean {
  const docAsString = nodeToString(state.doc);
  console.log(docAsString);
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugPrintAnchorPosition({state, view}: { state: EditorState, view: EditorView }): boolean {
  const sel = state.selection
  console.log(`From: ${sel.head}`)
  console.log(`Anchor: ${sel.anchor}`)
  console.log(`From: ${sel.from}`)
  console.log(`To: ${sel.to}`)
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function changeNodeAttribute({state, view}: { state: EditorState, view: EditorView }): boolean {

  const from = state.selection.$from;
  const fromDepth = findLowestDepth(from, (node => node.attrs.logicalBlock === true));
  const fromNode = from.node(fromDepth);
  const newAttrs = cloneDeep(fromNode.attrs);
  // Do something with newAttrs
  const transaction = state.tr;
  if (view.dispatch) {
    transaction.setNodeMarkup(from.before(fromDepth), undefined, newAttrs).setMeta(ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE, true);
    view.dispatch(transaction);
  }
  return false;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function printLogicalBlockNodeAttributes({state, view}: { state: EditorState, view: EditorView }): boolean {

  const from = state.selection.$from;
  const fromDepth = findLowestDepth(from, (node => node.attrs.logicalBlock === true));
  const fromNode = from.node(fromDepth);
  console.log(fromNode.attrs.semanticType, JSON.stringify(fromNode.attrs));

  return false;
}

/**
 * Stringifies a AbstractBlockViewModel. Cycles between references are broken up by only storing the parent guid of a given block
 * @param model The model to stringify
 */
function blockModelToString(model: AbstractBlockViewModel | null): string {
  return JSON.stringify(model, function (key: any, value: any) {
    if (key === 'parent') {
      return value.guid;
    }
    return value;
  });
}

/**
 * Stringifies a list of AbstractBlockViewModels. Cycles between references are broken up by only storing the parent guid of a given block
 * @param model The list of models to stringify
 */
function blockModelsToString(models: AbstractBlockViewModel[] | null): string {
  if (models === null) {
    return '[]'
  } else {
    return models.map(model => blockModelToString(model)).join(',');
  }
}

/**
 * Stringifies the given AbstractBlockViewModel, breaking the inherent cycle of the parent relationship by substituting the parents id
 * @param model
 */
function stringyfyAbstractBlockViewModel(model: AbstractBlockViewModel | null): string {
  return JSON.stringify(model, function (key: any, value: any) {

    if (key === 'parent') {
      return value.guid;
    }
    return value;
  });
}

export {
  debugDumpElementsAtAllDocumentPositions,
  debugPrintStructureAtAnchor,
  debugPrintStructureAtAnchorLevel,
  debugPrintProseMirrorStructure,
  debugPrintAnchorPosition,
  debugDumpAllNodesOfDocument,
  debugDumpRootNodeOfDocument,
  debugTraverseFragmentInorder,
  prettyPrintNodeShort,
  prettyPrintFragment,
  indentedMessagesFrom,
  blockModelToString,
  blockModelsToString,
  nodeToString,
  stringyfyAbstractBlockViewModel,
  changeNodeAttribute,
  printLogicalBlockNodeAttributes
}
